import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import callApi from "../../ApiCall";
import "./Payment.css";
import leaf1 from "../../assets/images/L1.png";
import { AiOutlineCheck } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import Constants from "../../Constants";
const PaymentComponent = () => {
  const [isINR, setIsINR] = useState(true);
  const [isMonthly, setIsMonthly] = useState(true);

  const [plansList, setPlansList] = useState([]);
  const navigate = useNavigate();
  const getPlans = async () => {
    const response = await callApi({
      apiURL: Constants.API.getSubscriptionPlans,
    });
    if (response?.success) {
      setPlansList(response.data.planList);
    }
  };

  const [userInfo, setUserInfo] = useState({});
  const getEmployeeDetail = async () => {
    const response = await callApi({
      apiURL: Constants.API.getUserDetails,
    });
    if (response?.success) {
      // if (response.data.userData.RoleMasterId == 1) {
      //   setIsSubscribed(1);
      // } else if (response.data.userData.IsSubscribed == 1) {
      //   if (
      //     response.data.userData.IsOnBoarded == 0 ||
      //     !response.data.userData.IsOnBoarded
      //   ) {
      //     navigate("/onboading/property");
      //   } else {
      //     setIsSubscribed(1);
      //   }
      // } else {
      //   setIsSubscribed(0);
      // }
      setUserInfo(response.data.userData);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    getPlans();
    getEmployeeDetail();
  }, []);
  const makePayment = async (plan) => {
    const stripe = await loadStripe(
      "pk_test_51NTlbdSGTxcOKy5gS4q1Ktjt308wQEvNoK6AMrF95q9g0lZrxB0ikmit9vt12j5rqfUbRta3x5ZcbqOoNpZa9WOn00xMVhObXR"
    );
    const price = isINR
      ? isMonthly
        ? plan.MonthlyPriceINR
        : plan.AnnualPriceINR
      : isMonthly
      ? plan.MonthlyPriceUSD
      : plan.AnnualPriceUSD;
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split("/payment")[0];

    const params = {
      productMasterId: plan.ProductMasterId,
      planId: plan.PlanMasterId,
      planName: plan.PlanName,
      price: price,
      isInr: isINR,
      isMonthly: isMonthly,
      emailAddress: userInfo.EmailAddress,
      mobile: userInfo.MobileNumber,
      baseUrl: baseUrl,
      productSubscriberId: userInfo.ProductSubscriberId,
      maxUsers: plan.MaxUsers,
    };
    localStorage.removeItem("sessionId");
    const response = await callApi({
      apiURL: Constants.API.createSubscription,
      requestBody: params,
    });
    if (response?.success) {
      localStorage.setItem("sessionId", response.data.sessionId);
      const result = stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });
      if (result.error) {
        console.log("error", result.error);
      }
    } else {
      console.log("error", response.error);
    }
  };

  const subscribeForFreeTrail = async (plan) => {
    const params = {
      planId: plan.PlanMasterId,
      noOfDays: plan.FreeTrialDays,
      maxUsers: plan.MaxUsers,
      productMasterId: plan.ProductMasterId,
      productSubscriberId: userInfo.ProductSubscriberId,
    };
    const response = await callApi({
      apiURL: Constants.API.freeTrial,
      requestBody: params,
    });
    if (response?.success) {
      localStorage.removeItem("sessionId");
      navigate("/payment-success");
    } else {
      console.log("error", response.error);
    }
  };

  return (
    <div>
      <div>
        <center>
          <h1 className="fourth-heading mt-20">Pricing</h1>
          <p className="fourth-text">
            Begin at no cost, and subsequently select your preferred plan.
          </p>
        </center>
        <div className="option">
          <div className="switch_">
            <main>
              <div className="_label">Monthly</div>
              <div className="switch-container">
                <input
                  type="checkbox"
                  id="switch1"
                  className="switch-input"
                  onChange={(e) => setIsMonthly(!isMonthly)}
                  checked={!isMonthly}
                />
                <label htmlFor="switch1" className="switch-label">
                  <span className="switch-text" />
                  <span className="switch-text" />
                </label>
              </div>
              <div className="_label">Annual</div>
            </main>
          </div>
          <div className="switch_">
            <main>
              <div className="_label">INR</div>
              <div className="switch-container">
                <input
                  type="checkbox"
                  id="switch2"
                  className="switch-input"
                  onChange={(e) => setIsINR(!isINR)}
                  checked={!isINR}
                />
                <label htmlFor="switch2" className="switch-label">
                  <span className="switch-text" />
                  <span className="switch-text" />
                </label>
              </div>
              <div className="_label">USD</div>
            </main>
          </div>
        </div>
        <div className="subscription-cards">
          {plansList.map(
            (plan) =>
              (plan.FreeTrialDays == 0 || userInfo.FreeTrialComplete != 1) && (
                <div key={plan.PlanMasterId} className="subscription-card">
                  <div>
                    <div className="firstcard1">
                      <h1 className="fourth-text">{plan.PlanName}</h1>
                      <img src={leaf1} />
                    </div>
                    <div>
                      <span className="inr">{isINR ? "INR " : "USD "}</span>
                      <span className="price">
                        {isINR
                          ? isMonthly
                            ? plan.MonthlyPriceINR
                            : plan.AnnualPriceINR
                          : isMonthly
                          ? plan.MonthlyPriceUSD
                          : plan.AnnualPriceUSD}
                      </span>
                      {plan.FreeTrialDays > 0 ? (
                        <span className="fourth-text">
                          {" / " + plan.FreeTrialDays + " Days"}
                        </span>
                      ) : (
                        <span className="fourth-text">
                          {isMonthly ? " / Month" : " / Year"}
                        </span>
                      )}
                    </div>
                    <ul>
                      {JSON.parse(plan.FeaturesList).map((feature, index) => (
                        <div className="flex ab ml-4">
                          <span className="mt-[4px]">
                            <AiOutlineCheck />
                          </span>
                          <span className="ml-2 text-[1rem]">
                            {feature.FeatureName}
                          </span>
                        </div>
                      ))}
                    </ul>
                  </div>
                  <div className="f-card-button1-container">
                    <button
                      className="f-card-button1"
                      onClick={() => {
                        plan.FreeTrialDays > 0
                          ? subscribeForFreeTrail(plan)
                          : makePayment(plan);
                      }}
                    >
                      Try Now
                    </button>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentComponent;
