import { styled, alpha } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import EmployeeMasterPopup from "./EmployeeMasterPopup";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import callApi from "../../ApiCall";
import * as XLSX from "xlsx";
import ExportFile from "../../common/ExportFile/ExportFile";
import { jsPDF } from "jspdf";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DeleteConfirmationDialog from "../../common/DeleteConfirmation/confirmdelete";
import "./EmployeeMaster.css";
import { toast,Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";
import SearchBar from "../../common/SearchBar/SearchBar";
import AddButton from "../../common/Button/Button";


const MenuItemWrapper = styled("li")(() => ({
  padding: "8px 8px",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F75555",
    color: "#FFFFFF",
    "img": {
      filter: "invert(92%) sepia(4%) saturate(8%) hue-rotate(126deg) brightness(138%) contrast(200%)",
    }
  },
  transition: "all 0.3s ease", 
}));


const IconWrapper = styled("span")(() => ({
  fontSize: "16px",
  marginRight: "8px",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));
export default function EmployeeMaster(props) {
  const columns = [
    { field: "id", headerName: "Sr No", flex: 1 },
    { field: "EmployeeName", headerName: "Employee Name", flex: 1 },

    { field: "Designation", headerName: "Designation", flex: 1 },
    { field: "MobileNumber", headerName: "Mobile Number", flex: 1 },
    { field: "BloodGroup", headerName: "Blod Group", flex: 1 },
    { field: "Gender", headerName: "Gender", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            color: params.row.status === "Active" ? "#0ABE75" : "#F75555",
          }}
        >
          {params.row.status}
        </div>
      ),
    },
    props.currentMenu.Edit || props.currentMenu.Delete
      ? {
          field: "actions",
          headerName: "Actions",
          flex: 1,
          renderCell: (params) => (
            <div>
              <IconButton
                color="primary"
                aria-label="actions"
                onClick={(event) => handleMenuOpen(event, params.row)}
              >
                <MoreVertIcon style={{ color: "gray" }} />
              </IconButton>
              <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{
                  "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                    {
                      boxShadow: "none",
                      backgroundColor: "#EEE",
                      borderRadius: "8px",
                    },
                  "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                    paddingTop: "0",
                    paddingBottom: "0",
                  },
                }}
              >
                {props.currentMenu.Edit ? (
                  <MenuItemWrapper
                    onClick={() => {
                      handleMenuSelect("edit");
                    }}
                    // sx={{ background: "#EEE" }}
                  >
                    <IconWrapper>
                      <img src={edit} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Edit</span>
                  </MenuItemWrapper>
                ) : null}

                {props.currentMenu.Delete ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("delete")}>
                    <IconWrapper>
                      <img src={trash} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Delete</span>
                  </MenuItemWrapper>
                ) : null}
              </Menu>
            </div>
          ),
        }
      : {},
  ];
  const [selectedStatus, setSelectedStatus] = useState("Active");
  const [selectedProductForDelete, setSelectedProductForDelete] =
    useState(null);
  const [showEmploeyeePopup, setShowEmployeePopup] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [tempselectedRowForActions, settempSelectedRowForActions] =
    useState(null);
  const [selectedRowForActions, setSelectedRowForActions] = useState(null);
  const [isAddMode, setIsAddMode] = useState(true);
  const initialSortedRows = employee;
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  // filtering based on status
  const selectedStatusData =
    selectedStatus !== "all"
      ? sortedRows.filter((val) => val?.status === selectedStatus)
      : sortedRows;
  const totalPages = Math.ceil(selectedStatusData.length / pageSize);

  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleDeleteClick = (selectedRow) => {
    setSelectedProductForDelete(selectedRow);
    setShowDeleteConfirmation(true);
  };
  const handleConfirmDelete = async (selectedRow) => {
    try {
      const params = {
        employeeMasterId: selectedRow.EmployeeMasterId,
        updatedDate: selectedRow.UpdatedDate,
      };

      const response = await callApi({
        apiURL: "employeemaster/deleteEmployee",
        requestBody: params,
      });

      if (response?.success) {
        toast.success(
          <div className="custom_Toast">
            <h5>Employee Deleted</h5>
            Employee has been Deleted successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
           
            theme: "colored",
            hideProgressBar: true,
            className: "toast_pop",
            autoClose: 1500,
            closeButton: true,
            transition: Zoom,
            
            onClose: () => {
              getUserDetails();
            },
          }
        );
        console.log("Feature deleted successfully");
      } else {
        console.error("Error deleting feature:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setShowDeleteConfirmation(false);
    }
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    settempSelectedRowForActions(row);
    setSelectedProductForDelete(row);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRowForActions(null);
    setIsMenuOpen(false);
  };

  const handleMenuSelect = (option) => {
    if (option === "edit") {
      setShowEmployeePopup(true);
      setSelectedRowForActions(tempselectedRowForActions);
      handleUpdateClick(tempselectedRowForActions);
    } else if (option === "delete") {
      handleDeleteClick(selectedProductForDelete);
    }
  };

  const handleUpdateClick = (selectedRow) => {
    setIsAddMode(false);
  };

  const handleAddProductClick = () => {
    setShowEmployeePopup(true);
    setIsAddMode(true);
  };

  const getUserDetails = async () => {
    try {
      const response = await callApi({
        apiURL: "employeemaster/getEmployee",
      });

      if (response?.success) {
        const { employeeList = [] } = response.data;
        const mappedData = employeeList.map((item, index) => ({
          ...item,
          id: index + 1,
          PropertyLocationId: item.PropertyLocationId,
          FirstName: item.FirstName,
          LastName: item.LastName,
          MiddleName: item.MiddleName,
          EmployeeName: item.EmployeeName,
          DOB: item.DOB == null ? "" : moment(item.DOB).format("DD-MM-YYYY"),
          Gender: item.Gender,
          EmailAddress: item.EmailAddress,
          Designation: item.Designation,
          MobileNumber: item.MobileNumber,
          Country: item.Country,
          State: item.State,
          City: item.City,
          PresentAddress: item.PresentAddress,
          PermanentAddress: item.PermanentAddress,
          Zip: item.Zip,
          PermanentZip: item.PermanentZip,
          BloodGroup: item.BloodGroupName,
          PanNo: item.PanNo,
          AadharNo: item.AadharNo,
          MaritalStatus: item.MaritalStatus,
          DOJ: item.DOJ == null ? "" : moment(item.DOJ).format("DD-MM-YYYY"),
          PDOC: item.PDOC == null ? "" : moment(item.PDOC).format("DD-MM-YYYY"),
          DOC: item.DOC == null ? "" : moment(item.DOC).format("DD-MM-YYYY"),
          DOR: item.DOR == null ? "" : moment(item.DOR).format("DD-MM-YYYY"),
          PDOL: item.PDOL == null ? "" : moment(item.PDOL).format("DD-MM-YYYY"),
          RelievingDate:
            item.RelievingDate == null
              ? ""
              : moment(item.RelievingDate).format("DD-MM-YYYY"),
          DOA: item.DOA == null ? "" : moment(item.DOA).format("DD-MM-YYYY"),
          DOL: item.DOL == null ? "" : moment(item.DOL).format("DD-MM-YYYY"),
          DepartmentId: item.DepartmentId,
          PermanentCountry: item.PermanentCountry,
          PermanentState: item.PermanentState,
          PermanentCity: item.PermanentCity,
          WeekDayOff: item.WeekDayOff,
          CardNo: item.CardNo,
          ResourceType: item.ResourceType,
          ReportingTo: item.ReportingTo,
          status: item.Active === 1 ? "Active" : "Inactive",
        }));
        console.log("mappedData", mappedData);

        setEmployee(mappedData);
      } else {
        console.error("Error fetching guest activity data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);
  const handleRowClick = (selectedEmployee) => {
  
    setSelectedEmployee(selectedEmployee);
    setShowEmployeePopup(true);
  };
  const handleCloseButtonClick = () => {
    getUserDetails();
    setIsMenuOpen(false);
    setSelectedEmployee(null);
    setShowEmployeePopup(false);
    setSelectedRowForActions(null);
  };
  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };
  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      return (
        <span className="arrow-icon">
          {sortOrder === "asc" ? (
            <i className="fa-solid fa-arrow-up" />
          ) : (
            <i className="fa-solid fa-arrow-down" />
          )}
        </span>
      );
    }
    return null;
  };
  useEffect(() => {
    if (employee.length > 0) {
      setSortedRows(employee);
    }
  }, [employee.length > 0]);

  useEffect(() => {
    if (sortedColumn) {
      const sorted = employee.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
  }, [sortedColumn, sortOrder, employee]);

  // Search
  const [filtering, setFiltering] = useState("");
  const handleSearchfilter = (event) => {
    setFiltering(event.target.value);
    setPage(1);
  };
  useEffect(() => {
    const searched = employee.filter((item) => {
      if (!filtering) {
        return true;
      } else {
        return (
          item.EmployeeName.toLowerCase().includes(filtering.toLowerCase()) ||
          item.Designation.toLowerCase().includes(filtering.toLowerCase()) ||
          item.MobileNumber.toLowerCase().includes(filtering.toLowerCase()) ||
          item.Gender.toLowerCase().includes(filtering.toLowerCase()) ||
          (item.BloodGroup !== null &&
            item.BloodGroup.toLowerCase().includes(filtering.toLowerCase()))
        );
      }
    });
    setSortedRows(searched);
  }, [filtering, employee]);

  const paginatedRows = selectedStatusData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  //export PDF and Excel

  const handleExportClick = () => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      const fileName = `Employee_${formattedDate.replace(/ /g, "")}.xlsx`;
      const worksheetName = `Employee Details`;

      if (paginatedRows?.length > 0) {
        const exportData = paginatedRows.map((row, index) => ({
          "Sr No.": index + 1,
          "Employee Name": row.EmployeeName,
          Designation: row.Designation,
          "Mobile No.": row.MobileNumber,
          "Blood Group": row.BloodGroup,
          Gender: row.Gender,
          Status: row.status,
        }));

        const workSheet = XLSX.utils.json_to_sheet(exportData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, worksheetName);
        XLSX.writeFile(workBook, fileName);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };
  const handleExportPDF = () => {
    const doc = new jsPDF("p");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    const fileName = `Employee_${formattedDate.replace(/ /g, "")}.pdf`;

    if (paginatedRows.length > 0) {
      let tableData = paginatedRows.map((row, index) => {
        return [
          index + 1,
          row.EmployeeName,
          row.Designation,
          row.MobileNumber,
          row.BloodGroup,
          row.Gender,
          row.status,
        ];
      });

      // Add title
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Employee Details", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      // Add table to PDF
      doc.autoTable({
        head: [
          [
            "Sr No.",
            "Employee Name",
            "Designation",
            "Mobile No.",
            "Blood Group",
            "Gender",
            "Status",
          ],
        ],
        body: tableData,

        theme: "grid",
      });

      // Add date and time at bottom right
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        formattedDate,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );

      doc.save(fileName);
    } else {
      // showToast("ERROR", "No Data To Export");
    }
  };
  const handleStatusChange = (event, newStatus) => {
    setSelectedStatus(newStatus);
    setCurrentPage(1);
  };

  return (
    <>
      {showEmploeyeePopup ? (
        <EmployeeMasterPopup
          onClose={handleCloseButtonClick}
          isAddMode={isAddMode}
          selectedRowForActions={selectedRowForActions}
          selectedEmployee={selectedEmployee}
        />
      ) : (
        <div className="employeemaster_MainPae">
          <div className="employeemaster_sect-1">
            <div className="employeemaster_manage">
              <h1 className="manage_page_heading">Manage Employees</h1>
            </div>
            <div className="export_employee">
            <ExportFile
              handleExportClick={handleExportClick}
              filteredRows={employee}
              handleExportPDF={handleExportPDF}
            />
            &nbsp;&nbsp;&nbsp;
            {props?.currentMenu?.Add ? (
              <>
              <AddButton buttonText={"Add Employee"}  onClick={handleAddProductClick}/>
              </>
            ) : (
              <></>
            )}</div>
          </div>
          <div className="employeemasterTable">
            <div className="Table">
              <div className="employeemaster_sect-2">
                <SearchBar 
                 value={filtering}
                 onChange={handleSearchfilter}
                />
                <div className="employeemaster_toggle">
                  <ToggleButtonGroup
                    className="ToggleButtonGroups"
                    value={selectedStatus}
                    exclusive
                    onChange={handleStatusChange}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value="all"
                      aria-label="all"
                      disabled={selectedStatus === "all"}
                      sx={{
                        textTransform: "none",
                        padding: "8px 16px",
                        borderRadius: "8px 0px 0px 8px",
                      }}
                    >
                      All
                    </ToggleButton>
                    <ToggleButton
                      value="Active"
                      aria-label="Active"
                      disabled={selectedStatus === "Active"}
                      sx={{ textTransform: "none", padding: "8px 16px" }}
                    >
                      Active
                    </ToggleButton>
                    <ToggleButton
                      value="Inactive"
                      aria-label="Inactive"
                      disabled={selectedStatus === "Inactive"}
                      sx={{
                        textTransform: "none",
                        padding: "8px 16px",
                        borderRadius: "0px 8px 8px 0px",
                      }}
                    >
                      Inactive
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
              <DeleteConfirmationDialog
                open={showDeleteConfirmation}
                handleClose={() => {
                  setShowDeleteConfirmation(false);
                  handleMenuClose();
                }}
                handleConfirm={() => {
                  handleConfirmDelete(selectedProductForDelete);
                  handleMenuClose();
                }}
                product={"Employee"}
              />
              <div className="empdatgrid">
                <div className="employeemaster-table">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns.map((column, i) => (
                            <TableCell
                              key={i}
                              className="tableHeaderCell"
                              onClick={() => handleSortClick(column.field)}
                            >
                              {column.headerName}
                              {renderArrowIcon(column.field)}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paginatedRows.map((row, i) => (
                          <TableRow key={i}>
                            {columns.map((column) => (
                              <TableCell
                                key={i}
                                className="tableBodyCell"
                                onClick={() => {
                                  if (column?.field !== "actions") {
                                    handleRowClick(row);
                                  } else {
                                    column.renderCell({ row });
                                  }
                                }}
                              >
                                {column.renderCell
                                  ? column.renderCell({ row })
                                  : row[column.field]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Pagination
                    count={totalPages}
                    page={page}
                    hideNextButton
                    hidePrevButton
                    onChange={(event, value) => setPage(value)}
                    renderItem={(item) => (
                      <PaginationItem     
                        {...item}
                        page={formattedPageNumber(item.page)}
                        className={item.selected ? "pagination-selected" : ""}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
