import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import callApi from "../../../ApiCall";
import "./PaymentSuccess.css";
import success from "../../../assets/images/success.png";
import Constants from "../../../Constants";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  const [isSuccess, setIsSuccess] = useState(false);
  const savePaymentInfo = async () => {
    let sessionId = localStorage.getItem("sessionId");
    if (sessionId) {
      const response = await callApi({
        apiURL: Constants.API.paymentSuccess,
        requestBody: { sessionId: sessionId },
      });
      if (response?.success) {
        setIsSuccess(true);
        localStorage.removeItem("sessionId");
      }
    } else {
      setIsSuccess(true);
    }
  };

  useEffect(() => {
    savePaymentInfo();
  }, []);

  return (
    <div className="container">
      {isSuccess && (
        <>
          <center>
            <img src={success} style={{ width: "100px", height: "100px" }} />
            <h1 className="fourth-heading">Payment Successful</h1>
            <p className="fourth-text">Thank you for your payment!</p>
            <p>Your subscription has been successfully processed.</p>
            <p>You can now enjoy our premium features.</p>
            <p>
              If you have any questions or issues, please contact our support
              team.
            </p>
          </center>
          <div className="button1-container">
            <button
              className="button1"
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Go to Dashboard
            </button>
          </div>
        </>
      )}
      {!isSuccess && (
        <>
          <center>
            <img src={success} style={{ width: "100px", height: "100px" }} />
            <h1 className="fourth-heading">Please Wait ...</h1>
            <p>
              If you have any questions or issues, please contact our support
              team.
            </p>
          </center>
        </>
      )}
    </div>
  );
};

export default PaymentSuccess;
