  import React, { useEffect, useState } from "react";
  import bg2 from "../../../assets/images/Bg (2).png";
  import Logo from "../../../assets/images/Logo.png";
  import "./UserLogin.css";

  import { useNavigate } from "react-router-dom";
  import callApi from "../../../ApiCall";
  import { encrypt } from "../../../CryptoUtil";
  import ImageSlider from "../../Slider/Slider";
  import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  const UserLogin = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(true);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [userMasterId, setUserMasterId] = useState("");

    const onSubmitClick = async () => {
      if (phoneNumber.trim() != "" && password.trim() != "") {
        const params = {
          mobileNumber: phoneNumber,
          password: encrypt(password),
        };
        const response = await callApi({
          apiURL: "userAuth/login",
          requestBody: params,
        });
        if (response?.success) {
          
          if (response.data.otpVerified) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("userId", response.data.userMasterId);
            localStorage.setItem("isSubscribed", response.data.isSubscribed);
            localStorage.setItem("menus", JSON.stringify(response.data.menus));
            localStorage.setItem("isSettings", 0);
            localStorage.setItem("isOnboarded", response.data.isOnboarded);
            if (response.data.isSubscribed) {
              navigate("/dashboard");
            } else {
              navigate("/payment");
            }
          } else {
            setUserMasterId(response.data.userMasterId);
            setIsLogin(false);
          }
        }
      } else {
        phoneNumber.trim() == "" && setPhoneNumberError("Field is required.");
        password.trim() == "" && setPasswordError("Field is required.");
      }
    };

    const onSubmitOtpClick = async () => {
      if (otp.trim() != "" && otpError === "") {
        const params = {
          userMasterId: userMasterId,
          otp: otp,
        };
        const response = await callApi({
          apiURL: "userAuth/otpVerification",
          requestBody: params,
        });
        if (response?.success) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userId", response.data.userMasterId);
          localStorage.setItem("isSubscribed", response.data.isSubscribed);
          localStorage.setItem("menus", JSON.stringify(response.data.menus));
          localStorage.setItem("isSettings", 0);
          localStorage.setItem("isOnboarded", response.data.isOnboarded);

          if (response.data.isSubscribed) {
            navigate("/dashboard");
          } else {
            navigate("/payment");
          }
        }
      } else {
        otp.trim() == "" && setOtpError("Field is required.");
      }
    };

    useEffect(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("isSubscribed");
      localStorage.removeItem("menus");
      localStorage.removeItem("isOnboarded");
    }, []);

    const handlePasswordEnter = (e) => {
      if (e.key === "Enter") {
        e.preventDefault(); // Prevent default form submission
        onSubmitClick(); // Call the submit function
      }
    };

    return (
      <div>
        {isLogin ? (
          <div style={{ display: "flex" }}>
            <section className="sec-left">
              <ImageSlider images={[bg2, bg2, bg2]} />
            </section>
            <section className="sec-right">
              <div style={{ marginTop: "8%" }}>
                <div className="loginsec">
                  <img id="logo" src={Logo} alt="Logo" />
                  <p id="typo1">Login</p>
                  <p id="typo2">Glad to see you back again</p>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      onSubmitClick();
                    }}
                  >
                    {" "}
                    {/* Form starts here */}
                    <div
                      style={{
                        display: "flex",
                        width: "70%",
                        flexDirection: "column",
                      }}
                    >
                      <div id="number">
                        <label
                          htmlFor="phone-number"
                          style={{ fontFamily: "urbanistl" }}
                        >
                          Mobile Number<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <br />
                        <input
                          className="inputn"
                          type="tel"
                          id="phone-number"
                          placeholder="Enter Mobile number"
                          maxLength={10}
                          value={phoneNumber}
                          onChange={(e) => {
                            const alphaNumericExp = /^[0-9]*$/;
                            if (alphaNumericExp.test(e.target.value)) {
                              setPhoneNumber(e.target.value);
                              if (e.target.value.length == 10) {
                                setPhoneNumberError("");
                              } else {
                                setPhoneNumberError(
                                  "Mobile number requires 10 digit value."
                                );
                              }
                            } else {
                              e.target.value == "" && setPhoneNumber("");
                              setPhoneNumberError(
                                "Mobile number requires 10 digit value."
                              );
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              // Optionally, you can move the focus to the next input field here if needed
                            }
                          }}
                        />
                        {phoneNumberError != "" && (
                          <p className="errorl">{phoneNumberError}</p>
                        )}
                      </div>
                      <div id="password">
                        <label
                          htmlFor="phone-number"
                          style={{ fontFamily: "urbanistl" }}
                        >
                          Password<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <br />
                        <input
                          className="inputn"
                          type="password"
                          id="password1"
                          placeholder="Enter Password"
                          value={password}
                          maxLength={200}
                          onChange={(e) => {
                            if (e.target.value != "") {
                              setPassword(e.target.value);
                              setPasswordError("");
                            } else {
                              e.target.value == "" && setPassword("");
                              setPasswordError("Field is required.");
                            }
                          }}
                          onKeyDown={(e) => handlePasswordEnter(e)}
                        />
                        {passwordError != "" && (
                          <p className="error">{passwordError}</p>
                        )}
                      </div>
                      <button
                        id="ca-button"
                        type="submit" // Ensure button triggers form submission
                      >
                        Submit
                      </button>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <p id="b-para">
                          Don’t have an account?{" "}
                          <span>
                            <a
                              onClick={() => {
                                navigate("/register");
                              }}
                              style={{ textDecoration: "none", color: "green" }}
                            >
                              Create new
                            </a>
                          </span>
                        </p>
                      </div>
                    </div>
                  </form>{" "}
                  {/* Form ends here */}
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <section className="sec-left">
              <ImageSlider images={[bg2, bg2, bg2]} />
            </section>
            <section className="sec-right">
              <div style={{ marginTop: "15%" }}>
                <div>
                  <img id="logo" src={Logo} alt="Logo" />
                  <p id="typo1">Login</p>
                  <p id="typo3">
                    Please provide otp for account verification which you will get
                    sms on your mobile - <b>{phoneNumber}</b>.
                  </p>
                  <div>
                    <div id="number">
                      <label
                        htmlFor="phone-number"
                        style={{ fontFamily: "urbanistl" }}
                      >
                        Otp<sup style={{ color: "red" }}>*</sup>
                      </label>
                      <br />
                      <input
                        className="inputn"
                        type="tel"
                        id="phone-number"
                        placeholder="Enter Otp"
                        maxLength={4}
                        value={otp}
                        onChange={(e) => {
                          const alphaNumericExp = /^[0-9]*$/;
                          if (alphaNumericExp.test(e.target.value)) {
                            setOtp(e.target.value);
                            if (e.target.value.length == 4) {
                              setOtpError("");
                            } else {
                              setPhoneNumberError("OTP requires 4 digit value.");
                            }
                          } else {
                            e.target.value == "" && setOtpError(e.target.value);
                            setPhoneNumberError("OTP requires 4 digit value.");
                          }
                        }}
                      />
                      {otpError != "" && (
                        <p className="error">{phoneNumberError}</p>
                      )}
                    </div>
                    <button
                      id="ca-button"
                      onClick={() => {
                        onSubmitOtpClick();
                      }}
                    >
                      Submit
                    </button>
                    <div className="newacc">
                      <p id="b-para">
                        Don’t have an account?{" "}
                        <span>
                          <a
                            onClick={() => {
                              navigate("/register");
                            }}
                            style={{ textDecoration: "none", color: "green" }}
                          >
                            Create new
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    );
  };

  export default UserLogin;
