import React, { useState } from 'react';
import SearchIcon from "@mui/icons-material/Search";
import { styled,alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  borderRadius: "8px",
  padding: "8px 16px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "300px",
  },
}));

const SearchIconWrapper = styled("div")(() => ({
  padding: "5px 8px",

  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    fontFamily: "Urbanist, Arial, sans-serif",
    "& .MuiInputBase-input": {
      transition: theme.transitions.create("width"),
      width: "100%",
      fontWeight: 600,
      [theme.breakpoints.up("sm")]: {
        width: "15ch",
        "&:focus": {
          width: "15ch",
        },
      },
    },
  }));

export default function SearchBar({ value, onChange }) {
    console.log(value)
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocused(false);
  };

  return (
      <div>
        <Search>
          <SearchIconWrapper>
            <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.25)"}} />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={isSearchFocused ? "" : "Search"}
            inputProps={{ "aria-label": "search" }}
            value={value}
            onChange={onChange}
            onFocus={handleSearchFocus}
            onBlur={handleSearchBlur}
          />
        </Search>
      </div>
  );
}
