import React, { useEffect, useState } from 'react'
import './DailyScheduleSummary.css'
import * as XLSX from "xlsx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ExportFile from "../../common/ExportFile/ExportFile";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import callApi from "../../ApiCall";
import Constants from "../../Constants";
import SearchBar from "../../common/SearchBar/SearchBar";
import DateSelect from "../../common/DatePicker/Datepicker";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const GuestShedule = () => {
  const columns = [
    { field: "id", headerName: "Sr No", flex: 1 },
    { field: "Guest", headerName: "Guest", flex: 1 },
    { field: "ActivityName", headerName: "ActivityName", flex: 1 },
    { field: "AssignedEmployee", headerName: "AssignedEmployee", flex: 1 },
    
  ]

  const [page, setPage] = useState(1);
  const [guestScheduleData,setGuestScheduleData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  // get api
  const getDatewiseGuestSchedule = async(date) => {
    if(!date){
      console.error("No date selected for fetching data")
      return
    }
    try {
      const formattedDate = new Date(date).toISOString().split('T')[0];
      const response = await callApi({
        apiURL: Constants.API.getDatewiseGuestSchedule,
        requestBody: {selectedDate: formattedDate}
      })
      if(response ?.success) {
        const {dateWiseGuestScheduleList = []} = response.data
        const mappedData = dateWiseGuestScheduleList.map((item,index) => ({
          id: index+1,
          ...item
        }))
        console.log(mappedData);
        
        setGuestScheduleData(mappedData)
      }
      
    } catch (error) {
      console.error("Error during API call:", error);
    }
  }

  useEffect(() => {
    getDatewiseGuestSchedule(selectedDate)
  
}, [selectedDate])

const handleDateChange = (date) => {
  setSelectedDate(date);
}

// Export PDF and Excel
const handleExportClick = (guestScheduleData) => {
  try {
    const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      const fileName = `GuestDietPlans_${formattedDate.replace(/ /g, " ")}.xlsx`;
      const worksheetName = `DailySchedules`;

      if (guestScheduleData?.length > 0) {
        const exportData = guestScheduleData.map((row) => ({
          "Sr No": row.id,
          "Guest": row.Guest,
          "Activityname":row.ActivityName,
          
          "AssignedEmployee" : row.AssignedEmployee,
         

        }));
        const workSheet = XLSX.utils.json_to_sheet(exportData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, worksheetName);
        XLSX.writeFile(workBook, fileName);
      }
    
  } catch (error) {
    console.error("Error exporting to Excel:", error);
    
  }

}

const handleExportPDF = () =>{
  const doc = new jsPDF("p");
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  const fileName = `DailySchedule_${formattedDate.replace(/ /g, "")}.pdf`;

  const temp = columns.filter((val) => val?.field !== "actions");
    const tableHeaders = [
      "Sr No",
      "Guest",
      "Activityname",
      
      "AssignedEmployee",
     

    ];
    if (guestScheduleData.length > 0) {
      let tableData = guestScheduleData.map((row) => {
        return [row.id, row.Guest, row.Activityname,
          row.AssignedEmployee
        ];
      });

      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("DailySchedule", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
        theme: "grid",
      });

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        formattedDate,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );

      doc.save(fileName);

} else{
  alert("No data to export");

}
}

// pagination sorting and searching
const initialSortedRows = guestScheduleData;
const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const pageSize = 10;
  const totalPages = Math.ceil(sortedRows.length / pageSize);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

  const handleSortClick = (column) => {

  }

  const renderArrowIcon = () => {

  }

  useEffect(() =>{
    if(guestScheduleData.length > 0){
      setSortedRows(guestScheduleData)
    }
  },[guestScheduleData.length > 0])

  useEffect(() => {
    if(sortedColumn){
      const sorted = guestScheduleData.slice().sort((a,b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if(aValue < bValue){
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      })
      setSortedRows(sorted)
    }
  },[sortedColumn,sortOrder,guestScheduleData])

  // SEARCH
  const [filtering, setFiltering] = useState("");
  const handleSearchfilter = (event) => {
    setFiltering(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    const searched = guestScheduleData.filter((item) => {
      if(!filtering){
        return true;
      } else {
        return(
          (item.Guest !== null && 
            item.Guest.toLowerCase().includes(
              filtering.toLowerCase()
            ) 
          ) || 
          (item.ActivityName !== null && 
            item.Guest.toLowerCase().includes(
              filtering.toLowerCase()
            ) 
          )
        )
      }
    })
    setSortedRows(searched)
  },[filtering, guestScheduleData])

  const paginatedRows = sortedRows.slice(startIndex, endIndex);

  return (
   <>
    <div className="propActivity_MainPae">
        <div className="propActivity_sect-1">
          <div className="propActivity_manage">
            <h1 className="manage_page_heading">Manage DailySchedule</h1>
          </div>
          <div className="export_dailyschedule">
          <div className="date-picker-container_dailyschedule">
        <div className="date-input-wrapper">
      <CalendarMonthIcon className="calendar-icon" />
      <DateSelect
      startDate={selectedDate}
      onChange={handleDateChange}
      format={"YYYY-MM-DD"}
    />
  </div>
          </div>
           &nbsp;&nbsp;&nbsp;
         <ExportFile
            handleExportClick={handleExportClick}
            filteredRows={guestScheduleData}
            handleExportPDF={handleExportPDF}
          />
         </div>
        </div>
        <div className="propActivityTable">
          <div className="Table">
            <SearchBar value={filtering} onChange={handleSearchfilter} />
             <div className="dailyschedule_data_grid">
              <div className="propActivity-table">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            className="dailyscheduletableHeaderCell"
                            onClick={() => handleSortClick(column.field)}
                          >
                            {column.headerName}
                            {renderArrowIcon(column.field)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedRows.map((row, i) => (
                        <TableRow key={i}>
                          {columns.map((column, ind) => (
                            <TableCell key={ind} className="tableBodyCell">
                              {column.renderCell
                                ? column.renderCell({ row })
                                : row[column.field]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={totalPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(event, value) => setPage(value)}
                  
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
   
   </>
  )
}


export default GuestShedule