import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import callApi from "../../ApiCall";
import PaymentComponent from "../Subscription/Payment";
import reservationdash from "../../assets/images/reservationDash.png";
import roomS from "../../assets/images/roomstatus.png";
import roomC from "../../assets/images/roomC.png";
import checkinG from "../../assets/images/checkinG.png";
import checkinImage from "../../assets/images/checkin.png";
import revenue from "../../assets/images/revenue.png";

import Constants from "../../Constants";
import GenderChart from "./GenderCharts";
import Setting2 from "../../../src/assets/images/setting2.svg";
import { SolarPower } from "@mui/icons-material";
import DashboardPopup from "./dashboardPopup";

const DashboardScreen = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [isSubscribed, setIsSubscribed] = useState("");
  const [genderCount, setGenderCount] = useState([]);

  const [isAddMode, setIsAddMode] = useState(true);
  const [dashboardPopup,setIsDashboardPopup] = useState(false);

  const getUserDetails = async () => {
    const response = await callApi({
      apiURL: Constants.API.getUserDetails,
    });
    if (response?.success) {
      if (response.data.userData.RoleMasterId == 1) {
        setIsSubscribed(1);
      } else if (response.data.userData.IsSubscribed == 1) {
        if (
          response.data.userData.IsOnBoarded == 0 ||
          !response.data.userData.IsOnBoarded
        ) {
          navigate("/onboading/property");
        } else {
          setIsSubscribed(1);
        }
      } else {
        setIsSubscribed(0);
      }
      setUserInfo(response.data.userData);
    } else {
      console.log("error");
    }
  };

  const getGenderCount = async () => {
    const response = await callApi({
      apiURL: Constants.API.getGenderCount,
    });
    if (response?.success) {
      setGenderCount(response.data.genderCount);
    } else {
      console.log("error");
    }
  };
  

  const handleDashboardSetting = () => {
    setIsAddMode(true);
    setIsDashboardPopup(true);
  }

  const handleDashboardPopupClose = async () => {
    setIsDashboardPopup(false);
    setIsAddMode(true);
  };


  useEffect(() => {
    getUserDetails();
    getGenderCount();
  }, []);

  return (
    <>
      {isSubscribed == 1 && (
        <div style={{ justifyContent: "space-around" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            
            }}
          >
            {
dashboardPopup && (
        <DashboardPopup
          onClose={handleDashboardPopupClose}
          isAddMode={isAddMode}
          // selectedDepartment={selectedDepartmentForUpdate}
          // getDepartment={getDepartment}
        />
      )}

            <img
              src={Setting2}
              alt="setting"
              style={{ marginLeft: "20px", maxWidth: "100%", height: "auto", cursor:'pointer',  border:"2px solid rgb(163 148 148)", borderRadius:'5px' }}  onClick={handleDashboardSetting}
            />
          </div>
          <br />
          <div style={{ display: "flex", justifyContent: "space-around" }} >
            <img
              src={reservationdash}
              style={{ width: "40%", height: "40%" }}
             
            />{" "}
            <img src={checkinG} />
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <img src={revenue} style={{ width: "40%", height: "40%" }} />
            <img src={roomC} />
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <img src={checkinImage} style={{ width: "40%", height: "40%" }} />{" "}
            <img src={roomS} />
          </div>
          <GenderChart />
        </div>
      )}
      {isSubscribed == 0 && <PaymentComponent userInfo={userInfo} />}
    </>
  );
};

export default DashboardScreen;
