import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import css from "./AboutProperty.module.scss";
import Logo from "../../../../src/assets/images/Logo.png";
import Onboarding_1 from "../../../../src/assets/images/Onboarding_1.png";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import callApi from "../../../ApiCall";
import Constants from "../../../Constants";

const AboutProperty = () => {
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [propertyLocationId, setPropertyLocationId] = useState(0);
  const [updatedDate, setUpdatedDate] = useState("");
  const [initialValues, setInitialValues] = useState({
    zipcode: "",
    address: "",
    gstNumber: "",
  });

  const navigate = useNavigate();

  const validationSchema = Yup.object({
    zipcode: Yup.string()
      .required("Enter valid pincode.")
      .matches(/^[0-9]{6}$/, "Enter valid pincode."),
    address: Yup.string().required("Address cannot be empty."),
  });

  const handleZipcodeChange = async (value, setFieldValue, setFieldError) => {
    setFieldValue("zipcode", value);
    setCity("");
    setState("");
    setCountry("");
    if (value.length === 6) {
      try {
        const response = await axios.get(
          `https://api.postalpincode.in/pincode/${value}`
        );
        if (response.status === 200 && response.data[0].Status === "Success") {
          setFieldError("zipcode", "");
          setCity(response.data[0].PostOffice[0].District);
          setState(response.data[0].PostOffice[0].State);
          setCountry(response.data[0].PostOffice[0].Country);
        } else {
          setFieldError("zipcode", "Enter valid pincode");
        }
      } catch (error) {
        setFieldError("zipcode", "Enter valid pincode");
      }
    }
  };

  const completeOnBoarding = async () => {
    const response = await callApi({
      apiURL: Constants.API.completeOnBoarding,
    });
    if (response?.success) {
      localStorage.setItem("isOnboarded", 1);
      navigate("/dashboard");
    } else {
      console.log("error");
    }
  };

  const getProperty = async () => {
    const response = await callApi({
      apiURL: Constants.API.getProperty,
    });
    if (response?.success) {
      if (response.data.propertyInfo) {
        let obj = response.data.propertyInfo;
        setPropertyLocationId(obj.PropertyLocationId);
        setUpdatedDate(obj.UpdatedDate);
        setCity(obj.City);
        setState(obj.State);
        setCountry(obj.Country);
        setInitialValues({
          zipcode: obj.Zip,
          address: obj.Address,
          gstNumber: obj.GSTNumber,
        });
      } else {
        navigate("/onboading/property");
      }
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    getProperty();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {

    if (propertyLocationId === 0) {
      navigate("/onboading/property");
    }

    const response = await callApi({
      apiURL: Constants.API.updatePropertyAddress,
      requestBody: {
        propertyLocationId,
        updatedDate,
        city,
        state,
        country,
        zip: values.zipcode,
        gstIn: values.gstNumber ? values.gstNumber : "",
        address: values.address,
      },
    });

    if (response?.success) {
      navigate("/onboading/add-room-type");
      // completeOnBoarding();
    } else {
      console.log("error");
    }
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <section className="sec-left">
          <img src={Onboarding_1} alt="Onboarding" />
        </section>
        <section className="sec1-right">
          <div style={{ marginTop: "10%" }}>
            <div className={css.flexone}>
              <section className={css.left}>
                <img src={Logo} alt="Logo" className={css.img} />
              </section>
              <section className={css.right}>
                <a
                  onClick={() => {
                    navigate("/onboading/property");
                  }}
                  style={{ marginRight: 20 }}
                >
                  <button className={css.icon}>
                    <AiOutlineArrowLeft  />
                  </button>
                </a>
                <a
                  onClick={() => {
                    document.getElementById("submitButton").click();
                  }}
                >
                  <button className={css.icon}>
                    <AiOutlineArrowRight />
                  </button>
                </a>
              </section>
            </div>
            <div className={css.title}>Tell us about your property</div>
            <div className={css.desc}>
              Please complete the basic information about your property’s
              location
            </div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, setFieldError }) => (
                <Form>
                  <div className={css.flexform}>
                    <div>
                      <label htmlFor="zipcode">Zip:</label> <br />
                      <Field
                        type="text"
                        id="zipcode"
                        name="zipcode"
                        placeholder="Enter Zip code"
                        maxLength={6}
                        onChange={(e) =>
                          handleZipcodeChange(
                            e.target.value,
                            setFieldValue,
                            setFieldError
                          )
                        }
                      />
                      <ErrorMessage
                        name="zipcode"
                        component="div"
                        className="error-message"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <label htmlFor="country">Country:</label> <br />
                      <Field
                        type="text"
                        id="country"
                        name="country"
                        placeholder="Country"
                        value={country}
                        disabled
                      />
                    </div>
                  </div>
                  <div className={css.flexform}>
                    <div>
                      <label htmlFor="state">State:</label> <br />
                      <Field
                        type="text"
                        id="state"
                        name="state"
                        placeholder="State"
                        value={state}
                        disabled
                      />
                    </div>
                    <div>
                      <label htmlFor="city">City:</label> <br />
                      <Field
                        type="text"
                        id="city"
                        name="city"
                        placeholder="City"
                        value={city}
                        disabled
                      />
                    </div>
                  </div>
                  <div className={css.address}>
                    <label htmlFor="address">Address:</label> <br />
                    <Field
                      type="text"
                      id="address"
                      name="address"
                      placeholder="Enter Address Line 1"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="error-message"
                      style={{ color: "red" }}
                    />
                  </div>
                  <div className={css.gst}>
                    <label htmlFor="gstNumber">GST Number:</label> <br />
                    <Field
                      type="text"
                      id="gstNumber"
                      name="gstNumber"
                      placeholder="Enter GST Number"
                    />
                  </div>
                  <button
                    type="submit"
                    id="submitButton"
                    style={{ display: "none" }}
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutProperty;
