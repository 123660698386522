import "./CSS/MainGuestpage6.css";
import React, { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Button,
  Box,
  Grid,
  Typography,
  IconButton,
  Checkbox,
  TextField,
  FormControlLabel,
} from "@mui/material";
import callApi from "../../../ApiCall";
import Constants from "../../../Constants";
import DatePicker from "react-multi-date-picker";
import "./CSS/MainGuestpage6.css";
import Select from "react-select";
import MySelect from "../../../common/SingleSelect";

export default function DietPage({ GuestMasterId }) {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "var(--primary-green)" : "white",
      "&:hover": {
        // backgroundColor: '#b4ba3d',
        backgroundColor: state.isSelected ? "var(--primary-green)" : "#b4ba3d",
        color: "white",
      },
    }),
  };

  const [selectedDate, setSelectedDate] = useState(moment());
  const [dateRange, setDateRange] = useState([]);
  const [tempSelectedDate, setTempSelectedDate] = useState(selectedDate);
  const [selectedGuestBookingHistory, setSelectedGuestBookingHistory] =
    useState([]);
  const [dietPlanStartDate, setDietPlanStartDate] = useState(null);
  const [dietPlanEndDate, setDietPlanEndDate] = useState(null);
  const [selectDietPlanError, setSelectDietPlanError] = useState("");
  const [dietPlanStartDateError, setDietPlanStartDateError] = useState("");
  const [dietPlanEndDateError, setDietPlanEndDateError] = useState("");
  const [activeDietPlanList, setActiveDietPlanList] = useState([]);
  const [dietPlanName, setDietPlanName] = useState("");
  const [mealInputs, setMealInputs] = useState({
    prebreakfast: "",
    postbreakfast: "",
    prelunch: "",
    postlunch: "",
    predinner: "",
    postdinner: ""
  });
  

  const getSelectedGuestBookingHistory = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.selectedGuestBookingHistory,
        requestBody: { guestMasterId: GuestMasterId },
      });
      if (response?.success) {
        setSelectedGuestBookingHistory(
          response.data.selectedGuestBookingHistory
        );
      } else {
        console.log("Error:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getActiveDietPlanList = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getActiveDietPlan,
      });
      if (response?.success) {
        setActiveDietPlanList(response.data.aciveDietPlanList);
      } else {
        console.log("Error:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleDietPlanChange = (selectedOption) => {
    if (selectedOption) {
      setDietPlanName(selectedOption);
    } else {
      setDietPlanName("");
    }
  };

  useEffect(() => {
    if (tempSelectedDate) {
      getSelectedGuestBookingHistory();
      getActiveDietPlanList();
    }
  }, [tempSelectedDate]);

  let todaysDate = moment();

  useEffect(() => {
    const startDate = selectedDate.clone().startOf("week");
    const endDate = selectedDate.clone().endOf("week");

    const dates = [];
    let currentDate = startDate.clone();

    while (currentDate.isSameOrBefore(endDate)) {
      dates.push(currentDate.clone());
      currentDate.add(1, "day");
    }

    setDateRange(dates);
  }, [selectedDate]);

  const handlePreviousWeek = () => {
    setSelectedDate((prevDate) => prevDate.clone().subtract(1, "week"));
  };

  const handleNextWeek = () => {
    setSelectedDate((prevDate) => prevDate.clone().add(1, "week"));
  };

  const isToday = (date) => {
    return date.isSame(tempSelectedDate, "day");
  };


  const handleMealInputChange = (e) => {
    const { id, value } = e.target;
    setMealInputs((prevInputs) => ({
      ...prevInputs,
      [id]: value,
    }));
  };
console.log(mealInputs)
  return (
    <>
      <div className="MainPae">
        <div className="DataTable">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              mb: "2%",
            }}
          >
            {/* Month and year display */}
            <Box mb={"1%"} mt={"1%"} width={"81%"}>
              <p
                style={{
                  fontSize: "16px",
                  fontFamily: "Urbanist",
                  fontWeight: "bold",
                }}
              >
                <div className="month-year">
                  {selectedDate.format("MMMM YYYY")}
                </div>
              </p>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                width: "90%",
              }}
            >
              <Button
                onClick={handlePreviousWeek}
                disableRipple
                endIcon={<ArrowBackIosIcon />}
              ></Button>
              {dateRange.map((date) => {
                const isDateInRange = selectedGuestBookingHistory.some(
                  (booking) => {
                    return (
                      date.isSameOrAfter(moment(booking.CheckInDate)) &&
                      date.isSameOrBefore(moment(booking.CheckOutDate))
                    );
                  }
                );

                const isTodayDate = isToday(date);

                const isClickable = isDateInRange || isTodayDate;

                return (
                  <div
                    key={date.format("YYYY-MM-DD")}
                    className={
                      isTodayDate
                        ? "currDate"
                        : isClickable
                        ? "allDate"
                        : "disabledDate"
                    }
                    style={{
                      border: date.isSame(todaysDate, "day")
                        ? "2px solid #6C7D02"
                        : "",
                      cursor: isClickable ? "pointer" : "not-allowed",
                    }}
                    onClick={() => {
                      if (isClickable) {
                        setTempSelectedDate(date);
                      }
                    }}
                  >
                    <div>{date.format("ddd")}</div>
                    <div>{date.format("D")}</div>
                  </div>
                );
              })}
              <Button
                onClick={handleNextWeek}
                disableRipple
                endIcon={<ArrowForwardIosIcon />}
              ></Button>
            </Box>
          </Box>
        </div>
      </div>

      <div className="diet-plan-container">
        <div className="data-row">
          <div className="select-container">
            <label className="data-label">
              Diet Plan Name <span className="required">*</span>
            </label>
            <div>
              <Select
                options={activeDietPlanList}
                onChange={handleDietPlanChange}
                value={dietPlanName}
                name="dietPlan"
                id="dietPlan"
                className="dietplan_dropdown"
                styles={customStyles}
              />
            </div>
            {selectDietPlanError && (
        <p className="error-message">{selectDietPlanError}</p>
      )}
          </div>

          <div className="date-picker-container">
            <label className="data-label">
              Diet Plan Start Date <span className="required">*</span>
            </label>
            <DatePicker
              value={dietPlanStartDate}
              onChange={(date) => {
                setDietPlanStartDate(date);
                setDietPlanEndDate("");
              }}
              format="DD-MM-YYYY"
              placeholder="Start Date"
              minDate={new Date()}
              className="date-picker"
              style={{
                width: "100%",
                borderRadius: "8px",
                borderColor: "#ccc",
                height: "46px",
              }}
            />
            {dietPlanStartDateError && (
              <p className="booking-error">{dietPlanStartDateError}</p>
            )}
          </div>

          <div className="date-picker-container">
            <label className="data-label">
              Diet Plan End Date <span className="required">*</span>
            </label>
            <DatePicker
              value={dietPlanEndDate}
              onChange={(value) => {
                setDietPlanEndDate(value);
              }}
              format="DD-MM-YYYY"
              placeholder="End Date"
              minDate={dietPlanStartDate}
              className="date-picker"
              style={{
                width: "100%",
                borderRadius: "8px",
                borderColor: "#ccc",
                height: "46px",
              }}
            />
            {dietPlanEndDateError && (
              <p className="booking-error">{dietPlanEndDateError}</p>
            )}
          </div>
        </div>
        <div className="meal-inputs-container">
          <div className="input-row">
            <h1>Breakfast</h1>
            <input
              id="prebreakfast"
              placeholder="Enter Prebreakfast"
              className="meal-input"
              value={mealInputs.prebreakfast}
              onChange={handleMealInputChange}
            />
            <input
              id="postbreakfast"
              placeholder="Enter Postbreakfast"
              className="meal-input"
              value={mealInputs.postbreakfast}
              onChange={handleMealInputChange}
            />
          </div>

          <div className="input-row">
            <h1>Lunch</h1>
            <input
              id="prelunch"
              placeholder="Enter Prelunch"
              className="meal-input"
              value={mealInputs.prelunch}
              onChange={handleMealInputChange}
            />
            <input
              id="postlunch"
              placeholder="Enter Postlunch"
              className="meal-input"
              value={mealInputs.postlunch}
              onChange={handleMealInputChange}
            />
          </div>

          <div className="input-row">
            <h1>Dinner</h1>
            <input
              id="predinner"
              placeholder="Enter Predinner"
              className="meal-input"
              value={mealInputs.predinner}
              onChange={handleMealInputChange}
            />
            <input
              id="postdinner"
              placeholder="Enter Postdinner"
              className="meal-input"
              value={mealInputs.postdinner}
              onChange={handleMealInputChange}
            />
          </div>
        </div>

        <div className="submit-container">
          <button className="submit-button">Submit</button>
        </div>
      </div>
    </>
  );
}
