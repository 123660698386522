import React from "react";
import css from "./Ads.module.scss";
import image from "../../../../assets/images/H-Second1.png";

const Ads = () => {
  return (
    <div className={css.maindiv}>
      <div className={css.secondmain}>
        <center>
          <section className={css["second-heading"]}>
            Empower your hospitality operations with advanced cloud technology.
          </section>
          <img src={image} className={css.image} alt="Second Image" />
        </center>
      </div>
    </div>
  );
};
export default Ads;
