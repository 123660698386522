import callApi from "./ApiCall";
import Constants from "./Constants";

const awsFileUpload = (data) => {
  return new Promise((resolve, reject) => {
    const params = data?.map((x) => {
      return { type: x.file.name.split(".")[1], index: x.index };
    });
    callApi({
      apiURL: Constants.API.getAwsFileName,
      requestBody: {
        images: params,
        screen: "halfOlive",
      },
    })
      .then(async (res) => {
        const responses = await Promise.all(
          res?.data?.imageUrls?.map(async (x, index) => {
            const fileUploadResult = await fetch(x.url, {
              method: "PUT",
              body: data.filter((p) => {
                return p.index == x.index;
              })[0].file,
            });
            const { ok = false, status = null } = fileUploadResult;
            if (ok && status == "200") {
              return {
                key: x.Key,
                index: x.index,
                fileName: data.filter((p) => {
                  return p.index == x.index;
                })[0].file.name,
              };
            }
          })
        );
        if (data.length == responses.length) {
          resolve(responses);
        } else {
          reject();
        }
      })
      .catch((error) => {
        // console.log("Error sending slider Data", error);
        reject(error);
      });
  });
};

export { awsFileUpload };
