import React, { useEffect, useState } from "react";
import "./PropertyMaster.css";
import PropertyMasterPopup from "./PropertyMasterPopup";
import ExportFile from "../../common/ExportFile/ExportFile";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteConfirmationDialog from "../../common/DeleteConfirmation/confirmdelete";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import callApi from "../../ApiCall";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";
import Constants from "../../Constants";
import SearchBar from "../../common/SearchBar/SearchBar";
import AddButton from "../../common/Button/Button";


const MenuItemWrapper = styled("li")(() => ({
  padding: "8px 8px",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F75555",
    color: "#FFFFFF",
    img: {
      filter:
        "invert(92%) sepia(4%) saturate(8%) hue-rotate(126deg) brightness(138%) contrast(200%)",
    },
  },
  transition: "all 0.3s ease",
}));


const IconWrapper = styled("span")(() => ({
  fontSize: "16px",
  marginRight: "8px",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));
const PropertyMaster = (props) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [selectedPropertyForDelete, setselectedPropertyForDelete] =
    useState(null);
  const [selectedPropertyForUpdate, setselectedPropertyForUpdate] =
    useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isAddMode, setIsAddMode] = useState(true);
  const [showPropertyPopup, setshowPropertyPopup] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [propertyData, setPropertyData] = useState([]); //daattttaaaaaa
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const initialSortedRows = propertyData;
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const totalPages = Math.ceil(sortedRows.length / pageSize);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };
  const columns = [
    { field: "propertyId", headerName: "Sr No", flex: 1 },
    { field: "PropertyName", headerName: "Property Name", flex: 1 },
    { field: "PropertyLink", headerName: "Property Link", flex: 1 },
    props.currentMenu.Edit || props.currentMenu.Delete
      ? {
          field: "actions",
          headerName: "Actions",
          flex: 1,
          renderCell: (params) => (
            <div>
              <IconButton
                color="primary"
                aria-label="actions"
                onClick={(event) => handleMenuOpen(event, params.row)}
              >
                <MoreVertIcon style={{ color: "gray" }} />
              </IconButton>
              <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{
                  "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                    {
                      boxShadow: "none",
                      backgroundColor: "#EEE",
                      borderRadius: "8px",
                    },
                  "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                    paddingTop: "0",
                    paddingBottom: "0",
                  },
                }}
              >
                {props.currentMenu.Edit ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("edit")}>
                    <IconWrapper>
                      <img src={edit} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Edit</span>
                  </MenuItemWrapper>
                ) : null}

                {props.currentMenu.Delete ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("delete")}>
                    <IconWrapper>
                      <img src={trash} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Delete</span>
                  </MenuItemWrapper>
                ) : null}
              </Menu>
            </div>
          ),
        }
      : {},
  ];

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setselectedPropertyForUpdate(row);
    setselectedPropertyForDelete(row);
    setIsMenuOpen(true);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };
  const handleUpdateClick = (selectedRow) => {
    setselectedPropertyForUpdate(selectedRow);
    setIsAddMode(false);
    setshowPropertyPopup(true);
    getProperty();
  };
  const handleDeleteClick = (selectedRow) => {
    setselectedPropertyForDelete(selectedRow);
    setShowDeleteConfirmation(true);
  };
  const handleMenuSelect = (option) => {
    if (option === "edit") {
      handleUpdateClick(selectedPropertyForUpdate);
      handleMenuClose();
    } else if (option === "delete") {
      handleDeleteClick(selectedPropertyForDelete);
    }
  };

  const handleAddPropertyClick = () => {
    setIsAddMode(true);
    setshowPropertyPopup(true);
  };

  //delete api
  const handleConfirmDelete = async (selectedRow) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.deletePropertyMaster,
        requestBody: {
          deletedPropertyMasterId: selectedRow.PropertyMasterId,
          updateDate: selectedRow.UpdatedDate,
        },
      });
      if (response?.success) {
        const actionMessage = "Deleted Successfully";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            <br />
            Property has been deleted successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            className: "toast_pop",
            transition: Zoom,
            autoClose: 1500,
            closeButton: true,
            onClose: () => {
              getProperty();
            },
          }
        );
        getProperty();
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setShowDeleteConfirmation(false);
      setPage(1);
    }
  };

  const handlePropertyActivityPopupClose = () => {
    setshowPropertyPopup(false);
    setIsAddMode(true);
    setselectedPropertyForUpdate(null);
    getProperty();
  };

  //select api
  const getProperty = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getPropertyMaster,
      });
      if (response?.success) {
        const { property = [] } = response?.data;
        const propertyData = property.map((item, index) => ({
          propertyId: index + 1,
          ...item,
        }));
        setPropertyData(propertyData);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    getProperty();
  }, []);

  const handleExportClick = (propertyData) => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      const fileName = `Property_${formattedDate.replace(/ /g, " ")}.xlsx`;
      const worksheetName = `Property`;

      if (propertyData?.length > 0) {
        const exportData = propertyData.map((row) => ({
          SrNo: row.PropertyMasterId,
          "Property Name": row.PropertyName,
          "Property Link": row.PropertyLink,
        }));
        const workSheet = XLSX.utils.json_to_sheet(exportData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, worksheetName);
        XLSX.writeFile(workBook, fileName);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF("p");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    const fileName = `Property_${formattedDate.replace(/ /g, "")}.pdf`;

    const tableHeaders = ["Sr No", "Property Name", "Property Link"];

    if (propertyData.length > 0) {
      let tableData = propertyData.map((row) => {
        return [row.PropertyMasterId, row.PropertyName, row.PropertyLink];
      });

      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Property Master", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
        theme: "grid",
      });

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        formattedDate,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );

      doc.save(fileName);
    } else {
      // showToast("ERROR", "No Data To Export");
    }
  };

  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      return (
        <span className="arrow-icon">
          {sortOrder === "asc" ? (
            <i className="fa-solid fa-arrow-up" />
          ) : (
            <i className="fa-solid fa-arrow-down" />
          )}
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
    if (propertyData.length > 0) {
      setSortedRows(propertyData);
    }
  }, [propertyData.length > 0]);

  useEffect(() => {
    if (sortedColumn) {
      const sorted = propertyData.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
  }, [sortedColumn, sortOrder, propertyData]);

  const [filtering, setFiltering] = useState("");

  const handleSearchfilter = (event) => {
    setFiltering(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    const searched = propertyData.filter((item) => {
      if (!filtering) {
        return true;
      } else {
        return (
          (item.PropertyName !== null &&
            item.PropertyName.toLowerCase().includes(
              filtering.toLowerCase()
            )) ||
          (item.PropertyLink !== null &&
            item.PropertyLink.toLowerCase().includes(filtering.toLowerCase()))
        );
      }
    });
    setSortedRows(searched);
  }, [filtering, propertyData]);

  const paginatedRows = sortedRows.slice(startIndex, endIndex);

  return (
    <>
      {showPropertyPopup && (
        <PropertyMasterPopup
          onClose={handlePropertyActivityPopupClose}
          isAddMode={isAddMode}
          selectedPropertyForUpdate={selectedPropertyForUpdate}
          getPropertyDetails={getProperty}
        />
      )}
      <div className="propertyMaster_main_component">
        <div className="propertyMaster_main_sec_1">
          <div className="propertyMaster_main_heading">
            <h1 className="manage_page_heading">Manage Properties</h1>
          </div>
          <div className="export_property">
          <ExportFile
            handleExportClick={handleExportClick}
            filteredRows={propertyData}
            handleExportPDF={handleExportPDF}
          />
          &nbsp;&nbsp;&nbsp;
          {props?.currentMenu?.Add ? (
            <>
              <AddButton buttonText={"Add Property"} onClick={handleAddPropertyClick}/>
            </>
          ) : (
            <></>
          )}</div>
        </div>
        <div className="property_main_sec_2">
          <div className="property_search_main_container">
            <SearchBar value={filtering} onChange={handleSearchfilter} />
            <DeleteConfirmationDialog
              open={showDeleteConfirmation}
              handleClose={() => {
                setShowDeleteConfirmation(false);
                handleMenuClose();
              }}
              handleConfirm={() => {
                handleConfirmDelete(selectedPropertyForDelete);
                handleMenuClose();
              }}
              product={"Property"}
            />
            <div className="property_data_grid">
              <div className="propety_data">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            className="propertytableHeaderCell"
                            onClick={() => handleSortClick(column.field)}
                          >
                            {column.headerName}
                            {renderArrowIcon(column.field)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedRows.map((row, i) => (
                        <TableRow key={i}>
                          {columns.map((column, ind) => (
                            <TableCell
                              key={ind}
                              className="propertytableBodyCell"
                            >
                              {column.renderCell
                                ? column.renderCell({ row })
                                : row[column.field]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={totalPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(event, value) => setPage(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyMaster;
