import React, { useState, useEffect } from "react";
import Logo from "../../../assets/images/Logo.png";
import "./PropertyOnboarding.css";
import bg from "../../../assets/images/Bg2.png";
import ImageSlider from "../../Slider/Slider";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import callApi from "../../../ApiCall";
import Constants from "../../../Constants";

// Define positionOptions array outside the component
const positionOptions = [
  { value: "Owner", label: "Owner" },
  { value: "Manager", label: "Manager" },
  { value: "Senior", label: "Senior" },
];

export default function PropertyOnboarding() {
  const images = [bg, bg, bg];
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    propertyName: "",
    propertyLocationName: "",
    propertyLink: "",
    position: null,
  });

  const [propertyMasterId, setPropertyMasterId] = useState(0);
  const [designationId, setDesignationId] = useState(0);
  const [updatedDate, setUpdatedDate] = useState("");
  const [propertyLocationId, setPropertyLocationId] = useState(0);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "var(--primary-green)" : "white",
      "&:hover": {
        backgroundColor: state.isSelected ? "var(--primary-green)" : "#b4ba3d",
        color: "white",
      },
    }),
  };

  const validationSchemaFormik = Yup.object().shape({
    propertyName: Yup.string().required("Property name is required"),
    propertyLocationName: Yup.string().required(
      "Property location name is required"
    ),
    propertyLink: Yup.string()
      .url("Invalid URL format")
      .required("Property link is required"),
    position: Yup.object()
      .shape({
        value: Yup.string().required("Position is required"),
      })
      .nullable()
      .required("Position is required"),
  });

  useEffect(() => {
    getProperty();
  }, []);

  const getProperty = async () => {
    const response = await callApi({
      apiURL: Constants.API.getProperty,
    });
    if (response?.success) {
      if (response.data.propertyInfo) {
        let obj = response.data.propertyInfo;
        setInitialValues({
          propertyName: obj.PropertyName,
          propertyLocationName: obj.PropertyLocationName,
          propertyLink: obj.PropertyLink,
          position: positionOptions.find(
            (option) => option.value === obj.Designation
          ),
        });
        setPropertyMasterId(obj?.PropertyMasterId);
        setDesignationId(obj?.DesignationId);
        setUpdatedDate(obj.UpdatedDate);
        setPropertyLocationId(obj.PropertyLocationId);
      }
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    console.log("Submitted values:", values);

    if (!propertyMasterId || propertyMasterId === 0) {
      const response = await callApi({
        apiURL: "onboarding/addProperty",
        requestBody: {
          propertyName: values.propertyName,
          propertyLocationName: values.propertyLocationName,
          propertyLink: values.propertyLink ? values.propertyLink : "",
          position: values.position.value,
        },
      });
      if (response?.success) {
        navigate("/onboading/about-property");
      } else {
        console.log("error");
      }
    } else {
      console.log("valueesss");

      const response = await callApi({
        apiURL: Constants.API.updateProperty,
        requestBody: {
          propertyName: values.propertyName,
          propertyLocationName: values.propertyLocationName,
          propertyLink: values.propertyLink ? values.propertyLink : "",
          position: values.position.value,
          designationId: designationId,
          propertyMasterId: propertyMasterId,
          propertyLocationId: propertyLocationId,
          updatedDate: updatedDate,
        },
      });
      if (response?.success) {
        navigate("/onboading/about-property");
      } else {
        console.log("error");
      }
    }

    resetForm();
  };

  return (
    <div>
      <div className="mainContainer">
        <section className="sec-left">
          <ImageSlider images={images} />
        </section>
        <section className="sec1-right">
          <div className="rightContainer">
            <div className="headContainer">
              <img src={Logo} id="logo" alt="Logo" />
              <p id="typo1">Property Info</p>
              <p id="typo2">Only one more step...</p>
              <p id="typo3">
                To set up your property, we first need a few details from you.
                Fortunately, these all fit on one page:
              </p>
            </div>
            <br />
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchemaFormik}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  {/* Property name */}
                  <div>
                    <label className="labelProperty">
                      Property name
                      <sup className="compulsary">*</sup>
                    </label>
                    <br />
                    <Field
                      type="text"
                      className="inputn"
                      name="propertyName"
                      placeholder="Property name"
                    />
                    <ErrorMessage
                      name="propertyName"
                      component="div"
                      className="error-message"
                      style={{ color: "red" }}
                    />
                  </div>
                  {/* Property Location Name */}
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      <label style={{ fontFamily: "urbanistl" }}>
                        Property Location Name
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <br />
                      <Field
                        className="input2"
                        type="text"
                        name="propertyLocationName"
                        placeholder="Property Location"
                      />
                      <ErrorMessage
                        name="propertyLocationName"
                        component="div"
                        className="error-message"
                        style={{ color: "red" }}
                      />
                    </div>

                    <div style={{ width: "50%", marginLeft: "20px" }}>
                      <label style={{ fontFamily: "urbanistl" }}>
                        Position
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <br />
                      <Select
                        options={positionOptions}
                        name="position"
                        onChange={(option) =>
                          setFieldValue("position", option)
                        }
                        value={values.position}
                        placeholder="Select"
                        styles={customStyles}
                      />
                      <ErrorMessage
                        name="position"
                        component="div"
                        className="error-message"
                        style={{ color: "red", width: "17vw" }}
                      />
                    </div>
                  </div>
                  {/* Property link */}
                  <div style={{ width: "100%" }}>
                    <label style={{ fontFamily: "urbanistl" }}>
                      Property link
                      <sup style={{ color: "red" }}>*</sup>
                    </label>
                    <br />
                    <Field
                      className="input"
                      type="url"
                      name="propertyLink"
                      placeholder="https://"
                    />
                    <ErrorMessage
                      name="propertyLink"
                      component="div"
                      className="error-message"
                      style={{ color: "red" }}
                    />
                  </div>
                  {/* Submit */}
                  <button type="submit" id="cal-button">
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </div>
    </div>
  );
}
