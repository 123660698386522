import React, { useEffect, useState } from "react";
import "./Blog.css";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import demo from "../../../assets/images/H-Second.png";

const CategorySelector = ({
  categories,
  selectedCategory,
  onCategoryChange,
}) => {
  return (
    <div className="categorySelector">
      <h2>Select a Category:</h2>
      <ul>
        <li
          className={!selectedCategory ? "selected" : ""}
          onClick={() => onCategoryChange(null)}
        >
          All Categories
        </li>
        {categories.map((category) => (
          <li
            key={category}
            className={selectedCategory === category ? "selected" : ""}
            onClick={() => onCategoryChange(category)}
          >
            {category}
          </li>
        ))}
      </ul>
    </div>
  );
};

const RecentBlogs = ({ blogs }) => {
  const recentBlogs = blogs.slice(0, 5); // Get the first 5 blogs

  return (
    <div className="recent">
      <h2 className="recentheading">Recent Posts</h2>
      {recentBlogs.map((blog) => (
        <div className="mainrecent" key={blog.id}>
          <a href={`/blog/${encodeURIComponent(blog.title)}`}>
            <h3 className="title">{blog.title}</h3>
          </a>
          <p className="date">{blog.createddate}</p>
        </div>
      ))}
    </div>
  );
};

const Blog = ({ post }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const blogsPerPage = 6;

  const blogs = [
    {
      id: 1,
      title: "Blog 1 THIS IS BLOG OF REV",
      content:
        "Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...",
      image: demo,
      category: "React",
      createddate: "Oct 15,2023",
    },
    {
      id: 2,
      title: "Blog 2 THIS IS BLOG OF REV",
      content:
        "Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...",
      image: demo,
      category: "JavaScript",
      createddate: "Oct 15,2023",
    },
    {
      id: 3,
      title: "Blog 2 THIS IS BLOG OF REV",
      content:
        "Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...",
      image: demo,
      category: "JavaScript455",
      createddate: "Oct 15,2023",
    },
    {
      id: 4,
      title: "Blog 2 THIS IS BLOG OF REV",
      content:
        "Lorem ipsum dolor sit ametgh gh dghg gf gh h gjsd gdgj  dfj gggghf jd hjfj fhj fjfhjhvj hg jghjghj drg fh gj hfjjjjgjh    ghjg jhfj jhfgh...",
      image: demo,
      category: "JavaScript455",
      createddate: "Oct 15,2023",
    },
    {
      id: 4,
      title: "Blog 2 THIS IS BLOG OF REV",
      content:
        "Lorem ipsum dolor sit ametgh gh dghg gf gh h gjsd gdgj  dfj gggghf jd hjfj fhj fjfhjhvj hg jghjghj drg fh gj hfjjjjgjh    ghjg jhfj jhfgh...",
      image: demo,
      category: "JavaScript455",
      createddate: "Oct 15,2023",
    },
    {
      id: 4,
      title: "Blog 2 THIS IS BLOG OF REV",
      content:
        "Lorem ipsum dolor sit ametgh gh dghg gf gh h gjsd gdgj  dfj gggghf jd hjfj fhj fjfhjhvj hg jghjghj drg fh gj hfjjjjgjh    ghjg jhfj jhfgh...",
      image: demo,
      category: "JavaScript455",
      createddate: "Oct 15,2023",
    },
    {
      id: 4,
      title: "Blog 2 THIS IS BLOG OF REV",
      content:
        "Lorem ipsum dolor sit ametgh gh dghg gf gh h gjsd gdgj  dfj gggghf jd hjfj fhj fjfhjhvj hg jghjghj drg fh gj hfjjjjgjh    ghjg jhfj jhfgh...",
      image: demo,
      category: "JavaScript455",
      createddate: "Oct 15,2023",
    },
    // Add more blogs here...
  ];

  const categories = [...new Set(blogs.map((blog) => blog.category))];

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); // Reset to first page when category changes
  };

  let filteredBlogs = blogs;
  if (selectedCategory) {
    filteredBlogs = blogs.filter((blog) => blog.category === selectedCategory);
  }

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  return (
    <div className="mt-20">
       <h1 className="text-5xl pt-5 text-center font-bold">Blog</h1>
      <div className="main">
        <section className="rightcat">
          <CategorySelector
            categories={categories}
            selectedCategory={selectedCategory}
            onCategoryChange={handleCategoryChange}
          />

          <RecentBlogs blogs={blogs} />
        </section>
        <section className="blogs">
          {currentBlogs.map((blog) => (
            <div key={blog.id} className="container">
              <section className="section1">
                <img src={blog.image} title="" alt="" />
              </section>
              <section className="section2">
                <a href={`/blog/${encodeURIComponent(blog.title)}`}>
                  <h1 className="h1">{blog.title}</h1>
                </a>
                <h6 className="text-gray-500">{blog.createddate}</h6>
                <p className="para">{blog.content}</p>
              </section>
            </div>
          ))}
        </section>
      </div>
      <div className="pagination">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <BsArrowLeft />
        </button>
        {Array(Math.ceil(filteredBlogs.length / blogsPerPage))
          .fill()
          .map((_, index) => (
            <button
              key={index}
              className={currentPage === index + 1 ? "active" : ""}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        <button
          disabled={indexOfLastBlog >= filteredBlogs.length}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <BsArrowRight />
        </button>
      </div>
    </div>
  );
};

export default Blog;
