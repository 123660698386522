import React from 'react';

const AddButton = ({ buttonText, onClick, width,type }) => {
  return (
    <div style={{marginBottom:"1%"}}>
      <button
        onClick={onClick}
        style={{
          backgroundColor: 'var(--primary-green)',
          border: 'none',
          borderRadius: '8px',
          width: width || '176px',
          color: 'rgb(255, 255, 255)',
          fontStyle: 'normal',
          fontSize: '16px',
          padding: '10px 16px',
          fontWeight: '600',
          cursor: 'pointer'
        }}
        type={type || 'button'} 
      >
        {buttonText}
      </button>
    </div>
  );
};

export default AddButton;
