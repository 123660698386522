import React from "react";
import * as XLSX from "xlsx";
import "./GuestDietPlan.css";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ExportFile from "../../common/ExportFile/ExportFile";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import callApi from "../../ApiCall";
import Constants from "../../Constants";
import SearchBar from "../../common/SearchBar/SearchBar";
import Tooltip from "@mui/material/Tooltip";
import DateSelect from "../../common/DatePicker/Datepicker";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


const GuestDietPlan = () => {

 //For Tooltip
  const renderDietCell = (dietType) => (params) => {
    const diet = params.row[dietType];
    if (typeof diet !== 'string' || !diet) return null;
  
    const items = diet.split(',');
    const firstItem = items[0];
    const additionalCount = items.length - 1;
  
    
    const tooltipContent = (
      <div style={{ fontSize: '16px' }}>
        {items.map((item, index) => (
          <div key={index}>
            {item}{index < items.length - 1 ? ', ' : ''}
          </div>
        ))}
      </div>
    );
  
    return (
      <Tooltip title={tooltipContent} arrow PopperProps={{ sx: { pointerEvents: 'none' } }}>
        <div style={{ cursor: 'pointer' }}>
          {additionalCount > 0 ? `${firstItem}, +${additionalCount}...` : firstItem}
        </div>
      </Tooltip>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr No", flex: 1 },
    { field: "Guest", headerName: "Guest", flex: 1 },
    { field: "DietPlanName", headerName: "Diet Plan Name", flex: 1 },
    { field: "PreBreakfast", headerName: "Pre Breakfast", flex: 1, renderCell: renderDietCell("PreBreakfast") },
    { field: "PostBreakfast", headerName: "Post Breakfast", flex: 1, renderCell: renderDietCell("PostBreakfast") },
    { field: "PreLunch", headerName: "Pre Lunch", flex: 1, renderCell: renderDietCell("PreLunch") },
    { field: "PostLunch", headerName: "Post Lunch", flex: 1, renderCell: renderDietCell("PostLunch") },
    { field: "PreDinner", headerName: "Pre Dinner", flex: 1, renderCell: renderDietCell("PreDinner") },
    { field: "PostDinner", headerName: "Post Dinner", flex: 1, renderCell: renderDietCell("PostDinner") },
    { field: "DietPlanDate", headerName: "Diet Plan Date", flex: 1 },
  ];
  
  const [page, setPage] = useState(1);
  const [guestDietPlandata, setGuestDietPlandata] = useState([]);


//     try {
//       const response = await callApi({
//         apiURL: Constants.API.getGuestDietPlan,
//       });
 

//       if (response?.success) {
//         const { guestDietPlanList = [] } = response.data;

//         const mappedData = guestDietPlanList.map((item, index) => ({
//           id: index + 1,
//           ...item,
         
//         }));
//         setGuestDietPlandata(mappedData);
//       } else {
//         console.error("Error fetching guestdietplan data:", response.error);
//       }
//     } catch (error) {
//       console.error("Error during API call:", error);
//     }
//   };


  const [selectedDate, setSelectedDate] = useState(new Date());

  // Fetch guest diet plan data for selected date
  const getDatesWiseGuestDietPlan = async (date) => {
    if (!date) {
      console.error("No date selected for fetching data.");
      return;
    }

    try {
      const formattedDate = new Date(date).toISOString().split('T')[0]; 
      const response = await callApi({
        apiURL: Constants.API.getDatesWiseGuestDietPlan,
        requestBody: { selectedDate: formattedDate },
      });

      if (response?.success) {
        const { dateWiseGuestDietPlanList = [] } = response.data;
        const mappedData = dateWiseGuestDietPlanList.map((item, index) => ({
          id: index + 1,
          ...item,
        }));
        setGuestDietPlandata(mappedData);
      } else {
        console.error("Error fetching data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  
  useEffect(() => {
    getDatesWiseGuestDietPlan(selectedDate);
  }, [selectedDate]);

  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  //export to xl
  const handleExportClick = (guestDietPlandata) => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
     
      const fileName = `GuestDietPlans_${formattedDate.replace(/ /g, " ")}.xlsx`;
      const worksheetName = `GuestDietPlans`;

      if (guestDietPlandata?.length > 0) {
        const exportData = guestDietPlandata.map((row) => ({
          "Sr No": row.id,
          "Guest": row.Guest,
          "Diet Plan Name":row.DietPlanName,
          
          "PreBreakfast" : row.PreBreakfast,
          "PostBreakfast": row.PostBreakfast,
          "PreLunch" : row.PreLunch,
          "PostLunch" : row.PostLunch,
          "PreDinner" : row.PreDinner,
          "PostDinner" : row.PostDinner,
          "Diet Plan Date":row.DietPlanDate,

        }));
        const workSheet = XLSX.utils.json_to_sheet(exportData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, worksheetName);
        XLSX.writeFile(workBook, fileName);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF("p");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    const fileName = `GuestDietPlans_${formattedDate.replace(/ /g, "")}.pdf`;

    const temp = columns.filter((val) => val?.field !== "actions");
    const tableHeaders = [
      "Sr No",
      "Guest",
      "Diet Plan Name",
      
      "PreBreakfast",
      "PostBreakfast",
      "PreLunch",
      "PostLunch" ,
      "PreDinner",
      "PostDinner",
      "Diet Plan Date",

    ];

    if (guestDietPlandata.length > 0) {
      let tableData = guestDietPlandata.map((row) => {
        return [row.id, row.Guest, row.DietPlanName,
          row.PreBreakfast,row.PostBreakfast,row.PreLunch,
          row.PostLunch,row.PreDinner,row.PostDinner,row.DietPlanDate
        ];
      });

      // Add title
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("GuestDietPlan", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
        theme: "grid",
      });

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        formattedDate,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );

      doc.save(fileName);
    } else {
      alert("No data to export");
    }
  };

  // pagination sorting and searching
  const initialSortedRows = guestDietPlandata;
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const pageSize = 10;
  const totalPages = Math.ceil(sortedRows.length / pageSize);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      return (
        <span className="arrow-icon">
          {sortOrder === "asc" ? (
            <i className="fa-solid fa-arrow-up" />
          ) : (
            <i className="fa-solid fa-arrow-down" />
          )}
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
    if (guestDietPlandata.length > 0) {
      setSortedRows(guestDietPlandata);
    }
  }, [guestDietPlandata.length > 0]);

  useEffect(() => {
    if (sortedColumn) {
      const sorted = guestDietPlandata.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
  }, [sortedColumn, sortOrder, guestDietPlandata]);

  //search
  const [filtering, setFiltering] = useState("");
  const handleSearchfilter = (event) => {
    setFiltering(event.target.value);
    setPage(1);
  };

 
  useEffect(() => {
    const searched = guestDietPlandata.filter((item) => {
      if (!filtering) {
        return true;
      } else {
        return (
          (item.Guest !== null &&
            item.Guest.toLowerCase().includes(
              filtering.toLowerCase()
            )) ||
          (item.DietPlanName !== null &&
            item.DietPlanName.toLowerCase().includes(
              filtering.toLowerCase()
            ))
        );
      }
    });
    setSortedRows(searched);
  }, [filtering, guestDietPlandata]);

  const paginatedRows = sortedRows.slice(startIndex, endIndex);
 return (
    <>
      
      <div className="propActivity_MainPae">
        <div className="propActivity_sect-1">
          <div className="propActivity_manage">
            <h1 className="manage_page_heading">Manage GuestDietPlan</h1>
          </div>
          <div className="export_guestdietplan">
          <div className="date-picker-container_guestdietplan">
        <div className="date-input-wrapper">
      <CalendarMonthIcon className="calendar-icon" />
      <DateSelect
      startDate={selectedDate}
      onChange={handleDateChange}
      format={"YYYY-MM-DD"}
    />
  </div>
          </div>
           &nbsp;&nbsp;&nbsp;
         <ExportFile
            handleExportClick={handleExportClick}
            filteredRows={guestDietPlandata}
            handleExportPDF={handleExportPDF}
          />
         </div>
        </div>
        <div className="propActivityTable">
          <div className="Table">
            <SearchBar value={filtering} onChange={handleSearchfilter} />
             <div className="guestdietplan_data_grid">
              <div className="propActivity-table">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            className="guestdietplantableHeaderCell"
                            onClick={() => handleSortClick(column.field)}
                          >
                            {column.headerName}
                            {renderArrowIcon(column.field)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedRows.map((row, i) => (
                        <TableRow key={i}>
                          {columns.map((column, ind) => (
                            <TableCell key={ind} className="tableBodyCell">
                              {column.renderCell
                                ? column.renderCell({ row })
                                : row[column.field]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={totalPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(event, value) => setPage(value)}
                  
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestDietPlan;
