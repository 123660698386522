import axios from "axios";
import constants from "./Constants";
import { toast } from 'react-toastify';

const callApi = async ({
    apiURL,
    requestBody = {},
}) => {
    let response = {};
    const headers = {};

    if (!constants.PUBLIC_APIS.includes(apiURL)) {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        headers.Authorization = `Bearer ${token}`;
        headers.userId = userId;
    }

    try {
        response = await axios
            .post(constants.BASE_URL + apiURL, requestBody, {
                headers
            })
        return response?.data;

    } catch (error) {
        if (error?.response?.data?.data?.message != "") {
            toast.error(error?.response?.data?.data?.message);
        } else {
            toast.error('Something went wrong. Please try again later!!!');
        }
    }
};

export default callApi;
