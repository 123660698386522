import React, { useState, useEffect } from "react";
import { toast, Zoom, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import callApi from "../../ApiCall";
import Constants from "../../Constants";
import MySelect from "../../common/SingleSelect";
import DatePicker from "react-multi-date-picker";
import { getDBFormateDateForInlineGrid } from "../../utils/DateTimeUtils";

const GuestActivityPopup = ({
    onClose,
    isAddMode,
    leaveData
}) => {
    const [showPopup, setShowPopup] = useState(true);
    const [leaveCategories, setLeaveCategories] = useState([]);
    const [leaveTypes, setLeaveTypes] = useState([]);
    const [showOverlay, setShowOverlay] = useState(true);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);

    useEffect(() => {
        const timePickerContainer = document.createElement("div");
        timePickerContainer.id = "time-picker-container";
        document.body.appendChild(timePickerContainer);

        return () => {
            document.body.removeChild(timePickerContainer);
        };
    }, []);

    const getLeaveCategoriesData = async () => {
        const response = await callApi({
            apiURL: Constants.API.getLeaveCategoriesData,
        });
        if (response?.success) {
            const { leaveCategoriesList = [] } = response.data;
            setLeaveCategories(leaveCategoriesList);
        } else {
            console.log("Error fetching leave categories");
        }
    };

    const getLeaveTypesData = async () => {
        const response = await callApi({
            apiURL: Constants.API.getLeaveTypesData,
        });
        if (response?.success) {
            const { leaveTypesList = [] } = response.data;
            setLeaveTypes(leaveTypesList);
        } else {
            console.log("Error fetching leave types");
        }
    };

    useEffect(() => {
        getLeaveCategoriesData();
        getLeaveTypesData();
    }, []);

    const handleCancel = () => {
        setShowPopup(false);
        onClose();
    };

    const handleOutsideClick = (event) => {
        if (event.target.classList.contains("guestActivity_PopMain")) {
            setShowPopup(false);
            onClose();
            setShowOverlay(false);
        }
    };

    const initialValues = {
        leavetype: isAddMode ? "" : leaveData.LeaveType,
        leavecategory: isAddMode ? "" : leaveData.LeaveCategory,
        startDate: isAddMode ? "" : leaveData.StartDate,
        endDate: isAddMode ? "" : leaveData.EndDate,
        reason: isAddMode ? "" : leaveData.Reason,
    };

    const validationSchema = Yup.object({
        leavetype: Yup.string().required("Please select a leave type"),
        leavecategory: Yup.string().required("Please select a leave category"),
        startDate: Yup.string().required("Please select a start date"),
        endDate: Yup.string()
            .required("Please select an end date"),
        reason: Yup.string().required("Please enter a reason"),
    });

    const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-').map(Number);
        return new Date(year, month - 1, day);
    };

    const handleSubmit = async (values, resetForm) => {
        const startDate = parseDate(values.startDate);
        const endDate = parseDate(values.endDate);
        const subMilliseconds = endDate - startDate;
        const totalDays = Math.round(subMilliseconds / (1000 * 60 * 60 * 24)) + 1;

        if (isAddMode) {
            try {
                const response = await callApi({
                    apiURL: Constants.API.addLeaveData,
                    requestBody: {
                        ...values,
                        totalDays,
                        startDate: getDBFormateDateForInlineGrid(values.startDate),
                        endDate: getDBFormateDateForInlineGrid(values.endDate),
                    },
                });
                if (response?.success) {
                    const actionMessage = isAddMode
                        ? "Added New Leave"
                        : "Updated Leave";
                    toast.success(
                        <div className="custom_Toast">
                            <h5>{actionMessage}</h5>
                            Leave has been {isAddMode ? "added" : "updated"} successfully
                        </div>,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            hideProgressBar: true,
                            transition: Zoom,
                            style: {
                                width: "350px",
                                backgroundColor: "#0ABE75",
                                color: "#FFFFFF",
                            },
                            autoClose: 1500,
                            closeButton: true,
                            onClose: () => {
                                onClose();
                                setShowPopup(false);
                                resetForm();
                            },
                        }
                    );
                }if (response?.success){
                    onClose();
                }
            } catch (error) {
                console.error("Error fetching data", error);
            }
        } else {
            try {
                const response = await callApi({
                    apiURL: Constants.API.updateLeaveData,
                    requestBody: {
                        ...values,
                        totalDays,
                        startDate: getDBFormateDateForInlineGrid(values.startDate),
                        endDate: getDBFormateDateForInlineGrid(values.endDate),
                        EmployeeId: leaveData.EmployeeId,
                        LeaveMasterId: leaveData.LeaveMasterId,
                        Status: leaveData.Status,
                        UpdatedDate: leaveData.UpdatedDate,
                        CreatedDate: leaveData.CreatedDate
                    },
                });
                if (response?.success) {
                    const actionMessage = isAddMode
                        ? "Added New Leave"
                        : "Updated Leave";
                    toast.success(
                        <div className="custom_Toast">
                            <h5>{actionMessage}</h5>
                            Leave has been {isAddMode ? "added" : "updated"} successfully
                        </div>,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            hideProgressBar: true,
                            transition: Zoom,
                            style: {
                                width: "350px",
                                backgroundColor: "#0ABE75",
                                color: "#FFFFFF",
                            },
                            autoClose: 1500,
                            closeButton: true,
                            onClose: () => {
                                // setShowPopup(false);
                                // onClose();
                                resetForm();
                            },
                        }
                    );
                }if (response?.success){
                    setShowPopup(false)
                    onClose();
                }
            } catch (error) {
                console.error("Error fetching data", error);
            }
        }
    };

    return (
        <>
            {showOverlay && <div className="Overlay" />}
            <div
                className={
                    showPopup ? "guestActivity_PopMain" : "guestActivity_PopMain hidden"
                }
                onClick={handleOutsideClick}
            >
                <div className="guestActivity_First">
                    <p>{isAddMode ? "Add Leave" : "Update Leave"}</p>
                    <button className="popup_Close_icon" onClick={handleCancel}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                fill="#424242"
                            />
                        </svg>
                    </button>
                </div>
                <br />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values, setSubmitting, resetForm);
                    }}

                >
                    {({ setFieldValue, values }) => (
                        <Form className="guestActivity_form">
                            <div className="guestActivity_ActivityDropdown">
                                <label htmlFor="leavetype">Leave Type<sup>*</sup></label>
                                <Field
                                    name="leavetype"
                                    component={MySelect}
                                    options={leaveTypes}
                                    placeholder="Select Type"
                                    onChange={(option) => setFieldValue("leavetype", option?.value)}
                                />
                                <div className="error_GuestActivity">
                                    <ErrorMessage name="leavetype" />
                                </div>
                            </div>
                            <div className="guestActivity_ActivityDropdown">
                                <label htmlFor="leavecategory">Leave Category<sup>*</sup></label>
                                <Field
                                    name="leavecategory"
                                    component={MySelect}
                                    options={leaveCategories}
                                    placeholder="Select Category"
                                    onChange={(option) => setFieldValue("leavecategory", option?.value)}
                                />
                                <div className="error_GuestActivity">
                                    <ErrorMessage name="leavecategory" />
                                </div>
                            </div>

                            <div className="guestActivity_Name">
                                <label htmlFor="startDate">Start Date <span className="custom-text-color">*</span></label>
                                <DatePicker
                                    value={values.startDate}
                                    onChange={(date) => {
                                        setFieldValue("startDate", date ? date.format("DD-MMM-YYYY") : "");
                                        setFieldValue("endDate", "");
                                    }}
                                    format="DD-MMM-YYYY"
                                    placeholder="Start Date"
                                    style={{
                                        width: "100%",
                                        borderRadius: "8px",
                                        borderColor: "#ccc",
                                        height: "46px",
                                    }}
                                />
                                <div className="error_GuestActivity">
                                    <ErrorMessage name="startDate" />
                                </div>
                            </div>

                            <div className="guestActivity_Name">
                                <label htmlFor="endDate">End Date <span className="custom-text-color">*</span></label>
                                <DatePicker
                                    value={values.endDate}
                                    onChange={(date) => setFieldValue("endDate", date ? date.format("DD-MMM-YYYY") : "")}
                                    format="DD-MMM-YYYY"
                                    placeholder="End Date"
                                    minDate={values.startDate}
                                    style={{
                                        width: "100%",
                                        borderRadius: "8px",
                                        borderColor: "#ccc",
                                        height: "46px",
                                    }}
                                />
                                <div className="error_GuestActivity">
                                    <ErrorMessage name="endDate" />
                                </div>
                            </div>
                            <div className="guestActivity_Name">
                                <label htmlFor="reason">Reason <sup>*</sup></label>
                                <Field
                                    as="textarea"
                                    id="reason"
                                    name="reason"
                                    placeholder="Enter Reason"
                                    className="custom-field_Address"
                                />
                                <div className="error">
                                    <ErrorMessage name="reason" />
                                </div>
                            </div>

                            <div className="guestActivity_End">
                                <button type="submit" className="guestActivity_buttonb">
                                    {isAddMode ? "Add" : "Update"}
                                </button>
                                &nbsp;&nbsp;&nbsp;
                                <button type="button" className="guestActivity_buttona" onClick={handleCancel}>
                                    Cancel
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <ToastContainer />
        </>
    );
};

export default GuestActivityPopup;
