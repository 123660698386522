import { Formik, Field, Form, ErrorMessage } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import callApi from "../../ApiCall";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Constants from "../../Constants";

const PropertyMasterPopup = ({
  onClose,
  isAddMode,
  selectedPropertyForUpdate,
}) => {
  const [showPopup, setShowPopup] = useState(true);

  const handleCancel = () => {
    setShowPopup(false);
    onClose();
  };

  const initialValuesForFormik = {
    propertyName: isAddMode ? "" : selectedPropertyForUpdate?.PropertyName,
    propertyLink: isAddMode ? "" : selectedPropertyForUpdate?.PropertyLink,



    // propertyName: selectedPropertyForUpdate?.PropertyName
    //   ? selectedPropertyForUpdate?.PropertyName
    //   : "",
    // propertyLink: selectedPropertyForUpdate?.PropertyLink
    //   ? selectedPropertyForUpdate?.PropertyLink
    //   : "",
  };
  const validationSchema = Yup.object({
    propertyName: Yup.string().required("Property name is required"),
    propertyLink: Yup.string().required("Property link is required"),
  });
  useEffect(() => {

    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  },);
  const handleSubmit = async (values, { resetForm }) => {
    try {
      let response;
      if (isAddMode) {
        response = await callApi({
          apiURL: Constants.API.addPropertyMaster,
          requestBody: {
            propertyName: values.propertyName,
            propertyLink: values.propertyLink,
          },
        });
      } else {
        response = await callApi({
          apiURL: Constants.API.updatePropertyMaster,
          requestBody: {
            updatePropertyMasterId: selectedPropertyForUpdate?.PropertyMasterId,
            propertyName: values.propertyName,
            propertyLink: values.propertyLink,
            updateDate: selectedPropertyForUpdate?.UpdatedDate
          },
        });
      }
      if (response?.success) {
        const actionMessage = isAddMode
          ? "Added new Property"
          : "Updated Property";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Property has been {isAddMode ? "added." : "updated."}
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            transition: Zoom,
            theme: "colored",
            className: "toast_pop",
            autoClose: 1500,
            closeButton: true,
            onClose: () => {
              setShowPopup(false);
              onClose();
              resetForm();
            },
          }
        );
        onClose();
        resetForm();
      } else {
        console.error("Error fetching guest activity data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }

  };
  return (
    <>
      <div className="Overlay" />
      <div className={showPopup ? "property_popup" : "property_popup hidden"}>
        <div className="property_popup_first">
          <p>{isAddMode ? "Add Property" : "Update Property"}</p>
          <button onClick={handleCancel}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#424242"
              />
            </svg>
          </button>
        </div>
        <br />

        <Formik
          initialValues={initialValuesForFormik}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form
          >
            <div className="property_Field_container">
              <label htmlFor="propertyName" className="Property_field_label">
                Property Name <sup>*</sup>
              </label>
              <Field
                type="text"
                id="propertyName"
                name="propertyName"
                placeholder="Enter Property Name"
                className="property_field"
              ></Field>
              <div className="property-field-error">
                <ErrorMessage name="propertyName" />
              </div>
            </div>
            <div className="property_Field_container">
              <label htmlFor="propertyLink" className="Property_field_label">
                Property Link <sup>*</sup>
              </label>
              <Field
                type="text"
                id="propertyLink"
                name="propertyLink"
                placeholder="Enter Property Link"
                className="property_field"
              ></Field>
              <div className="property-field-error">
                <ErrorMessage name="propertyLink" />
              </div>
            </div>
            <br />
            <div className="property_button_container">
              <button type="submit" className="property_buttonB">
                {isAddMode ? "Add" : "Update "}
              </button>
              &nbsp;&nbsp;&nbsp;
              <button className="property_buttonA" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default PropertyMasterPopup;
