import React, { useState } from "react";
import "./ExportFile.css";
import expor from "../../assets/icons/export.png";
import Menu from "@mui/material/Menu";

import { styled } from "@mui/material/styles";
const ExportFile = ({ handleExportClick, handleExportPDF, filteredRows }) => {


  const MenuItemWrapper = styled("li")(({ theme }) => ({
    // "&:hover": {
    //   backgroundColor: "var(--Secondary-500)",
    //   color: "#FFFFFF",
    // },
    padding: "8px 8px",
    boxShadow: "none",
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    background:"var( --default-white)",
    border:"1px solid var(--primary-green)",
    // borderRadius:"8px",
    borderTop:"unset",

    color:"var(--primary-green)"
  }));

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleMenuSelect = (option) => {
    if (option === "xl") {
      handleExportClick(filteredRows);
    } else if (option === "pdf") {
      handleExportPDF();
    }
    handleMenuClose();
  };
  return (
    <>
        <button
          className="button_export"
          onClick={(event) => {
            // handleExportClick(filteredRows);
            handleMenuOpen(event);
          }}
        >
          Export File &nbsp;
          <img style={{ width: "20px" }} src={expor} alt="" />
        </button>
        <Menu
          id="actions-menu"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            horizontal: "right",
          }}
          sx={{
            "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
              boxShadow: "none",
              // backgroundColor: "#EEE",
              background:"var( --default-white)",
              // borderRadius: "8px",
            },
            "& .css-6hp17o-MuiList-root-MuiMenu-list": {
              paddingTop: "0",
              paddingBottom: "0",
              width: "176px",
            },
          }}
        >
          <MenuItemWrapper
            onClick={() => handleMenuSelect("xl")}
            sx={{ background: "var(--default-white)",border:"1px solid var(--primary-green)",borderBottom:"1px solid #d1d3be" }}
          >
            <div style={{display:"flex",gap:"0.5rem", margin:"auto",alignItems:"center"}}>
            
            <span style={{ fontWeight: "600" }}>Excel</span>
           
              <i class="fa-regular fa-file-excel" style={{width:"20px"}}></i>
            </div>
          </MenuItemWrapper>
          <MenuItemWrapper onClick={() => handleMenuSelect("pdf")} sx={{borderBottomLeftRadius:"8px",borderBottomRightRadius:"8px"}}>
          <div style={{display:"flex",gap:"1rem",margin:"auto",alignItems:"center"}}>
            
            <span style={{ fontWeight: "600" }}>PDF</span>
  
            <i class="fa-regular fa-file-pdf" style={{  }} ></i>

            </div>
          </MenuItemWrapper>
        </Menu>
    </>
  );
};

export default ExportFile;
