import { ErrorMessage, Field, Formik , Form} from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import Constants from "../../Constants";
import "react-toastify/dist/ReactToastify.css";
import { toast,Zoom } from "react-toastify";
import callApi from "../../ApiCall";






const CategoryPopup = ({
    onClose,
    isAddMode,
    selectedCategoryForUpdate


}) => {
    const [showPopup, setShowPopup] = useState(true);
    const handleCancel = () => {
        setShowPopup(false)
        onClose();

    }

    const initialValuesForFormik = {
        categoryName: isAddMode ? "" : selectedCategoryForUpdate ?. CategoryName,
        categoryDescription: isAddMode ? "" : selectedCategoryForUpdate ?.CategoryDescription ,


        // categoryName: selectedCategoryForUpdate ?. CategoryName ? selectedCategoryForUpdate ?.CategoryName : "",
        // categoryDescription: selectedCategoryForUpdate?.CategoryDescription ? selectedCategoryForUpdate ?.CategoryDescription : ""

    }

    const validationSchema = Yup.object({
        categoryName: Yup.string().required("Category Name is required").max(40, 'maximum length is 40.'),
        categoryDescription: Yup.string().required("Category Description is required").max(100, 'maximum length is 100.')

    })

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset"
        }

    })

    const handleSubmit  = async(values, {resetForm}) => {
        try {
            let response;
            if(isAddMode) {
                response = await callApi({
                    apiURL: Constants.API.addCategoryMaster,
                    requestBody: {
                        categoryName: values.categoryName,
                        categoryDescription: values.categoryDescription,
                    }
                })
            } else{
                response = await callApi({
                    apiURL: Constants.API.updateCategoryMaster,
                    requestBody: {
                        updateCategoryMasterId: selectedCategoryForUpdate?.CategoryMasterId,
                        categoryName: values.categoryName,
                        categoryDescription: values.categoryDescription,
                        updateDate: selectedCategoryForUpdate?.UpdatedDate
                    }
                })
            }
            if(response?.success) {
                const actionMessage = isAddMode
          ? "Added new Category"
          : "Updated Category";
          toast.success(
            <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Category has been {isAddMode ? "added." : "updated."}
          </div>,
           {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            transition: Zoom,
            theme: "colored",
            className:"toast_pop",
            autoClose: 1500,
            closeButton: true,
            onClose: () => {
              setShowPopup(false);
              onClose();
              resetForm();
            },
          }

          )
          onClose()
          resetForm()
            } else {
                console.error("Error fetching category activity data:", response.error);
            } 
        } catch (error) {
            console.error("Error during API call:", error);
        }

    }
  return (
    <>
    <div className='Overlay' />
        <div className={showPopup ? 'category_popup' : 'category_popup hidden'}>
            <div className='category_popup_first'>
                <p>{isAddMode ? "Add Category" : "Update Category"}</p>
                <button onClick={handleCancel}>
                    <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none">
                        <path 
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                        fill="#424242"/>
                    </svg>
                </button>
            </div>
            <br />
            <Formik initialValues={initialValuesForFormik} 
             validationSchema={validationSchema}
             onSubmit={handleSubmit}
            >

                <Form>
                    <div className='category_field_container'>
                        <label htmlFor='categoryName' className='Category_field_label'>
                          Category Name <sup>*</sup>
                        </label>
                        <Field type = "text"
                        id = "categoryName"
                        name = "categoryName"
                        placeholder = "Enter Category Name"
                        className = "category_field"
                        ></Field>
                        <div className='category-field-error'>
                            <ErrorMessage name="categoryName" />
                        </div>
                    </div>
                    <div className='category_field_container'>
                        <label htmlFor='categoryDescription' className='Category_field_label'>
                        Category Description <sup>*</sup>
                        </label>
                        <Field type = "text"
                        id="categoryDescription"
                        name = "categoryDescription"
                        placeholder = "Enter Category Description"
                        className = "category_field">

                        </Field>
                        <div className='category-field-error'>
                            <ErrorMessage name="categoryDescription" />
                        </div>
                    </div>
                    <br/>
                    <div className='category_button_container'>
                        <button type='submit' className='category_buttonB'>
                        {isAddMode ? "Add" : "Update "}
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className="category_buttonA" onClick={handleCancel}>
                Cancel
              </button>
                    </div>

                </Form>
            </Formik>
        </div>
    
    </>
  )
}

export default CategoryPopup