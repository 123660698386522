import React from "react";
import "../../assets/colors/colors.css";
import "react-datepicker/dist/react-datepicker.css";
import "../Reservation/CSS/reservation.css";
import { useEffect, useState } from "react";
import { months, getYears } from "../../common/MonthYearCombo";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import callApi from "../../ApiCall";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteConfirmationDialog from "../../common/DeleteConfirmation/confirmdelete";

import Constants from "../../Constants";
import AddLeave from "./AddLeave";
import { getDisplayDate } from "../../components/DateTimeUtils";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  Pagination,
  PaginationItem,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/lab";
import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";
// import CheckIn from "./CheckIn";
import "./leave.css";
import MySelect from "../../common/SingleSelect";
import { toast, Zoom } from "react-toastify";
import ConfirmApproveReject from "../../common/ApproveRejectConfirmation/confirmApproveReject";
import AddButton from "../../common/Button/Button";
import SearchBar from "../../common/SearchBar/SearchBar";

const MenuItemWrapper = styled("li")(() => ({
  padding: "8px 8px",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F75555",
    color: "#FFFFFF",
    img: {
      filter:
        "invert(92%) sepia(4%) saturate(8%) hue-rotate(126deg) brightness(138%) contrast(200%)",
    },
  },
  transition: "all 0.3s ease",
}));

const IconWrapper = styled("span")(() => ({
  fontSize: "16px",
  marginRight: "8px",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));

export default function Leave(props) {
  const [filter, setFilter] = useState("");
  const [reservation, setReservation] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedProductForDelete, setSelectedProductForDelete] =
    useState(null);
  const [selectedApproveReject, setSelectedApproveReject] = useState([]);
  const [showApproveReject, setShowApproveReject] = useState(false);
  const [selectedProductForUpdate, setSelectedProductForUpdate] =
    useState(null);
  const [showReservationPopup, setShowReservationPopup] = useState(false);
  const [isAddMode, setIsAddMode] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRowForActions, setSelectedRowForActions] =
    React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isSearchFocused, setIsSearchFocused] = React.useState(false);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const initialSortedRows = reservation;
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const totalPages = Math.ceil(sortedRows.length / pageSize);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

  const currentYear = new Date().getFullYear();
  const currentMonthIndex = new Date().getMonth();
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [yearList, setYearList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [errors, setErrors] = useState({});
  const [showPop, setShowPop] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const [currentPage, setCurrentPage] = useState(1);
  const [showTextField, setShowTextField] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    setMonthList(months);
    setYearList(getYears());
    getListOfEmployees();
  }, []);

  const validate = () => {
    const errors = {};
    if (!selectedEmployee) {
      errors.employee = "Employee is required";
    }
    if (!selectedYear) {
      errors.year = "Year is required";
    }
    if (!selectedMonth) {
      errors.month = "Month is required";
    }
    return errors;
  };

  const handleSubmit = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      getLeaveMasterData();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleSelectChange = (setter, errorKey) => (option) => {
    setShowPop(false);
    setter(option?.value);
    setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: null }));
  };

  const columns = [
    {
      field: "EmployeeName",
      headerName: <span>Employee Name</span>,
      flex: 1,
    },
    {
      field: "LeaveTypeName",
      headerName: <span>Leave Type</span>,
      flex: 1,
    },
    {
      field: "LeaveCategoryName",
      headerName: <span>Leave Category</span>,
      flex: 1,
    },
    {
      field: "StartDate",
      headerName: <span>StartDate</span>,
      flex: 1,
    },
    {
      field: "EndDate",
      headerName: <span>EndDate</span>,
      flex: 1,
    },
    {
      field: "TotalDays",
      headerName: <span>Total Days</span>,
      flex: 1,
    },
    {
      field: "statusName",
      headerName: <span>Status</span>,
      flex: 1,
    },
    {
      field: "Reason",
      headerName: <span>Reason</span>,
      flex: 1,
    },
    selectedStatus === "Rejected"
      ? {
          field: "RejectedReason",
          headerName: <span>Rejected Reason</span>,
          flex: 1,
        }
      : null,
    (props.currentMenu.Edit || props.currentMenu.Delete) &&
    selectedEmployee !== props.currentMenu.loggedInId &&
    selectedStatus === "Pending"
      ? {
          field: "actions",
          headerName: "Actions",
          flex: 1,
          renderCell: (params, index) => {
            return (
              <div>
                <IconButton
                  color="primary"
                  aria-label="actions"
                  onClick={(event) => handleMenuOpen(event, params.row)}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <MoreVertIcon style={{ color: "gray" }} />
                </IconButton>
                <Menu
                  id="actions-menu"
                  anchorEl={anchorEl}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  sx={{
                    "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                      {
                        boxShadow: "none",
                        backgroundColor: "#EEE",
                        borderRadius: "8px",
                      },
                    "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                      paddingTop: "0",
                      paddingBottom: "0",
                    },
                  }}
                >
                  {props.currentMenu.Edit &&
                  props.currentMenu.loggedInId ===
                    selectedEmployee.toString() ? (
                    <MenuItemWrapper
                      onClick={() => {
                        handleMenuSelect("edit");
                      }}
                    >
                      <IconWrapper>
                        {/* <EditIcon /> */}
                        <img src={edit} />
                      </IconWrapper>
                      <span style={{ fontWeight: "400" }}>Edit</span>
                    </MenuItemWrapper>
                  ) : null}

                  {props.currentMenu.Delete &&
                  props.currentMenu.loggedInId ===
                    selectedEmployee.toString() ? (
                    <MenuItemWrapper onClick={() => handleMenuSelect("delete")}>
                      <IconWrapper>
                        {/* <IoMdTrash /> */}
                        <img src={trash} />
                      </IconWrapper>{" "}
                      <span style={{ fontWeight: "400" }}>Delete</span>
                    </MenuItemWrapper>
                  ) : null}

                  {props.currentMenu.loggedInId !==
                  selectedEmployee.toString() ? (
                    <MenuItemWrapper
                      onClick={() => handleMenuSelect("approve")}
                    >
                      <IconWrapper>
                        <CheckCircleOutlineRoundedIcon
                          style={{ color: "#6C7D02" }}
                        />
                      </IconWrapper>{" "}
                      <span style={{ fontWeight: "400" }}>Approve</span>
                    </MenuItemWrapper>
                  ) : null}

                  {props.currentMenu.loggedInId !==
                  selectedEmployee.toString() ? (
                    <MenuItemWrapper onClick={() => handleMenuSelect("reject")}>
                      <IconWrapper>
                        <img src={trash} />
                      </IconWrapper>{" "}
                      <span style={{ fontWeight: "400" }}>Reject</span>
                    </MenuItemWrapper>
                  ) : null}
                </Menu>
              </div>
            );
          },
        }
      : null,
  ].filter(Boolean);

  const handleAproveReject = (selectedRow, option) => {
    setSelectedApproveReject(selectedRow, option);
    setShowApproveReject(true);
  };

  const handleApproveRejectAPI = async (selectedRow) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.approveRejectLeave,
        requestBody: {
          ...selectedRow,
          selectedStatus: selectedOption === "approve" ? 79 : 78,
          rejectedReason: inputValue,
        },
      });

      if (response?.success) {
        toast.success(
          <div className="custom_Toast">
            <h5>Leave Updated</h5>
            Leave has been updated successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            hideProgressBar: true,
            transition: Zoom,
            style: {
              width: "350px",
              backgroundColor: "#0ABE75",
              color: "#FFFFFF",
            },
            autoClose: 1500,
            closeButton: true,
          }
        );
        getLeaveMasterData();
      } else {
        console.error("Error deleting reservation:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setShowApproveReject(false);
    }
  };

  const handleRejectWithReason = async (selectedRow) => {
    if (inputValue.length === 0) {
      toast.error("Reason is Required.");
    } else {
      await handleApproveRejectAPI(selectedRow);
    }
  };

  const handleApprove = async (selectedRow) => {
    await handleApproveRejectAPI(selectedRow);
  };

  const handleDeleteClick = (selectedRow) => {
    setSelectedProductForDelete(selectedRow);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async (selectedRow) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.deleteLeaveData,
        requestBody: selectedRow,
      });

      if (response?.success) {
        toast.success(
          <div className="custom_Toast">
            <h5>Leave deleted</h5>
            Leave has been deleted successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            hideProgressBar: true,
            transition: Zoom,
            style: {
              width: "350px",
              backgroundColor: "#0ABE75",
              color: "#FFFFFF",
            },
            autoClose: 1500,
            closeButton: true,
          }
        );
        getLeaveMasterData();
      } else {
        console.error("Error deleting reservation:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setShowDeleteConfirmation(false);
    }
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowForActions(row);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRowForActions(null);
    setIsMenuOpen(false);
  };
  const handleMenuSelect = (option) => {
    if (option === "edit") {
      handleUpdateClick(selectedRowForActions);
    } else if (option === "delete") {
      handleDeleteClick(selectedRowForActions);
    } else if (option === "checkIn") {
      handleCheckInClick(selectedRowForActions);
    } else if (option === "approve") {
      handleAproveReject(selectedRowForActions, option);
      setSelectedOption(option);
      setShowTextField(false);
    } else if (option === "reject") {
      handleAproveReject(selectedRowForActions, option);
      setInputValue("");
      setSelectedOption(option);
      setShowTextField(true);
    }
    handleMenuClose();
  };

  const handleUpdateClick = (selectedRow) => {
    setSelectedProductForUpdate(selectedRow);
    setIsAddMode(false);
    setShowReservationPopup(true);
  };

  const [checkInPopUp, setCheckInPopUp] = useState(false);
  const handleCheckInClick = (selectedRow) => {
    setSelectedProductForUpdate(selectedRow);
    setCheckInPopUp(true);
  };

  const handleAddProductClick = () => {
    setIsAddMode(true);
    setShowReservationPopup(true);
  };

  const handleReservationPopupClose = () => {
    setShowReservationPopup(false);
    setCheckInPopUp(false);
    setIsAddMode(true);
    setSelectedProductForUpdate(null);
    getLeaveMasterData();
  };

  const getListOfEmployees = async () => {
    const response = await callApi({
      apiURL: Constants.API.getEmployessSubData,
    });
    if (response?.success) {
      const { employeeList = [] } = response.data;
      const mappedData = employeeList?.map((item, index) => {
        return {
          ...item,
          label: item.EmployeeName,
          value: item.EmployeeMasterId,
        };
      });
      setEmployeeList(mappedData);
    } else {
      console.log("error");
    }
  };

  const getLeaveMasterData = async () => {
    if (selectedEmployee && selectedMonth && selectedStatus) {
      const response = await callApi({
        apiURL: Constants.API.getLeaveData,
        requestBody: { selectedMonth, selectedYear, selectedEmployee },
      });
      if (response?.success) {
        setShowPop(true);
        const { leaveList = [] } = response.data;

        const mappedData = leaveList?.map((item, index) => {
          return {
            ...item,
            id: index + 1,
            StartDate: getDisplayDate(item.StartDate),
            EndDate: getDisplayDate(item.EndDate),
          };
        });

        setReservation(mappedData);
      } else {
        console.log("error");
      }
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(1);
  };

  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };
  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      return (
        <span className="arrow-icon">
          {sortOrder === "asc" ? (
            <i className="fa-solid fa-arrow-up" />
          ) : (
            <i className="fa-solid fa-arrow-down" />
          )}
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
    if (reservation?.length > 0) {
      setSortedRows(reservation);
    }
  }, [reservation.length > 0]);

  useEffect(() => {
    if (sortedColumn) {
      const sorted = reservation?.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
  }, [sortedColumn, sortOrder, reservation]);

  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocused(false);
  };

  useEffect(() => {
    const searched = reservation.filter((item) => {
      if (!filter) {
        return true;
      } else {
        return (
          item.EmployeeName !== null &&
          item.EmployeeName.toLowerCase().includes(filter.toLowerCase())
        );
      }
    });
    setSortedRows(searched);
  }, [filter, reservation]);

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setSelectedStatus(newStatus);
    }
  };

  const filteredReservations =
    selectedStatus === "all"
      ? reservation
      : reservation.filter((item) => item.statusName === selectedStatus);

  const paginatedRows = filteredReservations.slice(startIndex, endIndex);

  return (
    <>
      {showReservationPopup && (
        <AddLeave
          onClose={handleReservationPopupClose}
          isAddMode={isAddMode}
          leaveData={selectedProductForUpdate}
        />
      )}
      <div className="reservation_MainPae">
        <div className="reservation_sect-1">
          <div className="reservation_manage">
            <h1 className="manage_page_heading">Leave</h1>
          </div>
          {props?.currentMenu?.Add ? (
          <>
            <AddButton
              buttonText={"Add Leave"}
              onClick={handleAddProductClick}
            />
            </>
        ) : (
          <></>
        )}
        </div>

        <div
          className="formfielddata"
          style={{ justifyContent: "flex-start" }}
        >
          <div className="employee-detail-item">
            <label className="employee-detail-label">
              Employee<sup>*</sup>
            </label>
            <MySelect
              name="employee"
              options={employeeList}
              placeholder="Select Employee"
              onChange={handleSelectChange(setSelectedEmployee, "employee")}
            />
            {errors.employee && <div className="error">{errors.employee}</div>}
          </div>
          <div className="employee-detail-item">
            <label className="employee-detail-label">
              Year<sup>*</sup>
            </label>
            <MySelect
              name="year"
              options={yearList}
              placeholder="Select Year"
              onChange={handleSelectChange(setSelectedYear, "year")}
            />
            {errors.year && <div className="error">{errors.year}</div>}
          </div>
          <div className="employee-detail-item">
            <label className="employee-detail-label">
              Month<sup>*</sup>
            </label>
            <MySelect
              name="month"
              options={monthList}
              placeholder="Select Month"
              onChange={handleSelectChange(setSelectedMonth, "month")}
            />
            {errors.month && <div className="error">{errors.month}</div>}
          </div>
          <div
            className="employee-detail-item"
            style={{ width: "0%", marginTop: "27px" }}
          >
            <button className="button_add_" onClick={handleSubmit}>
              Go
            </button>
          </div>
        </div>
        <div className="ReservationTable">
          <div className="Table">
            <div className="reservation_sect-2">
              <SearchBar value={filter} onChange={handleFilterChange} />
              <div className="employeemaster_toggle">
                <ToggleButtonGroup
                  className="ToggleButtonGroups"
                  value={selectedStatus}
                  exclusive
                  onChange={handleStatusChange}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    value="Pending"
                    aria-label="Pending"
                    sx={{ textTransform: "none", padding: "8px 16px" }}
                  >
                    Pending
                  </ToggleButton>
                  <ToggleButton
                    value="Approved"
                    aria-label="Approved"
                    sx={{ textTransform: "none", padding: "8px 16px" }}
                  >
                    Approved
                  </ToggleButton>
                  <ToggleButton
                    value="Rejected"
                    aria-label="Rejected"
                    sx={{
                      textTransform: "none",
                      padding: "8px 16px",
                      borderRadius: "0px 8px 8px 0px",
                    }}
                  >
                    Rejected
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            <DeleteConfirmationDialog
              open={showDeleteConfirmation}
              handleClose={() => setShowDeleteConfirmation(false)}
              handleConfirm={() =>
                handleConfirmDelete(selectedProductForDelete)
              }
              product={selectedProductForDelete?.FullName + " booking details"}
            />
            <ConfirmApproveReject
              open={showApproveReject}
              handleClose={() => setShowApproveReject(false)}
              handleConfirm={() =>
                selectedOption === "approve"
                  ? handleApprove(selectedApproveReject)
                  : handleRejectWithReason(selectedApproveReject)
              }
              product={" booking details"}
              heading={selectedOption === "approve" ? "Approve" : "Reject"}
              showTextField={showTextField}
              inputValue={inputValue}
              handleInputChange={(e) => setInputValue(e.target.value)}
            />
            {showPop && (
              <div className="Property_Location_DataGrid">
                <div className="propActivity-table">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableHeaderCell">
                            Sr. No.
                          </TableCell>
                          {columns.map((column, i) => (
                            <TableCell
                              key={i}
                              className="tableHeaderCell"
                              onClick={() => handleSortClick(column.field)}
                            >
                              {column.headerName}
                              {renderArrowIcon(column.field)}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {paginatedRows?.length > 0 ? (
                        <TableBody>
                          {paginatedRows.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                              <TableCell className="tableBodyCell">
                                {rowIndex + 1}
                              </TableCell>
                              {columns.map((column, colIndex) => (
                                <TableCell
                                  key={colIndex}
                                  className="tableBodyCell"
                                >
                                  {row &&
                                    (column.renderCell
                                      ? column.renderCell({ row })
                                      : row[column.field])}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <p style={{ display: "flex" }}>No data found</p>
                      )}
                    </Table>
                  </TableContainer>
                  <Pagination
                    count={totalPages}
                    page={page}
                    hideNextButton
                    hidePrevButton
                    onChange={(event, value) => setPage(value)}
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        page={formattedPageNumber(item.page)}
                        className={item.selected ? "pagination-selected" : ""}
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
