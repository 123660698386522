import React, { useState, useEffect } from 'react';
import callApi from '../ApiCall';
import styled from 'styled-components';
import moment from 'moment'; 
import Constants from '../Constants';

const PopupContainer = styled.div`
  position: fixed;
  width: 80%;
  max-width: 1000px; 
  height: auto;
  max-height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  z-index: 1001;
  overflow: auto; 
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0px;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 18px;
`;

const TableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Table = styled.table`
  width: 48%; 
  border-collapse: collapse;
  border: 1px solid #ddd; 
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f5f5f5;
  }
`;

const TableCell = styled.td`
  padding: 6px;
  text-align: left;
  border: 1px solid #ddd; /* Border around each cell */
`;

const ProfilePopup = ({ isPopupOpen, handleClosePopup }) => {
  const [profile, setProfile] = useState(null);

  const getProfile = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getProfileData,
      });
  
      if (response?.success) {
        const employeeProfile = response.data.response[0];
  
        // Example of handling null values and date formatting
        const formatAndSetDate = (dateString) => {
          return dateString ? moment(dateString).format('D-MMM-YYYY') : '';
        };
  
        employeeProfile.DOB = formatAndSetDate(employeeProfile.DOB);
        employeeProfile.DOJ = formatAndSetDate(employeeProfile.DOJ);
        employeeProfile.DOC = formatAndSetDate(employeeProfile.DOC);
        employeeProfile.PDOC = formatAndSetDate(employeeProfile.PDOC);
        employeeProfile.DOR = formatAndSetDate(employeeProfile.DOR);
        employeeProfile.DOL = formatAndSetDate(employeeProfile.DOL);
        employeeProfile.PDOL = formatAndSetDate(employeeProfile.PDOL);
        employeeProfile.DOA = formatAndSetDate(employeeProfile.DOA);
        employeeProfile.RelievingDate = formatAndSetDate(employeeProfile.RelievingDate);
  
        setProfile(employeeProfile);
      } else {
        console.error('Error fetching employee data:', response.error);
      }
    } catch (error) {
      console.error('Error during API call:', error);
    }
  };
  

  useEffect(() => {
    if (isPopupOpen) {
      getProfile();
    }
   
  }, [isPopupOpen]);

  return (
    <>
      {isPopupOpen && (
        <>
          <div className='Overlay' onClick={handleClosePopup} />
          <PopupContainer>
            <CloseButton onClick={handleClosePopup}>X</CloseButton>
            <Title>Profile</Title>
            <hr/>
            <TableContainer>
              <Table>
                <tbody>
                  <TableRow>
                    <TableCell>Employee Name</TableCell>
                    <TableCell>{profile?.EmployeeName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Date of Birth</TableCell>
                    <TableCell>{profile?.DOB}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Gender</TableCell>
                    <TableCell>{profile?.Gender}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Present Address</TableCell>
                    <TableCell>{profile?.PresentAddress}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Permanent Address</TableCell>
                    <TableCell>{profile?.PermanentAddress}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>{profile?.MobileNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Email Address</TableCell>
                    <TableCell>{profile?.EmailAddress}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Marital Status</TableCell>
                    <TableCell>{profile?.MaritalStatusName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Property Location Name</TableCell>
                    <TableCell>{profile?.PropertyLocationName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Property Name</TableCell>
                    <TableCell>{profile?.PropertyName}</TableCell>
                  </TableRow>
                </tbody>
              </Table>
              <Table>
                <tbody>
                  <TableRow>
                    <TableCell>Designation</TableCell>
                    <TableCell>{profile?.Designation}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>DOJ</TableCell>
                    <TableCell>{profile?.DOJ}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>DOC</TableCell>
                    <TableCell>{profile?.DOC}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>PDOC</TableCell>
                    <TableCell>{profile?.PDOC}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>DOR</TableCell>
                    <TableCell>{profile?.DOR}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>DOL</TableCell>
                    <TableCell>{profile?.DOL}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>PDOL</TableCell>
                    <TableCell>{profile?.PDOL}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>DOA</TableCell>
                    <TableCell>{profile?.DOA}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Relieving Date</TableCell>
                    <TableCell>{profile?.RelievingDate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Department</TableCell>
                    <TableCell>{profile?.DepartmentName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Reporting To</TableCell>
                    <TableCell>{profile?.Report}</TableCell>
                  </TableRow>
                </tbody>
              </Table>
            </TableContainer>
          </PopupContainer>
        </>
      )}
    </>
  );
};

export default ProfilePopup;
