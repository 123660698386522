import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import callApi from "../../ApiCall";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MySelect from "../../common/SingleSelect";
import DateSelect from "../../common/DatePicker/Datepicker";
import moment from "moment";
import "./EmployeeMaster.css";
const EmployeeMasterPopup = ({
  onClose,
  isAddMode,
  selectedRowForActions,
  selectedEmployee,
}) => {
  const [reportingToList, setReportingToList] = useState([]);
  const [reportingTo, setReportingTo] = useState(
    isAddMode ? "" : { value: selectedRowForActions?.ReportingTo }
  );

  const [propertyDetail, setPropertyDetail] = useState([]);
  const [property, setProperty] = useState(
    isAddMode ? "" : { value: selectedRowForActions?.PropertyLocationId }
  );

  const [genderList, setGenderList] = useState([]);
  const [selectGender, setSelectGender] = useState(
    isAddMode
      ? ""
      : {
          value: selectedRowForActions?.GenderId,
        }
  );

  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState(
    isAddMode
      ? ""
      : {
          value: selectedRowForActions?.RoleMasterId,
        }
  );

  const [departmentList, setDepartmentList] = useState([]);
  const [department, setDepartment] = useState(
    isAddMode
      ? ""
      : {
          value: selectedRowForActions?.Department,
        }
  );

  const [designationList, setDesignationList] = useState([]);
  const [designation, setDesignation] = useState(
    isAddMode
      ? ""
      : {
          value: selectedRowForActions?.DesignationId,
        }
  );

  const [statusList, setStatusList] = useState([]);
  const [status, setStatus] = useState(
    isAddMode
      ? ""
      : {
          value: selectedRowForActions?.WorkingStatus,
        }
  );

  const [bloodGroupList, setBloodGroupList] = useState([]);
  const [bloodGroup, setBloodGroup] = useState(
    isAddMode
      ? ""
      : {
          value: selectedRowForActions?.BloodGroupId,
        }
  );

  const [weekDayOffList, setWeekDayOffList] = useState([]);
  const [weekDayOff, setWeekDayOff] = useState(
    isAddMode
      ? ""
      : {
          value: selectedRowForActions?.WeekDayOff,
        }
  );

  const [resourceTypeList, setResourceTypeList] = useState([]);
  const [resourceType, setResourceType] = useState(
    isAddMode
      ? ""
      : {
          value: selectedRowForActions?.ResourceType,
        }
  );

  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState(
    isAddMode
      ? ""
      : {
          value: selectedRowForActions?.MaritalStatus,
        }
  );

  const [employeeStatusList, setEmployeeStatusList] = useState([]);
  const [employeeStatus, setEmployeeStatus] = useState(
    isAddMode
      ? ""
      : {
          value: status
            ? selectedRowForActions?.status == "Active"
              ? 1
              : 0
            : 1,
        }
  );

  const initialValues = {
    LocationName: isAddMode ? "" : selectedRowForActions?.PropertyLocationId,
    FirstName: isAddMode ? "" : selectedRowForActions?.FirstName,
    MiddleName: isAddMode ? "" : selectedRowForActions?.MiddleName,
    LastName: isAddMode ? "" : selectedRowForActions?.LastName,
    Designation: isAddMode ? "" : selectedRowForActions?.DesignationId,
    DepartmentId: isAddMode ? "" : selectedRowForActions?.Department,
    Status: isAddMode ? "" : selectedRowForActions?.WorkingStatus,
    BloodGroup: isAddMode ? "" : selectedRowForActions?.BloodGroupId,
    Role: isAddMode ? "" : selectedRowForActions?.RoleMasterId,
    ReportingTo: isAddMode ? "" : selectedRowForActions?.ReportingTo,
    Gender: isAddMode ? "" : selectedRowForActions?.GenderId,
    MobileNumber: isAddMode ? "" : selectedRowForActions?.MobileNumber,
    DOJ: isAddMode ? "" : selectedRowForActions?.DOJ,
    DOC: isAddMode ? "" : selectedRowForActions?.DOC,
    PDOC: isAddMode ? "" : selectedRowForActions?.PDOC,
    DOR: isAddMode ? "" : selectedRowForActions?.DOR,
    PDOL: isAddMode ? "" : selectedRowForActions?.PDOL,
    RelievingDate: isAddMode ? "" : selectedRowForActions?.RelievingDate,
    DOL: isAddMode ? "" : selectedRowForActions?.DOL,
    WeekDayOff: isAddMode ? "" : selectedRowForActions?.WeekDayOff,
    CardNo: isAddMode ? "" : selectedRowForActions?.CardNo,
    ResourceType: isAddMode ? "" : selectedRowForActions?.ResourceType,
    DOB: isAddMode ? "" : selectedRowForActions?.DOB,
    MaritalStatus: isAddMode ? "" : selectedRowForActions?.MaritalStatus,
    DOA: isAddMode ? "" : selectedRowForActions?.DOA,
    EmailAddress: isAddMode ? "" : selectedRowForActions?.EmailAddress,
    PanNo: isAddMode ? "" : selectedRowForActions?.PanNo,
    AadharNo: isAddMode ? "" : selectedRowForActions?.AadharNo,
    PresentAddress: isAddMode ? "" : selectedRowForActions?.PresentAddress,
    City: isAddMode ? "" : selectedRowForActions?.City,
    State: isAddMode ? "" : selectedRowForActions?.State,
    Country: isAddMode ? "" : selectedRowForActions?.Country,
    Zip: isAddMode ? "" : selectedRowForActions?.Zip,
    PermanentAddress: isAddMode ? "" : selectedRowForActions?.PermanentAddress,
    PermanentCountry: isAddMode ? "" : selectedRowForActions?.PermanentCountry,
    PermanentState: isAddMode ? "" : selectedRowForActions?.PermanentState,
    PermanentCity: isAddMode ? "" : selectedRowForActions?.PermanentCity,
    PermanentZip: isAddMode ? "" : selectedRowForActions?.PermanentZip,
    employeeStatus: isAddMode ? "" : selectedRowForActions?.Active,
  };
  const handleReportingToChange = (option) => {
    setReportingTo(option);
  };
  const handlePropertyChange = (option) => {
    setProperty(option);
  };
  const handleRoleChange = (option) => {
    setRole(option);
  };
  const handleGenderChange = (option) => {
    setSelectGender(option);
  };
  const handleDepartmentChange = (option) => {
    setDepartment(option);
  };
  const handleJobStatusChange = (option) => {
    setStatus(option);
  };
  const handleDayOffChange = (option) => {
    setWeekDayOff(option);
  };
  const handleBloodGroupChange = (option) => {
    setBloodGroup(option);
  };
  const handleResourceChange = (option) => {
    setResourceType(option);
  };
  const handleMaritalStatusChange = (option) => {
    setMaritalStatus(option);
  };
  const handleDesignationChange = (option) => {
    setDesignation(option);
  };
  const handleEmployeeStatusChange = (option) => {
    setEmployeeStatus(option);
  };
  const validateSchema = Yup.object({
    LocationName: Yup.string().required("Location is required"),
    FirstName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "It must contain only letters and spaces")
      .max(20, "First Name must be at most 20 characters")
      .required("First Name is required"),
    MiddleName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "It must contain only letters and spaces")
      .max(20, "Middle Name must be at most 20 characters"),
    LastName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "It must contain only letters and spaces")
      .max(20, "Last Name must be at most 20 characters")
      .required("Last Name is required"),
    Role: Yup.string().required("Role is Required"),
    ReportingTo: Yup.string().required("Reporting to is Required"),
    Designation: Yup.string().required("Designation is required"),
    DepartmentId: Yup.string().required("Department is required"),
    Status: Yup.string().required("Working-status is required"),
    BloodGroup: Yup.string().required("BloodGroup is required"),
    Gender: Yup.string().required("Gender is required"),
    MobileNumber: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Invalid mobile no."
      )
      .required("Mobile Number is required"),
    DOJ: Yup.string().required("Date of Joining is required"),
    DOC: Yup.string(),
    PDOC: Yup.string(),
    WeekDayOff: Yup.string().required("WeekDay off is required"),
    CardNo: Yup.string()
      .matches(/^[A-Za-z0-9]*$/, "It must contain only letters and numbers")
      .max(20, "Card Number must be at most 20 characters")
      .required("Card Number is required"),
    ResourceType: Yup.string().required("Resource Type is required"),
    DOB: Yup.string().required("Date of Birth is required"),
    MaritalStatus: Yup.string().required("Marital-Status is required"),
    DOA: Yup.string(),
    EmailAddress: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    PanNo: Yup.string()
      .matches(/^[A-Za-z0-9]*$/, "It must contain only letters and numbers")
      .max(10, "Pan Number must be at most 10 characters")
      .required("Pan Number is required"),
    AadharNo: Yup.string()
      .matches(/^[0-9]*$/, "It must contain only numbers")
      .max(12, "Aadhar Number must be at most 12 digits")
      .required("Aadhar Number is required"),
    PresentAddress: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]*$/,
        "It must contain only letters, numbers, and spaces"
      )
      .max(100, "Present Address must be at most 100 characters")
      .required("Present Address is required"),
    PermanentAddress: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]*$/,
        "It must contain only letters, numbers, and spaces"
      )
      .max(100, "Permanent Address must be at most 100 characters")
      .required("Permanent Address is required"),
    employeeStatus: Yup.string().required("Employee Status is required"),
    DOR: Yup.string(),
    PDOL: Yup.string(),
    DOL: Yup.string(),
    RelievingDate: Yup.string(),
  });

  const handleSubmit = async (values) => {
    values.employeeStatus = employeeStatus?.value;
    values.Gender = selectGender?.value;
    values.LocationName = property?.value;
    values.Role = role?.value;
    values.DepartmentId = department?.value;
    values.Status = status?.value;
    values.BloodGroup = bloodGroup?.value;
    values.WeekDayOff = weekDayOff?.value;
    values.ResourceType = resourceType?.value;
    values.MaritalStatus = maritalStatus?.value;
    values.Designation = designation?.value;
    values.ReportingTo = reportingTo?.value;

    const dateKeys = [
      "DOJ",
      "DOC",
      "PDOC",
      "DOR",
      "PDOL",
      "DOL",
      "RelievingDate",
      "DOB",
      "DOA",
    ];
    dateKeys.forEach((key) => {
      if (values[key]) {
        values[key] = moment(values[key], "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    });
    const params = { ...values };
    try {
      let response;
      if (isAddMode) {
        response = await callApi({
          apiURL: "employeemaster/addEmployee",
          requestBody: params,
        });
      } else {
        response = await callApi({
          apiURL: "employeemaster/updateEmployee",
          requestBody: {
            ...params,
            employeeMasterId: selectedRowForActions?.EmployeeMasterId,
            updatedDate: selectedRowForActions?.UpdatedDate,
          },
        });
      }
      if (response?.success) {
        const actionMessage = isAddMode ? "Added Employee" : "Updated Employee";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            <span>
              Employee has been {isAddMode ? "Added" : "Updated"} successfully
            </span>
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            hideProgressBar: true,
            className: "toast_pop",
            autoClose: 1500,
            transition: Zoom,
            closeButton: true,
            onClose: () => {
              onClose();
            },
          }
        );
      } else {
        console.error("Error fetching guest activity data:", response.error);
      }
      console.log("response", response);
    } catch (error) {
      console.error(error);
    }
    console.log(values, "helll");
    onClose();
  };

  //   get api for dropdowns
  const getReportingToo = async () => {
    try {
      const response = await callApi({
        apiURL: "employeemaster/getAsignedEmplyee",
      });
      const dropdownData = response?.data?.response?.map((val) => ({
        Category: val?.Category,
        Data: JSON.parse(val?.Data),
      }));

      const reportingTo = dropdownData[0]?.Data?.map((val) => ({
        value: val?.Emp_id,
        label: val?.Emp_Name,
      }));
      setReportingToList(reportingTo);
      console.log("response", reportingTo);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  const getAddEmployee = async () => {
    try {
      const response = await callApi({
        apiURL: "employeemaster/getAddEmployee",
      });
      const dropdownData = response?.data?.response?.map((val) => ({
        Category: val?.Category,
        Data: JSON.parse(val?.Data),
      }));

      const roles = dropdownData.find((item) => item.Category === "Role");
      const roleData =
        roles &&
        roles?.Data.map((item) => ({
          label: item?.RoleName,
          value: item?.RoleId,
        }));
      setRoleList(roleData);

      // Active-inactive status
      const employeeStatus = dropdownData.find(
        (item) => item.Category === "EmployeeStatus"
      );
      const employeeStatusData =
        employeeStatus &&
        employeeStatus?.Data?.map((item) => ({
          value: item?.statusId,
          label: item?.statusName,
        }));
      setEmployeeStatusList(employeeStatusData);
      // bloodgroup
      const bloodGroup = dropdownData.find(
        (item) => item.Category === "BloodGroup"
      );
      const bloodGroupData =
        bloodGroup &&
        bloodGroup?.Data?.map((item) => ({
          value: item?.ID,
          label: item?.Name,
        }));
      setBloodGroupList(bloodGroupData);

      // Department name
      const department = dropdownData.find(
        (item) => item.Category === "Department"
      );
      const departnmentData =
        department &&
        department?.Data?.map((item) => ({
          label: item?.Department,
          value: item?.DepartmentId,
        }));
      console.log("department", department);
      setDepartmentList(departnmentData);

      // Designation name
      const designation = dropdownData?.find(
        (item) => item.Category === "Designation"
      );
      const designationData =
        designation &&
        designation?.Data?.map((item) => ({
          label: item?.Designation,
          value: item?.DesignationId,
        }));
      setDesignationList(designationData);

      // Gender
      const gender = dropdownData.find((item) => item.Category === "Gender");
      const genderData =
        gender &&
        gender?.Data?.map((value) => ({
          label: value?.Name,
          value: value?.ID,
        }));
      setGenderList(genderData);

      // Marita Status
      const maritalStatus = dropdownData.find(
        (item) => item.Category === "MaritalStatus"
      );
      const maritalData =
        maritalStatus &&
        maritalStatus?.Data?.map((value) => ({
          label: value?.Name,
          value: value?.ID,
        }));
      setMaritalStatusList(maritalData);

      // resource type
      const resource = dropdownData.find(
        (item) => item.Category === "ResourceType"
      );
      const resourceTypeData =
        resource &&
        resource?.Data?.map((item) => ({
          label: item?.Name,
          value: item?.ID,
        }));
      setResourceTypeList(resourceTypeData);

      // Week day off
      const weekDay = dropdownData.find(
        (item) => item.Category === "WeekDayOff"
      );
      const dayOffData =
        weekDay &&
        weekDay?.Data?.map((value) => ({
          value: value?.ID,
          label: value?.Name,
        }));
      setWeekDayOffList(dayOffData);

      // working Status
      const workingStatus = dropdownData.find(
        (item) => item.Category === "WorkingStatus"
      );
      const statusData =
        workingStatus &&
        workingStatus?.Data?.map((item) => ({
          label: item.Name,
          value: item?.ID,
        }));
      setStatusList(statusData);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getPropertyDetail = async () => {
    try {
      const response = await callApi({
        apiURL: "employeemaster/getPropertyDetails",
      });
      const dropdownData = response.data.response.map((val) => ({
        Category: val?.Category,
        Data: JSON.parse(val?.Data),
      }));

      const propertyList = dropdownData[0]?.Data?.map((val) => ({
        value: val?.LocationID,
        label: `${val?.LocationName} - ${val?.PropertyName}`,
      }));

      setPropertyDetail(propertyList);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  useEffect(() => {
    getAddEmployee();
    getPropertyDetail();
    getReportingToo();
  }, []);
  console.log("selectedRowForActions", selectedRowForActions);
  return selectedEmployee ? (
    <>
      <div className="employee-details-container">
        <Formik initialValues={selectedEmployee}>
          <Form className="employee-details-form">
            <fieldset className="fieldset">
              <legend>Employee Details</legend>
              <div className="mainBorder">
                {/* First set of fields */}
                <div className="formfielddata">
                  {/* Location Name */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="First Name">
                      Location Name
                    </label>
                    <Field
                      type="text"
                      id="LocationName"
                      name="LocationName"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* Firstname */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="First Name">
                      First Name
                    </label>
                    <Field
                      type="text"
                      id="FirstName"
                      name="FirstName"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* MiddleName */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Middle Name"
                    >
                      Middle Name
                    </label>
                    <Field
                      type="text"
                      id="MiddleName"
                      name="MiddleName"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                </div>

                {/* Second set of fields */}
                <div className="formfielddata">
                  {/* LastName */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title=" Last Name">
                      Last Name
                    </label>
                    <Field
                      type="text"
                      id="LastName"
                      name="LastName"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* Role */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="Role">
                      Role
                    </label>
                    <Field
                      type="text"
                      id="RoleName"
                      name="RoleName"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* Reporting To */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Reporting To"
                    >
                      Reporting To
                    </label>
                    <Field
                      type="text"
                      id="Report"
                      name="Report"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* Designation */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Designation"
                    >
                      Designation
                    </label>
                    <Field
                      type="text"
                      id="Designation"
                      name="Designation"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* DepartmentId */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="Department">
                      Department
                    </label>
                    <Field
                      type="text"
                      id="DepartmentName"
                      name="DepartmentName"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* Status */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="WorkingStatus"
                    >
                      Status
                    </label>
                    <Field
                      type="text"
                      id="WorkingStatusName"
                      name="WorkingStatusName"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                </div>

                {/* Third set of fields */}
                <div className="formfielddata">
                  {/* BloodGroup */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Blood Group"
                    >
                      Blood Group
                    </label>
                    <Field
                      type="text"
                      id="BloodGroupName"
                      name="BloodGroup"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* Gender */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="Gender">
                      Gender
                    </label>
                    <Field
                      type="text"
                      id="Gender"
                      name="Gender"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* MobileNumber */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Mobile Number"
                    >
                      Mobile
                    </label>
                    <Field
                      type="text"
                      id="MobileNumber"
                      name="MobileNumber"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                </div>

                {/* Fourth set of fields */}
                <div className="formfielddata">
                  {/* DOJ */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Date of Joining"
                    >
                      DOJ
                    </label>
                    <Field
                      type="text"
                      id="DOJ"
                      name="DOJ"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* Doc */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Predefined Date Of Confirmation"
                    >
                      DOC
                    </label>
                    <Field
                      type="text"
                      id="DOC"
                      name="DOC"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* PDOC */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Predefined Date Of Confirmation"
                    >
                      PDOC
                    </label>
                    <Field
                      type="text"
                      id="PDOC"
                      name="PDOC"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                </div>

                {/* Fifth set of fields */}
                <div className="formfielddata">
                  {/* DOR */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Date Of Resignation"
                    >
                      DOR
                    </label>
                    <Field
                      type="text"
                      id="DOR"
                      name="DOR"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* PDOL */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Planned Date Of Leaving"
                    >
                      PDOL
                    </label>
                    <Field
                      type="text"
                      id="PDOL"
                      name="PDOL"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* RelievingDate */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Relieving Date"
                    >
                      Relieving Date
                    </label>
                    <Field
                      type="text"
                      id="RelievingDate"
                      name="RelievingDate"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                </div>
                {/* sixth set of fields */}
                <div className="formfielddata">
                  {/* DOL */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Date Of Leaving"
                    >
                      DOL
                    </label>
                    <Field
                      type="text"
                      id="DOL"
                      name="DOL"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* weekdaysoff */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="WeekDays Off"
                    >
                      WeekDays Off
                    </label>
                    <Field
                      type="text"
                      id="WeekDayOffName"
                      name="WeekDayOffName"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* Card No */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="Card No">
                      Card No
                    </label>
                    <Field
                      type="text"
                      id="CardNo"
                      name="CardNo"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                </div>
                {/* Resource Type */}
                <div className="employee-detail-item">
                  <label
                    className="employee-detail-label"
                    title="Resource Type"
                  >
                    Resource Type
                  </label>
                  <Field
                    type="text"
                    id="ResourceTypeName"
                    name="ResourceTypeName"
                    disabled
                    className="employee-detail-input"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="fieldset">
              <legend>Personal</legend>
              <div className="mainBorder2">
                <div className="formfielddata">
                  {/* DOB */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Date of Birth"
                    >
                      DOB
                    </label>
                    <Field
                      type="text"
                      id="DOB"
                      name="DOB"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* maritial */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Marital Status"
                    >
                      Marital Status
                    </label>
                    <Field
                      type="text"
                      id="MaritalStatusName"
                      name="MaritalStatusName"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>

                  {/* DOA */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Date of Anniversary"
                    >
                      DOA
                    </label>
                    <Field
                      type="text"
                      id="DOA"
                      name="DOA"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                </div>
                <div className="formfielddata">
                  {/* email */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="Email">
                      Email
                    </label>
                    <Field
                      type="text"
                      id="EmailAddress"
                      name="EmailAddress"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* panno */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="PAN Number">
                      Pan No.
                    </label>
                    <Field
                      type="text"
                      id="PanNo"
                      name="PanNo"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                  {/* adharno */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Aadhar Number"
                    >
                      Aadhar No.
                    </label>
                    <Field
                      type="text"
                      id="AadharNo"
                      name="AadharNo"
                      disabled
                      className="employee-detail-input"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="fieldset">
              <legend>Address:</legend>
              <div className="mainBorder">
                <div className="present_div">
                  {/* Present Address */}
                  <div className="employee-detail-Address">
                    <label
                      className="employee-detail-label"
                      title="Present Address"
                    >
                      Present Address<sup>*</sup>
                    </label>
                    <Field
                      as="textarea"
                      type="text"
                      id="PresentAddress"
                      name="PresentAddress"
                      placeholder="Enter Address"
                      className="employee-detail-textarea"
                      disabled
                    />
                  </div>

                  {/* country */}
                  <div className="employee-detail-preaddress">
                    <label className="employee-detail-label" title="Country">
                      Country<sup>*</sup>
                    </label>

                    <Field
                      type="text"
                      id="Country"
                      name="Country"
                      className="employee-detail-input"
                      disabled
                    />
                  </div>
                </div>
                <div className="formfielddata">
                  {/* state */}
                  <div className="employee-detail-item">
                    {" "}
                    <label className="employee-detail-label" title="State">
                      State<sup>*</sup>
                    </label>
                    <Field
                      type="text"
                      id="State"
                      name="State"
                      className="employee-detail-input"
                      disabled
                    />
                  </div>
                  {/* city */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="City">
                      City<sup>*</sup>
                    </label>
                    <Field
                      type="text"
                      id="City"
                      name="City"
                      className="employee-detail-input"
                      disabled
                    />
                  </div>
                  {/* Zip */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="ZIP">
                      Zip<sup>*</sup>
                    </label>
                    <Field
                      type="text"
                      id="Zip"
                      name="Zip"
                      className="employee-detail-input"
                      disabled
                    />
                  </div>
                  {/* Permanent Address */}
                  <div className="employee-detail-Address">
                    <label
                      className="employee-detail-label"
                      title="Permanent Address"
                    >
                      Permanent Address<sup>*</sup>
                    </label>
                    <Field
                      placeholder="Enter Address"
                      as="textarea"
                      id="PermanentAddress"
                      name="PermanentAddress"
                      className="employee-detail-textarea"
                      disabled
                    />
                  </div>
                  {/* permanent country */}
                  <div className="employee-detail-preaddress">
                    <label className="employee-detail-label" title="Country">
                      Country<sup>*</sup>
                    </label>
                    <Field
                      type="text"
                      id="PermanentCountry"
                      name="PermanentCountry"
                      className="employee-detail-input"
                      disabled
                    />
                  </div>
                  {/* permanent State */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="State">
                      State<sup>*</sup>
                    </label>
                    <Field
                      type="text"
                      id="PermanentState"
                      name="PermanentState"
                      className="employee-detail-input"
                      disabled
                    />
                  </div>
                  {/* permanent city */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="City">
                      City<sup>*</sup>
                    </label>
                    <Field
                      type="text"
                      id="PermanentCity"
                      name="PermanentCity"
                      className="employee-detail-input"
                      disabled
                    />
                  </div>
                  {/* Permanent Zip */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="ZIP">
                      {" "}
                      Zip<sup>*</sup>
                    </label>
                    <Field
                      type="text"
                      id="PermanentZip"
                      name="PermanentZip"
                      className="employee-detail-input"
                      disabled
                    />
                  </div>
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="Role">
                      Employee Status<sup>*</sup>
                    </label>

                    <Field
                      type="text"
                      id="StatusName"
                      name="StatusName"
                      className="employee-detail-input"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </Form>
        </Formik>
        <div className="employee-details-button-container">
          <button onClick={onClose} className="employee-details-cancelbutton">
            Close
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="employee-details-container">
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={handleSubmit}
        >
          <Form className="employee-details-form">
            <fieldset className="fieldset">
              <legend>Employee Details</legend>
              <div className="mainBorder">
                {/* property name */}

                {/* First set of fields */}

                <div className="formfielddata">
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Property Location Name"
                      htmlFor="LocationName"
                    >
                      Location Name<sup>*</sup>
                    </label>

                    <Field
                      component={MySelect}
                      options={propertyDetail}
                      onChange={handlePropertyChange}
                      name="LocationName"
                      className="employee-detail-dropdown"
                      placeholder="Select"
                    ></Field>
                    <ErrorMessage
                      name="LocationName"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Firstname */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="First Name">
                      First Name<sup>*</sup>
                    </label>
                    <Field
                      type="text"
                      id="FirstName"
                      name="FirstName"
                      className="employee-detail-input"
                      placeholder="Enter First Name"
                    />
                    <ErrorMessage
                      name="FirstName"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* MiddleName */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Middle Name"
                    >
                      Middle Name
                    </label>
                    <Field
                      type="text"
                      id="MiddleName"
                      name="MiddleName"
                      placeholder="Enter Middle Name"
                      className="employee-detail-input"
                    />
                    <ErrorMessage
                      name="MiddleName"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* LastName */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="Last Name">
                      Last Name<sup>*</sup>
                    </label>
                    <Field
                      type="text"
                      id="LastName"
                      name="LastName"
                      placeholder="Enter Last Name"
                      className="employee-detail-input"
                    />
                    <ErrorMessage
                      name="LastName"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Role */}

                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="Role">
                      Role<sup>*</sup>
                    </label>

                    <Field
                      component={MySelect}
                      name="Role"
                      options={roleList}
                      onChange={handleRoleChange}
                      className="employee-detail-dropdown"
                      placeholder="Select"
                    ></Field>
                    <ErrorMessage
                      name="Role"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* ReportingTo */}
                  {/* Dropdown */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Reporting To"
                    >
                      Reporting To<sup>*</sup>
                    </label>
                    <Field
                      component={MySelect}
                      id="ReportingTo"
                      name="ReportingTo"
                      options={reportingToList}
                      onChange={handleReportingToChange}
                      className="employee-detail-dropdown"
                      placeholder="Select"
                    ></Field>
                    <ErrorMessage
                      name="ReportingTo"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                {/* Second set of fields */}
                <div className="formfielddata">
                  {/* Designation */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Designation"
                    >
                      Designation<sup>*</sup>
                    </label>

                    <Field
                      component={MySelect}
                      options={designationList}
                      onChange={handleDesignationChange}
                      id="Designation"
                      name="Designation"
                      className="employee-detail-dropdown"
                      placeholder="Select"
                    ></Field>
                    <ErrorMessage
                      name="Designation"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* DepartmentId */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label "
                      title="Department Name"
                    >
                      Department<sup>*</sup>
                    </label>

                    <Field
                      component={MySelect}
                      options={departmentList}
                      onChange={handleDepartmentChange}
                      name="DepartmentId"
                      placeholder="Select"
                      className="employee-detail-dropdown"
                    ></Field>
                    <ErrorMessage
                      name="DepartmentId"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Status */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="Status">
                      Status<sup>*</sup>
                    </label>

                    <Field
                      component={MySelect}
                      id="Status"
                      name="Status"
                      className="employee-detail-dropdown"
                      options={statusList}
                      placeholder="Select"
                      onChange={handleJobStatusChange}
                    ></Field>

                    <ErrorMessage
                      name="Status"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                {/* Third set of fields */}
                <div className="formfielddata">
                  {/* BloodGroup */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Blood Group"
                    >
                      Blood Group<sup>*</sup>
                    </label>

                    <Field
                      component={MySelect}
                      id="BloodGroupName"
                      name="BloodGroup"
                      placeholder="Select"
                      className="employee-detail-dropdown"
                      options={bloodGroupList}
                      onChange={handleBloodGroupChange}
                    ></Field>
                    <ErrorMessage
                      name="BloodGroup"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Gender */}

                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="Gender">
                      Gender<sup>*</sup>
                    </label>

                    <Field
                      component={MySelect}
                      options={genderList}
                      onChange={handleGenderChange}
                      id="Gender"
                      name="Gender"
                      className="employee-detail-dropdown"
                      placeholder="Select"
                    ></Field>
                    <ErrorMessage
                      name="Gender"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* MobileNumber */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Mobile Number"
                    >
                      Mobile<sup>*</sup>
                    </label>
                    <Field
                      type="text"
                      id="MobileNumber"
                      name="MobileNumber"
                      className="employee-detail-input"
                      placeholder="Enter Mobile No."
                    />
                    <ErrorMessage
                      name="MobileNumber"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                {/* Fourth set of fields */}
                <div className="formfielddata">
                  {/* DOJ */}

                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Date of Joining"
                    >
                      DOJ<sup>*</sup>
                    </label>
                    <Field name="DOJ">
                      {({ field, form }) => (
                        <DateSelect
                          startDate={field.value} // Pass the value of DOJ as startDate
                          onChange={(date) => {
                            form.setFieldValue(
                              "DOJ",
                              date ? date.format("DD-MM-YYYY") : "" // Format the date before setting the field value
                            );
                          }}
                          format={"DD-MM-YYYY"}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="DOJ"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* DOC */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Date Of Confirmation"
                    >
                      DOC
                    </label>

                    <Field name="DOC">
                      {({ field, form }) => (
                        <DateSelect
                          startDate={field.value} // Pass the value of DOJ as startDate
                          onChange={(date) => {
                            form.setFieldValue(
                              "DOC",
                              date ? date.format("DD-MM-YYYY") : "" // Format the date before setting the field value
                            );
                          }}
                          format={"DD-MM-YYYY"}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="DOC"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* PDOC */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Predefined Date Of Confirmation"
                    >
                      PDOC
                    </label>

                    <Field name="PDOC">
                      {({ field, form }) => (
                        <DateSelect
                          startDate={field.value} // Pass the value of DOJ as startDate
                          onChange={(date) => {
                            form.setFieldValue(
                              "PDOC",
                              date ? date.format("DD-MM-YYYY") : "" // Format the date before setting the field value
                            );
                          }}
                          format={"DD-MM-YYYY"}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="PDOC"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>

                {/* Fifth set of fields */}
                <div className="formfielddata">
                  {/* DOR */}

                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Date Of Resignation"
                    >
                      DOR
                    </label>
                    <Field name="DOR">
                      {({ field, form }) => (
                        <DateSelect
                          startDate={field.value} // Pass the value of DOJ as startDate
                          onChange={(date) => {
                            form.setFieldValue(
                              "DOR",
                              date ? date.format("DD-MM-YYYY") : "" // Format the date before setting the field value
                            );
                          }}
                          format={"DD-MM-YYYY"}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="DOR"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* PDOL */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Planned Date Of Leaving"
                    >
                      PDOL
                    </label>
                    <Field name="PDOL">
                      {({ field, form }) => (
                        <DateSelect
                          startDate={field.value} // Pass the value of DOJ as startDate
                          onChange={(date) => {
                            form.setFieldValue(
                              "PDOL",
                              date ? date.format("DD-MM-YYYY") : "" // Format the date before setting the field value
                            );
                          }}
                          format={"DD-MM-YYYY"}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="PDOL"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* RelievingDate */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Relieving Date"
                    >
                      Relieving Date
                    </label>

                    <Field name="RelievingDate">
                      {({ field, form }) => (
                        <DateSelect
                          startDate={field.value} // Pass the value of DOJ as startDate
                          onChange={(date) => {
                            form.setFieldValue(
                              "RelievingDate",
                              date ? date.format("DD-MM-YYYY") : "" // Format the date before setting the field value
                            );
                          }}
                          format={"DD-MM-YYYY"}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="RelievingDate"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
                {/* six set of fields */}
                <div className="formfielddata">
                  {/* DOL */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Date Of Leaving"
                    >
                      DOL
                    </label>
                    <Field name="DOL">
                      {({ field, form }) => (
                        <DateSelect
                          startDate={field.value} // Pass the value of DOJ as startDate
                          onChange={(date) => {
                            form.setFieldValue(
                              "DOL",
                              date ? date.format("DD-MM-YYYY") : "" // Format the date before setting the field value
                            );
                          }}
                          format={"DD-MM-YYYY"}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="DOL"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Weekdayoff */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="WeekDays Off"
                    >
                      WeekDays Off<sup>*</sup>
                    </label>

                    <Field
                      component={MySelect}
                      options={weekDayOffList}
                      onChange={handleDayOffChange}
                      id="WeekDayOff"
                      name="WeekDayOff"
                      className="employee-detail-dropdown"
                      placeholder="Select"
                    ></Field>

                    <ErrorMessage
                      name="WeekDayOff"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* RelievingDate */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Card Number"
                    >
                      Card No.<sup>*</sup>
                    </label>
                    <Field
                      placeholder="Enter Card Number"
                      type="text"
                      id="CardNo"
                      name="CardNo"
                      className="employee-detail-input"
                    />
                    <ErrorMessage
                      name="CardNo"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
                {/* ResourceType */}
                <div className="employee-detail-item">
                  <label
                    className="employee-detail-label"
                    title="Resource Type"
                  >
                    Resource Type<sup>*</sup>
                  </label>

                  <Field
                    component={MySelect}
                    options={resourceTypeList}
                    onChange={handleResourceChange}
                    id="ResourceType"
                    name="ResourceType"
                    placeholder="Select"
                    className="employee-detail-dropdown"
                  ></Field>
                  <ErrorMessage
                    name="ResourceType"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="fieldset">
              <legend>Personal</legend>
              <div className="mainBorder2">
                <div className="formfielddata">
                  {/* DOB */}

                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="DOB">
                      DOB<sup>*</sup>
                    </label>
                    <Field name="DOB">
                      {({ field, form }) => (
                        <DateSelect
                          startDate={field.value} // Pass the value of DOJ as startDate
                          onChange={(date) => {
                            form.setFieldValue(
                              "DOB",
                              date ? date.format("DD-MM-YYYY") : "" // Format the date before setting the field value
                            );
                          }}
                          format={"DD-MM-YYYY"}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="DOB"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* maritial */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Marital Status"
                    >
                      Marital Status<sup>*</sup>
                    </label>

                    <Field
                      component={MySelect}
                      options={maritalStatusList}
                      onChange={handleMaritalStatusChange}
                      id="MaritalStatus"
                      name="MaritalStatus"
                      placeholder="Select"
                      className="employee-detail-dropdown"
                    ></Field>
                    <ErrorMessage
                      name="MaritalStatus"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* DOA */}

                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="DOA">
                      DOA
                    </label>
                    <Field name="DOA">
                      {({ field, form }) => (
                        <DateSelect
                          startDate={field.value} // Pass the value of DOJ as startDate
                          onChange={(date) => {
                            form.setFieldValue(
                              "DOA",
                              date ? date.format("DD-MM-YYYY") : "" // Format the date before setting the field value
                            );
                          }}
                          format={"DD-MM-YYYY"}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="DOA"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
                <div className="formfielddata">
                  {/* email */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="Email">
                      Email<sup>*</sup>
                    </label>
                    <Field
                      placeholder="Enter Email"
                      type="text"
                      id="EmailAddress"
                      name="EmailAddress"
                      className="employee-detail-input"
                    />
                    <ErrorMessage
                      name="EmailAddress"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* panno */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="PAN Number">
                      Pan No.<sup>*</sup>
                    </label>
                    <Field
                      placeholder="Enter PAN Number"
                      type="text"
                      id="PanNo"
                      name="PanNo"
                      className="employee-detail-input"
                    />
                    <ErrorMessage
                      name="PanNo"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* adharno */}
                  <div className="employee-detail-item">
                    <label
                      className="employee-detail-label"
                      title="Aadhar Number  "
                    >
                      Aadhar No.<sup>*</sup>
                    </label>
                    <Field
                      placeholder="Enter Aadhar Number"
                      type="text"
                      id="AadharNo"
                      name="AadharNo"
                      className="employee-detail-input"
                    />
                    <ErrorMessage
                      name="AadharNo"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="fieldset">
              <legend>Address</legend>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "1%",
                  paddingLeft: "10%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  {/* Present Address */}
                  <div className="employee-detail-Address">
                    <label
                      className="employee-detail-label"
                      title="Present Address"
                    >
                      Present Address<sup>*</sup>
                    </label>
                    <Field
                      as="textarea"
                      type="text"
                      id="PresentAddress"
                      name="PresentAddress"
                      placeholder="Enter Address"
                      className="employee-detail-textarea"
                    />
                    <ErrorMessage
                      name="PresentAddress"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  {/* country */}
                  <div className="employee-detail-preaddress">
                    <label className="employee-detail-label" title="Country">
                      Country<sup>*</sup>
                    </label>

                    <Field
                      as="select"
                      id="Country"
                      name="Country"
                      className="employee-detail-input placeholder"
                    >
                      <option value="">Select Country</option>
                      <option value="talod">talod</option>
                      <option value="naroda">naroda</option>
                    </Field>
                    <ErrorMessage
                      name="Country"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
                <div className="formfielddata">
                  {/* state */}
                  <div className="employee-detail-item">
                    {" "}
                    <label className="employee-detail-label" title="State">
                      State<sup>*</sup>
                    </label>
                    <Field
                      as="select"
                      id="State"
                      name="State"
                      className="employee-detail-input placeholder"
                    >
                      <option value="">Select State</option>
                      <option value="talod">talod</option>
                      <option value="naroda">naroda</option>
                    </Field>
                    <ErrorMessage
                      name="State"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* city */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="City">
                      City<sup>*</sup>
                    </label>
                    <Field
                      as="select"
                      id="City"
                      name="City"
                      className="employee-detail-input placeholder"
                    >
                      <option value="">Select City</option>
                      <option value="talod">talod</option>
                      <option value="naroda">naroda</option>
                    </Field>
                    <ErrorMessage
                      name="City"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Zip */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="ZIP">
                      Zip<sup>*</sup>
                    </label>
                    <Field
                      placeholder="Enter Zip"
                      type="text"
                      id="Zip"
                      name="Zip"
                      className="employee-detail-input"
                    />
                    <ErrorMessage
                      name="Pin Code"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Permanent Address */}
                  <div className="employee-detail-Address">
                    <label
                      className="employee-detail-label"
                      title="Permanent Address"
                    >
                      Permanent Address<sup>*</sup>
                    </label>
                    <Field
                      placeholder="Enter Address"
                      as="textarea"
                      id="PermanentAddress"
                      name="PermanentAddress"
                      className="employee-detail-textarea"
                    />
                    <ErrorMessage
                      name="PermanentAddress"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* permanent country */}
                  <div className="employee-detail-preaddress">
                    <label className="employee-detail-label" title="Country">
                      Country<sup>*</sup>
                    </label>
                    <Field
                      as="Select"
                      id="PermanentCountry"
                      name="PermanentCountry"
                      className="employee-detail-input placeholder"
                    >
                      <option value="">Select Country</option>
                      <option value="talod">talod</option>
                      <option value="naroda">naroda</option>
                    </Field>
                    <ErrorMessage
                      name="PermanentCountry"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* permanent State */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="State">
                      State<sup>*</sup>
                    </label>
                    <Field
                      as="select"
                      id="PermanentState"
                      name="PermanentState"
                      className="employee-detail-input placeholder"                     
                    >
                      <option value="">Select State</option>
                      <option value="talod">talod</option>
                      <option value="naroda">naroda</option>
                    </Field>
                    <ErrorMessage
                      name="PermanentState"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* permanent city */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="City">
                      City<sup>*</sup>
                    </label>
                    <Field
                      as="select"
                      id="PermanentCity"
                      name="PermanentCity"
                      className="employee-detail-input placeholder"
                    >
                      <option value="">Select City</option>
                      <option value="talod">talod</option>
                      <option value="naroda">naroda</option>
                    </Field>
                    <ErrorMessage
                      name="PermanentCity"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* Permanent Zip */}
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="ZIP">
                      {" "}
                      Zip<sup>*</sup>
                    </label>
                    <Field
                      placeholder="Enter Zip"
                      type="text"
                      id="PermanentZip"
                      name="PermanentZip"
                      className="employee-detail-input"
                    />
                    <ErrorMessage
                      name="PermanentZip"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="employee-detail-item">
                    <label className="employee-detail-label" title="Role">
                      Employee Status<sup>*</sup>
                    </label>

                    <Field
                      component={MySelect}
                      name="employeeStatus"
                      options={employeeStatusList}
                      onChange={handleEmployeeStatusChange}
                      className="employee-detail-dropdown"
                      placeholder="Select"
                    ></Field>
                    <ErrorMessage
                      name="employeeStatus"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <div className="employee-edit-button-container">
              <button
                type="button"
                onClick={onClose}
                className="employee-details-cancelbutton"
              >
                Cancel
              </button>
              <button type="submit" className="employee-details-addbutton">
                {isAddMode ? "Add New" : "Update"}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default EmployeeMasterPopup;
