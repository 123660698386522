import React, { useState, useEffect } from "react";
import css from "./Steps.module.scss";

import { IoIosArrowRoundBack } from "react-icons/io";
import { IoIosArrowRoundForward } from "react-icons/io";

import slide1 from "../../../../assets/images/Dashboard-cuate 1.png";
import slide2 from "../../../../assets/images/Reservation.png";
import slide3 from "../../../../assets/images/Guest.png";
import slide4 from "../../../../assets/images/Daily Schedules for Therapies.png";
import slide5 from "../../../../assets/images/Housekeeping.png";
import slide6 from "../../../../assets/images/Inventory.png";

const Steps = () => {
  const slides = [
    {
      id: 1,
      text: "This module handles the client-facing functions of the wellness center, such as reception, check-in, and booking appointments.",
      heading: "Front desk",
      image: slide1,
    },
    {
      id: 2,
      text: "This module manages the reservations and scheduling of services and treatments offered by the wellness center.",
      heading: "Reservation",

      image: slide2,
    },
    {
      id: 3,
      text: "This module maintains a database of guests, their contact information, preferences, and booking history.",
      heading: "Guest",

      image: slide3,
    },
    {
      id: 4,
      text: "This module provides a view of the daily schedules for therapists and practitioners, along with their availability and appointment bookings.",
      heading: "Daily Schedules for Therapies",

      image: slide4,
    },
    {
      id: 5,
      text: "This module provides a view of the daily schedules for therapists and practitioners, along with their availability and appointment bookings.",
      heading: "Housekeeping",
      image: slide5,
    },
    {
      id: 6,
      text: "This module manages the inventory of products and supplies used in the wellness center, such as skincare products, supplements, and equipment.",
      heading: "Inventory",

      image: slide6,
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  useEffect(() => {
    const intervalId = setInterval(goToNextSlide, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const { text, image, heading, id } = slides[currentSlide];

  return (
    <>
      <section className={css.texthead}>
        simplifies the daily operations of a wellness center
      </section>

      <div className={css.container}>
        {" "}
        {/* Use the SCSS module for the container class */}
        <div className={css.containerleft}>
          <div className={css.heading}>{heading}</div>

          <div className={css.text}>{text}</div>

          <div className={css.controls}>
            {" "}
            {/* Use the SCSS module for the controls class */}
            <div onClick={goToPreviousSlide} className={css.prev}>
              <IoIosArrowRoundBack />
            </div>
            <div onClick={goToNextSlide} className={css.next}>
              <IoIosArrowRoundForward />
            </div>
          </div>
        </div>
        <div className={css.containerright}>
          <div className={css.image}>
            <img src={image} alt="Slider Image" />
          </div>
        </div>
        {/* Use the SCSS module for the text class */}
      </div>

      <center>
        <span>{id}</span>/{slides.length}
      </center>
    </>
  );
};

export default Steps;
