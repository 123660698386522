import React, { useEffect, useState } from "react";
import css from "./AddRoomRate.module.css";

import Logo from "../../../../src/assets/images/Logo.png";
import Onboarding_3 from "../../../../src/assets/images/Onboarding_3.png";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

import { AiOutlineArrowRight } from "react-icons/ai";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import callApi from "../../../ApiCall";
import Constants from "../../../Constants";
import { Checkbox, FormControlLabel } from "@mui/material";

const weeks = [
  {
    day: "Default price for all days",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  {
    id: 41,
    day: "Sunday",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  {
    id: 42,
    day: "Monday",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  {
    id: 43,
    day: "Tuesday",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  {
    id: 44,
    day: "Wednesday",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  {
    id: 45,
    day: "Thursday",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  {
    id: 46,
    day: "Friday",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  {
    id: 47,
    day: "Saturday",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  // {
  //   day: "Sunday",
  //   singleBed: "",
  //   doubleBed: "",
  //   extraBed: "",
  // },
];

const AddRoomRate = () => {
  const navigate = useNavigate();
  const [selectedRoom, setSelectedRoom] = useState(0);
  const [roomTypeError, setRoomTypeError] = useState("");
  const [roomTypeList, setRoomTypeList] = useState([]);
  const [roomRateError, setRoomRateError] = useState("");
  const [sameForAll, setSameForAll] = useState(true);
  const [data, setData] = useState(weeks);
  const [updatedDate, setUpdatedDate] = useState(null);
  const [roomRateAdded, setRoomRateAdded] = useState(0);

  const getRoomDetails = async (index) => {
    const response = await callApi({
      apiURL: Constants.API.getRoomDetails,
    });
    if (response?.success) {
      if (response.data.roomsInfo) {
        let obj = response.data.roomsInfo;
        if (obj.NoOfRooms) {
          setSelectedRoom(index);
          setRoomTypeList(JSON.parse(obj.RoomTypeList));
          setRoomRateAdded(response.data.rateAdded);
        } else {
          navigate("/onboading/add-room-type");
        }
      } else {
        navigate("/onboading/property");
      }
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    getRoomDetails(0);
  }, []);

  const handleRoomChange = (e) => {
    setSelectedRoom(e.target.value);
    setRoomTypeError("");
    setRoomRateError("");
    if (e.target.value && e.target.value > 0) {
      getRoomRates(e.target.value);
    }
  };

  const getRoomRates = async (roomTypeId) => {
    const response = await callApi({
      apiURL: Constants.API.getRoomRates,
      requestBody: { roomTypeId: roomTypeId },
    });
    if (response?.success) {
      if (response.data.roomRates.BedRates) {
        setData(JSON.parse(response.data.roomRates.BedRates));
        setUpdatedDate(response.data.roomRates.UpdatedDate);
      } else {
        setData(weeks);
        setUpdatedDate(response.data.roomRates.UpdatedDate);
      }
    } else {
      console.log("error");
    }
  };

  const handlePriceChange = (day, bedType, value) => {
    if (/^\d*$/.test(value)) {
      if (day === "Default price for all days") {
        setData((prevData) =>
          prevData.map((item) => {
            return { ...item, [bedType]: value };
          })
        );
      } else {
        setData((prevData) =>
          prevData.map((item) => {
            if (item.day === day) {
              return { ...item, [bedType]: value };
            }
            return item;
          })
        );
      }
    }
  };
  const validateRoomRate = () => {
    return data.some((item, index) => {
      if (
        index > 0 &&
        (item.singleBed === "" || item.doubleBed === "" || item.extraBed === "")
      ) {
        return true;
      }
      return false;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedRoom === 0) {
      setRoomTypeError("Please select room type.");
      return;
    } else {
      setRoomTypeError("");
    }
    if (validateRoomRate()) {
      setRoomRateError("Please add room rate for all days.");
      return;
    } else {
      setRoomRateError("");
    }
  
    // Filter out the default entry "Default price for all days"
    const filteredData = data.filter((item) => item.day !== "Default price for all days");
  
    const response = await callApi({
      apiURL: Constants.API.saveRoomRate,
      requestBody: {
        roomTypeId: selectedRoom,
        updatedDate,
        roomRates: filteredData,  // Send filtered data instead of all days
      },
    });
    if (response?.success) {
      getRoomDetails(selectedRoom);
      getRoomRates(selectedRoom);
      setRoomRateError("Saved Successfully!!!");
    } else {
      console.log("error");
    }
  };
  

  return (
    <div>
      <div style={{ display: "flex" }}>
        <section className="sec-left">
          <img src={Onboarding_3} />
        </section>
        <section className="sec1-right">
          <div style={{ marginTop: "5%" }}>
            <div className={css.flexone}>
              <section className={css.left}>
                <img src={Logo} alt="" className={css.img} />
              </section>
              <section className={css.right}>
                <button
                  className={css.icon}
                  onClick={() => {
                    navigate("/onboading/add-room-type");
                  }}
                  style={{ marginRight: 20 }}
                >
                  <AiOutlineArrowLeft />
                </button>
                {roomRateAdded && (
                  <button
                    className={css.icon}
                    onClick={() => {
                      navigate("/onboading/add-room-services");
                    }}
                  >
                    <AiOutlineArrowRight />
                  </button>
                )}
              </section>
            </div>
            <div className={css.title}>Add room rate</div>
            <div className={css.desc}>
              Finally, please enter per person price at which the room are to be
              sold.
            </div>
            <div className={css.rt}>
              <InputLabel id="room-type-label">
                Room type <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <br />
              <Select
                labelId="room-type-label"
                id="room-type"
                value={selectedRoom}
                onChange={handleRoomChange}
                style={{ width: "70%" }}
              >
                <MenuItem value="">Select Room Type</MenuItem>
                {roomTypeList?.map((x) => (
                  <MenuItem key={x.RoomTypeId} value={x.RoomTypeId}>
                    {x.RoomTypeName}
                  </MenuItem>
                ))}
              </Select>
              {roomTypeError !== "" && (
                <div className="error-message" style={{ color: "red" }}>
                  {roomTypeError}
                </div>
              )}
            </div>

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sameForAll}
                    onChange={(e) => setSameForAll(e.target.checked)}
                  />
                }
                label="Same For All Days"
              />
            </div>
            <div className={css.table}>
              <table>
                <thead>
                  <tr>
                    <th>Accommodation</th>
                    <th>Single bed</th>
                    <th>Double bed</th>
                    <th>Extra bed</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    return (
                      (index > 0 || (index == 0 && sameForAll)) && (
                        <tr key={index}>
                          <td>{item.day}</td>
                          <td>
                            <input
                              type="text"
                              value={item.singleBed}
                              onChange={(e) =>
                                handlePriceChange(
                                  item.day,
                                  "singleBed",
                                  e.target.value
                                )
                              }
                              maxLength={5}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={item.doubleBed}
                              onChange={(e) =>
                                handlePriceChange(
                                  item.day,
                                  "doubleBed",
                                  e.target.value
                                )
                              }
                              maxLength={5}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={item.extraBed}
                              onChange={(e) =>
                                handlePriceChange(
                                  item.day,
                                  "extraBed",
                                  e.target.value
                                )
                              }
                              maxLength={5}
                            />
                          </td>
                        </tr>
                      )
                    );
                  })}
                </tbody>
              </table>
            </div>
            {roomRateError != "" && (
              <div className="error-message" style={{ color: "red" }}>
                {roomRateError}
              </div>
            )}
            <div className={css.flexform3}>
              <div className={css.left}></div>
              <div className={css.right}>
                <button onClick={handleSubmit}>Save and add more</button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddRoomRate;
