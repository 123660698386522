import React, { useEffect, useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import callApi from '../../ApiCall';
import Constants from '../../Constants';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const GenderChart = () => {
  const [genderCount, setGenderCount] = useState([]);

  useEffect(() => {
    const fetchGenderCount = async () => {
      try {
        const response = await callApi({
          apiURL: Constants.API.getGenderCount,
        });
        if (response?.success) {
          setGenderCount(response.data.genderCount);
        } else {
          console.error('Failed to fetch gender count:', response?.error);
        }
      } catch (error) {
        console.error('API call error:', error);
      }
    };

    fetchGenderCount();
  }, []);

  const options = {
    animationEnabled: true,
    theme: 'light2',
    title: {
      text: 'Male vs Female Visitors',
      
    },
    legend: {
      verticalAlign: 'center',
      horizontalAlign: 'right',
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: 'Urbanist',
      markerMargin: 20,
      itemWidth: 180,
      itemWrap: true,
      cursor: 'pointer',
    },
    data: [{
      type: 'doughnut',
      startAngle: 180,
      radius: '100%',
      innerRadius: '70%',
      showInLegend: true,
      legendText: '{name}: {y}',
      indexLabel: '{y}',
      indexLabelPlacement: 'inside',
      indexLabelFontColor: '#fff',
      indexLabelFontSize: 14,
      indexLabelFontWeight: 'bold',
      dataPoints: genderCount.map((data) => ({
        name: data.GenderName,
        y: data.Count,
        color: data.GenderName === 'Male' ? '#6C7D02' : '#b4ba3d',
      })),
    }],
  };

  return (
    <div style={{ width: '300px', height: 'auto' }}>
      <h2 style={{ fontSize: '14px', textAlign: 'center', margin: '8px 0' }}>Gender Count Chart</h2>
      <CanvasJSChart options={options} />
    </div>
  );
};

export default GenderChart;
