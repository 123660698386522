import Constants from "../Constants";
import moment from "moment";

export const getDisplayDate = (d) => {
  return moment(d).format(Constants.appConfig.dateFormatForGrid);
};

export const getDateMonthYear = (d) => {
  return moment(d).format(Constants.appConfig.dateFormat);
};

export const getDBFormateDate = (d) => {
  return moment(d, Constants.appConfig.dateFormatForGrid).format(
    Constants.appConfig.dbDateFormat
  );
};

export const getDBFormateDateForInlineGrid = (d) => {
  return moment(d).format(Constants.appConfig.dbDateFormat);
};

export const getDBFormateDateTime = (d) => {
  return moment(d, Constants.appConfig.dateFormatForGrid).format(
    Constants.appConfig.dbDateTimeFormat
  );
};

export const getPrintStampDateTime = (d) => {
  return moment(d).format(Constants.appConfig.dateTimeFormatForPrintStamp);
};

export const getDateFormatForDatePicker = (d) => {
  return moment(d, Constants.appConfig.dateFormatForGrid).format(
    Constants.appConfig.dbDateFormat
  );
};

export const getDCRFormatDate = (d) => {
  return moment(d).format("YYYY-MM-DD");
};

export const getAttendanceFormatDate = (d) => {
  return moment(d).format("DDMMMYYYY");
};

export const getAttendanceFormatTime = (d) => {
  return moment(d).format('hh:mm A');
};

export const getBrowserFormatDate = (d) => {
  return moment(d).toDate();
};

export const getInOutFormatTime = (d) => {
  return moment(d).format('HH:mm');
};