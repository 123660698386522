import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import { alpha, styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { PiBellLight } from "react-icons/pi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import drawerClose from "../../assets/icons/Back.png";
import logo4 from "../../assets/icons/Ellipse 188.png";
import logo5 from "../../assets/icons/Vector.png";
import logo8 from "../../assets/icons/logout.png";
import logo6 from "../../assets/icons/profile.png";
import logo7 from "../../assets/icons/setting-2.png";
import "./header.css";
import { DrawerData } from "./sidebar";
import Logo from "../../assets/images/Logo header.png";
import { useState } from "react";
import ProfilePopup from "../../components/Profile";

const drawerWidth = 236;

const LogoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const LogoButton = styled("button")(({ theme }) => ({
  border: "none",
  background: "transparent",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
}));

const DropdownList = styled("ul")(({ theme }) => ({
  position: "absolute",
  top: "80px",
  right: "10px",
  zIndex: 2,
  listStyle: "none",
  padding: 0,
  margin: 0,
  background: theme.palette.common.white,
  boxShadow: theme.shadows[4],
  borderRadius: 8,
  width: 130,
}));

const DropdownListItem = styled("li")(({ theme }) => ({
  padding: theme.spacing(1),
  "&:hover": {
    background: alpha(theme.palette.common.black, 0.05),
  },
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    width: "60%",

    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  boxShadow: "box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.05);",
}));

export default function PrivateRoute({ component: Component }) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [drawerList, setDrawerList] = React.useState([]);
  const [currentMenu, setCurrentMenu] = useState({});
  const [loggedInId, setLoggedInId] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleProfileClick = () => {
    setIsPopupOpen(true);
    setIsDropdownOpen(false);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleLogoClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const currentPath = location.pathname;
    let isSettings = localStorage.getItem("isSettings");
    let menus = localStorage.getItem("menus")
      ? isSettings == 0
        ? JSON.parse(localStorage.getItem("menus")).filter(
            (x) => x.IsSettingMenu == 0
          )
        : JSON.parse(localStorage.getItem("menus")).filter(
            (x) => x.IsSettingMenu == 1
          )
      : [];
    let menu = menus.filter((x) =>
      x.MenuPath.includes(currentPath.split("/")[1])
    )[0];
    let title = menu && menu.MenuName;
    setTitle(title ? title : "Half Olive");
    if (!title) {
      // navigate(-1);
      navigate("/login");
    }
    let isOnboarded = localStorage.getItem("isOnboarded");
    setCurrentMenu(menu ? menu : {});
    if (+isOnboarded) {
      setDrawerList(menus);
    } else {
      setDrawerList(
        menus.filter(
          (x) => x.MenuName == "Onboarding" || x.MenuName == "Dashboard"
        )
      );
      // navigate("/login");
    }
    let loggedInId = localStorage.getItem("userId");
    setLoggedInId(loggedInId);
  }, [location.pathname]);

  const [title, setTitle] = useState("Half Olive");

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        open={open}
        sx={{ boxShadow: "10px 0px 10px 0px rgba(0, 0, 0, 0.05)" }}
      >
        <Toolbar
          sx={{
            color: "#6C7D02",
            backgroundColor: "#ffffff",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <div>
              <h6 style={{ fontSize: "24px", fontWeight: "800" }}>{title}</h6>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <PiBellLight style={{ fontSize: "30px", color: "#000000" }} />
            <LogoContainer>
              <LogoButton onClick={handleLogoClick}>
                <img src={logo4} alt="Profile" className="logo4s" />
                <img src={logo5} alt="Profile" className="logo5s" />
                <div className="dropdown-arrow">
                  {" "}
                  <span
                    id="arrow"
                    className={isDropdownOpen ? "arrow up" : "arrow down"}
                  ></span>
                </div>
              </LogoButton>
              {isDropdownOpen && (
                <DropdownList>
                  <p
                    onClick={() => {
                      setIsDropdownOpen(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      fontWeight: "bold",
                      paddingRight: "10%",
                      cursor: "pointer",
                    }}
                  >
                    X
                  </p>
                  <DropdownListItem
                    className="drop"
                    onClick={handleProfileClick}
                  >
                    <img className="logo6" src={logo6} alt="" />
                    &nbsp;&nbsp; Profile
                  </DropdownListItem>
                  <DropdownListItem
                    className="drop"
                    onClick={() => {
                      setIsDropdownOpen(false);
                      localStorage.setItem("isSettings", 1);
                      navigate("/guestActivity");
                    }}
                  >
                    <img className="logo6" src={logo6} alt="" />
                    &nbsp;&nbsp; Settings
                  </DropdownListItem>
                  <DropdownListItem
                    className="drop"
                    onClick={() => {
                      setIsDropdownOpen(false);
                      // List of items to remove
                      const itemsToRemove = [
                        "sOnboarded",
                        "isSubscribed",
                        "menus",
                        "isSettings",
                        "userId",
                        "token",
                      ];

                      itemsToRemove.forEach((item) =>
                        localStorage.removeItem(item)
                      );
                      navigate("/login");
                    }}
                  >
                    <img className="logo6" src={logo8} alt="" />
                    &nbsp;&nbsp; Logout
                  </DropdownListItem>
                </DropdownList>
              )}
            </LogoContainer>
          </div>
        </Toolbar>
      </AppBar>
      <ProfilePopup
        isPopupOpen={isPopupOpen}
        handleClosePopup={handleClosePopup}
      />

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            boxShadow: "10px 0px 10px 0px rgba(0, 0, 0, 0.05)",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{ marginY: "20px" }}>
          <div style={{ display: "flex", gap: "80px" }}>
            <div>
              <a
                onClick={() => {
                  setIsDropdownOpen(false);
                  localStorage.setItem("isSettings", 0);
                  navigate("/dashboard");
                }}
                // className="redirect"
              >
                <img
                  src={Logo}
                  className="md:ml-12 cursor-pointer"
                  alt="Logo"
                />
              </a>
            </div>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <img
                  src={drawerClose}
                  style={{ width: "24px", height: "24px" }}
                />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
        </DrawerHeader>
        <Divider />

        <List sx={{ paddingLeft: "8px" }}>
          {drawerList.map((item, index) => (
            <li
              key={index}
              className={"nav-text"}
              onClick={() => {
                // Close the drawer and navigate to the link
                handleDrawerClose();
                setTitle(item.MenuName);
                navigate(item.MenuPath);
              }}
            >
              <Link
                to={item.path}
                style={{
                  backgroundColor: title == item.MenuName ? "#6C7D02" : "white",
                  color: title == item.MenuName ? "white" : "#6C7D02",
                }}
              >
                <span>{item.MenuName}</span>
              </Link>
            </li>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {currentMenu?.View == 1 && (
          <Component currentMenu={{ ...currentMenu, loggedInId }} />
        )}
      </Main>
    </Box>
  );
}
