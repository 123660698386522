import React, { useEffect, useState } from "react";
import "./CustomSelect.css";
import { Box, IconButton, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";
// import { showToast } from "../common/showToast";
import CloseIcon from "@mui/icons-material/Close";
import DeleteConfirmationDialog from "../DeleteConfirmation/confirmdelete";
import DeleteIcon from "@mui/icons-material/Delete";
import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";

const CustomSelect = ({options,addData, updateData, deleteCategory , selectedCategory, newSelectedData}) => {
  
  //open-close dropdown
  
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedProductForDelete, setSelectedProductForDelete] =
    useState(null);

  //temparray for testing till db is linked
  const [tempArray, setTempArray] = useState(options);
  useEffect(() => {
    setTempArray(options); 
  }, [options]);
  

  const [selectedInputValueUpdate, setselectedInputValueUpdate] = useState("");
  const [selectedInputValueAdd, setselectedInputValueAdd] = useState("");

  const selectedOption = tempArray.find((val) => val.id === selectedCategory);//find already selected id
  const [selectedData, setSelectedData] = useState(selectedOption ? selectedOption : []);


  const [isEditMode, setIsEditMode] = useState(false);
  const [isAddOptionMode, setIsAddOptionMode] = useState(false);
  const [searchQuery, setsearchQuery] = useState("");

  const [selectedItem, setSelectedItem] = useState(selectedOption ? true : false);
  useEffect(() => {
    searchQuery.trim().length > 0 && setisDropdownOpen(true);
    searchQuery.trim().length == 0 && setisDropdownOpen(false);
  }, [searchQuery]);

  const handleInputValue = (data) => {
    setSelectedItem(false);
    setIsEditMode(true);
    setselectedInputValueUpdate(data?.name);
    setSelectedData(data);
  };
  const handleDeleteValue = (data) => {
    setSelectedProductForDelete(data); 
    setShowDeleteConfirmation(true); 
  };
  
  const handleConfirmDelete = async (selectedRow) => {
    
    deleteCategory(selectedRow?.id );
    setShowDeleteConfirmation(false);
  };
  

  const setHandleInputAdd = () => {
    if (selectedInputValueAdd.length > 0) {
      const alreadyExist = tempArray?.find(
        (val) => val.name.trim() === selectedInputValueAdd.trim()
      );
      if (alreadyExist) {
        // showToast("ERROR", "Already Present");
      } else {
        
        setTempArray([
          ...tempArray,
          {
            name: selectedInputValueAdd,
          },
        ]);
        
        addData(selectedInputValueAdd)
        // setAddCategoryDone(true)
        setSelectedItem(false);
        setselectedInputValueAdd(""); // Reset the text field
        setIsAddOptionMode(false);
        setselectedInputValueUpdate("");
        setSelectedData([]);
        setsearchQuery("");
      }
    } else {
      // showToast("ERROR", "Please Add Data");
    }
  };
  const setHandleInputUpdate = () => {
    if (selectedInputValueUpdate.length > 0) {
      const objIndex = tempArray?.findIndex((val) => {
        return val.id == selectedData?.id});
      tempArray[objIndex].name = selectedInputValueUpdate;
      updateData(selectedData?.id, selectedInputValueUpdate);
      setIsEditMode(false);
      setselectedInputValueAdd("");
      setselectedInputValueUpdate("");
      setSelectedData([]);
      setsearchQuery("");
      setSelectedItem(false);
    } else {
      // showToast("ERROR", "Please Add Data");
    }
  };

  const handleSelectItem = (data) => {
    setSelectedItem(true);
    setSelectedData(data);
    setIsEditMode(false);
    setselectedInputValueUpdate(data?.name);
    setselectedInputValueAdd(""); // Reset the text field
    setsearchQuery("");
    setisDropdownOpen(false); // Close the dropdown
    newSelectedData(data.id)
   
  }

  const searchedData = tempArray.length != 0 && tempArray?.filter((val) =>
    val?.name?.toLowerCase().trim().includes(searchQuery?.toLowerCase().trim())
  );

  const handleSelectedInputAdd = () => {
    setIsAddOptionMode(!isAddOptionMode);
    setSelectedItem(false);
  };

  return (
    <>
      <DeleteConfirmationDialog
        open={showDeleteConfirmation}
        handleClose={() => setShowDeleteConfirmation(false)}
        handleConfirm={() => handleConfirmDelete(selectedProductForDelete)}
        product={"Category"}
      />
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <>
          {/* edit data */}
          {isEditMode ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                borderRadius: "8px",
                border: "1px solid #899197",
                cursor: "pointer",
                height: "46px !important",
                // backgroundColor: "#FFFFFF",
              }}
            >
              <Box sx={{ width: "80%", height: "46px !important"}}>
                <TextField
                  value={selectedInputValueUpdate}
                  onChange={(e) => setselectedInputValueUpdate(e.target.value)}
                  multiline
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    color: "gray",
                    padding: 1.25,
                    fontSize: 16,
                    wordBreak: "break-word",
                    ml: 2,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "20%",
                }}
              >
                <DoneIcon
                  fontSize="medium"
                  sx={{
                    color: "gray",
                  }}
                  onClick={() => setHandleInputUpdate()}
                />
              </Box>
              <Box mr={1}>
                <IconButton
                  color={"error"}
                  onClick={() => {
                    setIsEditMode(!isEditMode);
                  }}
                >
                  <CloseIcon fontSize="medium" />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                height: "93%;",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                borderRadius: "8px",
                // backgroundColor: "#FFFFFF",
                cursor: "pointer",
                border: "1px solid #899197",
              }}
              // onClick={() => setisDropdownOpen(!isDropdownOpen)}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "46px !important",
                }}
              >
                {selectedItem ? (
                  <TextField
                    sx={{
                      color: "gray",
                      padding: 1.25,
                      fontSize: 16,
                      wordBreak: "break-word",
                      ml: 2,
                      width:"100% !important",
                    }}
                    value={selectedData?.name}
                    multiline
                    variant="standard"
                    InputProps={{ disableUnderline: true, readOnly: true }}
                  />
                ) : /* Add data */
                isAddOptionMode ? (
                  
                  <TextField
                    sx={{
                      color: "gray",
                      padding: 1.25,
                      fontSize: 16,
                      wordBreak: "break-word",
                      ml: 2,
                    }}
                    placeholder="Add"
                    value={selectedInputValueAdd}
                    onChange={(e) => setselectedInputValueAdd(e.target.value)}
                    multiline
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "80%",
                      padding: 0.8,
                      ml: 1.5,
                    }}
                  >
                    {/* search field */}
                    <TextField
                      placeholder="Select"
                      value={searchQuery}
                      onChange={(e) => {
                        setsearchQuery(e.target.value);
                      }}
                      multiline
                      sx={{ height: "fit-content" }}
                      type="search"
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                    />
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "20%",
                }}
              >
                {/* for icons */}
                {isAddOptionMode ? (
                  <IconButton onClick={setHandleInputAdd}>
                    <DoneIcon
                      sx={{
                        color: "gray",
                      }}
                    />
                  </IconButton>
                ) : tempArray?.length > 0 ? (
                  // open drop down
                  isDropdownOpen ? (
                    <KeyboardArrowUpIcon
                      onClick={() => setisDropdownOpen(!isDropdownOpen)}
                      sx={{
                        color: "gray",
                      }}
                    />
                  ) : (
                    // close drop down
                    <KeyboardArrowDownIcon
                      onClick={() => setisDropdownOpen(!isDropdownOpen)}
                      sx={{
                        color: "gray",
                      }}
                    />
                  )
                ) : null}
              </Box>
              {/* icons for close add */}
              <Box mr={1}>
                <IconButton
                  onClick={() => {
                    handleSelectedInputAdd();
                  }}
                  size="large"
                  color={isAddOptionMode ? "error" : "secondary"}
                  aria-label="add"
                >
                  {isAddOptionMode ? <CloseIcon /> : <AddIcon sx={{color:'#6C7D02'}}/>}
                </IconButton>
              </Box>
            </Box>
          )}

          {/* alll data */}
          {isDropdownOpen && (
            <Box
              className={
                searchedData && searchedData?.length <= 5
                  ? "parent-box-dropdown-content-small"
                  : "parent-box-dropdown-content-big"
              }
              sx={{ backgroundColor: "#fff" }}
            >
              {/* for dropdown */}
              {searchedData &&
                searchedData?.map((val, index) => {
                  return (
                    <Box  
                      key={index + 1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        width: "100%",
                        padding: 1,
                        
                      }}
                    >
                      <Box
                        sx={{
                          width: "80% !important",
                          cursor:"pointer!important"
                        }}
                        onClick={() => handleSelectItem(val)}
                      >
                        <TextField
                          value={val.name}
                          variant="standard"
                          InputProps={{
                            disableUnderline: true, // <== added this
                            readOnly: true,
                          }}
                          style={{ textAlign: "center !important" }}
                          sx={{
                            color: "black",
                            display: "flex !important",
                            alignItems: "center !important",
                            justifyContent: "center !important",
                            height: "100%",
                            cursor:"pointer !important",
                            textAlign: "center !important",
                          }}
                        />
                      </Box>
                      <Box
                      sx={{
                        display:"flex"
                      }}>
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleInputValue(val)}
                        >
                        <img src={edit} />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteValue(val)}
                        >
                          <img src={trash} />
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          )}
        </>
      </Box>
    </>
  );
};

export default CustomSelect;
