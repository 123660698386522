import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import callApi from "../../../ApiCall";
import Constants from "../../../Constants";
import { encrypt } from "../../../CryptoUtil";
import bg from "../../../assets/images/BG1.png";
import Logo from "../../../assets/images/Logo.png";
import ImageSlider from "../../Slider/Slider";
import "./UserCreateAccount.css";

const UserCreateAccount = () => {
  const images = [bg, bg, bg];
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [email, setEmail] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const navigate = useNavigate();
  const [isRegister, setIsRegister] = useState(true);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [userMasterId, setUserMasterId] = useState("");

  const onSubmitClick = async () => {
    try {
      if (
        firstName.trim() !== "" &&
        lastName.trim() !== "" &&
        phoneNumber.trim() !== "" &&
        password.trim() !== "" &&
        email.trim() !== "" &&
        firstNameError === "" &&
        lastNameError === "" &&
        phoneNumberError === "" &&
        passwordError === "" &&
        emailAddressError === ""
      ) {
        const params = {
          firstName: firstName,
          lastName: lastName,
          mobileNumber: phoneNumber,
          password: encrypt(password),
          emailAddress: email,
          gender: gender,
        };
        const response = await callApi({
          apiURL: "userAuth/register",
          requestBody: params,
        });
        if (response?.success) {
          setUserMasterId(response.data.userMasterId);
          setIsRegister(false);
        }
      } else {
        firstName.trim() === "" && setFirstNameError("Field is required.");
        lastName.trim() === "" && setLastNameError("Field is required.");
        phoneNumber.trim() === "" && setPhoneNumberError("Field is required.");
        password.trim() === "" && setPasswordError("Field is required.");
        email.trim() === "" && setEmailAddressError("Field is required.");
      }
    } catch (error) {
      console.error("Error during registration:", error);
    } finally {
    }
  };

  const onSubmitOtpClick = async () => {
    try {
      if (otp.trim() !== "" && otpError === "") {
        const params = {
          userMasterId: userMasterId,
          otp: otp,
        };
        const response = await callApi({
          apiURL: "userAuth/otpVerification",
          requestBody: params,
        });
        if (response?.success) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userId", response.data.userMasterId);
          localStorage.setItem("isSubscribed", response.data.isSubscribed);
          localStorage.setItem("menus", JSON.stringify(response.data.menus));
          localStorage.setItem("isSettings", 0);
          localStorage.setItem("isOnboarded", response.data.isOnboarded);
          if (response.data.isSubscribed) {
            navigate("/dashboard");
          } else {
            navigate("/payment");
          }
        }
      } else {
        otp.trim() === "" && setOtpError("Field is required.");
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
    } finally {
    }
  };

  const [gender, setGender] = useState("");
  const [genderList, setGenderList] = useState([]);
  const getGender = async () => {
    const response = await callApi({
      apiURL: Constants.API.getGender,
    });
    if (response?.success) {
      setGenderList(response.data.genderList);
      setGender(response.data.genderList[0].Value);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    getGender();
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("isSubscribed");
    localStorage.removeItem("menus");
    localStorage.removeItem("isOnboarded");
  }, []);

  return (
    <div>
      {isRegister ? (
        <div style={{ display: "flex" }}>
          <section className="sec-left">
            <ImageSlider images={images} />
          </section>
          <section className="sec-right">
            <div  className="usernew">
              <div>
                <img id="logo" src={Logo} alt="Logo" />
                <p id="typo1">Create an account</p>
                <p id="typo2">Registration is completely free</p>
                <p id="typo3">
                  Please provide basic information to register yourself
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "fit-content",
                  }}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    <div>
                      <label style={{ fontFamily: "urbanistl" }}>
                        First name<sup style={{ color: "red" }}>*</sup>
                      </label>
                      <br />
                      <input
                        className="input"
                        type="text"
                        value={firstName}
                        maxLength={100}
                        onChange={(e) => {
                          const alphaNumericExp = /^[a-zA-Z0-9]*$/;
                          if (alphaNumericExp.test(e.target.value)) {
                            setFirstName(e.target.value);
                            setFirstNameError("");
                          } else {
                            e.target.value == "" &&
                              setFirstName(e.target.value);
                            setFirstNameError("Field is required.");
                          }
                        }}
                        placeholder="Enter first name"
                      />
                      {firstNameError != "" && (
                        <p className="error">{firstNameError}</p>
                      )}
                    </div>
                    <div id="i2">
                      <label style={{ fontFamily: "urbanistl" }}>
                        Last name<sup style={{ color: "red" }}>*</sup>
                      </label>
                      <br />
                      <input
                        className="input"
                        type="text"
                        value={lastName}
                        maxLength={100}
                        onChange={(e) => {
                          const alphaNumericExp = /^[a-zA-Z0-9]*$/;
                          if (alphaNumericExp.test(e.target.value)) {
                            setLastName(e.target.value);
                            setLastNameError("");
                          } else {
                            e.target.value == "" && setLastName(e.target.value);
                            setLastNameError("Field is required.");
                          }
                        }}
                        placeholder="Enter Last name"
                      />
                      {lastNameError != "" && (
                        <p className="error">{lastNameError}</p>
                      )}
                    </div>
                  </div>
                  <div style={{ display: "flex", width: "100%" }}>
                    <div id="number">
                      <label
                        htmlFor="phone-number"
                        style={{ fontFamily: "urbanistl" }}
                      >
                        Mobile Number<sup style={{ color: "red" }}>*</sup>
                      </label>
                      <br />
                      <input
                        className="inputn"
                        type="tel"
                        id="phone-number"
                        placeholder="Enter Mobile number"
                        maxLength={10}
                        value={phoneNumber}
                        onChange={(e) => {
                          const alphaNumericExp = /^[0-9]*$/;
                          if (alphaNumericExp.test(e.target.value)) {
                            setPhoneNumber(e.target.value);
                            if (e.target.value.length == 10) {
                              setPhoneNumberError("");
                            } else {
                              setPhoneNumberError(
                                "Mobile number requires 10 digit value."
                              );
                            }
                          } else {
                            e.target.value == "" &&
                              setPhoneNumber(e.target.value);
                            setPhoneNumberError(
                              "Mobile number requires 10 digit value."
                            );
                          }
                        }}
                      />
                      {phoneNumberError != "" && (
                        <p className="error">{phoneNumberError}</p>
                      )}
                    </div>
                    <div id="number" style={{ marginLeft: "10%" }}>
                      <label style={{ fontFamily: "urbanistl" }}>
                        Gender<sup style={{ color: "red" }}>*</sup>
                      </label>
                      <br />
                      {genderList?.map((x) => {
                        return (
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value={x.Value}
                              checked={x.Value == gender}
                              style={{ fontFamily: "urbanistl" }}
                              onChange={() => setGender(x.Value)}
                            />
                            {" " + x.Label} &nbsp;&nbsp;&nbsp;
                          </label>
                        );
                      })}
                    </div>
                  </div>
                  <div id="number">
                    <label htmlFor="email" style={{ fontFamily: "urbanistl" }}>
                      Email Address<sup style={{ color: "red" }}>*</sup>
                    </label>
                    <br />
                    <input
                      className="inputn"
                      type="tel"
                      id="email"
                      placeholder="Enter Email Address"
                      maxLength={100}
                      value={email}
                      onChange={(e) => {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        setEmail(e.target.value);
                        if (emailRegex.test(e.target.value)) {
                          setEmailAddressError("");
                        } else {
                          setEmailAddressError(
                            "Please enter valid email address."
                          );
                        }
                      }}
                    />
                    {emailAddressError != "" && (
                      <p className="error">{emailAddressError}</p>
                    )}
                  </div>
                  <div id="password">
                    <label
                      htmlFor="phone-number"
                      style={{ fontFamily: "urbanistl" }}
                    >
                      Password<sup style={{ color: "red" }}>*</sup>
                    </label>
                    <br />
                    <input
                      className="inputn"
                      type="password"
                      id="password1"
                      placeholder="Enter Password"
                      value={password}
                      maxLength={200}
                      onChange={(e) => {
                        if (e.target.value != "") {
                          setPassword(e.target.value);
                          setPasswordError("");
                        } else {
                          e.target.value == "" && setPassword(e.target.value);
                          setPasswordError("Field is required.");
                        }
                      }}
                    />
                    {passwordError != "" && (
                      <p className="error">{passwordError}</p>
                    )}
                  </div>
                  
                  <button
                    id="ca-button"
                    onClick={() => {
                      onSubmitClick();
                    }}
                  >
                    Submit
                  </button>
                  <p id="b-para">
                    Already have an account?
                    <span>
                      <a
                        href="/login"
                        style={{ textDecoration: "none", color: "green" }}
                      >
                        Login
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div>
          <div style={{ display: "flex" }}>
            <section className="sec-left">
              <ImageSlider images={images} />
            </section>
            <section className="sec-right">
              <div style={{ marginTop: "15%" }}>
                <div>
                  <img id="logo" src={Logo} alt="Logo" />
                  <p id="typo1">Create an account</p>
                  <p id="typo2">Registration is completely free</p>
                  <p id="typo3">
                    Please provide otp for account verification which you will
                    get sms on your mobile - <b>{phoneNumber}</b>.
                  </p>
                  <div>
                    <div id="number">
                      <label
                        htmlFor="phone-number"
                        style={{ fontFamily: "urbanistl" }}
                      >
                        Otp<sup style={{ color: "red" }}>*</sup>
                      </label>
                      <br />
                      <input
                        className="inputn"
                        type="tel"
                        id="phone-number"
                        placeholder="Enter Otp"
                        maxLength={4}
                        value={otp}
                        onChange={(e) => {
                          const alphaNumericExp = /^[0-9]*$/;
                          if (alphaNumericExp.test(e.target.value)) {
                            setOtp(e.target.value);
                            if (e.target.value.length == 4) {
                              setOtpError("");
                            } else {
                              setPhoneNumberError(
                                "OTP requires 4 digit value."
                              );
                            }
                          } else {
                            e.target.value == "" && setOtpError(e.target.value);
                            setPhoneNumberError("OTP requires 4 digit value.");
                          }
                        }}
                      />
                      {otpError != "" && (
                        <p className="error">{phoneNumberError}</p>
                      )}
                    </div>
                    <button
                      id="ca-button"
                      onClick={() => {
                        onSubmitOtpClick();
                      }}
                    >
                      Submit
                    </button>
                    <p id="b-para">
                      Already have an account?
                      <span>
                        <a
                          href="/login"
                          style={{ textDecoration: "none", color: "green" }}
                        >
                          Login
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserCreateAccount;
