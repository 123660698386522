import React from "react";
import * as XLSX from "xlsx";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "./ServiceMaster.css";
import ExportFile from "../../common/ExportFile/ExportFile.jsx";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { useEffect, useState } from "react";
import { toast, Zoom } from "react-toastify";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteConfirmationDialog from "../../common/DeleteConfirmation/confirmdelete";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ServiceMasterPopup from "./ServiceMasterPopup";
import callApi from "../../ApiCall.js";

import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";
import Constants from "../../Constants.js";
import SearchBar from "../../common/SearchBar/SearchBar.js";
import AddButton from "../../common/Button/Button.js";

const MenuItemWrapper = styled("li")(() => ({
  padding: "8px 8px",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F75555",
    color: "#FFFFFF",
    img: {
      filter:
        "invert(92%) sepia(4%) saturate(8%) hue-rotate(126deg) brightness(138%) contrast(200%)",
    },
  },
  transition: "all 0.3s ease",
}));

const IconWrapper = styled("span")(() => ({
  fontSize: "16px",
  marginRight: "8px",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));

const ServiceMaster = (props) => {
  //Table Columns
  const columns = [
    { field: "id", headerName: "Sr No", flex: 1 },
    { field: "ServiceName", headerName: "Service Name", flex: 1 },

    {
      field: "ServiceRate",
      headerName: "Service Rate",
      flex: 1,
    },
    {
      field: "CategoryName",
      headerName: "Category Name",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            color: params.row.status === "Active" ? "#0ABE75" : "#F75555",
          }}
        >
          {params.row.status}
        </div>
      ),
    },
    props.currentMenu.Edit || props.currentMenu.Delete
      ? {
          field: "actions",
          headerName: "Actions",
          flex: 1,
          renderCell: (params) => (
            <div>
              <IconButton
                color="primary"
                aria-label="actions"
                onClick={(event) => handleMenuOpen(event, params.row)}
              >
                <MoreVertIcon style={{ color: "gray" }} />
              </IconButton>
              <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{
                  "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                    {
                      boxShadow: "none",
                      backgroundColor: "#EEE",
                      borderRadius: "8px",
                    },
                  "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                    paddingTop: "0",
                    paddingBottom: "0",
                  },
                }}
              >
                {props.currentMenu.Edit ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("edit")}>
                    <IconWrapper>
                      <img src={edit} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Edit</span>
                  </MenuItemWrapper>
                ) : null}

                {props.currentMenu.Delete ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("delete")}>
                    <IconWrapper>
                      <img src={trash} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Delete</span>
                  </MenuItemWrapper>
                ) : null}
              </Menu>
            </div>
          ),
        }
      : {},
  ];
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedServiceToDelete, setSelectedServiceToDelete] = useState(null);
  const [selectedServicetoUpdate, setSelectedServiceToUpdate] = useState(null);
  const [showServiceActivityPopup, setShowServiceActivityPopup] =
    useState(false);
  const [isAddMode, setIsAddMode] = useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  // const [isSearchFocused, setIsSearchFocused] = React.useState(false);
  const [page, setPage] = useState(1);

  const [serviceData, setServiceData] = useState([]);

  const handleDeleteClick = (selectedRow) => {
    setSelectedServiceToDelete(selectedRow);
    setShowDeleteConfirmation(true);
  };

  // Delete API
  const handleConfirmDelete = async (selectedRow) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.deleteService,
        requestBody: {
          serviceMasterId: selectedRow?.ServiceMasterId,
          updateDate: selectedRow?.UpdatedDate,
        },
      });
      if (response?.success) {
        const actionMessage = "Deleted Successfully";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Service has been deleted successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            theme: "colored",
            className: "toast_pop",
            autoClose: 1500,
            transition: Zoom,
            closeButton: true,
            onClose: () => {
              getServices();
            },
          }
        );
        getServices();
      } else {
        console.error("Error deleting plan:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setShowDeleteConfirmation(false);
      setPage(1);
    }
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedServiceToUpdate(row);
    setSelectedServiceToDelete(row);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleMenuSelect = (option) => {
    if (option === "edit") {
      handleUpdateClick(selectedServicetoUpdate);
      handleMenuClose();
    } else if (option === "delete") {
      handleDeleteClick(selectedServiceToDelete);
    }
  };

  const handleUpdateClick = (selectedRow) => {
    setSelectedServiceToUpdate(selectedRow);
    setIsAddMode(false);
    setShowServiceActivityPopup(true);
  };

  const handleAddProductClick = () => {
    setIsAddMode(true);
    setShowServiceActivityPopup(true);
  };

  const handleserviceActivityPopupClose = () => {
    setShowServiceActivityPopup(false);
    setIsAddMode(true);
    setSelectedServiceToUpdate(null);
    getServices();
  };

  const getServices = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getService,
      });
      if (response?.success) {
        const { serviceList = [] } = response.data;
        const mappedData = serviceList.map((item, index) => ({
          id: index + 1,
          ...item,
          status: item.Active === 1 ? "Active" : "Inactive",
        }));
        console.log(mappedData, "mappedData");
        setServiceData(mappedData);
      } else {
        console.error("Error fetching guest activity data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  const handleExportClick = () => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      const fileName = `Service_${formattedDate.replace(/ /g, "")}.xlsx`;
      const worksheetName = `Service`;

      if (paginatedRows.length > 0) {
        const exportData = paginatedRows.map((row, index) => ({
          "Sr No.": index + 1,
          "Service Name": row.ServiceName,
          "Service Rate": row.ServiceRate,
          "Category Name": row.CategoryName,
          Status: row.status,
        }));

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
        XLSX.writeFile(workbook, fileName);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF("p");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    const fileName = `Service_${formattedDate.replace(/ /g, "")}.pdf`;

    if (paginatedRows.length > 0) {
      let tableData = paginatedRows.map((row, index) => {
        return [
          index + 1,
          row.ServiceName,
          row.ServiceRate,
          row.CategoryName,
          row.status,
        ];
      });

      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Services", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      autoTable(doc, {
        head: [
          ["Sr No", "Service Name", "Service Rate", "Category Name", "Status"],
        ],
        body: tableData,
        theme: "grid",
      });

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        formattedDate,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );

      doc.save(fileName);
    } else {
      console.error("No data to export");
    }
  };

  const handleStatusChange = (event, newStatus) => {
    setPage(1);
    setSelectedStatus(newStatus);
  };
  const initialSortedRows = serviceData;
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const pageSize = 10;
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };
  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };
  const selectedStatusData =
    selectedStatus !== "all"
      ? sortedRows.filter((val) => val.status === selectedStatus)
      : sortedRows;
  const totalPages = Math.ceil(selectedStatusData.length / pageSize);

  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      return (
        <span className="arrow-icon">
          {sortOrder === "asc" ? (
            <i className="fa-solid fa-arrow-up" />
          ) : (
            <i className="fa-solid fa-arrow-down" />
          )}
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
    if (serviceData.length > 0) {
      setSortedRows(serviceData);
    }
  }, [serviceData.length > 0]);

  useEffect(() => {
    if (sortedColumn) {
      const sorted = serviceData.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
  }, [sortedColumn, sortOrder, serviceData]);

  const [filtering, setFiltering] = useState("");
  const handleSearchfilter = (event) => {
    setFiltering(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    const searched = serviceData.filter((item) => {
      if (!filtering) {
        return true;
      } else {
        return (
          (item.ServiceName !== null &&
            item.ServiceName.toLowerCase().includes(filtering.toLowerCase())) ||
          (item.ServiceRate !== null &&
            item.ServiceRate.toString()
              .toLowerCase()
              .includes(filtering.toLowerCase())) ||
          (item.CategoryName !== null &&
            item.CategoryName.toLowerCase().includes(filtering.toLowerCase()))
        );
      }
    });
    setSortedRows(searched);
  }, [filtering, serviceData]);

  const paginatedRows = selectedStatusData.slice(startIndex, endIndex);

  return (
    <>
      {showServiceActivityPopup && (
        <ServiceMasterPopup
          onClose={handleserviceActivityPopupClose}
          isAddMode={isAddMode}
          selectedserviceActivity={selectedServicetoUpdate}
        />
      )}
      <div className="serviceActivity_MainPae">
        <div className="serviceActivity_sect-1">
          <div className="serviceActivity_manage">
            <h1 className="manage_page_heading">Manage Services</h1>
          </div>
          <div className="export_service">
          <ExportFile
            handleExportClick={handleExportClick}
            filteredRows={serviceData}
            handleExportPDF={handleExportPDF}
          />
          &nbsp;&nbsp;&nbsp;
          {props?.currentMenu?.Add ? (
            <>
              <AddButton
                buttonText={"Add Service"}
                onClick={handleAddProductClick}
              />
            </>
          ) : (
            <></>
          )}</div>
        </div>

        <div className="serviceActivityTable">
          <div className="Table">
            <div className="serviceActivity_sect-2">
              <SearchBar value={filtering} onChange={handleSearchfilter} />
              <div className="serviceActivity_toggle">
                <ToggleButtonGroup
                  className="ToggleButtonGroups"
                  value={selectedStatus}
                  exclusive
                  onChange={handleStatusChange}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    value="all"
                    aria-label="all"
                    disabled={selectedStatus === "all"}
                    sx={{
                      textTransform: "none",
                      padding: "8px 16px",
                      borderRadius: "8px 0px 0px 8px",
                    }}
                  >
                    All
                  </ToggleButton>
                  <ToggleButton
                    value="Active"
                    aria-label="Active"
                    disabled={selectedStatus === "Active"}
                    sx={{ textTransform: "none", padding: "8px 16px" }}
                  >
                    Active
                  </ToggleButton>
                  <ToggleButton
                    value="Inactive"
                    aria-label="Inactive"
                    disabled={selectedStatus === "Inactive"}
                    sx={{
                      textTransform: "none",
                      padding: "8px 16px",
                      borderRadius: "0px 8px 8px 0px",
                    }}
                  >
                    Inactive
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            <DeleteConfirmationDialog
              open={showDeleteConfirmation}
              handleClose={() => {
                setShowDeleteConfirmation(false);
                handleMenuClose();
              }}
              handleConfirm={() => {
                handleConfirmDelete(selectedServiceToDelete); //added until real db is used to close dialog
                handleMenuClose();
              }}
              product={"Service"}
            />
            <div className="Service_DataGrid">
              <div className="serviceActivity-table">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            className="tableHeaderCell"
                            onClick={() => handleSortClick(column.field)}
                          >
                            {column.headerName}
                            {renderArrowIcon(column.field)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedRows.map((row, i) => (
                        <TableRow key={i}>
                          {columns.map((column, ind) => (
                            <TableCell key={ind} className="tableBodyCell">
                              {column.renderCell
                                ? column.renderCell({ row })
                                : row[column.field]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={totalPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(event, value) => setPage(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceMaster;
