import React, { useState, useEffect } from "react";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import callApi from "../../ApiCall";
import MySelect from "../../common/SingleSelect";
import Constants from "../../Constants";
const PropertyLocationPopup = ({
  onClose,
  isAddMode,
  selectedLocationActivity,
}) => {
  const selectedCurrency = isAddMode
    ? ""
    : {
        value:
          selectedLocationActivity &&
          JSON.parse(selectedLocationActivity?.Currency)[0]?.CurrencyId,
        label:
          selectedLocationActivity &&
          JSON.parse(selectedLocationActivity?.Currency)[0]?.Currency,
      };
  const [showPopup, setShowPopup] = useState(true);
  const [currency, setCurrency] = useState(selectedCurrency);
  const [currencyData, setCurrencyData] = useState([]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  });

  // get api for selecting currency
  const getAddCurrency = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getAddPropertyLocationCurrency,
      });
      if (response?.success) {
        const { currency = [] } = response.data;
        const currencyData = currency.map((item) => ({
          SelectCurrency: item?.SelectCurrency,
          Data: JSON.parse(item?.Data),
        }));

        const options = currencyData
          .filter((item) => item.SelectCurrency === "Currency")
          .flatMap((item) =>
            item.Data.map((currency) => ({
              value: currency.Id,
              label: currency.Name,
            }))
          );
        setCurrencyData(options);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    getAddCurrency();
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleCancel = () => {
    setShowPopup(false);
    onClose();
  };

  const initialValuesFormik = {
    propertyLocationName: isAddMode ? "" :selectedLocationActivity?.propertyLocationName,

  address: isAddMode ? "" : selectedLocationActivity?.address,

  contactNo: isAddMode ? "" : selectedLocationActivity?.contactNo,

  email: isAddMode ? "" : selectedLocationActivity?.email,

  NoOfRooms: isAddMode ? "" : selectedLocationActivity?.NoOfRooms,

  billingAddress: isAddMode ? "" : selectedLocationActivity?.billingAddress,

  gstNo: isAddMode ? "" : selectedLocationActivity?.gstNo,

  licenceNo: isAddMode ? "" : selectedLocationActivity?.licenceNo,

  description: isAddMode ? "" : selectedLocationActivity?.description,

  currency: isAddMode ? "" : selectedCurrency.value,

  checkInTime: isAddMode ? "" : selectedLocationActivity?.CheckInTime,

  checkOutTime: isAddMode ? "" : selectedLocationActivity?.CheckOutTime,


    // propertyLocationName: selectedLocationActivity?.propertyLocationName
    //   ? selectedLocationActivity?.propertyLocationName
    //   : "",
    // address: selectedLocationActivity?.address
    //   ? selectedLocationActivity?.address
    //   : "",
    // contactNo: selectedLocationActivity?.contactNo
    //   ? selectedLocationActivity?.contactNo
    //   : "",
    // email: selectedLocationActivity?.email
    //   ? selectedLocationActivity?.email
    //   : "",
    // NoOfRooms: selectedLocationActivity?.NoOfRooms
    //   ? selectedLocationActivity?.NoOfRooms
    //   : "",
    // billingAddress: selectedLocationActivity?.billingAddress
    //   ? selectedLocationActivity?.billingAddress
    //   : "",
    // gstNo: selectedLocationActivity?.gstNo
    //   ? selectedLocationActivity?.gstNo
    //   : "",
    // licenceNo: selectedLocationActivity?.licenceNo
    //   ? selectedLocationActivity?.licenceNo
    //   : "",
    // description: selectedLocationActivity?.description
    //   ? selectedLocationActivity?.description
    //   : "",
    // currency: isAddMode ? "" : selectedCurrency.value,
    // checkInTime: selectedLocationActivity?.CheckInTime
    //   ? selectedLocationActivity?.CheckInTime
    //   : "",
    // checkOutTime: selectedLocationActivity?.CheckOutTime
    //   ? selectedLocationActivity?.CheckOutTime
    //   : "",
  };

  const validationSchemaFormik = Yup.object({
    propertyLocationName: Yup.string()
      .required("Property location Name is required")
      .max(40, "Max 40 Characters"),
    address: Yup.string().required("Address is required"),
    contactNo: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Invalid contact number"
      )
      .required("Contact Number is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/,
        "Invalid email"
      ),
    NoOfRooms: Yup.string()
      .required(" Number Of Rooms is required")
      .matches(/^[0-9 ]*$/, "It must contain only number"),
    billingAddress: Yup.string().required("Billing Address is required"),
    gstNo: Yup.string().required("GST Number is required"),
    licenceNo: Yup.string().required("Licence number is required"),
    description: Yup.string().required("Description is required"),
    checkInTime: Yup.string().required("Check In Time is required"),
    checkOutTime: Yup.string().required("Check Out Time is required"),
    currency: Yup.number().required("Currency is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    values.currency = currency?.value;
    // Add Update API
    let response;
    try {
      if (isAddMode) {
        response = await callApi({
          apiURL: Constants.API.addPropertyLocation,
          requestBody: {
            propertyMasterId: values.propertyMasterId,
            propertyLocationName: values.propertyLocationName,
            NoOfRooms: values.NoOfRooms,
            address: values.address,
            billingAddress: values.billingAddress,
            contactNo: values.contactNo,
            description: values.description,
            email: values.email,
            gstNo: values.gstNo,
            licenceNo: values.licenceNo,
            currency: values.currency,
            checkInTime: values.checkInTime,
            checkOutTime: values.checkOutTime,
          },
        });
      } else {
        response = await callApi({
          apiURL: Constants.API.updatePropertyLocation,
          requestBody: {
            updatepropertyLocationId:
              selectedLocationActivity?.PropertyLocationId,
            propertyLocationName: values.propertyLocationName,
            NoOfRooms: values.NoOfRooms,
            address: values.address,
            billingAddress: values.billingAddress,
            contactNo: values.contactNo,
            description: values.description,
            email: values.email,
            gstNo: values.gstNo,
            licenceNo: values.licenceNo,
            currency: values.currency,
            checkInTime: values.checkInTime,
            checkOutTime: values.checkOutTime,
            updateDate: selectedLocationActivity?.UpdatedDate,
          },
        });
      }
      if (response?.success) {
        const actionMessage = isAddMode
          ? "Added new Location"
          : "Updated Location";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Location has been {isAddMode ? "added" : "updated"} successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            hideProgressBar: true,
            className: "toast_pop",
            autoClose: 1500,
            closeButton: true,
            transition: Zoom,
            onClose: () => {
              setShowPopup(false);
              onClose();
            },
          }
        );
      } else {
        console.error("Error fetching Property Location data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
    onClose();
    resetForm();
  };

  const handleChangecurrency = (selectedOption) => {
    setCurrency(selectedOption);
  };
  return (
    <>
      <div className="Overlay" />
      <div
        className={
          showPopup ? "propActivity_PopMain" : "propActivity_PopMain hidden"
        }
      >
        <div className="propActivity_First">
          <p>
            {isAddMode ? "Add Property Location" : "Update Property Location"}
          </p>
          <button onClick={handleCancel}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#424242"
              />
            </svg>
          </button>
        </div>
        <br />

        <Formik
          initialValues={initialValuesFormik}
          validationSchema={validationSchemaFormik}
          onSubmit={handleSubmit}
        >
          <Form className="property-form">
            <div className="propActivity_Name">
              <label htmlFor="propertyLocationName" className="Property-label">
                Location Name <sup>*</sup>
              </label>

              <Field
                type="text"
                id="propertyLocationName"
                name="propertyLocationName"
                placeholder="Enter Property location Name"
                className="custom-field"
              ></Field>
              <div className="error">
                <ErrorMessage name="propertyLocationName" />
              </div>
            </div>

            <div className="propActivity_Name">
              <label htmlFor="NoOfRooms" className="Property-label">
                No. of rooms <sup>*</sup>
              </label>

              <Field
                type="number"
                id="NoOfRooms"
                name="NoOfRooms"
                className="custom-field"
                placeholder="Enter Num of rooms"
              ></Field>
              <div className="error">
                <ErrorMessage name="NoOfRooms" />
              </div>
            </div>

            <div className="address_container">
              <h5 className="address_heading">Property Address</h5>
              <div className="address_inner_container">
                <div className="address_fields">
                  <label htmlFor="address" className="Property-label">
                    Address <sup>*</sup>
                  </label>

                  <Field
                    as="textarea"
                    id="address"
                    name="address"
                    placeholder="Enter Address"
                    className="custom-field_Address"
                  ></Field>
                  <div className="error">
                    <ErrorMessage name="address" />
                  </div>
                </div>

                <div className="address_fields">
                  <label htmlFor="country" className="Property-label">
                    Country <sup>*</sup>
                  </label>
                  <Field
                    type="text"
                    id="country"
                    name="country"
                    placeholder="Enter Country"
                    className="custom-field"
                  ></Field>
                  <div className="error">
                    <ErrorMessage name="country" />
                  </div>
                </div>

                <div className="address_fields">
                  <label htmlFor="state" className="Property-label">
                    State <sup>*</sup>
                  </label>
                  <Field
                    type="text"
                    id="state"
                    name="state"
                    className="custom-field"
                    placeholder="Enter State"
                  ></Field>
                  <div className="error">
                    <ErrorMessage name="state" />
                  </div>
                </div>

                <div className="address_fields">
                  <label htmlFor="city" className="Property-label">
                    City <sup>*</sup>
                  </label>
                  <Field
                    type="text"
                    id="city"
                    name="city"
                    placeholder="Enter City"
                    className="custom-field"
                  ></Field>
                  <div className="error">
                    <ErrorMessage name="city" />
                  </div>
                </div>

                <div className="address_fields">
                  <label htmlFor="zip" className="Property-label">
                    Zip Code <sup>*</sup>
                  </label>
                  <Field
                    type="number"
                    id="zip"
                    name="zip"
                    className="custom-field"
                    placeholder="Enter Zip-code"
                  ></Field>
                  <div className="error">
                    <ErrorMessage name="zip" />
                  </div>
                </div>
              </div>
            </div>

            <div className="address_container">
              <h5 className="address_heading">Billing Address</h5>
              <div className="address_inner_container">
                <div className="address_fields">
                  <label htmlFor="billingAddress" className="Property-label">
                    Address <sup>*</sup>
                  </label>

                  <Field
                    as="textarea"
                    id="billingAddress"
                    name="billingAddress"
                    className="custom-field_Address"
                    placeholder="Enter Billing address"
                  ></Field>
                  <div className="error">
                    <ErrorMessage name="billingAddress" />
                  </div>
                </div>

                <div className="address_fields">
                  <label htmlFor="billingCountry" className="Property-label">
                    Country <sup>*</sup>
                  </label>

                  <Field
                    type="text"
                    id="billingCountry"
                    name="billingCountry"
                    className="custom-field"
                    placeholder="Enter Billing Country"
                  ></Field>
                  <div className="error">
                    <ErrorMessage name="billingCountry" />
                  </div>
                </div>

                <div className="address_fields">
                  <label htmlFor="billingState" className="Property-label">
                    State <sup>*</sup>
                  </label>

                  <Field
                    type="text"
                    id="billingState"
                    name="billingState"
                    className="custom-field"
                    placeholder="Enter Billing State"
                  ></Field>
                  <div className="error">
                    <ErrorMessage name="billingState" />
                  </div>
                </div>

                <div className="address_fields">
                  <label htmlFor="billingCity" className="Property-label">
                    City <sup>*</sup>
                  </label>

                  <Field
                    type="text"
                    id="billingCity"
                    name="billingCity"
                    className="custom-field"
                    placeholder="Enter Billing City"
                  ></Field>
                  <div className="error">
                    <ErrorMessage name="billingCity" />
                  </div>
                </div>

                <div className="address_fields">
                  <label htmlFor="billingZip" className="Property-label">
                    Zip Code<sup>*</sup>
                  </label>

                  <Field
                    type="text"
                    id="billingZip"
                    name="billingZip"
                    className="custom-field"
                    placeholder="Enter Billing Zip Code"
                  ></Field>
                  <div className="error">
                    <ErrorMessage name="billingZip" />
                  </div>
                </div>
              </div>
            </div>

            <div className="propActivity_Name">
              <label htmlFor="contactNo" className="Property-label">
                Contact No <sup>*</sup>
              </label>

              <Field
                type="text"
                id="contactNo"
                name="contactNo"
                className="custom-field"
                placeholder="Enter Contact No"
              ></Field>
              <div className="error">
                <ErrorMessage name="contactNo" />
              </div>
            </div>

            <div className="propActivity_Name">
              <label htmlFor="email" className="Property-label">
                Email <sup>*</sup>
              </label>

              <Field
                type="text"
                id="email"
                name="email"
                className="custom-field"
                placeholder="Enter Email"
              ></Field>
              <div className="error">
                <ErrorMessage name="email" />
              </div>
            </div>

            <div className="propActivity_Name">
              <label htmlFor="propActivity_fname" className="Property-label">
                GST Number <sup>*</sup>
              </label>

              <Field
                type="text"
                id="gstNo"
                name="gstNo"
                className="custom-field"
                placeholder="Enter GST number"
              ></Field>
              <div className="error">
                <ErrorMessage name="gstNo" />
              </div>
            </div>

            <div className="propActivity_Name">
              <label htmlFor="licenceNo" className="Property-label">
                Licence No <sup>*</sup>
              </label>

              <Field
                type="text"
                id="licenceNo"
                name="licenceNo"
                className="custom-field"
                placeholder="Enter Licence No"
              ></Field>
              <div className="error">
                <ErrorMessage name="licenceNo" />
              </div>
            </div>

            <div className="propActivity_Name">
              <label htmlFor="" className="Property-label">
                Description <sup>*</sup>
              </label>
              <Field
                type="text"
                id="dscrption"
                name="description"
                className="custom-field"
                placeholder="Enter Description"
              />
              <div className="error">
                <ErrorMessage name="description" />
              </div>
            </div>

            <div className="propActivity_Name">
              <label htmlFor="currency" className="Property-label">
                Currency <sup>*</sup>
              </label>
              <Field
                component={MySelect}
                id="currency"
                name="currency"
                options={currencyData}
                placeholder="Select"
                onChange={handleChangecurrency}
                className="Currency-dropdown"
              />
              <div className="error">
                <ErrorMessage name="currency" />
              </div>
            </div>

            <div className="propActivity_Name">
              <label htmlFor="checkin-time" className="Property-label">
                Standard Check-in Time <sup>*</sup>
              </label>
              <Field
                type="time"
                id="checkin-time"
                name="checkInTime"
                className="currency-select"
              ></Field>

              <div className="error">
                <ErrorMessage name="checkInTime" />
              </div>
            </div>
            <div className="propActivity_Name">
              <label htmlFor="checkOut-time" className="Property-label">
                Standard Check-out Time <sup>*</sup>
              </label>
              <Field
                type="time"
                id="checkOut-time"
                name="checkOutTime"
                className="currency-select"
              ></Field>

              <div className="error">
                <ErrorMessage name="checkOutTime" />
              </div>
            </div>

            <div className="propActivity_End">
              <button type="submit" className="propActivity_buttonb">
                {isAddMode ? "Add" : "Update "}
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button className="propActivity_buttona" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </Form>
        </Formik>

        <br />
      </div>
    </>
  );
};

export default PropertyLocationPopup;
