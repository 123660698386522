import React, { useState, useEffect } from "react";
import DatePicker from "react-multi-date-picker";

export default function DateSelect({ startDate, onChange, format, minDate }) {
  const [selectedDate, setSelectedDate] = useState(startDate);

  useEffect(() => {
    setSelectedDate(startDate); // Update selected date when startDate prop changes
  }, [startDate]);

  const handleChange = (date) => {
    setSelectedDate(date);
    onChange(date); 
  };
 
  
  

  return (
    <DatePicker
      style={{cursor:"pointer"}}
      label="Select Date"
      value={selectedDate}
      onChange={handleChange} 
      placeholder="DD-MM-YYYY"
      format={format}
    />
  );
}
