import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Constants from "../../../Constants";
import callApi from "../../../ApiCall";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Button,
  Box,
  Grid,
  Typography,
  IconButton,
  Checkbox,
  TextField,
  FormControlLabel,
} from "@mui/material";
import "./Daily.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import { toast ,Zoom} from "react-toastify";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import Multiselect from "multiselect-react-dropdown";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const localizer = momentLocalizer(moment);
console.log(localizer)

export default function DailySchedule({ GuestMasterId }) {
  const [events, setEvents] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState(1);
  const [bookingHistory, setBookingHistory] = useState([]);
  const [dynamicActivityList, setDynamicActivityList] = useState([]);
  const [specialActivityList, setSpecialActivityList] = useState([]);
  const [applyToAllDynamic, setApplyToAllDynamic] = useState(false);
  const [selectedGuestBookingHistory, setSelectedGuestBookingHistory] =
    useState([]);

  const [activityRows, setActivityRows] = useState([
    // {
    //   GuestActivityPreferencesId: "",
    //   ActivityMasterId: "",
    //   TimeSlotId: "",
    //   selectedEmployees: [],
    //   availableTimeSlots: [],
    //   availableEmployees: [],
    //   appliesToAllDays: false,
    // },
  ]);

  const [specialActivityRows, setSpecialActivityRows] = useState([
    // {
    //   GuestActivityPreferencesId: "",
    //   ActivityMasterId: "",
    //   TimeSlotId: "",
    //   selectedEmployees: [],
    //   availableEmployees: [],
    //   startTime: "",
    //   endTime: "",
    // },
  ]);

  const [selectedDate, setSelectedDate] = useState(moment());
  const [dateRange, setDateRange] = useState([]);
  const [tempSelectedDate, setTempSelectedDate] = useState(selectedDate);
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const [editMode, setEditMode] = useState(false); // State to manage edit mode
  const [editIndex, setEditIndex] = useState(null);

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.backgroundColor,
      color: event.color,
    };
    return {
      style,
    };
  };

  const newEventArr = events.map((val) => ({
    title: val?.title.split("(")[0],
    start: val?.start,
    end: val?.end,
    backgroundColor: val?.backgroundColor,
    color: val?.color,
    opacity: 0.5,
  }));


  let todaysDate = moment();

  useEffect(() => {
    const startDate = selectedDate.clone().startOf("week");
    const endDate = selectedDate.clone().endOf("week");

    const dates = [];
    let currentDate = startDate.clone();

    while (currentDate.isSameOrBefore(endDate)) {
      dates.push(currentDate.clone());
      currentDate.add(1, "day");
    }

    setDateRange(dates);
  }, [selectedDate]);

  const handlePreviousWeek = () => {
    setSelectedDate((prevDate) => prevDate.clone().subtract(1, "week"));
  };

  const handleNextWeek = () => {
    setSelectedDate((prevDate) => prevDate.clone().add(1, "week"));
  };

  const isToday = (date) => {
    return date.isSame(tempSelectedDate, "day");
  };

  const handleAddRow = () => {
    setActivityRows([
      ...activityRows,
      {
        ActivityMasterId: "",
        TimeSlotId: "",
        selectedEmployees: [],
        availableTimeSlots: [],
        availableEmployees: [],
      },
    ]);
  };

  const handleSpecialAddRow = () => {
    setSpecialActivityRows([
      ...specialActivityRows,
      {
        ActivityMasterId: "",
        selectedEmployees: [],
        availableEmployees: [],
      },
    ]);
  };


  const handleEditActivity = (guestActivityPreferencesId) => {
    setEditMode(true);

    // Find the activity based on GuestActivityPreferencesId
    const activity = selectedPreferences.find(
      (activity) =>
        activity.GuestActivityPreferencesId === guestActivityPreferencesId
    );

    if (!activity) {
      console.error(
        `Activity with GuestActivityPreferencesId ${guestActivityPreferencesId} not found.`
      );
      return;
    }

    if (activity.Timeslot) {
      // Populate dynamic activity form fields
      setActivityRows([
        {
          GuestActivityPreferencesId: activity.GuestActivityPreferencesId,
          ActivityMasterId: activity.ActivityMasterId,
          TimeSlotId: activity.GuestActivityTimingSlotsId,
          availableEmployees: JSON.parse(activity.PreferredEmployees),
          selectedEmployees: JSON.parse(activity.PreferredEmployees),
          availableTimeSlots: [
            {
              GuestActivityTimingSlotsId: activity.GuestActivityTimingSlotsId,
              TimeSlots: activity.Timeslot,
            },
          ],
        },
      ]);
      setSpecialActivityRows([]); 
    } else {
     
      setSpecialActivityRows([
        {
          GuestActivityPreferencesId: activity.GuestActivityPreferencesId,
          ActivityMasterId: activity.ActivityMasterId,
          startTime: activity.SpecialTherapyStartTime,
          endTime: activity.SpecialTherapyEndTime,
          availableEmployees: JSON.parse(activity.PreferredEmployees),
          selectedEmployees: JSON.parse(activity.PreferredEmployees),
        },
      ]);
      setActivityRows([]); 
    }
  };

  const handleDeleteRow = async (index) => {
    let updatedRows = [...activityRows];
    updatedRows.splice(index, 1);
    setActivityRows(updatedRows);
  };

  const handleDeleteActivity = async (guestActivityPreferencesId) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.deleteGuestActivityPreferrences, 

        requestBody: {
          guestActivityPreferencesId: guestActivityPreferencesId,
        },
      });

      if (response?.success) {
        // Update state to reflect the deletion
        setSelectedPreferences((prev) =>
          prev.filter(
            (activity) =>
              activity.GuestActivityPreferencesId !== guestActivityPreferencesId
          )

        );
        toast.success(
          <div className="custom_Toast">
            <h5>Deleted Preference</h5>
            Preference Deleted Successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            hideProgressBar: true,
            transition: Zoom,
  
            style: {
              width: "350px",
              backgroundColor: "#0ABE75",
              color: "#FFFFFF",
            },
            autoClose: 1500,
            closeButton: true,
            onClose: () => {},
          }
        );
      } else {
        console.log("Failed to delete activity preference");
      }
    } catch (error) {
      console.error("Error deleting activity preference:", error);
    }
  };

  const handleSpecialDeleteRow = (index) => {
    const updatedRows = [...specialActivityRows];
    updatedRows.splice(index, 1);
    setSpecialActivityRows(updatedRows);
  };
  const handleActivityChange = async (event, index) => {
    
    const { value } = event.target;

    // Call API to fetch time slots for the selected activity
    const responseTimeSlots = await callApi({
      apiURL: Constants.API.getGuestActivityTimeSlots,
      requestBody: { activityId: value },
    });

    const responseEmployees = await callApi({
      apiURL: Constants.API.getDynamicActivityEmployees,
      requestBody: { activityId: value, guestMasterId: GuestMasterId },
    });

    if (responseTimeSlots?.success && responseEmployees?.success) {
      const availableTimeSlots = responseTimeSlots.data.guestActivityTimeSlots;
      const availableEmployees =
        responseEmployees.data.dynamicActivityEmployees;
      const updatedRows = [...activityRows];
      updatedRows[index].ActivityMasterId = value;
      updatedRows[index].TimeSlotId = "";
      updatedRows[index].selectedEmployees = [];
      updatedRows[index].availableTimeSlots = availableTimeSlots;
      updatedRows[index].availableEmployees = availableEmployees;

      setActivityRows(updatedRows);
    } else {
      console.log("Failed to fetch time slots or employees");
    }
  };

  const handleSpecialActivityChange = async (event, index) => {
    const { value } = event.target;

    const responseEmployees = await callApi({
      apiURL: Constants.API.getSpecialActivityEmployees,
      requestBody: { activityId: value },
    });

    if (responseEmployees?.success) {
      const availableEmployees =
        responseEmployees.data.specialActivityEmployees;
      const updatedRows = [...specialActivityRows];
      updatedRows[index].ActivityMasterId = value;
      updatedRows[index].selectedEmployees = [];
      updatedRows[index].availableEmployees = availableEmployees;
      updatedRows[index].startTime = "";
      updatedRows[index].endTime = "";
      setSpecialActivityRows(updatedRows);
      console.log(specialActivityRows, "ui")
    } else {
      console.log("Failed to fetch employees for special activity");
    }
  };

  const handleAppliesToAllDaysChange = (event) => {
    setApplyToAllDynamic(event.target.checked);
    const updatedRows = activityRows.map((row) => ({
      ...row,
      appliesToAllDays: event.target.checked,
    }));
    setActivityRows(updatedRows);
  };

  const handleSubmit = async () => {
    const bookingForSelectedDate = selectedGuestBookingHistory.find(
      (booking) => {
        const checkInDate = moment(booking.CheckInDate);
        const checkOutDate = moment(booking.CheckOutDate);
        return tempSelectedDate.isBetween(
          checkInDate,
          checkOutDate,
          null,
          "[]"
        );
      }
    );

    if (!bookingForSelectedDate) {
      toast.error("No booking found for the selected date.");
      return;
    }

    // Extract BookingMasterId from the filtered booking
    const bookingMasterId = bookingForSelectedDate.BookingMasterId;
    // Validation for dynamic activities
    const isValidSelectDynamic = activityRows.every(
      (row) => row.ActivityMasterId !== "" && row.TimeSlotId !== ""
    );
    const isValidTimeSelectDynamic = activityRows.every(
      (row) => row.startTime !== "" && row.endTime !== ""
    );

    // Validation for special activities
    const isValidSelectSpecial = specialActivityRows.every(
      (row) => row.ActivityMasterId !== ""
    );
    const isValidTimeSelectSpecial = specialActivityRows.every(
      (row) => row.startTime !== "" && row.endTime !== ""
    );
    const isValidMultiselectSpecial = specialActivityRows.every(
      (row) => row.selectedEmployees.length > 0
    );

    // Check if all fields are valid for dynamic activities
    if (!isValidSelectDynamic || !isValidTimeSelectDynamic) {
      toast.error(
        <div className="custom_Toast">
          
          Please fill out fields for dynamic activities.
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          hideProgressBar: true,
          transition: Zoom,

          style: {
            width: "350px",
            backgroundColor: "#F8350C",
            color: "#FFFFFF",
          },
          autoClose: 1500,
          closeButton: true,
          onClose: () => {},
        }
      );
      return;
    }

    // Check if all fields are valid for special activities
    if (
      !isValidSelectSpecial ||
      !isValidTimeSelectSpecial ||
      !isValidMultiselectSpecial
    ) {
      toast.error(
        <div className="custom_Toast">
          
          Please fill out all fields for special activities.
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          hideProgressBar: true,
          transition: Zoom,

          style: {
            width: "350px",
            backgroundColor: "#F8350C",
            color: "#FFFFFF",
          },
          autoClose: 1500,
          closeButton: true,
          onClose: () => {},
        }
      );
      return;
    }

    // Additional validation for special activity time on today's date
    const today = moment();
    const isValidSpecialTime = specialActivityRows.every((row) => {
      if (moment(tempSelectedDate).isSame(today, "day")) {
        const startTime = moment(row.startTime, "HH:mm");
        const endTime = moment(row.endTime, "HH:mm");
        return (
          startTime.isSameOrAfter(today, "minute") &&
          endTime.isSameOrAfter(today, "minute")
        );
      }
      return true;
    });

    if (!isValidSpecialTime) {
      toast.error(
        <div className="custom_Toast">
          
          Start and End times for Special Activities on today's date cannot be in the past.
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          hideProgressBar: true,
          transition: Zoom,

          style: {
            width: "350px",
            backgroundColor: "#F8350C",
            color: "#FFFFFF",
          },
          autoClose: 1500,
          closeButton: true,
          onClose: () => {},
        }
      );
      return;
    }

    // Process dynamic activity time data
    const dynamicActivityTimeData = activityRows.map((row) => ({
      activityMasterId: row.ActivityMasterId,
      timeSlotId: row.TimeSlotId,
      selectedEmployees: row.selectedEmployees,
      startTime: null,
      endTime: null,
    }));

    // Process special activity data
    const specialActivityData = specialActivityRows.map((row) => ({
      activityMasterId: row.ActivityMasterId,
      timeSlotId: null,
      selectedEmployees: row.selectedEmployees,
      startTime: row.startTime ? `${row.startTime}:00` : null,
      endTime: row.endTime ? `${row.endTime}:00` : null,
    }));

    // Construct formData object
    const formData = {
      bookingMasterId: bookingMasterId,
      guestMasterId: GuestMasterId,
      selectedDate: tempSelectedDate.format("YYYY-MM-DD HH:mm:ss"),
      appliesToAllDays: applyToAllDynamic,
      dailyActivity: dynamicActivityTimeData,
      specialActivity: specialActivityData,
    };

    const response = await callApi({
      apiURL: Constants.API.savePreferrences,
      requestBody: formData,
    });

    if (response?.success) {
      setActivityRows([
       
      ]);

      setSpecialActivityRows([
       
      ]);
      toast.success(
        <div className="custom_Toast">
          <h5>Added New Preference</h5>
          Preferences saved successfully
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          hideProgressBar: true,
          transition: Zoom,

          style: {
            width: "350px",
            backgroundColor: "#0ABE75",
            color: "#FFFFFF",
          },
          autoClose: 1500,
          closeButton: true,
          onClose: () => {},
        }
      );
    } else {
      console.log("error");
    }
  };

  const handleTimeSlotChange = (event, index) => {
    const { value } = event.target;

    const updatedRows = [...activityRows];
    updatedRows[index].TimeSlotId = value;
    setActivityRows(updatedRows);
  };

  const handleEmployeeChange = (selectedList, index) => {
    const updatedRows = [...activityRows];
    updatedRows[index].selectedEmployees = selectedList.map(
      (employee) => employee.value
    );
    setActivityRows(updatedRows);
  };
  const handleSpecialEmployeeChange = (selectedList, index) => {
    const updatedRows = [...specialActivityRows];
    updatedRows[index].selectedEmployees = selectedList.map(
      (employee) => employee.value
    );
    setSpecialActivityRows(updatedRows);
  };
  const isPastDate = (date) => {
    return moment(date).isBefore(moment(), "day");
  };

  const getGuestSchedule = async () => {
    const response = await callApi({
      apiURL: Constants.API.getGuestSchedule,
      requestBody: {
        guestMasterId: GuestMasterId,
        selectedDate: tempSelectedDate.format("YYYY-MM-DD"),
      },
    });
    if (response?.success) {
      if (response?.data?.guestSchedule?.length > 0) {
        let data = response.data.guestSchedule.map((x) => {
          let activityDate = new Date(x.ActivityDate);
          let activityStartTime = x.ActivityStartTime.split(":");
          let activityEndTime = x.ActivityEndTime.split(":");
          return {
            title:
              x.ActivityName + " (" + x.ActivityType + ") - " + x.LocationName,
            start: new Date(
              +activityDate.getFullYear(),
              +activityDate.getMonth(),
              +activityDate.getDate(),
              +activityStartTime[0],
              +activityStartTime[1]
            ),
            end: new Date(
              +activityDate.getFullYear(),
              +activityDate.getMonth(),
              +activityDate.getDate(),
              +activityEndTime[0],
              +activityEndTime[1]
            ),
            backgroundColor:
              x.ActivityType === "Default Always"
                ? "#e3cce1"
                : x.ActivityType === "Default Dynamic"
                ? "#f08848"
                : "#FFEB4F",
            color: x.ActivityType === "Default Always" ? "black" : "white",
          };
        });
        setEvents([...data]);
      }
    } else {
      console.log("error");
    }
  };

  const getGuestPrefrence = async () => {
    const response = await callApi({
      apiURL: Constants.API.getActivityPreferences,
      requestBody: {
        guestMasterId: GuestMasterId,
        selectedDate: tempSelectedDate.format("YYYY-MM-DD"),
      },
    });
    if (response?.success) {
      const selectedGuestActivity = response.data.selectedGuestActivity;
      setSelectedPreferences(selectedGuestActivity);
    }
  };

  const getDynamicActivityList = async () => {
    const response = await callApi({
      apiURL: Constants.API.getDynamicActivityList,
    });
    if (response?.success) {
      setDynamicActivityList(response.data.dynamicActivityList);
    } else {
      console.log("error");
    }
  };

  const getSpecialGuestActivityList = async () => {
    const response = await callApi({
      apiURL: Constants.API.getSpecialGuestActivityList,
    });
    if (response?.success) {
      setSpecialActivityList(response.data.guestSpecialActivityList);
    } else {
      console.log("error");
    }
  };

  const getSelectedGuestBookingHistory = async (guestMasterId) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.selectedGuestBookingHistory,
        requestBody: { guestMasterId: GuestMasterId },
      });
      if (response?.success) {
        setSelectedGuestBookingHistory(
          response.data.selectedGuestBookingHistory
        );
      } else {
        console.log("Error:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleUpdate = async (index) => {
    try {
      if (editMode && index !== null) {
        // Determine if it's an activity row or special activity row based on state
        if (activityRows.length > 0 && index < activityRows.length) {
          const updatedRow = activityRows[index];
          const response = await callApi({
            apiURL: Constants.API.updatePreferences,
            requestBody: {
              data: {
                guestActivityPreferencesId:
                  updatedRow.GuestActivityPreferencesId,
                activityMasterId: updatedRow.ActivityMasterId,
                timeSlotId: updatedRow.TimeSlotId,
                selectedEmployees: updatedRow.selectedEmployees,
                startTime: null,
                endTime: null,
              },
            },
          });

          if (response?.success) {
            console.log("Activity row updated successfully");
            // Optionally, update local state or trigger a reload of data
            setEditMode(false);
            setEditIndex(null);
            setActivityRows([]);
            setSpecialActivityRows([]);
          } else {
            console.error("Error updating activity row:", response.error);
            // Handle error scenario
          }
        } else if (
          specialActivityRows.length > 0 &&
          index < specialActivityRows.length
        ) {
          const updatedRow = specialActivityRows[index];
          const response = await callApi({
            apiURL: Constants.API.updatePreferences,
            requestBody: {
              data: {
                guestActivityPreferencesId:
                  updatedRow.GuestActivityPreferencesId,
                activityMasterId: updatedRow.ActivityMasterId,
                timeSlotId: null,
                startTime: updatedRow.startTime
                  ? `${updatedRow.startTime}:00`
                  : null,
                endTime: updatedRow.endTime ? `${updatedRow.endTime}:00` : null,
                selectedEmployees: updatedRow.selectedEmployees,
              },
            },
          });

          if (response?.success) {
            console.log("Special activity row updated successfully");
            // Optionally, update local state or trigger a reload of data
            setEditMode(false);
            setEditIndex(null);
            setActivityRows([]);
            setSpecialActivityRows([]);
          } else {
            console.error(
              "Error updating special activity row:",
              response.error
            );
            // Handle error scenario
          }
        } else {
          console.error("Invalid index or row type");
        }
      } else {
        console.log("Submit new preference form");
        // Handle add logic here if needed
      }
    } catch (error) {
      console.error("Error during update:", error);
      // Handle error scenario
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    setActivityRows([]);
    setSpecialActivityRows([]);
  };

  useEffect(() => {
    if (tempSelectedDate) {
      getGuestSchedule();
      getGuestPrefrence();
      getDynamicActivityList();
      getSpecialGuestActivityList();
      getSelectedGuestBookingHistory();
    }
  }, [tempSelectedDate, activityRows, specialActivityRows]);


  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* Date selection */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          mb: "2%",
        }}
      >
        {/* Month and year display */}
        <Box mb={"1%"} mt={"1%"} width={"81%"}>
          <p
            style={{
              fontSize: "16px",
              fontFamily: "Urbanist",
              fontWeight: "bold",
            }}
          >
            <div className="month-year">{selectedDate.format("MMMM YYYY")}</div>
          </p>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            width: "90%",
          }}
        >
          <Button
            onClick={handlePreviousWeek}
            disableRipple
            endIcon={<ArrowBackIosIcon />}
          ></Button>
          {dateRange.map((date) => {
            // Check if date is within the check-in and check-out dates of any booking
            const isDateInRange = selectedGuestBookingHistory.some(
              (booking) => {
                return (
                  date.isSameOrAfter(moment(booking.CheckInDate)) &&
                  date.isSameOrBefore(moment(booking.CheckOutDate))
                );
              }
            );

            const isTodayDate = isToday(date);

            const isClickable = isDateInRange || isTodayDate;

            return (
              <div
                key={date.format("YYYY-MM-DD")}
                className={
                  isTodayDate
                    ? "currDate"
                    : isClickable
                    ? "allDate"
                    : "disabledDate"
                }
                style={{
                  border: date.isSame(todaysDate, "day")
                    ? "2px solid #6C7D02"
                    : "",
                  cursor: isClickable ? "pointer" : "not-allowed",
                }}
                onClick={() => {
                  if (isClickable) {
                    setTempSelectedDate(date);
                  }
                }}
              >
                <div>{date.format("ddd")}</div>
                <div>{date.format("D")}</div>
              </div>
            );
          })}
          <Button
            onClick={handleNextWeek}
            disableRipple
            endIcon={<ArrowForwardIosIcon />}
          ></Button>
        </Box>
      </Box>

      {/* Main content grid */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          width: "50%",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
          fontWeight: "600",
          border: "1px solid var(--primary-green)",
          margin: "20px",
          padding: "5px",
        }}
      >
        {" "}
        <h1>ADD PREFERENCES</h1>
      </Box>

      <Grid container spacing={3}>
        {/* Left side: Dynamic Activity and Special Activity */}
        {!editMode ? (
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Special Activity */}

              <Box>
                <h1 className="special-therapies">
                  SPECIAL THERAPIES
                  <span>
                    {/* Add icon */}
                    <IconButton
                      onClick={handleSpecialAddRow}
                      aria-label="add"
                      disabled={isPastDate(tempSelectedDate)}
                    >
                      <AddIcon />
                    </IconButton>
                  </span>
                </h1>
                

                {
                  
                specialActivityRows.map((row, index) => (
                  <Box key={index} className="single-therapy-select">
                    <Grid container spacing={2} alignItems="center">
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        {/* Select Activity */}
                        <FormControl variant="standard" sx={{ minWidth: 160 }}>
                          <InputLabel id={`activity-label-${index}`}>
                            Activity
                          </InputLabel>
                          <Select
                            labelId={`activity-label-${index}`}
                            id={`activity-select-${index}`}
                            value={row.ActivityMasterId}
                            onChange={(e) =>
                              handleSpecialActivityChange(e, index)
                            }
                            label="Activity"
                            disabled={isPastDate(tempSelectedDate)}
                          >
                            {specialActivityList
                              .filter(
                                (activity) =>
                                  !specialActivityRows.some(
                                    (row, rowIndex) =>
                                      rowIndex !== index &&
                                      row.ActivityMasterId ===
                                        activity.ActivityMasterId
                                  )
                              )
                              .map((activity) => (
                                <MenuItem
                                  key={activity.ActivityMasterId}
                                  value={activity.ActivityMasterId}
                                >
                                  {activity.ActivityName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        {/* Select Employees */}
                        <FormControl variant="standard" sx={{ minWidth: 160 }}>
                          <InputLabel id={`employee-label-${index}`}>
                            Select Therapist
                          </InputLabel>

                          <Multiselect
                            options={row.availableEmployees.map((employee) => ({
                              value: employee.EmployeeMasterId,
                              label: employee.EmployeeName,
                            }))}
                            onSelect={(selectedList) =>
                              handleSpecialEmployeeChange(selectedList, index)
                            }
                            onRemove={(selectedList) =>
                              handleSpecialEmployeeChange(selectedList, index)
                            }
                            selectedValues={row.selectedEmployees.map(
                              (employeeId) => ({
                                value: employeeId,
                                label:
                                  row.availableEmployees.find(
                                    (employee) =>
                                      employee.EmployeeMasterId === employeeId
                                  )?.EmployeeName || "",
                              })
                            )}
                            displayValue="label"
                            placeholder="Select Expert"
                            disabled={isPastDate(tempSelectedDate)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12} gap={"10px"}>
                        {/* Time inputs */}{" "}
                        <TextField
                          id={`start-time-${index}`}
                          label="Start Time"
                          type="time"
                          value={row.startTime}
                          onChange={(e) => {
                            const { value } = e.target;
                            const updatedRows = [...specialActivityRows];
                            updatedRows[index].startTime = value;
                            setSpecialActivityRows(updatedRows);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300,
                          }}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12} gap={"10px"}>
                        <TextField
                          id={`end-time-${index}`}
                          label="End Time"
                          type="time"
                          value={row.endTime}
                          onChange={(e) => {
                            const { value } = e.target;
                            const updatedRows = [...specialActivityRows];
                            updatedRows[index].endTime = value;
                            setSpecialActivityRows(updatedRows);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300,
                          }}
                        />
                      </Grid>
                    </Grid>
                    {/* Delete icon */}
                    <IconButton
                      onClick={() => handleSpecialDeleteRow(index)}
                      aria-label="delete"
                      disabled={isPastDate(tempSelectedDate)}
                    >
                      <ClearIcon sx={{ fontSize: "15px" }} />
                    </IconButton>
                  </Box>
                ))}
              </Box>
              {selectedPreferences
                .filter((activity) => !activity.Timeslot)
                .map((activity, index) => (
                  <Box
                    key={`selected-activity-${index}`}
                    sx={{
                      border: "1px solid #ccc",
                      padding: "10px",
                      marginBottom: "10px",
                      position: "relative",
                    }}
                  >
                    <Typography variant="h5">
                      {activity.ActivityName}
                    </Typography>
                    <Typography variant="body1">
                      Start Time: {activity.SpecialTherapyStartTime}
                    </Typography>
                    <Typography variant="body1">
                      End Time: {activity.SpecialTherapyEndTime}
                    </Typography>
                    <Typography variant="body1">
                      Preferred Employees:{" "}
                      {JSON.parse(activity.PreferredEmployees)
                        .map((employee) => employee.EmployeeName)
                        .join(", ")}
                    </Typography>

                    {/* Edit and Delete buttons */}
                    <Box
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                    >
                      <IconButton
                        onClick={() =>
                          handleEditActivity(
                            activity.GuestActivityPreferencesId
                          )
                        }
                        aria-label="edit"
                        style={{ marginRight: 10 }}
                        disabled={isPastDate(tempSelectedDate)}
                      >
                        <EditIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleDeleteActivity(
                            activity.GuestActivityPreferencesId
                          )
                        }
                        aria-label="delete"
                        disabled={isPastDate(tempSelectedDate)}
                      >
                        <DeleteIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              <br />
              {/* Dynamic Activity */}
              <Box>
                <h1 className="dynamic-therapies">
                  TIMESLOTS
                  <span>
                    {/* Add icon */}
                    <IconButton
                      onClick={handleAddRow}
                      aria-label="add"
                      disabled={isPastDate(tempSelectedDate)}
                    >
                      <AddIcon />
                    </IconButton>
                  </span>
                </h1>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applyToAllDynamic} // Step 4
                      onChange={handleAppliesToAllDaysChange} // Step 3
                    />
                  }
                  label="Apply to all days"
                />

                {activityRows.map((row, index) => (
                  <Box key={index} className="single-therapy-select">
                    <Grid container spacing={2} alignItems="center">
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        {/* Select Activity */}
                        <FormControl variant="standard" sx={{ minWidth: 160 }}>
                          <InputLabel id={`activity-label-${index}`}>
                            Activity
                          </InputLabel>
                          <Select
                            labelId={`activity-label-${index}`}
                            id={`activity-select-${index}`}
                            value={row.ActivityMasterId}
                            onChange={(e) => handleActivityChange(e, index)}
                            label="Activity"
                            disabled={isPastDate(tempSelectedDate)}
                          >
                            {dynamicActivityList
                              .filter(
                                (activity) =>
                                  !activityRows.some(
                                    (row, rowIndex) =>
                                      rowIndex !== index &&
                                      row.ActivityMasterId ===
                                        activity.ActivityMasterId
                                  )
                              )
                              .map((activity) => (
                                <MenuItem
                                  key={activity.ActivityMasterId}
                                  value={activity.ActivityMasterId}
                                >
                                  {activity.ActivityName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        {/* Select Time Slot */}
                        <FormControl variant="standard" sx={{ minWidth: 160 }}>
                          <InputLabel id={`time-slot-label-${index}`}>
                            Activity Time Slot
                          </InputLabel>
                          <Select
                            labelId={`time-slot-label-${index}`}
                            id={`time-slot-select-${index}`}
                            value={row.TimeSlotId}
                            onChange={(e) => handleTimeSlotChange(e, index)}
                            label="Activity Time Slot"
                            disabled={isPastDate(tempSelectedDate)}
                          >
                            {row.availableTimeSlots.map((slot, i) => (
                              <MenuItem
                                key={i}
                                value={slot.GuestActivityTimingSlotsId}
                              >
                                {slot.TimeSlots}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="dynamic-employee-select"
                      >
                        {row.availableEmployees.map((employee) => {
                          console.log(employee, "res");
                        })}
                        {/* Select Employees */}
                        <FormControl variant="standard">
                          <InputLabel id={`employee-label-${index}`}>
                            Select Therapist
                          </InputLabel>
                          <Multiselect
                            options={row.availableEmployees.map((employee) => ({
                              value: employee.EmployeeMasterId,
                              label: employee.EmployeeName,
                            }))}
                            selectedValues={row.selectedEmployees.map(
                              (employeeId) => ({
                                value: employeeId,
                                label: row.availableEmployees.find(
                                  (emp) => emp.EmployeeMasterId === employeeId
                                ).EmployeeName,
                              })
                            )}
                            onSelect={(selectedList) =>
                              handleEmployeeChange(selectedList, index)
                            }
                            onRemove={(selectedList) =>
                              handleEmployeeChange(selectedList, index)
                            }
                            displayValue="label"
                            placeholder="Select Therapist"
                            disabled={isPastDate(tempSelectedDate)}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    {/* Delete icon */}
                    <IconButton
                      onClick={() => handleDeleteRow(index)}
                      aria-label="delete"
                      disabled={isPastDate(tempSelectedDate)}
                    >
                      <ClearIcon sx={{ fontSize: "15px" }} />
                    </IconButton>
                  </Box>
                ))}
              </Box>
              {selectedPreferences
                .filter((activity) => activity.Timeslot)
                .map((activity, index) => (
                  <Box
                    key={`selected-activity-${index}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid #ccc",
                      padding: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Box>
                      <Typography variant="h5">
                        {activity.ActivityName}
                      </Typography>
                      <Typography variant="body1">
                        Time Slot: {activity.Timeslot}
                      </Typography>
                      <Typography variant="body1">
                        Preferred Employees:{" "}
                        {JSON.parse(activity.PreferredEmployees)
                          .map((employee) => employee.EmployeeName)
                          .join(", ")}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() =>
                          handleEditActivity(
                            activity.GuestActivityPreferencesId
                          )
                        }
                        aria-label="edit"
                        style={{ marginRight: 10 }}
                        disabled={isPastDate(tempSelectedDate)}
                      >
                        <EditIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleDeleteActivity(
                            activity.GuestActivityPreferencesId
                          )
                        }
                        aria-label="delete"
                        disabled={isPastDate(tempSelectedDate)}
                      >
                        <DeleteIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                    </Box>
                  </Box>
                ))}

              <Button
                onClick={handleSubmit}
                variant="contained"
                className="save-prefrences"
                // endIcon={<SaveIcon />}
                disabled={isPastDate(tempSelectedDate)}
                sx={{
                  background: "var(--primary-green)",
                  // width: { xs: "80%", md: "80%", lg: "85%" },
                  "&:hover": {
                    background: "var(--secondary-green)",
                  },
                }}
              >
                Save Preferences
              </Button>
            </Box>
          </Grid>
        ) : (
          <Grid item lg={7} md={7} sm={12} xs={12}>
            {/* Conditional rendering based on specialActivityRows length */}
            {specialActivityRows.length > 0 && (
              <Box>
                <h1 className="special-therapies">SPECIAL THERAPIES</h1>
                {specialActivityRows.map((row, index) => (
                  <Box key={index} className="single-therapy-select">
                    <Grid container spacing={2} alignItems="center">
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        {/* Select Activity */}
                        <FormControl variant="standard" sx={{ minWidth: 160 }}>
                          <InputLabel id={`activity-label-${index}`}>
                            Activity
                          </InputLabel>
                          <Select
                            labelId={`activity-label-${index}`}
                            id={`activity-select-${index}`}
                            value={row.ActivityMasterId}
                            onChange={(e) =>
                              handleSpecialActivityChange(e, index)
                            }
                            label="Activity"
                            disabled={isPastDate(tempSelectedDate)}
                          >
                            {specialActivityList
                              .filter(
                                (activity) =>
                                  !specialActivityRows.some(
                                    (row, rowIndex) =>
                                      rowIndex !== index &&
                                      row.ActivityMasterId ===
                                        activity.ActivityMasterId
                                  )
                              )
                              .map((activity) => (
                                <MenuItem
                                  key={activity.ActivityMasterId}
                                  value={activity.ActivityMasterId}
                                >
                                  {activity.ActivityName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        {/* Select Employees */}
                        <FormControl variant="standard" sx={{ minWidth: 160 }}>
                          <InputLabel id={`employee-label-${index}`}>
                            Select Therapist
                          </InputLabel>
                          <Multiselect
                            options={row.availableEmployees.map((employee) => ({
                              value: employee.EmployeeMasterId,
                              label: employee.EmployeeName,
                            }))}
                            onSelect={(selectedList) =>
                              handleSpecialEmployeeChange(selectedList, index)
                            }
                            onRemove={(selectedList) =>
                              handleSpecialEmployeeChange(selectedList, index)
                            }
                            selectedValues={row.selectedEmployees
                              .map((employeeId) => {
                                const selectedEmployee =
                                  row.availableEmployees.find(
                                    (employee) =>
                                      employee.EmployeeMasterId ===
                                      employeeId.EmployeeMasterId
                                  );

                                if (selectedEmployee) {
                                  return {
                                    value: employeeId.EmployeeMasterId,
                                    label: selectedEmployee.EmployeeName,
                                  };
                                } else {
                                  return null;
                                }
                              })
                              .filter((employee) => employee !== null)}
                            displayValue="label"
                            placeholder="Select Expert "
                            disabled={isPastDate(tempSelectedDate)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12} gap={"10px"}>
                        {/* Time inputs */}
                        <TextField
                          id={`start-time-${index}`}
                          label="Start Time"
                          type="time"
                          value={row.startTime}
                          onChange={(e) => {
                            const { value } = e.target;
                            const updatedRows = [...specialActivityRows];
                            updatedRows[index].startTime = value;
                            setSpecialActivityRows(updatedRows);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300,
                          }}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12} gap={"10px"}>
                        <TextField
                          id={`end-time-${index}`}
                          label="End Time"
                          type="time"
                          value={row.endTime}
                          onChange={(e) => {
                            const { value } = e.target;
                            const updatedRows = [...specialActivityRows];
                            updatedRows[index].endTime = value;
                            setSpecialActivityRows(updatedRows);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          mt: 2,
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleUpdate(index)}
                          sx={{
                            mr: 2,
                            backgroundColor: "var(--primary-green)",
                            "&:hover": {
                              backgroundColor: "var(--secondary-green)",
                            },
                          }}
                        >
                          Update
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleCancel}
                          sx={{
                            backgroundColor: "var(--primary-green)",
                            "&:hover": {
                              backgroundColor: "var(--secondary-green)",
                            },
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Grid>
                  </Box>
                ))}
              </Box>
            )}
            {/* Conditional rendering based on activityRows length */}
            {activityRows.length > 0 && (
              <Box>
                <h1 className="dynamic-therapies">ADD TIMESLOT</h1>
                {activityRows.map((row, index) => (
                  <Box key={index} className="single-therapy-select">
                    <Grid container spacing={2} alignItems="center">
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        {/* Select Activity */}
                        <FormControl variant="standard" sx={{ minWidth: 160 }}>
                          <InputLabel id={`activity-label-${index}`}>
                            Activity
                          </InputLabel>
                          <Select
                            labelId={`activity-label-${index}`}
                            id={`activity-select-${index}`}
                            value={row.ActivityMasterId}
                            onChange={(e) => handleActivityChange(e, index)}
                            label="Activity"
                            disabled={isPastDate(tempSelectedDate)}
                          >
                            {dynamicActivityList
                              .filter(
                                (activity) =>
                                  !activityRows.some(
                                    (row, rowIndex) =>
                                      rowIndex !== index &&
                                      row.ActivityMasterId ===
                                        activity.ActivityMasterId
                                  )
                              )
                              .map((activity) => (
                                <MenuItem
                                  key={activity.ActivityMasterId}
                                  value={activity.ActivityMasterId}
                                >
                                  {activity.ActivityName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        {/* Select Time Slot */}
                        <FormControl variant="standard" sx={{ minWidth: 160 }}>
                          <InputLabel id={`time-slot-label-${index}`}>
                            Activity Time Slot
                          </InputLabel>
                          <Select
                            labelId={`time-slot-label-${index}`}
                            id={`time-slot-select-${index}`}
                            value={row.TimeSlotId}
                            onChange={(e) => handleTimeSlotChange(e, index)}
                            label="Activity Time Slot"
                            disabled={isPastDate(tempSelectedDate)}
                          >
                            {row.availableTimeSlots.map((slot, i) => (
                              <MenuItem
                                key={i}
                                value={slot.GuestActivityTimingSlotsId}
                              >
                                {slot.TimeSlots}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="dynamic-employee-select"
                      >
                        {/* Select Employees */}
                        <FormControl variant="standard">
                          <InputLabel id={`employee-label-${index}`}>
                            Select Therapist
                          </InputLabel>
                          <Multiselect
                            options={row.availableEmployees.map((employee) => ({
                              value: employee.EmployeeMasterId,
                              label: employee.EmployeeName,
                            }))}
                            onSelect={(selectedList) =>
                              handleEmployeeChange(selectedList, index)
                            }
                            onRemove={(selectedList) =>
                              handleEmployeeChange(selectedList, index)
                            }
                            selectedValues={row.selectedEmployees
                              .map((employeeId) => {
                                const selectedEmployee =
                                  row.availableEmployees.find(
                                    (employee) =>
                                      employee.EmployeeMasterId ===
                                      employeeId.EmployeeMasterId
                                  );

                                if (selectedEmployee) {
                                  return {
                                    value: employeeId.EmployeeMasterId,
                                    label: selectedEmployee.EmployeeName,
                                  };
                                } else {
                                  return null;
                                }
                              })
                              .filter((employee) => employee !== null)}
                            displayValue="label"
                            placeholder="Select Therapist"
                            disabled={isPastDate(tempSelectedDate)}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          mt: 2,
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleUpdate(index)}
                          sx={{
                            mr: 2,
                            backgroundColor: "var(--primary-green)",
                            "&:hover": {
                              backgroundColor: "var(--secondary-green)",
                            },
                          }}
                        >
                          Update
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleCancel}
                          sx={{
                            backgroundColor: "var(--primary-green)",
                            "&:hover": {
                              backgroundColor: "var(--secondary-green)",
                            },
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Grid>
                  </Box>
                ))}
              </Box>
            )}
          </Grid>
        )}
        {/* Right side: Calendar */}
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <Box
            sx={{
              width: "100%",
              border: "1px solid #E0E0E0",
              borderRadius: "8px",
            }}
          >
            <Calendar
              key={tempSelectedDate.format("YYYY-MM-DD")}
              localizer={localizer}
              events={newEventArr}
              // startAccessor="start"
              // endAccessor="end"
              style={{ width: "100%" }}
              defaultView="day"
              views={["day"]}
              defaultDate={tempSelectedDate.toDate()}
              toolbar={false}
              eventPropGetter={eventStyleGetter}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
