import React from "react";
import { useNavigate } from "react-router-dom";
import success from "../../../assets/images/success.png";
import "./PaymentCancel.css";

const PaymentCancel = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <center>
        <img src={success} style={{ width: "100px", height: "100px" }} />
        <h1 className="class1">Payment Cancelled</h1>
        <p>Your subscription has not been successfully processed.</p>
        <p>
          If you have any questions or issues, please contact our support team.
        </p>
        <div className="button1-container">
          <button
            className="button1"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Go to Dashboard
          </button>
        </div>
      </center>
    </div>
  );
};

export default PaymentCancel;
