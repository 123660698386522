import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import callApi from "../../ApiCall";
import { toast,Zoom } from "react-toastify";
import Constants from "../../Constants";

const DepartmentPopUp = ({ onClose, isAddMode, selectedDepartment }) => {
  const [showPopup, setShowPopup] = useState(true);
  const [status, setStatus] = useState(
    selectedDepartment
      ? selectedDepartment.Active
        ? "Active"
        : "Inactive"
      : "Active"
  );

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setStatus(newStatus);
    }
  };

  // Add/update department API
  const handleSubmit = async (values, { resetForm }) => {
    values.active = status === "Active" ? 1 : 0;

    if (isAddMode) {
        try {
          const response = await callApi({
            apiURL: Constants.API.addDepartment,
            requestBody: {
              active: values.active,
              departmentName: values.departmentName,
            },
          });
    
          if (response?.success) {
            const actionMessage = "Added new Department" ;
            toast.success(
              <div className="custom_Toast">
                <h5>{actionMessage}</h5>
                Department Added Successfully
              </div>,
              {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                hideProgressBar: true,
                transition: Zoom,
      
                style: {
                  width: "350px",
                  backgroundColor: "#0ABE75",
                  color: "#FFFFFF",
                },
                autoClose: 1500,
                closeButton: true,
                onClose: () => {},
              }
            );
            onClose();
        resetForm();
          } else {
            console.error("Error fetching guest activity data:", response.error);
          }
        } catch (error) {
          console.error("Error during API call:", error);
        }
      } else {
        try {
          const updateResponse = await callApi({
            apiURL: Constants.API.updateDepartment,
            requestBody: {
              active: values.active,
              departmentId: selectedDepartment?.DepartmentId,
              departmentName: values.departmentName,
              updatedDate: selectedDepartment?.UpdatedDate,
            }
          });
          if (updateResponse?.success) {
            const actionMessage = "Updated Department";
            toast.success(
              <div className="custom_Toast">
                <h5>{actionMessage}</h5>
                Department has been updated successfully
              </div>,
              {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                hideProgressBar: true,
                transition: Zoom,
                className: "toast_pop",
                autoClose: 1500,
                closeButton: true,
                onClose: () => {
                  setShowPopup(false);
                  onClose();
                },
              }
            );
          } else {
            console.error("Error fetching guest activity data:", updateResponse.error);
          }
        } catch (error) {
          console.error("Error during API call:", error);
        }
      }
    
      resetForm();
      onClose();
  
  };
  useEffect(()=> {
    document.body.style.overflow = "hidden"; 
    return () => {
      document.body.style.overflow = "unset"; 
    };
  })

  const initialValuesFormik = {
    departmentName: isAddMode ? "" : selectedDepartment?.DepartmentName,
    active: selectedDepartment ? (selectedDepartment.Active ? 1 : 0) : 1,
  };

  const validationSchemaFormik = Yup.object({
    departmentName: Yup.string()
      .required("Designation Name is required")
      .max(40, "Max 40 Characters"),
  });

  return (
    <>
      <div className="Overlay" />
      <div
        className={
          showPopup ? "department_PopMain" : "department_PopMain hidden"
        }
      >
        <div className="department_First">
          <p>{isAddMode ? "Add Department" : "Update Department"}</p>
          <button onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#424242"
              />
            </svg>
          </button>
        </div>

        <Formik
          initialValues={initialValuesFormik}
          validationSchema={validationSchemaFormik}
          onSubmit={handleSubmit}
        >
          <Form className="department_form">
            <div className="department_Name">
              <label htmlFor="departmentName" className="department-label">
                Department Name <sup>*</sup>
              </label>
              
              <Field
                type="text"
                id="departmentName"
                name="departmentName"
                placeholder="Enter Department Name"
                className="custom-field"
              />
              <div className="error">
                <ErrorMessage name="departmentName" />
              </div>
            </div>

            <div className="serviceActivity_Togglebutton-1">
              <ToggleButtonGroup
                className="serviceActivity_ToggleButtonGroups-1"
                sx={{ width: "30px", height: "32px", fontSize: "14px" }}
                value={status}
                exclusive
                onChange={handleStatusChange}
                aria-label="text alignment"
              >
                <ToggleButton
                  value="Active"
                  aria-label="Active"
                  style={{ borderRadius: "8px 0px 0px 8px" }}
                >
                  Active
                </ToggleButton>
                <ToggleButton
                  value="Inactive"
                  aria-label="Inactive"
                  style={{ borderRadius: "0px 8px 8px 0px" }}
                >
                  Inactive
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <br/>
            <div className="department_End">
              <button type="submit" className="department_buttonb">
                {isAddMode ? "Add New" : "Update "}
              </button>
              &nbsp;&nbsp;&nbsp;
              <button className="department_buttona" onClick={onClose}>
                Cancel
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};
export default DepartmentPopUp;
