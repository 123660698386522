import React, { useState, useEffect } from "react";
//validation import
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Checkbox, FormControlLabel } from "@mui/material";
import Multiselect from "multiselect-react-dropdown";
import callApi from "../../ApiCall";
import { toast,Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MediaPicker from "../../components/mediaPicker";
import { awsFileUpload } from "../../fileUpload";
import Constants from "../../Constants";

//temp data for weeks and amenities
const weeks = [
  {
    day: "Default price for all days",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  {
    id: 41,
    day: "Sunday",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  {
    id: 42,
    day: "Monday",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  {
    id: 43,
    day: "Tuesday",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  {
    id: 44,
    day: "Wednesday",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  {
    id: 45,
    day: "Thursday",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  {
    id: 46,
    day: "Friday",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  {
    id: 47,
    day: "Saturday",
    singleBed: "",
    doubleBed: "",
    extraBed: "",
  },
  // {
  //   day: "Sunday",
  //   singleBed: "",
  //   doubleBed: "",
  //   extraBed: "",
  // },
];

const RoomMasterPopup = ({ isAddMode, onClose, selectedRoomActivity }) => {
  let selectedImages = "";
  try {
    selectedImages =
      selectedRoomActivity &&
      selectedRoomActivity.RoomImages &&
      JSON.parse(selectedRoomActivity.RoomImages)?.map((x) => {
        return {
          image: Constants.awsMediaPath + x,
          key: x,
          isUpdated: false,
        };
      });
  } catch (error) {
    console.error('Error parsing RoomImages:', error);
  }

  const [roomImage, setRoomImage] = useState(null);
  const [changeRoomImages, setChangeRoomImages] = useState(
    selectedRoomActivity ? selectedImages : []
  );
  //bed prices
  const [data, setData] = useState(weeks);
  const [sameForAll, setSameForAll] = useState(false);
  //multi select for amenities

  const selectedAmenities = selectedRoomActivity?.Amenities
    ? JSON.parse(selectedRoomActivity?.Amenities)
    : [];
  const [amenitiesData, setAminitiesData] = useState(
    isAddMode ? selectedAmenities : []
  );

  const [selectedData, setSelectedData] = useState(
    selectedAmenities ? selectedAmenities : []
  );
  

  const handleAmenitiesChange = (selectedOptions, setFieldValue) => {
    const newData = selectedOptions.map((option) => option.value);
    setSelectedData(newData);
    setFieldValue("Amenities", newData);
  };

  useEffect(() => {
    if (selectedRoomActivity && selectedRoomActivity.BedRates) {
      const selectedRoomRates = JSON.parse(selectedRoomActivity.BedRates);
      setData((prevData) =>
        prevData?.map((item) => {
          const correspondingRate = selectedRoomRates.find(
            (rate) => rate.id === item.id
          );
          return correspondingRate
            ? {
                ...item,
                singleBed: correspondingRate.singleBed,
                doubleBed: correspondingRate.doubleBed,
                extraBed: correspondingRate.extraBed,
              }
            : item;
        })
      );
    }
  }, [selectedRoomActivity]);

  // get data for dropdowns
  const getRoomData = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getRoomData,
      });
      if (response?.data && response?.data?.roomList) {
        const Amenities = JSON.parse(response?.data?.roomList[0].Amenities).map(
          (item) => ({
            label: item.AmenititesData,
            value: item.AmenitiesId,
          })
        );
        setAminitiesData(Amenities);
      } else {
        console.error("Error fetching guest activity data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    getRoomData();
  }, []);

  const remainingAmenities = amenitiesData.filter((amenity) =>
    selectedAmenities.includes(amenity.value)
  );

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  });
  const handleImageChange = (file, image) => {
    setChangeRoomImages([
      ...changeRoomImages,
      {
        image,
        file,
        isUpdated: true,
      },
    ]);
    setRoomImage(null);
  };
 
  //formik values
  const initialValuesForFormiks = {
    RoomTypeName: isAddMode ? "" :  selectedRoomActivity?.RoomTypeName,

    // NoOfSingleBed: isAddMode ? "" : selectedRoomActivity?.NoOfSingleBed,

    // NoOfDoubleBed:isAddMode ? "" :  selectedRoomActivity?.NoOfDoubleBed,

    Amenities: selectedData,
    roomRates:  selectedRoomActivity?.BedRates
      ? JSON.parse(selectedRoomActivity?.BedRates)
      : data,
    roomImages: changeRoomImages,

    // RoomTypeName: selectedRoomActivity?.RoomTypeName
    //   ? selectedRoomActivity?.RoomTypeName
    //   : "",

    // // NoOfSingleBed: selectedRoomActivity?.NoOfSingleBed
    // //   ? selectedRoomActivity?.NoOfSingleBed
    // //   : 0,
    // // NoOfDoubleBed: selectedRoomActivity?.NoOfDoubleBed
    // //   ? selectedRoomActivity?.NoOfDoubleBed
    // //   : 0,
    // Amenities: selectedData,
    // roomRates: selectedRoomActivity?.BedRates
    //   ? JSON.parse(selectedRoomActivity?.BedRates)
    //   : data,
    // roomImages: changeRoomImages,
  };

  const handleSubmit = (
    values,
    { resetForm }
  ) => {
    let updatedImages = [];

    values.Amenities = selectedData;
    values.roomRates =
      data.filter((day) => day.day !== "Default price for all days") || "";

    changeRoomImages?.forEach((img, y) => {
      if (img.isUpdated && img.file != null) {
        updatedImages.push({ ...img, index: y });
      }
    });
    if (updatedImages.length > 0) {
      awsFileUpload(updatedImages).then((uploadImageRes) => {
        if (uploadImageRes.length > 0) {
          saveData(uploadImageRes, values, resetForm);
        }
      });
    } else {
      saveData([], values, resetForm);
    }
    onClose();
    resetForm();
  };

  const saveData = async (media, values, resetForm) => {
    let response;

    values.roomImages = changeRoomImages?.map((img, curr) => {
      return img?.isUpdated
        ? media?.filter((y) => {
            return y?.index === curr;
          })[0]?.key
        : img?.key;
    });

    try {
      if (!selectedRoomActivity) {
        response = await callApi({
          apiURL: Constants.API.addRoom,
          requestBody: {
            ...values,
          },
        });
      } else {
        response = await callApi({
          apiURL: Constants.API.updateRoom,
          requestBody: {
            ...values,
            roomTypeId: selectedRoomActivity?.RoomTypeId,
            updatedDate: selectedRoomActivity?.UpdatedDate,
          },
        });
      }
      if (response?.success) {
        const actionMessage = isAddMode
          ? "Added new Room Type"
          : "Updated Room Type";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Room Type has been {isAddMode ? "added" : "updated"} successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            hideProgressBar: true,
            transition: Zoom,
            style: {
              width: "350px",
              backgroundColor: "#0ABE75",
              color: "#FFFFFF",
            },
            autoClose: 1500,
            closeButton: true,
            onClose: () => {
              setShowPopup(false);
              onClose();
              resetForm();
            },
          }
        );
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
    setShowPopup(false);
    onClose();
    resetForm();
  };

  const validationSchema = Yup.object({
    RoomTypeName: Yup.string().required("Please fill Room Type"),
    Amenities: Yup.array().min(1, "At least one amenity must be selected"),
    roomRates: Yup.array()
      .of(
        Yup.object().shape({
          singleBed: Yup.string().when("day", {
            is: (day) => day !== "Default price for all days",
            then: () => Yup.string().required("Rate is required"),
            otherwise: () => Yup.string(),
          }),
          doubleBed: Yup.string().when("day", {
            is: (day) => day !== "Default price for all days",
            then: () => Yup.string().required("Rate is required"),
            otherwise: () => Yup.string(),
          }),
          extraBed: Yup.string().when("day", {
            is: (day) => day !== "Default price for all days",
            then: () => Yup.string().required("Rate is required"),
            otherwise: () => Yup.string(),
          }),
        })
      )
      .required("Room rates are required"),
    // NoOfSingleBed: Yup.number(),
    // NoOfDoubleBed: Yup.number(),
  });

  //showing popup
  const [showPopup, setShowPopup] = useState(true);

  const handleCancel = () => {
    setShowPopup(false);
    onClose();
  };
  const handlePriceChange = (day, bedType, value, setFieldValue) => {
    if (/^\d*$/.test(value)) {
      if (day === "Default price for all days") {
        setData((prevData) =>
          prevData.map((item) => {
            return { ...item, [bedType]: value };
          })
        );
        data.forEach((item, index) => {
          setFieldValue(`roomRates.${index}.${bedType}`, value);
        });
      } else {
        setData((prevData) =>
          prevData.map((item) => {
            if (item.day === day) {
              return { ...item, [bedType]: value };
            }
            return item;
          })
        );
        const index = data.findIndex((item) => item.day === day);
        setFieldValue(`roomRates.${index}.${bedType}`, value);
      }
    }
  };

  return (
    <>
      <div className="Overlay"></div>
      <div className={showPopup ? "roomPopupOpen" : "roomPopupOpen hidden"}>
        <div className="roomPopHeading">
          <p className="roomMaster_p">
            {isAddMode ? "Add Room Activity" : "Update Room Actiivity"}
          </p>
          <button onClick={handleCancel}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#424242"
              />
            </svg>
          </button>
        </div>
        <Formik
          initialValues={initialValuesForFormiks}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                padding: "4% 3% 0% 3%",
              }}
            >
              <div className="room_field">
                <label htmlFor="RoomTypeName">
                  Room Type Name <sup>*</sup>
                </label>
                <br />
                <Field
                  typpe="text"
                  id="roomType"
                  name="RoomTypeName"
                  placeholder="Enter Room Type"
                ></Field>
                <div className="error">
                  <ErrorMessage name="RoomTypeName" />
                </div>
              </div>

              {/* <div className="room_field">
                <label htmlFor="NoOfSingleBed">
                  No of Single Bed 
                </label>
                <br />
                <Field
                  type="number"
                  id="noOfSingleBed"
                  name="NoOfSingleBed"
                  placeholder="Enter No of Single Beds"
                  disabled
                ></Field>
                <div className="error">
                  <ErrorMessage name="NoOfSingleBed" />
                </div>
              </div> */}

              {/* Multiselect for amenities */}
              <div className="roomMaster_amenitiesDropdown">
                <label htmlFor="RoomAmenities">
                  Amenities <sup>*</sup>
                </label>
                <br />
                <Field
                  as={Multiselect}
                  options={amenitiesData}
                  selectedValues={remainingAmenities}
                  displayValue="label"
                  onSelect={(selectedList) =>
                    handleAmenitiesChange(selectedList, setFieldValue)
                  }
                  onRemove={(selectedList) =>
                    handleAmenitiesChange(selectedList, setFieldValue)
                  }
                  placeholder="Select Amenities"
                  name="Amenities"
                  id="Amenities"
                />
                <div className="error">
                  <ErrorMessage
                    name="Amenities"
                    component="div"
                    className="errorMsg"
                  />
                </div>
              </div>

              {/* <div className="room_field">
                <label htmlFor="NoOfDoubleBed">
                  No of Double Bed
                </label>
                <br />
                <Field
                  type="number"
                  id="noOfDoubleBed"
                  name="NoOfDoubleBed"
                  placeholder="Enter of Double Beds"
                  disabled 
                ></Field>
                <div className="error">
                  <ErrorMessage name="NoOfDoubleBed" />
                </div>
              </div> */}

              <div className="roomRate_Field_Table">
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sameForAll}
                        onClick={() => setSameForAll(!sameForAll)}
                      />
                    }
                    label="Same for all"
                  />
                </div>
                <div>
                  <table className="roomMaster_table">
                    <thead className="roomMaster_table_head">
                      <tr>
                        <th>Accommodation</th>
                        <th>Single Bed Rate</th>
                        <th>Double Bed Rate</th>
                        <th>Extra Bed Rate</th>
                      </tr>
                    </thead>
                    <tbody className="roomMaster_table_body">
                      {data?.map((item, index) => {
                        return (
                          (index >= 1 || (index === 0 && sameForAll)) && (
                            <tr key={index}>
                              <td>{item?.day}</td>
                              <td>
                                <Field
                                  type="text"
                                  maxLength={5}
                                  value={item.singleBed}
                                  className="RoomMaster_table_field"
                                  name={`roomRates.${index}.singleBed`}
                                  onChange={(e) =>
                                    handlePriceChange(
                                      item.day,
                                      "singleBed",
                                      e.target.value,
                                      setFieldValue
                                    )
                                  }
                                ></Field>
                                <div className="error">
                                  <ErrorMessage
                                    name={`roomRates.${index}.singleBed`}
                                  />
                                </div>
                              </td>
                              <td>
                                <Field
                                  type="text"
                                  maxLength={5}
                                  name={`roomRates.${index}.doubleBed`}
                                  value={item.doubleBed}
                                  className="RoomMaster_table_field"
                                  onChange={(e) =>
                                    handlePriceChange(
                                      item.day,
                                      "doubleBed",
                                      e.target.value,
                                      setFieldValue
                                    )
                                  }
                                ></Field>
                                <div className="error">
                                  <ErrorMessage
                                    name={`roomRates.${index}.doubleBed`}
                                  />
                                </div>
                              </td>
                              <td>
                                <Field
                                  type="text"
                                  maxLength={5}
                                  value={item.extraBed}
                                  className="RoomMaster_table_field"
                                  name={`roomRates.${index}.extraBed`}
                                  onChange={(e) =>
                                    handlePriceChange(
                                      item.day,
                                      "extraBed",
                                      e.target.value,
                                      setFieldValue
                                    )
                                  }
                                ></Field>
                                <div className="error">
                                  <ErrorMessage
                                    name={`roomRates.${index}.extraBed`}
                                  />
                                </div>
                              </td>
                            </tr>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* <div className="room_field">
                <label htmlFor="roomImage">Room Images</label>
                <br />
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {changeRoomImages?.length > 0 &&
                    changeRoomImages?.map((curr, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <MediaPicker
                            type="image"
                            source={curr?.image}
                            id={"fileUpload_" + index}
                            className={
                              "flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            }
                            name="roomImages"
                            imageHeight={150}
                            imageWidth={150}
                            onChange={handleImageChange}
                          />
                          <button
                            className="rmv_btn"
                            onClick={() => {
                              changeRoomImages.splice(index, 1);
                              setChangeRoomImages([...changeRoomImages]);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      );
                    })}

                  <div
                    style={{ width: "150px", height: "150px", margin: "10px" }}
                  >
                    <MediaPicker
                      type="image"
                      onChange={handleImageChange}
                      source={roomImage?.image}
                      id={"fileUpload"}
                      name="roomImages"
                      className={
                        "flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      }
                      multiple
                     
                    />
                  </div>
                </div>
              </div> */}
              

              {/* ***********buttons****************** */}
              <div className="roomPop_buttons">
                <button type="submit" className="roomMaster_buttonn">
                  {isAddMode ? "Add" : "Update"}
                </button>
                &nbsp;&nbsp;&nbsp;
                <button
                  type="button"
                  className="roomMaster_button"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default RoomMasterPopup;
