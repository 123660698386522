import React from "react";
import * as XLSX from "xlsx";
import "./DietPlan.css";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteConfirmationDialog from "../../common/DeleteConfirmation/confirmdelete";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ExportFile from "../../common/ExportFile/ExportFile";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import callApi from "../../ApiCall";
import DietPlanMasterPopup from "./DietPlanMasterPopup";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";
import Constants from "../../Constants";
import SearchBar from "../../common/SearchBar/SearchBar";
import AddButton from "../../common/Button/Button";



const MenuItemWrapper = styled("li")(() => ({
  padding: "8px 8px",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F75555",
    color: "#FFFFFF",
    img: {
      filter: "invert(92%) sepia(4%) saturate(8%) hue-rotate(126deg) brightness(138%) contrast(200%)",
    },
  },
  transition: "all 0.3s ease",
}));

const IconWrapper = styled("span")(() => ({
  fontSize: "16px",
  marginRight: "8px",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));

const DietPlanMaster = (props) => {
  const columns = [
    { field: "id", headerName: "Sr No", flex: 1 },
    {
      field: "DietPlanName",
      headerName: "Diet Plan Name",
      flex: 1,
    },
    { field: "DietPlanDescription", headerName: "Diet Plan Description", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: params.row.status === "Active" ? "#0ABE75" : "#F75555" }}>
          {params.row.status}
        </div>
      ),
    },
    props.currentMenu.Edit || props.currentMenu.Delete
      ? {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => (
          <div>
            <IconButton
              color="primary"
              aria-label="actions"
              onClick={(event) => handleMenuOpen(event, params.row)}
            >
              <MoreVertIcon style={{ color: "gray" }} />
            </IconButton>
            <Menu
              id="actions-menu"
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
              sx={{
                "& .MuiPaper-root": {
                  boxShadow: "none",
                  backgroundColor: "#EEE",
                  borderRadius: "8px",
                },
                "& .MuiList-root": {
                  paddingTop: "0",
                  paddingBottom: "0",
                },
              }}
            >
              {props.currentMenu.Edit ? (
                <MenuItemWrapper onClick={() => handleMenuSelect("edit")}>
                  <IconWrapper>
                    <img src={edit} alt="Edit" />
                  </IconWrapper>
                  <span style={{ fontWeight: "400" }}>Edit</span>
                </MenuItemWrapper>
              ) : null}

              {props.currentMenu.Delete ? (
                <MenuItemWrapper onClick={() => handleMenuSelect("delete")}>
                  <IconWrapper>
                    <img src={trash} alt="Delete" />
                  </IconWrapper>
                  <span style={{ fontWeight: "400" }}>Delete</span>
                </MenuItemWrapper>
              ) : null}
            </Menu>
          </div>
        ),
      }
      : {},
  ];

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedDietPlanForDelete, setSelectedDietPlanForDelete] = useState(null);
  const [selectedDietPlanForUpdate, setSelectedDietPlanForUpdate] = useState(null);
  const [showDietPlanActivityPopup, setShowDietPlanActivityPopup] = useState(false);
  const [isAddMode, setIsAddMode] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [dietplandata, setdietplandata] = useState([]);

  const handleDeleteClick = (selectedRow) => {
    setSelectedDietPlanForDelete(selectedRow);
    setShowDeleteConfirmation(true);
    setIsMenuOpen(false);
  };

  const handleConfirmDelete = async (selectedRow) => {
    try {
      const params = {
        dietPlanId : selectedRow.DietPlanId,
        updatedDate : selectedRow.UpdatedDate
      };

      const response = await callApi({
        apiURL: Constants.API.deleteDietPlan,
        requestBody: params,
      });

      if (response?.success) {
        const actionMessage = "Deleted Data";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Diet Plan has been deleted successfully
          </div>,            
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            transition: Zoom,
            hideProgressBar: true,
            className: "toast_pop",
            autoClose: 1500,
            closeButton: true,
            onClose : () => {
              getDietPlan();
            },
            }
        );
        getDietPlan();
      } else {
        console.error("Error deleting plan:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setShowDeleteConfirmation(false);
      setPage(1);
    }
    };
   const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedDietPlanForUpdate(row);
    setSelectedDietPlanForDelete(row);
    setIsMenuOpen(true);
   };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleMenuSelect = (option) => {
    if (option === "edit") {
      handleUpdateClick(selectedDietPlanForUpdate);
    } else if (option === "delete") {
      handleDeleteClick(selectedDietPlanForDelete);
    }
  };
  
  const handleUpdateClick = (selectedRow) => {
    setIsAddMode(false);
    setShowDietPlanActivityPopup(true);
    setSelectedDietPlanForUpdate(selectedRow);
    setIsMenuOpen(false);
  };

  const handleAddDietPlanClick = () => {
    setIsAddMode(true);
    setShowDietPlanActivityPopup(true);
  };

  const handleDietPlanActivityPopupClose = () => {
    setShowDietPlanActivityPopup(false);
    setSelectedDietPlanForUpdate(null);
    getDietPlan();
    setIsAddMode(true);
  };

  const getDietPlan = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getDietPlan,
      });
      if (response?.success) {
        const {dietPlanList = []} = response.data;
        const mappedData = dietPlanList?.map((item, index) => ({
          id : index + 1,
          ...item,
          status : item.IsActive === 1 ? "Active" : "InActive" ,
        }));
        setdietplandata(mappedData);
      } else {
        console.error("Error fetching dietplan data:", response.error);
        }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  
  useEffect(() => {
    getDietPlan();
  }, []);

  const handleExportClick = (dietplandata) => {
    try{
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      
      });
      const fileName = `DietPlan_${formattedDate.replace(/ /g, " ")}.xlsx`;
      const worksheetName = `DietPlan`;

      if(dietplandata.length > 0){
        const exportData = dietplandata.map((row) => ({
          "Sr No": row.id,
          "Diet Plan Name": row.DietPlanName,
          " Diet Plan Description": row.DietPlanDescription,
          Status: row.status,
        }));
        const workbook = XLSX.utils.json_to_sheet(exportData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workbook, worksheetName);
        XLSX.writeFile(workBook, fileName);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };
  
  const handleExportPDF = () => {
    const doc = new jsPDF("p");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    const fileName = `DietPlan_${formattedDate.replace(/ /g, "_")}.pdf`;

    const temp = columns.filter((val) => val?.field !== "actions");
    const tableHeaders = [
      "Sr No",
      "Diet Plan Name",
      "Diet Plan Description",
      "Status",
    ];

    if (dietplandata.length > 0) {
      let tableData = dietplandata.map((row) => {
        return [row.id, row.DietPlanName, row.DietPlanDescription, row.status];
    
      });
      
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Diet Plan", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      autoTable(doc, {
        theme: "grid",
        head: [tableHeaders],
        body: tableData,
        
      });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        formattedDate,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10,
        {
          align: "right",
        }
        
      );
      doc.save(fileName);
    }else{

    }
  };

  const initialSortedRows = dietplandata;
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const pageSize = 10;
  const totalPages = Math.ceil(sortedRows.length / pageSize);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  
  

  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

 const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      return (
        <span>
          {sortOrder === "asc" ? (
            <i className="fa-solid fa-arrow-up" />
          
          ) : (
            <i className="fa-solid fa-arrow-down" />
           
          )}
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
    if (dietplandata.length > 0) {
      setSortedRows(dietplandata);
    }
  }, [dietplandata.length > 0]);

  useEffect(() => {
    if (sortedColumn) {
      const sorted = dietplandata.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
      }, [sortedColumn, sortOrder, dietplandata]);

      const[filtering, setFiltering] = useState("");
      const handleSearchfilter = (event) => {
        setFiltering(event.target.value);
        setPage(1);
      };

      useEffect(() => {
        const searched = dietplandata.filter((item) => {
          if (!filtering) {
            return true;
          }else{
          return (
            (item.DietPlanName !== null &&
            item.DietPlanName.toLowerCase().includes
            (filtering.toLowerCase()
          )) 
        );
        }
        });
        setSortedRows(searched);
      }, [filtering,dietplandata]);

      const paginatedRows = sortedRows.slice(startIndex, endIndex);


  

  return (
  <>
     {showDietPlanActivityPopup && (
       <DietPlanMasterPopup
          onClose={handleDietPlanActivityPopupClose}
          isAddMode={isAddMode}
          selectedDietPlan = {selectedDietPlanForUpdate}
          getDietPlan={getDietPlan}
  />
    )}
    <div className="propActivity_MainPae">
      
      <div className="propActivity_sect-1">
      <div className="propActivity_manage">
            <h1 className="manage_page_heading">Manage DietPlan</h1>
          </div>
      <div className="export_dietplan" >
        <ExportFile 
        handleExportClick={handleExportClick}
        filteredRows={dietplandata}
        handleExportPDF={handleExportPDF}
        />
        &nbsp;&nbsp;&nbsp;
          {props?.currentMenu?.Add ? (
            <>
              <AddButton
                buttonText={"Add Diet Plan"}
                onClick={handleAddDietPlanClick}
              />
            </>
          ) : (
            <></>
          )}
      </div>
      </div>
      <div className="propActivityTable">
       <div className="Table">
       <SearchBar value={filtering} onChange={handleSearchfilter} />

       <DeleteConfirmationDialog
       open={showDeleteConfirmation}
       handleClose={() => {
         setShowDeleteConfirmation(false);
         handleMenuClose();
       }}
       handleConfirm={() => {
         handleConfirmDelete(selectedDietPlanForDelete);
         handleMenuClose();
       }}
       product={"DietPlan"}
       />
        <div className="dietplan_data_grid">
          <div className="propActivity-table">
          <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column,i) => (
                <TableCell 
                key={i}
                className="dietplantableHeaderCell"
                onClick={() => handleSortClick(column.field)}
                
                >
                  {column.headerName}
                  {renderArrowIcon(column.field)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row,i) => (
              <TableRow
                key={i}>
               {columns.map((column,ind) => (
                 <TableCell key={ind} className="tableBodyCell">
                   {column.renderCell
                     ? column.renderCell({ row })
                     : row[column.field]}
                 </TableCell>
               ))}
               </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
   
      <Pagination
                  count={totalPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(event, value) => setPage(value)}
                  //   onChange={(event, value) => handlePageChange(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
          </div>
        </div>
       </div>
      </div>
      
     
      
    </div>
  </>
  );

};

export default DietPlanMaster;
