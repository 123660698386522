import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import callApi from "../../ApiCall";
import { toast,Zoom } from "react-toastify";
import MySelect from "../../common/SingleSelect";
import Constants from "../../Constants";



const DietPlanMasterPopup = ({onClose, isAddMode, selectedDietPlan }) => {
  const [showPopup, setShowPopup] = useState(true);
  const [propertyDetail, setPropertyDetail] = useState([]);

  
  const [status, setStatus] = useState(
    selectedDietPlan
      ? selectedDietPlan.IsActive
        ? "Active"
        : "Inactive"
      : "Active"
  );
 
  const [propertyLocationName, setPropertyLocationName] = useState(
    isAddMode ? "" : { value: selectedDietPlan?.PropertyLocationId }
  );
  

  // useEffect(() => {
  //   const fetchProperties = async () => {
  //     try {
  //       const response = await callApi({ apiURL: Constants.API.getDietPlanProperty});
  //       if (response?.success) {
  //         const { propertyList = [] } = response.data;
  //         console.log("propertyList", response.data);
  //         const propertyData = propertyList.map((property) => ({
  //           value: property.PropertyMasterId,
  //           label: property.PropertyName,
  //         }));
  //         setPropertyList(propertyData);
  //       } else {
  //         console.error("Error fetching property data:", response.error);
  //       }
  //     } catch (error) {
  //       console.error("Error during API call:", error);
  //     }
  //   };

  //   const fetchPropertyLocation = async () => {
  //     try {
  //       const response = await callApi({ apiURL: Constants.API.getDietPlanPropertyLocation});
  //       if (response?.success) {
  //         const { propertyLocationList = [] } = response.data;
  //         const propertyLocationData = propertyLocationList.map((property) => ({
  //           value: property.PropertyLocationId,
  //           label: property.PropertyLocationName,
  //         }));
  //         setPropertyLocationList(propertyLocationData);
  //       } else {
  //         console.error("Error fetching property location data:", response.error);
  //       }
  //     } catch (error) {
  //       console.error("Error during API call:", error);
  //     }
  //   };

  //   fetchProperties();
  //   fetchPropertyLocation();
  // }, []);
  
  // Function to get property details
  const getPropertyDetail = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getPropertyDetails,
      });
      console.log("response", response);
      const dropdownData = response.data.response.map((val) => ({
        LocationType: val?.LocationType,
        Data: JSON.parse(val?.Data),
      }));

      const propertyList = dropdownData[0]?.Data?.map((val) => ({
        value: val?.LocationID,
        label: `${val?.LocationName} - ${val?.PropertyName}`,
      }));
      
      setPropertyDetail(propertyList);
      console.log("propertyList", propertyList);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  useEffect(() => {
    
    getPropertyDetail();
    
  }, []);

  // Function to check if diet plan name exists
  const checkDietPlanExists = async (dietPlanName, currentDietPlanName = null) => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getDietPlan, 
      });
      
      if (response?.success) {
        const { dietPlanList = [] } = response.data;
  
        
        const dietPlanExists = dietPlanList.some((plan) => 
          plan.DietPlanName.toLowerCase() === dietPlanName.toLowerCase() &&
          plan.DietPlanName !== currentDietPlanName
        );
  
        return dietPlanExists;
      } else {
        console.error("Error fetching diet plan data:", response.error);
        return false;
      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;
    }
  };
  

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setStatus(newStatus);
    }
  }
  

  const handleSubmit = async (values, { resetForm }) => {
    
    console.log("Submitted values before processing:", values);
    
    values.isActive = status === "Active" ? 1 : 0;
    values.propertyLocationId = propertyLocationName?.value || values.propertyMasterId;
    console.log("Processed values for submission:", values);
  
    if (isAddMode) {

      const dietPlanExists = await checkDietPlanExists(values.dietPlanName);

      if (dietPlanExists) {
        const actionMessage = "Diet Plan Name Already Exists";
        toast.error(
          <div className="custom_ErrorToast">
            <h5>{actionMessage}</h5>
          
          </div>,
          {
           
            theme: "colored",
            hideProgressBar: true,
            transition: Zoom,
            style: {
              width: "250px",
              fontSize : "12px",
              background: "--alert-status-error",
              color: "#fff",
          },
            
            autoClose: 1500,
            closeButton: true,
        

          }
        )
        return;
      }
    
      try {
        const response = await callApi({
          apiURL: Constants.API.addDietPlan,
          requestBody: {
            dietPlanName: values.dietPlanName,
            isActive: values.isActive,
            propertyLocationId: values.propertyLocationId,
            dietPlanDescription: values.dietPlanDescription,
          },
        });
        console.log("API Response (Add):", response);
        
        if (response?.success) {
          const actionMessage = "Added new Diet Plan";
          toast.success(
            <div className="custom_Toast">
              <h5>{actionMessage}</h5>
              DietPlan has been added successfully
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored",
              hideProgressBar: true,
              transition:Zoom,
              style: {
                width: "300px",
               
                background: "#0ABE75",
                color: "#fff",
            },
            autoClose: 1500,
            closeButton: true,
            onClose: () => {
              setShowPopup(false);
              onClose();
            },
          }
          );
          onClose();
          resetForm();
        } else {
          console.error("Error response from API:", response);
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    } else {
      // Update mode
      const isEditing = !!selectedDietPlan; 
  
      const hasDietPlanNameChanged = isEditing && selectedDietPlan.dietPlanName !== values.dietPlanName;
      
      
      if (hasDietPlanNameChanged) {
        const dietPlanExists = await checkDietPlanExists(
          values.dietPlanName, 
          isEditing ? selectedDietPlan.DietPlanName : null 
        );
    
        if (dietPlanExists) {
        const actionMessage = "Diet Plan Name Already Exists";

          toast.error(
            <div className="custom_ErrorToast">
              <h5>{actionMessage}</h5>
            
            </div>,
            {
             
              theme: "colored",
              hideProgressBar: true,
              transition: Zoom,
              style: {
                width: "250px",
                fontSize : "12px",
                background: "--alert-status-error",
                color: "#fff",
            },
              
              autoClose: 1500,
              closeButton: true,
          
  
            }
          )
          return;
        }
      }
      try {
        const updateResponse = await callApi({
          apiURL: Constants.API.updateDietPlan,
          
          requestBody: {
            dietPlanId: selectedDietPlan?.DietPlanId,
            dietPlanName: values.dietPlanName,
            isActive: values.isActive,
            propertyLocationId: values.propertyLocationId,
            dietPlanDescription: values.dietPlanDescription,
            updatedDate: selectedDietPlan?.UpdatedDate,
          },
        });
        console.log("API Response (Update):", updateResponse);
        
        if (updateResponse?.success) {
          const actionMessage = "Updated Diet Plan";
          toast.success(
            <div className="custom_Toast">
              <h5>{actionMessage}</h5>
              DietPlan has been updated successfully
            </div>,
            {
              position: toast.POSITION.TOP_CENTER,
              theme: "colored",
              hideProgressBar: true,
              transition: Zoom,
              className:"toast_pop",
              autoClose: 1500,
              closeButton: true,
              onClose: () => {
                setShowPopup(false);
                onClose();
              },
            }
          );
           } else {
          console.error("Error response from API (Update):", updateResponse);
        }
      } catch (error) {
        console.error("Error during API call (Update):", error);
      }
    }

    resetForm();
    onClose();
  };
  
  
  const handleChangeProperty = (option) => {
    setPropertyLocationName(option);
  }

  console.log(propertyLocationName , "12")
 

  const initialValuesFormik = {
   dietPlanName: isAddMode ? "" : selectedDietPlan?.DietPlanName,
    dietPlanDescription: isAddMode ? "" : selectedDietPlan?.DietPlanDescription,
    isActive: selectedDietPlan ? (selectedDietPlan?.IsActive ? 1 : 0) : 1,
    
    propertyLocationId: isAddMode ? "" : selectedDietPlan?.PropertyLocationId
  };

  const validationSchemaFormik = Yup.object({
  
    propertyLocationId: Yup.string().required("Property location is required"),
    dietPlanName: Yup.string().
    required("Diet plan name is required")
    .max(40, "Max 40 Characters"),

    dietPlanDescription: Yup.string().required("Diet plan description is required"),
    
  });

  



  return(
   <>
     
      <div className="Overlay" />
      <div
        className={
          showPopup ? "dietplan_PopMain" : "dietplan_PopMain hidden"
        }
      >
           
           <div className="dietplan_First">
          <p>{isAddMode ? "Add Diet Plan" : "Update Diet Plan"}</p>
          <button onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#424242"
              />
            </svg>
          </button>
        </div>
       <Formik 
        initialValues={initialValuesFormik} 
        validationSchema={validationSchemaFormik} 
        onSubmit={handleSubmit}>
        
            <Form className="dietplan-form">
              
                
                <div className="dietplan_Name">
                  <label htmlFor="property" className="dietplan-label">
                    Property <sup>*</sup>
                  </label>
                  <Field
                    
                    component={MySelect}
                    name="propertyLocationId"
                    options={propertyDetail}
                    placeholder="Select Property"
                    onChange= {handleChangeProperty}
                    className="dietplan-dropdown"
                  />
                  <div className="error">
                  <ErrorMessage name="propertyLocationId"/>

                  </div>
                </div>

                {/* Diet Plan Name and Description Fields Side by Side */}
                <div className="dietplan_Name">
                  <label htmlFor="dietPlanName"className="dietplan-label">
                    Diet Plan Name <sup>*</sup>
                  </label>
                  <Field
                    type="text"
                    id="dietPlanName"
                    name="dietPlanName"
                    placeholder="Enter Diet Plan Name"
                   className="custom-field"
                  />
                  <div className="error">
                  <ErrorMessage name="dietPlanName" />
                  </div>
                </div>

                <div className="dietplan_Name">
                  <label htmlFor="dietPlanDescription" className="dietplan-label">
                    Diet Plan Description <sup>*</sup>
                  </label>
                  <Field
                    as="textarea"
                    id="dietPlanDescription"
                    name="dietPlanDescription"
                    placeholder="Enter Diet Plan Description"
                   className="custom-field"
                style={{ height: "120px", resize: "none" }}

                  />
                  <div className="error">
                  <ErrorMessage name="dietPlanDescription"  />

                  </div>
                </div>

                {/* Active/Inactive Radio Group */}
                <div className="serviceActivity_Togglebutton-1">
                  
                  <ToggleButtonGroup
                    value={status}
                    exclusive
                    onChange={handleStatusChange}

                    className="serviceActivity_ToggleButtonGroups-1"
                    sx={{ width: "30px", height: "32px", fontSize: "14px" }}
                aria-label="text alignment"

                  >
                    <ToggleButton
                      value="Active"
                  aria-label="Active"

                  style={{ borderRadius: "8px 0px 0px 8px" }}
                     
                    >
                      Active
                    </ToggleButton>
                    <ToggleButton
                      value="Inactive"
                      aria-label="Inactive"
                      style={{ borderRadius: "0px 8px 8px 0px" }}
                    >
                      Inactive
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>

                {/* DialogActions */}
              
              <div className="dietplan_End">
              <button
                  type="submit"
                  className="dietplan_buttonb"           
 >
                  {isAddMode ? "Add New" : "Update"}
                </button>
              
              &nbsp;&nbsp;&nbsp;

              <button
                className="dietplan_buttona"
                  onClick={onClose}>
                  Cancel
                </button>
              </div>
             
            </Form>
          
        </Formik>
    
    
      </div>
  
   </>
  ) 
};

export default DietPlanMasterPopup;
