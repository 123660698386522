import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import callApi from "../../ApiCall";
import { toast,Zoom } from "react-toastify";
import MySelect from "../../common/SingleSelect";
import Constants from "../../Constants";

const DesignationPopup = ({ onClose, isAddMode, selectedDesignation }) => {
  const [showPopup, setShowPopup] = useState(true);
  const [departmentList, setDepartmentList] = useState([]);
  const [reportingToList, setReportingToList] = useState([]);
  const [status, setStatus] = useState(
    selectedDesignation
      ? selectedDesignation.Active
        ? "Active"
        : "Inactive"
      : "Active"
  );
  const [departmentName, setDepartmentName] = useState(
    isAddMode ? "" : { value: selectedDesignation?.DepartmentId }
  );
  const [reportingToName, setReportingToName] = useState(
    isAddMode ? "" : { value: selectedDesignation?.ReportingToId }
  );

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await callApi({ apiURL: Constants.API.getDesignationDepartment });
        if (response?.success) {
          const { departmentList = [] } = response.data;
          const departmentData = departmentList.map((department) => ({
            value: department.DepartmentId,
            label: department.DepartmentName,
          }));
          setDepartmentList(departmentData);
        } else {
          console.error("Error fetching department data:", response.error);
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    };

    const fetchReportingToDesignations = async () => {
      try {
        const response = await callApi({
          apiURL: Constants.API.getReportingToDesignation,
        });
        if (response?.success) {
          const { reportingToDesignationList = [] } = response.data;
          const reportingToData = reportingToDesignationList.map(
            (reporting) => ({
              value: reporting.ReportingToId,
              label: reporting.ReprtingToDesignation,
            })
          );

          setReportingToList(reportingToData);
        } else {
          console.error("Error fetching reportingTo data:", response.error);
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    };

    fetchDepartments();
    fetchReportingToDesignations();

    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setStatus(newStatus);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    console.log("Submitted values:", values);
    values.Active = status === "Active" ? 1 : 0;
    values.departmentId = departmentName?.value;
    values.reportingTo = reportingToName?.value;
    if (isAddMode) {
      try {
        const response = await callApi({
          apiURL: Constants.API.addDesignation,
          requestBody: {
            designationName: values.designationName,
            Active: values.Active,
            departmentId: values.departmentId,
            designationCode: values.designationCode,
            jobDescription: values.jobDescription,
            reportingTo: values.reportingTo,
          },
        });

        if (response?.success) {
          const actionMessage = "Added new Designation";
          toast.success(
            <div className="custom_Toast">
              <h5>{actionMessage}</h5>
              Designation has been added successfully
            </div>,
            {
              position: toast.POSITION.TOP_CENTER,
              theme: "colored",
              hideProgressBar: true,

              style: {
                width: "350px",
                backgroundColor: "#0ABE75",
                color: "#FFFFFF",
              },
              autoClose: 500,
              closeButton: true,
              onClose: () => {
                setShowPopup(false);
                onClose();
              },
            }
          );
          onClose();
          resetForm();
        } else {
          console.error("Error fetching guest activity data:", response.error);
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    } else {
      try {
        const updateResponse = await callApi({
          apiURL: Constants.API.updateDesignation,
          requestBody: {
            designationId: selectedDesignation?.DesignationId,
            designationName: values.designationName,
            Active: values.Active,
            departmentId: values.departmentId,
            designationCode: values.designationCode,
            jobDescription: values?.jobDescription,
            reportingTo: values.reportingTo,
            updatedDate: selectedDesignation?.UpdatedDate,
          },
        });
        if (updateResponse?.success) {
          const actionMessage = "Updated Designation";
          toast.success(
            <div className="custom_Toast">
              <h5>{actionMessage}</h5>
              Designation has been Updated successfully
            </div>,
            {
              position: toast.POSITION.TOP_CENTER,
              theme: "colored",
              hideProgressBar: true,
              transition: Zoom,
              className: "toast_pop",
              autoClose: 1500,
              closeButton: true,
              onClose: () => {
                setShowPopup(false);
                onClose();
              },
            }
          );
        } else {
          console.error(
            "Error fetching guest activity data:",
            updateResponse.error
          );
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    }

    resetForm();
    onClose();
  };

  const handleChangeDepartment = (option) => {
    setDepartmentName(option);
  };
  const handleChangeReporingTo = (option) => {
    setReportingToName(option);
  };
  const initialValuesFormik = {
    designationName: isAddMode ? "" : selectedDesignation?.DesignationName,
    designationCode: isAddMode ? "" : selectedDesignation?.DesignationCode,
    departmentId: isAddMode ? "" : selectedDesignation?.DepartmentId,
    reportingTo: isAddMode ? "" : selectedDesignation?.ReportingToId,
    jobDescription: isAddMode ? "" : selectedDesignation?.JobDescription,
    Active: selectedDesignation ? (selectedDesignation.Active ? 1 : 0) : 1,
  };

  const validationSchemaFormik = Yup.object({
    designationName: Yup.string()
      .required("Designation Name is required")
      .max(40, "Max 40 Characters"),
    designationCode: Yup.string()
      .required("Designation Code is required")
      .max(40, "Max 40 Characters"),
    departmentId: Yup.string().required("Department is required"),
    jobDescription: Yup.string().required("Job Description is required"),
  });

  return (
    <>
      <div className="Overlay" />
      <div
        className={
          showPopup ? "designation_PopMain" : "designation_PopMain hidden"
        }
      >
        <div className="designation_First">
          <p>{isAddMode ? "Add Designation" : "Update Designation"}</p>
          <button onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#424242"
              />
            </svg>
          </button>
        </div>

        <Formik
          initialValues={initialValuesFormik}
          validationSchema={validationSchemaFormik}
          onSubmit={handleSubmit}
        >
          <Form className="designation-form">
            <div className="designation_Name">
              <label htmlFor="designationName" className="Designation-label">
                Designation Name <sup>*</sup>
              </label>

              <Field
                type="text"
                id="designationName"
                name="designationName"
                placeholder="Enter Designation Name"
                className="custom-field"
              />
              <div className="error">
                <ErrorMessage name="designationName" />
              </div>
            </div>
            <div className="designation_Name">
              <label htmlFor="designationCode" className="Designation-label">
                Designation Code <sup>*</sup>
              </label>
              <Field
                type="text"
                id="designationCode"
                name="designationCode"
                placeholder="Enter Designation Code"
                className="custom-field"
              />
              <div className="error">
                <ErrorMessage name="designationCode" />
              </div>
            </div>
            <div className="designation_Name">
              <label htmlFor="department" className="Designation-label">
                Department <sup>*</sup>
              </label>
              <Field
                component={MySelect}
                name="departmentId"
                options={departmentList}
                placeholder="Select Department"
                className="designation-dropdown"
                onChange={handleChangeDepartment}
              />
              <div className="error">
                <ErrorMessage name="departmentId" />
              </div>
            </div>
            <div className="designation_Name">
              <label htmlFor="reportingTo" className="Designation-label">
                Reporting To
              </label>
              <Field
                component={MySelect}
                name="reportingTo" 
                options={reportingToList}
                placeholder="Select Reporting To"
                className="designation-dropdown"
                onChange={handleChangeReporingTo} 
              />
              <div className="error">
                <ErrorMessage name="reportingTo" />
              </div>
            </div>

            <div className="designation_Name">
              <label htmlFor="jobDescription" className="Designation-label">
                Job Description <sup>*</sup>
              </label>
              <Field
                as="textarea"
                id="jobDescription"
                name="jobDescription"
                placeholder="Enter Job Description"
                className="custom-field"
                style={{ height: "120px", resize: "none" }}
              />
              <div className="error">
                <ErrorMessage name="jobDescription" />
              </div>
            </div>
            <div className="serviceActivity_Togglebutton-1">
              <ToggleButtonGroup
                className="serviceActivity_ToggleButtonGroups-1"
                sx={{ width: "30px", height: "32px", fontSize: "14px" }}
                value={status}
                exclusive
                onChange={handleStatusChange}
                aria-label="text alignment"
              >
                <ToggleButton
                  value="Active"
                  aria-label="Active"
                  style={{ borderRadius: "8px 0px 0px 8px" }}
                >
                  Active
                </ToggleButton>
                <ToggleButton
                  value="Inactive"
                  aria-label="Inactive"
                  style={{ borderRadius: "0px 8px 8px 0px" }}
                >
                  Inactive
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className="designation_End">
              <button type="submit" className="designation_buttonb">
                {isAddMode ? "Add New" : "Update "}
              </button>
              &nbsp;&nbsp;&nbsp;
              <button className="designation_buttona" onClick={onClose}>
                Cancel
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default DesignationPopup;
