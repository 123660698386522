import React, { useState,useEffect } from 'react';
import Logo1 from '../../assets/icons/Group 32736.png'
import Logo2 from '../../assets/icons/id 1.png'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import Guest from './Guest';
import callApi from '../../ApiCall';
import Constants from '../../Constants';
import moment from 'moment';



var pdata = {
            "Name": "John Doe",
            "Mobile":"(702) 555-0122",
            "mail":"johndoe@example.com",
            "DOB":"21-May-1996",
            "Adress":"2118 Thornridge Cir, Connecticut 35624"
            }
var rdata = {
            "RoomNo": "1001",
            "Package": "Naturopathy",
            "TotalMember": "05",
            "Adult" : "04",
            "Children" : "01",
            "Checkin" : "12/3, 12:00 PM",
            "Checkout" : "17/3, 11:00 AM",
            "Status" : "Checked in"
}
var mdata = {
            "Pname" : "Silver",
            "DOP" : "28-Jun-2021",
            "DOE" : "24-Jun-2022",
            "DOEX" : "116 Days"
        
}
var bdata = {
            "Amount" : "₹1200",
            "Paid1" : "₹600",
            "Paid2" : "₹600"
}
function GuestDetails({handleBackToGuest,GuestMasterId }) {
    const [open, setOpen] = useState(false);
    const [selectedGuestDetails,setSelectedGuestDetails] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getSelectedGuestDetails = async (guestMasterId) => {
        try {
          const response = await callApi({
            apiURL: Constants.API.selectedGuestDetails,
            requestBody: { guestMasterId: guestMasterId },
          });
          if (response?.success) {
            setSelectedGuestDetails(response.data.selectedGuestDetails[0]);
          } else {
            console.log("Error:", response.error);
          }
        } catch (error) {
          console.error("Error during API call:", error);
        }
      };
    
      useEffect(() => {
        if (GuestMasterId) {
            getSelectedGuestDetails(GuestMasterId);
        }
      }, [GuestMasterId]);
      console.log(selectedGuestDetails)

    return (
        <div className='containerGD'>
            <div className='container-1'>
            {selectedGuestDetails && ( 
                <div className='container1'>
                    <div className='Logo'>
                        <img src={Logo1} alt='' />
                    </div>
                    <div className='subcontainer1'>
                        <div className='JohnDoe'>{selectedGuestDetails.GuestName}</div>
                        <div className='Num1'>{selectedGuestDetails.MobileNumber}</div>
                        <div className='Num1'>{selectedGuestDetails.EmailAddress}</div>
                    </div>
                    <div className='subcontainer2'>
                    <div className='Num1'>{moment(selectedGuestDetails.DateOfBirth).format('DD/MM/YYYY')}</div>
                        <div className='Num1'>{selectedGuestDetails.Address}</div>
                    </div>
                    <div className='Logo2' style={{ cursor: 'pointer' }}>
                        <MoreVertIcon />
                    </div>
                </div>)}
                <br />
                {/* ****** */}
                <Divider style={{ width: '97%', color: '#E0E0E0' }} /><br />
                {/* ****** */}
                <div className='container2'>
                    <d iv className='subcontainer-1'>
                        <div className='subcontainer-1-1'>
                            <b>Room details</b><br/><br/>
                            <p>Room No.</p>
                            <p>Package</p>
                            <p>Total Member</p>
                            <p>Adult(s)</p>
                        </div>
                        <div className='subcontainer-1-2'>
                            <b></b>
                            <p>{rdata.RoomNo}</p>
                            <p>{rdata.Package}</p>
                            <p>{rdata.TotalMember}</p>
                            <p>{rdata.Adult}</p>
                        </div>
                    </d>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <div className='subcontainer-1'>
                        <div className='subcontainer-1-1' style={{ paddingTop: '6%', paddingLeft: '10%',marginRight:'12%' }}><br/>
                            <p>Children(s)</p>
                            <p>Check in</p>
                            <p>Check out</p>
                            <p>Status</p>
                        </div>
                        <div className='subcontainer-1-2'>
                            <b></b>
                            <p>{rdata.Children}</p>
                            <p>{rdata.Checkin}</p>
                            <p>{rdata.Checkout}</p>
                            <p className='checkedin' style={{ color: '#246BFD', cursor: 'pointer' }}><b>{rdata.Status}</b></p>
                        </div>
                    </div>
                </div><br />
                {/* ****** */}
                <Divider style={{ width: '97%', color: '#E0E0E0' }} /><br />
                {/* ****** */}
                <div className='container3'>
                    <div className='subcontainer-1'>
                        <b>Membership</b>
                        <div className='Cancel'>Cancel</div>
                    </div><br/>
                    <div className='subcontainer-2'>
                        <div className='subcontainer-1-1'>
                            <p>Package name</p>
                            <p>Date of purchase</p>
                        </div>
                        <div className='subcontainer-1-2' style={{ paddingLeft: '11.5%' }}>
                            <p>{mdata.Pname}</p>
                            <p>{mdata.DOP}</p>
                        </div>

                        <Divider style={{paddingLeft:'9.8%'}} orientation="vertical" variant="middle" flexItem />
                        <div className='subcontainer-2'>
                            <div className='subcontainer-1-1' style={{ paddingLeft: '20.2%' }}>
                                <p>Date of expire</p>
                                <p>Date of expire</p>
                            </div>
                            <div className='subcontainer-1-2'style={{ paddingLeft: '94.5%' }}>
                                <p>{mdata.DOE}</p>
                                <p>{mdata.DOEX}</p>
                            </div>
                        </div></div><br/>
                    <div className='subcontainer-3'>
                       <div className='button'> 
                        <button className='button1'>Upgrade</button>
                        <button className='button2'>Renew</button>
                        <button className='button2' onClick={handleBackToGuest}>Back</button>
                       
                        </div>
                    </div><br />
                </div>
            </div>
            {/* ****** */} 
            <div className='container2'>
                <div className='container-1'>
                    <div><b>Balance</b></div>
                    <div className='box'>
                        <div className='box1'>
                            <div>Amount</div>
                            <div style={{ fontSize: "16px" }}>{bdata.Amount}</div>
                        </div>
                        <div className='box1'>
                            <div>Paid</div>
                            <div style={{ fontSize: "16px" }}>{bdata.Paid1}</div>
                        </div>
                        <div className='box1'>
                            <div>Paid</div>
                            <div style={{ fontSize: "16px" }}>{bdata.Paid2}</div>
                        </div><br />
                    </div>
                </div><br />
                <div className='container-1'>
                    <div className='sub-container'>
                        <b>Purpose of visit</b>
                        <p>Change</p>
                    </div>
                    <p style={{paddingRight:'5%'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div><br />
                <div className='container-2'>
                    <div className='sub-container'>
                        <div><img src={Logo2} alt='' /></div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <p1>Documents</p1>
                        <p onClick={handleClickOpen}>View</p>
                    </div>
                </div>
            </div>
            {/* <Dialog open={open} onClose={handleClose}>
                <div>
                    <div className='guestdocument'>
                        <div className='guestdocument-container'>
                        <img src={Constants.awsMediaPath + (selectedGuestDetails && JSON.parse(selectedGuestDetails.IDProof)[0])} alt='' /> 

                        </div>
                    </div><br />
                </div>
            </Dialog> */}
        </div>
    )
}

export default GuestDetails;