import React, { useState, useEffect } from "react";
import { toast, Zoom, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import callApi from "../../ApiCall";
import Constants from "../../Constants";
import MySelect from "../../common/SingleSelect";
import DatePicker from "react-multi-date-picker";
import { getDBFormateDateForInlineGrid } from "../../utils/DateTimeUtils";
import MultipleCheckboxSelect from "../../common/MultiCheckBoxSelect.js/MultiCheckBoxSelect";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const AddLeaveBalancePopUp = ({
    onClose,
    isAddMode,
    leaveBalanceData
}) => {
    const [showPopup, setShowPopup] = useState(true);
    const [leaveTypes, setLeaveTypes] = useState([]);
    const [designationList, setDesignationList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(isAddMode ? "PreMonth" : leaveBalanceData.row.PrePost);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);

    useEffect(() => {
        const timePickerContainer = document.createElement("div");
        timePickerContainer.id = "time-picker-container";
        document.body.appendChild(timePickerContainer);

        return () => {
            document.body.removeChild(timePickerContainer);
        };
    }, []);

    const getRoles = async () => {
        const response = await callApi({
            apiURL: Constants.API.getDesignations,
        });
        if (response?.success) {
            const { designationsList = [] } = response.data;
            const mappedList = designationsList.map((role) => ({
                ...role,
            }));
            setDesignationList(mappedList);
        } else {
            console.log("error");
        }
    };

    const getQuarterTypesData = async () => {
        const response = await callApi({
            apiURL: Constants.API.getQuarterTypesData,
        });
        if (response?.success) {
            const { quarterList = [] } = response.data;
            setLeaveTypes(quarterList);
        } else {
            console.log("Error fetching leave types");
        }
    };

    useEffect(() => {
        getQuarterTypesData();
        getRoles();
    }, []);

    const handleCancel = () => {
        setShowPopup(false);
        onClose();
    };

    // const handleOutsideClick = (event) => {
    //     if (event.target.classList.contains("guestActivity_PopMain")) {
    //         setShowPopup(false);
    //         onClose();
    //         setShowOverlay(false);
    //     }
    // };

    const initialValues = {
        seakBalance: isAddMode ? "" : leaveBalanceData.row.SickBalance,
        previlegedBalance: isAddMode ? "" : leaveBalanceData.row.PrevilegedLeaveBalance,
        casualBalance: isAddMode ? "" : leaveBalanceData.row.CasualLeaveBalance,
        selectedDesignationAll: [],
        effectiveDate: isAddMode ? "" : leaveBalanceData.row.EffectiveDate,
        quarter: isAddMode ? "": leaveBalanceData.row.Quater,
    };

    const validationSchema = Yup.object({
        seakBalance: Yup.string().required("Seak Balance is required."),
        previlegedBalance: Yup.string().required("Previleged Balance is required."),
        casualBalance: Yup.string().required("Casual Balance is required."),
        selectedDesignationAll: Yup.array().min(1, "Division is required."),
        effectiveDate: Yup.string().required("Please select an effective date"),
        quarter: Yup.string().required("Please select a quarter"),
    });

    const handleStatusChange = (event, newStatus) => {
        if (newStatus !== null) {
            setSelectedStatus(newStatus);
        }
    };

    const handleSubmit = async (values, resetForm) => {

        try {
            const response = await callApi({
                apiURL: Constants.API.updateLeaveBalance,
                requestBody: {
                    ...values,
                    effectiveDate: getDBFormateDateForInlineGrid(values.effectiveDate),
                    prePost: selectedStatus==="PreMonth" ? 81: 82, 
                },
            });
            if (response?.success) {
                const actionMessage = isAddMode
                    ? "Added New Leave"
                    : "Updated Leave";
                toast.success(
                    <div className="custom_Toast">
                        <h5>{actionMessage}</h5>
                        Leave has been {isAddMode ? "added" : "updated"} successfully
                    </div>,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        hideProgressBar: true,
                        transition: Zoom,
                        style: {
                            width: "350px",
                            backgroundColor: "#0ABE75",
                            color: "#FFFFFF",
                        },
                        autoClose: 1500,
                        closeButton: true,
                        onClose: () => {
                            setShowPopup(false);
                            onClose();
                            resetForm();
                        },
                    }
                );
            }if (response?.success){
                onClose();
            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    return (
        <>
            { <div className="Overlay" />}
            <div
                className={
                    showPopup ? "guestActivity_PopMain" : "guestActivity_PopMain hidden"
                }
            // onClick={handleOutsideClick}
            >
                <div className="guestActivity_First">
                    <p>{isAddMode ? "Add Leave Balance" : "Update Leave"}</p>
                    <button className="popup_Close_icon" onClick={handleCancel}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                fill="#424242"
                            />
                        </svg>
                    </button>
                </div>
                <br />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values, setSubmitting, resetForm);
                    }}
                >
                    {({ setFieldValue, values, touched, errors }) => (
                        <Form className="guestActivity_form">
                            <div className="guestActivity_Name">
                                <MultipleCheckboxSelect
                                    items={designationList}
                                    keyField={"value"}
                                    textField={"label"}
                                    checked={values.selectedDesignationAll}
                                    label={"Designation"}
                                    error={touched.selectedDesignationAll && !!errors.selectedDesignationAll}
                                    setChecked={(values) => {
                                        setFieldValue("selectedDesignationAll", values);
                                    }}
                                    required={true}
                                    errorMessage={errors.selectedDesignationAll}
                                    isInline={true}
                                />
                                {errors.selectedDesignationAll && <div className="error_GuestActivity">
                                    {errors.selectedDesignationAll}
                                </div>}
                            </div>
                            <div className="guestActivity_Name">
                                <label htmlFor="leavetype">Sick Balance<sup>*</sup></label>
                                <Field
                                    type="number"
                                    id="seakBalance"
                                    name="seakBalance"
                                    placeholder="Seak Balance"
                                />
                                <div className="error_GuestActivity">
                                    <ErrorMessage name="seakBalance" />
                                </div>
                            </div>
                            <div className="guestActivity_Name">
                                <label htmlFor="leavetype">Previleged Balance<sup>*</sup></label>
                                <Field
                                    type="text"
                                    id="previlegedBalance"
                                    name="previlegedBalance"
                                    placeholder="Previleged Balance"
                                />
                                <div className="error_GuestActivity">
                                    <ErrorMessage name="previlegedBalance" />
                                </div>
                            </div>
                            <div className="guestActivity_Name">
                                <label htmlFor="leavetype">Casual Balance<sup>*</sup></label>
                                <Field
                                    type="text"
                                    id="casualBalance"
                                    name="casualBalance"
                                    placeholder="Casual Balance"
                                />
                                <div className="error_GuestActivity">
                                    <ErrorMessage name="casualBalance" />
                                </div>
                            </div>
                            <div className="guestActivity_Name">
                                <label htmlFor="startDate">Effective Date <span className="custom-text-color">*</span></label>
                                <DatePicker
                                    value={values.effectiveDate}
                                    onChange={(date) => setFieldValue("effectiveDate", date ? date.format("DD-MMM-YYYY") : "")}
                                    format="DD-MMM-YYYY"
                                    placeholder="Effective Date"
                                    // minDate={new Date()}
                                    style={{
                                        width: "100%",
                                        borderRadius: "8px",
                                        borderColor: "#ccc",
                                        height: "46px",
                                    }}
                                />
                                <div className="error_GuestActivity">
                                    <ErrorMessage name="effectiveDate" />
                                </div>
                            </div>
                            <div className="guestActivity_Name">
                                <label htmlFor="quarter">Select Quarter<sup>*</sup></label>
                                <Field
                                    name="quarter"
                                    component={MySelect}
                                    options={leaveTypes}
                                    placeholder="Select Quarter"
                                    onChange={(option) => setFieldValue("quarter", option?.value)}
                                />
                                <div className="error_GuestActivity">
                                    <ErrorMessage name="quarter" />
                                </div>
                            </div>
                            <div className="guestActivity_Name">
                                <label htmlFor="startDate">Prepost</label>
                                <ToggleButtonGroup
                                    className="ToggleButtonGroups"
                                    value={selectedStatus}
                                    exclusive
                                    onChange={handleStatusChange}
                                    aria-label="text alignment"
                                >
                                    <ToggleButton
                                        value="PreMonth"
                                        aria-label="Pending"
                                        sx={{ textTransform: "none", padding: "8px 16px" }}
                                    >
                                        Premonth
                                    </ToggleButton>
                                    <ToggleButton
                                        value="PostMonth"
                                        aria-label="Approved"
                                        sx={{ textTransform: "none", padding: "8px 16px" }}
                                    >
                                        PostMonth
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            <div className="guestActivity_End">
                                <button type="submit" className="guestActivity_buttonb">
                                    {isAddMode ? "Add" : "Update"}
                                </button>
                                &nbsp;&nbsp;&nbsp;
                                <button type="button" className="guestActivity_buttona" onClick={handleCancel}>
                                    Cancel
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <ToastContainer />
        </>
    );
};

export default AddLeaveBalancePopUp;
