import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";


const ConfirmApproveReject = ({ open, handleClose, handleConfirm, product, showTextField, inputValue, handleInputChange, heading }) => {
    return (
        <Dialog open={open} onClose={handleClose}
            sx={{
                '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
                    borderRadius: '8px', width: '352px',
                }
            }}>
            <DialogContent>
                <DialogContentText sx={{ fontSize: '20px', color: '#000000' }}>
                    {`Are you sure you want to delete this ${product}?`}
                </DialogContentText>
                {showTextField && (
                    <>
                        <label htmlFor="reason">Reason <sup>*</sup></label>
                        <input
                            label="Input"
                            className="custom-field_Address"
                            value={inputValue}
                            onChange={handleInputChange}
                            fullWidth
                            sx={{ marginTop: '16px' }}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: "space-around",
                    position: "relative",
                    borderTop: '1px solid #ccc',
                }}
            >
                <Button onClick={handleClose} sx={{ color: "#000000", position: "relative", "&:hover": { backgroundColor: "#f0f0f0", }, }}>
                    <span style={{ textTransform: 'none', fontSize: '20px' }}>Cancel</span>
                </Button>
                <Button onClick={handleConfirm} sx={{ color: "#F75555", "&:hover": { backgroundColor: "#f0f0f0", }, }} autoFocus>
                    <span style={{ textTransform: 'none', fontSize: '20px' }}>{heading}</span>
                </Button>
                <span
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "1px",
                        height: "60%",
                        backgroundColor: "#ccc",
                        transform: "translate(-50%, -50%)",
                    }}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmApproveReject;
