import React, { useEffect, useState } from "react";
import "./BlogInfo.css";
import demo from "../../../assets/images/H-Second.png";

const BlogInfo = ({ blogs }) => {
  return (
    <div className="mt-15">
      <div className="container-blog">
        <center>
          <h1 className="heading">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h1>
          <p className="para">
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
            fugit, sed quia consequuntur magni dolores eos qui ratione
            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem
            ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non
            numquam eius modi tempora incidunt ut labore et dolore magnam
            aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
            exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid
            ex ea commodi consequatur? Quis autem vel eum eos et accusamus et
            iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
            deleniti atque corrupti quos{" "}
          </p>
          <div className="cotainer2">
            <img src={demo} title="" alt="" className="img" />
          </div>
          <p className="para">
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
            fugit, sed quia consequuntur magni dolores eos qui ratione
            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem
            ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non
            numquam eius modi tempora incidunt ut labore et dolore magnam
            aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
            exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid
            ex ea commodi consequatur? Quis autem vel eum eos et accusamus et
            iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
            deleniti atque corrupti quos{" "}
          </p>
        </center>

        <div className="container3">
          <section className="section1">
            <img src={demo} />
          </section>
          <section className="section2">
            <h2 className="h1">
              We can help you to get software which drives sales Smart Business
              solutions encompasses Web.
            </h2>
            <p className="para">
              We have found a simple solution to a peculiar problem. Finding the
              right talent for your software project requirements is no more a
              task to lay down your precious time on. We do the hiring and
              management of the resources and you keep focus on better and
              bigger tasks at hand. Choose the best the indust ing the right
              talent for your software project requirements is no more a task to
              lay down your precious time on. We do the hiring and management of
              the resources and you keep focus on better and bigger tasks at
              hand. ry has to offer. A wide range of talent is available for
              hire at Reveation.
            </p>
          </section>
        </div>

        <center>
          <p className="para">
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
            fugit, sed quia consequuntur magni dolores eos qui ratione
            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem
            ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non
            numquam eius modi tempora incidunt ut labore et dolore magnam
            aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
            exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid
            ex ea commodi consequatur? Quis autem vel eum eos et accusamus et
            iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
            deleniti atque corrupti quos{" "}
          </p>
          <div className="cotainer2">
            <img src={demo} title="" alt="" className="img" />
          </div>
        </center>
      </div>
    </div>
  );
};

export default BlogInfo;
