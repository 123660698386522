import React from "react";
import * as XLSX from "xlsx";
import "./Designation.css";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteConfirmationDialog from "../../common/DeleteConfirmation/confirmdelete";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ExportFile from "../../common/ExportFile/ExportFile";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import callApi from "../../ApiCall";
import DesignationPopup from "./DesignationPopUp";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";
import Constants from "../../Constants";
import SearchBar from "../../common/SearchBar/SearchBar";
import AddButton from "../../common/Button/Button";

const MenuItemWrapper = styled("li")(() => ({
  padding: "8px 8px",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F75555",
    color: "#FFFFFF",
    img: {
      filter:
        "invert(92%) sepia(4%) saturate(8%) hue-rotate(126deg) brightness(138%) contrast(200%)",
    },
  },
  transition: "all 0.3s ease",
}));

const IconWrapper = styled("span")(() => ({
  fontSize: "16px",
  marginRight: "8px",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));

const Designation = (props) => {
  const columns = [
    { field: "id", headerName: "Sr No", flex: 1 },
    {
      field: "DesignationName",
      headerName: "Designation Name",
      flex: 1,
    },
    // { field: "DesignationCode", headerName: "Designation Code", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,

      renderCell: (params) => (
        <div
          style={{
            color: params.row.status === "Active" ? "#0ABE75" : "#F75555",
          }}
        >
          {params.row.status}
        </div>
      ),
    },
    props.currentMenu.Edit || props.currentMenu.Delete
      ? {
          field: "actions",
          headerName: "Actions",
          flex: 1,
          renderCell: (params) => (
            <div>
              <IconButton
                color="primary"
                aria-label="actions"
                onClick={(event) => handleMenuOpen(event, params.row)}
              >
                <MoreVertIcon style={{ color: "gray" }} />
              </IconButton>
              <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{
                  "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                    {
                      boxShadow: "none",
                      backgroundColor: "#EEE",
                      borderRadius: "8px",
                    },
                  "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                    paddingTop: "0",
                    paddingBottom: "0",
                  },
                }}
              >
                {props.currentMenu.Edit ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("edit")}>
                    <IconWrapper>
                      <img src={edit} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Edit</span>
                  </MenuItemWrapper>
                ) : null}

                {props.currentMenu.Delete ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("delete")}>
                    <IconWrapper>
                      <img src={trash} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Delete</span>
                  </MenuItemWrapper>
                ) : null}
              </Menu>
            </div>
          ),
        }
      : {},
  ];

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedDesignationForDelete, setSelectedDesignationForDelete] =
    useState(null);
  const [selectedDesignationForUpdate, setSelectedDesignationForUpdate] =
    useState(null);
  const [showDesignationActivityPopup, setShowDesignationActivityPopup] =
    useState(false);
  const [isAddMode, setIsAddMode] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [page, setPage] = useState(1);
  const [designationdata, setdesignationdata] = useState([]);

  const handleDeleteClick = (selectedRow) => {
    setSelectedDesignationForDelete(selectedRow);
    setShowDeleteConfirmation(true);
  };

  // Delete Api
  /**
   * @description This function is called when the user clicks on the delete button.
   * @param {Object} selectedRow The selected row object containing the designationId and UpdatedDate properties.
   */
  const handleConfirmDelete = async (selectedRow) => {
    try {
      const params = {
        designationId: selectedRow.DesignationId,
        updatedDate: selectedRow.UpdatedDate,
  /**
   * @description This function is called when the user confirms the deletion of the designation.
   * @param {Object} selectedRow The selected row object containing the designationId and UpdatedDate properties.
   */
      };

      const response = await callApi({
        apiURL: Constants.API.deleteDesignation,
        requestBody: params,
      });

      if (response?.success) {
        const actionMessage = "Deleted Data";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Designation has been Deleted successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            transition: Zoom,
            hideProgressBar: true,
            className: "toast_pop",
            autoClose: 1500,
            closeButton: true,
            onClose: () => {
              getDesignations();
            },
          }
        );
        getDesignations();
      } else {
        console.error("Error deleting plan:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setShowDeleteConfirmation(false);
      setPage(1);
    }
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedDesignationForUpdate(row);
    setSelectedDesignationForDelete(row);
    setIsMenuOpen(true);
  };
  /**
   * @description This function is called when the user clicks on the edit or delete button.
   * @param {Object} event The event object containing the target property.
   * @param {Object} row The selected row object containing the designationId and UpdatedDate properties.
   */

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleMenuSelect = (option) => {
  /**
   * @description This function is called when the user closes the menu.
   */
    if (option === "edit") {
      handleUpdateClick(selectedDesignationForUpdate);
      handleMenuClose();
    } else if (option === "delete") {
      handleDeleteClick(selectedDesignationForDelete);
  /**
   * @description This function is called when the user selects an option from the menu.
   */
    }
  };

  const handleUpdateClick = (selectedRow) => {
    setSelectedDesignationForUpdate(selectedRow);
    setIsAddMode(false);
    setShowDesignationActivityPopup(true);
  };

  /**
   * @description This function is called when the user clicks on the update button.
   * @param {Object} selectedRow The selected row object containing the designationId and UpdatedDate properties.
   */
  const handleAddDesignationClick = () => {
    setIsAddMode(true);
    setShowDesignationActivityPopup(true);
  };

  const handleDesignationActivityPopupClose = async () => {
  /**
   * @description This function is called when the user clicks on the add button.
   */
    setShowDesignationActivityPopup(false);
    setIsAddMode(true);
    setSelectedDesignationForUpdate(null);
    getDesignations();
  };
  /**
   * @description This function is called when the user closes the designation activity popup.
   */

  // get api to get all data
  const getDesignations = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getDesignation,
      });
  /**
   * @description This function is called when the user searches for a designation.
   * @param {Object} event The event object containing the target property.
   */

      if (response?.success) {
        const { designationList = [] } = response.data;

        const mappedData = designationList.map((item, index) => ({
          id: index + 1,
          ...item,
          status: item.Active === 1 ? "Active" : "Inactive",
        }));
        setdesignationdata(mappedData);
      } else {
        console.error("Error fetching designation data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    getDesignations();
  }, []);

  //export to xl
  const handleExportClick = (designationdata) => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      const fileName = `Designations_${formattedDate.replace(/ /g, " ")}.xlsx`;
      const worksheetName = `Designations`;

      if (designationdata?.length > 0) {
        const exportData = designationdata.map((row) => ({
          "Sr No": row.id,
          "Designation Name": row.DesignationName,
          "Designation Code": row.DesignationCode,
          Status: row.status,
        }));
        const workSheet = XLSX.utils.json_to_sheet(exportData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, worksheetName);
        XLSX.writeFile(workBook, fileName);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF("p");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    const fileName = `Designation_${formattedDate.replace(/ /g, "")}.pdf`;

    const temp = columns.filter((val) => val?.field !== "actions");
    const tableHeaders = [
      "Sr No",
      "Designation Name",
      "Designation Code",
      "Status",
    ];

    if (designationdata.length > 0) {
      let tableData = designationdata.map((row) => {
        return [row.id, row.DesignationName, row.DesignationCode, row.status];
      });

      // Add title
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Designation", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
        theme: "grid",
      });

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        formattedDate,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );

      doc.save(fileName);
    } else {
      // showToast("ERROR", "No Data To Export");
    }
  };

  // pagination sorting and searching
  const initialSortedRows = designationdata;
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const pageSize = 10;
  const totalPages = Math.ceil(sortedRows.length / pageSize);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      return (
        <span className="arrow-icon">
          {sortOrder === "asc" ? (
            <i className="fa-solid fa-arrow-up" />
          ) : (
            <i className="fa-solid fa-arrow-down" />
          )}
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
    if (designationdata.length > 0) {
      setSortedRows(designationdata);
    }
  }, [designationdata.length > 0]);

  useEffect(() => {
    if (sortedColumn) {
      const sorted = designationdata.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
  }, [sortedColumn, sortOrder, designationdata]);

  //search
  const [filtering, setFiltering] = useState("");
  const handleSearchfilter = (event) => {
    setFiltering(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    const searched = designationdata.filter((item) => {
      if (!filtering) {
        return true;
      } else {
        return (
          (item.DesignationName !== null &&
            item.DesignationName.toLowerCase().includes(
              filtering.toLowerCase()
            )) ||
          (item.DesignationCode !== null &&
            item.DesignationCode.toLowerCase().includes(
              filtering.toLowerCase()
            ))
        );
      }
    });
    setSortedRows(searched);
  }, [filtering, designationdata]);

  const paginatedRows = sortedRows.slice(startIndex, endIndex);

  return (
    <>
      {showDesignationActivityPopup && (
        <DesignationPopup
          onClose={handleDesignationActivityPopupClose}
          isAddMode={isAddMode}
          selectedDesignation={selectedDesignationForUpdate}
          getDesignations={getDesignations}
        />
      )}
      <div className="propActivity_MainPae">
        <div className="propActivity_sect-1">
          <div className="propActivity_manage">
            <h1 className="manage_page_heading">Manage Designation</h1>
          </div>
          <div className="export_designation">
          <ExportFile
            handleExportClick={handleExportClick}
            filteredRows={designationdata}
            handleExportPDF={handleExportPDF}
          />
          &nbsp;&nbsp;&nbsp;
          {props?.currentMenu?.Add ? (
            <>
              <AddButton
                buttonText={"Add Designation"}
                onClick={handleAddDesignationClick}
              />
            </>
          ) : (
            <></>
          )}
          </div>
        </div>
        <div className="propActivityTable">
          <div className="Table">
            <SearchBar value={filtering} onChange={handleSearchfilter} />

            <DeleteConfirmationDialog
              open={showDeleteConfirmation}
              handleClose={() => {
                setShowDeleteConfirmation(false);
                handleMenuClose();
              }}
              handleConfirm={() => {
                handleConfirmDelete(selectedDesignationForDelete);
                handleMenuClose();
              }}
              product={"Designation"}
            />
            <div className="designation_data_grid">
              <div className="propActivity-table">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            className="designationtableHeaderCell"
                            onClick={() => handleSortClick(column.field)}
                          >
                            {column.headerName}
                            {renderArrowIcon(column.field)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedRows.map((row, i) => (
                        <TableRow key={i}>
                          {columns.map((column, ind) => (
                            <TableCell key={ind} className="tableBodyCell">
                              {column.renderCell
                                ? column.renderCell({ row })
                                : row[column.field]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={totalPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(event, value) => setPage(value)}
                  //   onChange={(event, value) => handlePageChange(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Designation;
