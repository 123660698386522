var CryptoJS = require("crypto-js");

export const encrypt = (msg) => {
  try {
    return CryptoJS.AES.encrypt(msg, "subscription").toString();
  } catch (err) {
    console.error(err);
    return "";
  }
};
export const decrypt = (msg) => {
  try {
    const bytes = CryptoJS.AES.decrypt(msg, "subscription");
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (err) {
    console.error(err);
    return "";
  }
};
