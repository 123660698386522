import React, { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./HealthHistory.css";
import logo1 from "../../../assets/icons/Male1.png";
import logo2 from "../../../assets/icons/Female1.png";
import logo3 from "../../../assets/icons/Blood.png";
import CustomizedHook from "./CustomizedHook";

function HealthHistory() {
  const bloodGroupArray = [
    {
      bloodGroup: "A+",
    },
    {
      bloodGroup: "A-",
    },
    {
      bloodGroup: "B+",
    },
    {
      bloodGroup: "B-",
    },
    {
      bloodGroup: "AB+",
    },
    {
      bloodGroup: "AB-",
    },
    {
      bloodGroup: "O+",
    },
    {
      bloodGroup: "O-",
    },
  ];

  const [selectedLogo, setSelectedLogo] = useState(null);

  const imageContainers = document.querySelectorAll(".image-container");
  imageContainers.forEach((container) => {
    container.addEventListener("click", () => {
      imageContainers.forEach((container) => {
        container.classList.remove("selecteda");
      });
      container.classList.add("selecteda");
    });
  });

  const heightSpans = document.querySelectorAll(".Height .Ftcm span");
  heightSpans.forEach((span) => {
    span.addEventListener("click", () => {
      heightSpans.forEach((otherSpan) => otherSpan.classList.remove("active"));
      span.classList.add("active");
    });
  });

  const weightSpans = document.querySelectorAll(".Weight .Lbskg span");
  weightSpans.forEach((span) => {
    span.addEventListener("click", () => {
      weightSpans.forEach((otherSpan) => otherSpan.classList.remove("active"));
      span.classList.add("active");
    });
  });

  const buttons = document.querySelectorAll(".selectable");
  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      buttons.forEach((otherButton) =>
        otherButton.classList.remove("selectede")
      );
      button.classList.toggle("selectede");
    });
  });

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const [activeBloodGroup, setActiveBloodGroup] = useState("");

  return (
    <div className="HealthMain">
      <div className="Health1">
        <br />
        <b className="Heading">General</b>
        <p className="Description">Enter basic medical information.</p>
        <br />
        <div className="MaleFemale">
          <div
            className={selectedLogo === "logo1" ? "Img selected" : "Img"}
            onClick={() => setSelectedLogo("logo1")}
          >
            <img
              src={logo1}
              alt=""
              style={
                selectedLogo === "logo1"
                  ? {
                      backgroundColor: "#d1d1d1",
                      display: "inline-block",
                      padding: "10px",
                      borderRadius: "30%",
                      width: "30%",
                    }
                  : {
                      display: "inline-block",
                    }
              }
            />
            <p className="Male">Male</p>
          </div>
          <div
            className={selectedLogo === "logo2" ? "Img-2 selected" : "Img-2"}
            onClick={() => setSelectedLogo("logo2")}
          >
            <img
              src={logo2}
              alt=""
              style={
                selectedLogo === "logo2"
                  ? {
                      backgroundColor: "#d1d1d1",
                      display: "inline-block",
                      padding: "9px 13px",
                      borderRadius: "30%",
                      width: "30%",
                    }
                  : {
                      display: "inline-block",
                    }
              }
            />
            <p className="Male">Female</p>
          </div>
        </div>
        <br />
        <div class="Height">
          <p>Height? *</p>
          <input type="number" placeholder="What's your height?" />
          <div class="Ftcm">
            <span data-unit="ft">Ft</span>
            <span data-unit="cm">Cm</span>
          </div>
        </div>

        <div class="Weight">
          <p>Weight? *</p>
          <input type="number" placeholder="What's your weight?" />
          <div class="Lbskg">
            <span data-unit="lbs">Lbs</span>
            <span data-unit="kg">Kg</span>
          </div>
        </div>

        <br />
        <b>Blood group</b>
        <br />
        <div class="blood-drop">
          <div class="grid">
            {bloodGroupArray.map((val, i) => {
              return (
                <div
                  key={i}
                  class="image-container"
                  onClick={() => {
                    setActiveBloodGroup(val.bloodGroup);
                  }}
                  style={{
                    border: "2px solid red",
                    borderRadius: "93% 7% 61% 39% / 66% 0% 100% 34% ",
                    width: "51px",
                    height: "49px",
                    rotate: "-46deg",
                    cursor: "pointer",
                    backgroundColor:
                      val.bloodGroup == activeBloodGroup
                        ? "rgb(236 76 76)"
                        : "",
                    color:
                      val.bloodGroup == activeBloodGroup ? "white" : "black",
                  }}
                >
                  <p>{val.bloodGroup}</p>
                </div>
              );
            })}
            {/* <div class="image-container" style={{border:"2px solid red", borderRadius:"93% 7% 61% 39% / 66% 0% 100% 34% ",width:"51px", height:"49px", rotate:"-46deg" }}>
                            <img src={logo3} alt="" />
                            <p style={{}}>A+</p>
                        </div> */}

            {/* <div class="image-container">
              <img src={logo3} alt="" />
              <p>A-</p>
            </div>
            <div class="image-container">
              <img src={logo3} alt="" />
              <p>B+</p>
            </div>
            <div class="image-container">
              <img src={logo3} alt="" />
              <p>B-</p>
            </div>
            <div class="image-container">
              <img src={logo3} alt="" />
              <p>AB+</p>
            </div>
            <div class="image-container">
              <img src={logo3} alt="" />
              <p>AB-</p>
            </div>
            <div class="image-container">
              <img src={logo3} alt="" />
              <p>O+</p>
            </div>
            <div class="image-container">
              <img src={logo3} alt="" />
              <p>O-</p>
            </div> */}
          </div>
        </div>

        <br />
      </div>
      <div className="Health2">
        <b>Medical History</b>
        <p>
          All medical conditions that have been reported to or diagnosed by a
          doctor
        </p>
        <CustomizedHook />
        <br />
      </div>
      <div className="Health3">
        <div className="AllergyHistory">
          <b>Allergy History</b>
          <br />
          <div class="button">
            <button type="button" class="selectable">
              Food
            </button>
            <button type="button" class="selectable">
              Environment
            </button>
            <button type="button" class="selectable">
              Medicine
            </button>
          </div>
          <br />
          <div className="togglebutton">
            <p>
              <input type="checkbox" />
              &emsp;Dust
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Dust mites
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Dust mites
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Pollen
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Cockroaches
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Cockroaches
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Mold
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Dust mites
            </p>
            <p>
              <input type="checkbox" />
              &emsp;Dust mites
            </p>
          </div>
          <br />
        </div>
        <br />
        <div className="HabitHistory">
          <b>Habit History</b>
          <br />
          <p>
            <input type="checkbox" />
            &emsp;&nbsp;Smoke&emsp;&emsp;&emsp;&nbsp;
            {/* <DatePicker
              placeholder="1994"
              style={{ width: "28%" }}
              onChange={onChange}
              picker="year"
            /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  placeholder="1994"
                  sx={{
                    width: "30% !important",
                    height: "50%  !important",
                  }}
                  onChange={onChange}
                  views={["year"]}
                />
              </DemoContainer>
            </LocalizationProvider>
          </p>
          <br />
          <p>
            <input type="checkbox" />
            &nbsp;Drink&emsp;&emsp;&emsp;&emsp;
            {/* <DatePicker
              placeholder="1994"
              style={{ width: "28%" }}
              onChange={onChange}
              picker="year"
            /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  placeholder="1994"
                  sx={{
                    width: "30% !important",
                    height: "50%  !important",
                  }}
                  onChange={onChange}
                  views={["year"]}
                />
              </DemoContainer>
            </LocalizationProvider>
          </p>
          <br />
          <p>
            <input type="checkbox" />
            &emsp;&nbsp;Drunk&emsp;&emsp;&emsp;&ensp;&nbsp;
            {/* <DatePicker
              placeholder="1994"
              style={{ width: "28%" }}
              onChange={onChange}
              picker="year"
            /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  placeholder="1994"
                  sx={{
                    width: "30% !important",
                    height: "50%  !important",
                  }}
                  onChange={onChange}
                  views={["year"]}
                />
              </DemoContainer>
            </LocalizationProvider>
          </p>
          <br />
        </div>
      </div>
    </div>
  );
}

export default HealthHistory;
