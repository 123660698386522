import React, { useState } from "react";
import Logo from "../../../assets/images/Logo header.png";
import { Link, useLocation } from "react-router-dom";
import "./header.css";

const Navbar = () => {
  const Links = [
    { name: "Home", link: "/" },
    { name: "Features", link: "/features" },
    { name: "Pricing", link: "/pricing" },
  ];

  const [open, setOpen] = useState(false);
  const location = useLocation();

  const handleLinkClick = () => {
    setOpen(false);
  };

  return (
    <div className="z-[1000] shadow-md-white w-full fixed top-0 left-0 headermain shadow-sm">
      <div className="md:flex bg-white items-center justify-between py-4 md:px-10 px-7">
        <div className="flex items-center">
          <div className="cursor-pointer" onClick={() => setOpen(false)}>
            <Link to="/">
              <img src={Logo} className="md:mr-12" alt="Logo" />
            </Link>
          </div>
          <ul
            id="headerId"
            className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
              open ? "top-20 opacity-100" : "top-[-490px]"
            } md:opacity-100 opacity-0`}
          >
            {Links.map((link) => (
              <li
                key={link.name}
                className="md:ml-8 text-[1.2rem] md:my-0 my-7 text-[urbanist]"
              >
                <Link
                  to={link.link}
                  onClick={handleLinkClick}
                  className={`hover:text-[#5D7202] duration-500 ${
                    location.pathname === link.link
                      ? "text-[#5D7202]"
                      : "text-[#B4BA3D]"
                  }`}
                >
                  {link.name}
                </Link>
              </li>
            ))}
              <li className="try-mobile">
        <Link to="/register">
          <button className="flex items-center justify-center w-fit px-6 py-3 border-2 border-[#6c7d02] rounded-full bg-[#6c7d02] text-white text-base font-medium transition-all duration-300 hover:bg-[#9cad2c] mt-3">
            Try for free
          </button>
        </Link>

          </li>
          </ul>
        
        </div>
        <div className="try-free">
        <Link to="/register">
          <button className="flex items-center justify-center w-fit px-6 py-3 border-2 border-[#6c7d02] rounded-full bg-[#6c7d02] text-white text-base font-medium transition-all duration-300 hover:bg-[#9cad2c] mt-3">
            Try for free
          </button>
        </Link>
        </div>
        <div
          onClick={() => setOpen(!open)}
          className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
        >
          <ion-icon
            className="text-black bg-black"
            name={open ? "close" : "menu"}
          ></ion-icon>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
