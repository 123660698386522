import React from 'react';
import Divider from '@mui/material/Divider';
import { alpha, styled } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import './Treatment.css';


export default function Treatment() {

    const PinkSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: blueGrey[300],
          '&:hover': {
            backgroundColor: alpha(blueGrey[300], theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: blueGrey[300],
        },
      }));
      

    return (
        <div className='containerw'>
            <div className='boxs'>
                <h2 className='title'>Holistic Programs</h2><br />
                <Divider style={{ width: '100%' }} /><br />
                <div className='boxs1'>
                    <p>Naturopathy Wonders</p>
                    <PinkSwitch defaultChecked color="default" />
                </div>
                <div className='boxs1'>
                    <p>Ayurveda</p>
                    <PinkSwitch />
                </div>
                <div className='boxs1'>
                    <p>Yoga & Meditation</p>
                    <PinkSwitch />
                </div>
                <div className='boxs1'>
                    <p>Physiotherapy </p>
                    <PinkSwitch />
                </div>
                <div className='boxs1'>
                    <p>Holistic Therapies</p>
                    <PinkSwitch />
                </div>
            </div>
            <div className='boxs'>
                <h2 className='title'>True Treatments</h2><br />
                <Divider style={{ width: '100%' }} /><br />
                <div className='boxs1'>
                    <p>Respiratory Aliments</p>
                    <PinkSwitch />
                </div>
                <div className='boxs1'>
                    <p>Gastro Intestinal Disorders</p>
                    <PinkSwitch />
                </div>
                <div className='boxs1'>
                    <p>Musculoskeletal Condition </p>
                    <PinkSwitch />
                </div>
                <div className='boxs1'>
                    <p>Endocrine Disorders </p>
                    <PinkSwitch />
                </div>
                <div className='boxs1'>
                    <p>Lifestyle Disorder </p>
                    <PinkSwitch />
                </div>
            </div>
            <div className='boxs'>
                <h2 className='title'>Retreat & Revive</h2><br />
                <Divider style={{ width: '100%' }} /><br />
                <div className='boxs1'>
                    <p>Healing Abode</p>
                    <PinkSwitch />
                </div>
                <div className='boxs1'>
                    <p>Recreation Spaces</p>
                    <PinkSwitch />
                </div>
                <div className='boxs1'>
                    <p>Meditation Caves</p>
                    <PinkSwitch />
                </div>
                <div className='boxs1'>
                    <p>Cardio Gym</p>
                    <PinkSwitch />
                </div>
            </div>
        </div>
    );
}
