import Select from "react-select";


const MySelect = ({ options, field, form, placeholder, onChange, width, padding, className, isDisabled,...props }) => {
  // react single select dropdown options color change
const customStyles = {
  container: (provided) => ({
    ...provided,
    width: width ? width : "100%", 
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "var(--primary-green)" : "white",
    "&:hover": {
      // backgroundColor: '#b4ba3d',
      backgroundColor: state.isSelected ? "var(--primary-green)" : "#b4ba3d",
      color: "white",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: padding ? padding : "0px", 
  }),
};
  
  return(

  
    <Select
      id={field?.name}
      name={field?.name}
      value={
        options ? options.find((option) => option.value === field?.value) : ""
      }
      onChange={(option) => {
        form?.setFieldValue(field?.name, option?.value);
        onChange(option); 
      }}
      onBlur={field?.onBlur}
      options={options}
      placeholder={placeholder}
      styles={customStyles}
      className={className}
      isDisabled={isDisabled}
      {...props}
    />)
    };
  

export default MySelect;