import React from "react";
import Demo from "./Demo/Demo";
import Ads from "./Ads/Ads";
import Steps from "./Steps/Steps";
import Testimonials from "./Testimonials/Testimonials";
import Pricing from "./Pricing/Pricing";

const Home = () => {
  return (
    <div>
      <Demo />
      <Ads />
      <Steps />
      <Pricing />
      {/* <Testimonials /> */}
    </div>
  );
};

export default Home;
