import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import GuestDetails from "./GuestDetails";
import BookingHistory from "./BookingHistory/BookHistory";
import Treatment from "./Treatment/Treatment";
import HealthHistory from "./HealthHistory/HealthHistory";
import DailySchedule from "./DailySchedule/DailySchedule";
import DietPage from "./DietPlan/DietPage";
import "./Guest.css";
import callApi from "../../ApiCall";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { toast ,Zoom} from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  PaginationItem,
} from "@mui/material";
import Constants from "../../Constants";
import Booking from "../Reservation/Booking";
import SearchBar from "../../common/SearchBar/SearchBar";
import AddButton from "../../common/Button/Button";


const Guest = (props) => {
  const [value, setValue] = useState(0);
  const [isDataSelected, setisDataSelected] = useState(false);
  const [filter, setFilter] = useState("");
  const [guestData, setGuestData] = useState([]);
  const [showGuestPopup, setshowGuestPopup] = useState(false);
  const [isAddMode, setIsAddMode] = useState(true);
  const [refreshPage, setRefreshPage] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("Checked-In"); 

  useEffect(() => {
    getUserDetails();
  }, [refreshPage]);

  const handleSearchfilter = (event) => {
    setFilter(event.target.value);
    setPage(1);
  };

  const handleReservationPopupClose = () => {
    setshowGuestPopup(false);
  };

  const getUserDetails = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getGuestList,
        headers: { Authorization: "Bearer YourAccessToken" }, // Adjust this
      });

      if (response?.success) {
        const { guestList = [] } = response.data;
        setGuestData(guestList);
      } else {
        console.error("Error fetching Guest data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleRowClick = (data) => {
    setSelectedGuest(data);
    setisDataSelected(true);
  };

  const handleAddProductClick = () => {
    setIsAddMode(true);
    setshowGuestPopup(true);
  };

  const handleBackToGuest = () => {
    setisDataSelected(false);
    setValue(0); // Reset the tab value when going back to the table view
  };

  const handleStatusChange = (event, newStatus) => {
    setSelectedStatus(newStatus);
  };

  const pageSize = 10;
  const totalPages = Math.ceil(guestData?.length / pageSize);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const filteredData = guestData.filter((row) => {
    const guestName = row.GuestName ? row.GuestName.toLowerCase() : "";
    const mobile = row.MobileNumber ? row.MobileNumber : "";
    const gender = row.Gender ? row.Gender: "";
    const filterLower = filter.toLowerCase();
    return guestName.includes(filterLower) || mobile.includes(filterLower) || gender.includes(filterLower);
  });

  const paginatedRows = filteredData.slice(startIndex, endIndex);

  // Filter the paginated rows based on selectedStatus
  const filteredRows = paginatedRows.filter((row) => {
    if (selectedStatus === "all") {
      return true;
    } else if (selectedStatus === "Checked-In") {
      return row.BookingStatus === "Checked-In"; 
    } else {
      return row.BookingStatus !== "Checked-In"; 
    }
  });

  const getStatusColor = (status) => {
    switch (status) {
      case "Checked-In":
        return "check-in-text";
      case "Checked-Out":
        return "check-out-text";
      default:
        return "default-color";
    }
  };

  const handleStartSchedule = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.startSChedule,
      });
  
      if (response?.success) {
        toast.success(
          <div className="custom_Toast">
            <h5>Schedule Start for next day</h5>
            
          </div>
        );
      } else {
        toast.error('Failed to Start SChedule.');
      }
    } catch (error) {
      console.error('Error updating Schedule:', error);
      toast.error('An error occurred while Starting Schedule');
    }
  };

  return (
    <>
      {!isDataSelected && (
        <div className="reservation_MainPae">
          {/* Header */}
          <div className="reservation_sect-1">
            <div className="reservation_manage">
              <h1 className="manage_page_heading">Guest</h1>
            </div>
            
            <button style={{ 
    backgroundColor: 'var(--primary-green)',
    color: 'white',
    margin: '10px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  }} onClick={handleStartSchedule}>
    Start Schedule
  </button>
            {props?.currentMenu?.Add ? (
            <>
              <AddButton buttonText={"Add Booking"} onClick={handleAddProductClick}/>
            </>):(<></>)}

          </div>
          <div className="guest_sect-2">
              
                <SearchBar
                value={filter}
                onChange={handleSearchfilter}
                />
                <div className="reservation_toggle">
                  <ToggleButtonGroup
                    className="ToggleButtonGroups"
                    value={selectedStatus}
                    exclusive
                    onChange={handleStatusChange}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value="all"
                      aria-label="all"
                      disabled={selectedStatus === "all"}
                      sx={{
                        textTransform: "none",
                        padding: "8px 16px",
                        borderRadius: "8px 0px 0px 8px",
                      }}
                    >
                      All
                    </ToggleButton>
                    <ToggleButton
                      value="Checked-In"
                      aria-label="Checked-In"
                      disabled={selectedStatus === "Checked-In"}
                      sx={{ textTransform: "none", padding: "8px 16px" }}
                    >
                      Checked-In
                    </ToggleButton>
                    <ToggleButton
                      value="Inactive"
                      aria-label="Inactive"
                      disabled={selectedStatus === "Inactive"}
                      sx={{
                        textTransform: "none",
                        padding: "8px 16px",
                        borderRadius: "0px 8px 8px 0px",
                      }}
                    >
                      Other
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
          {/* Table */}
          <div className="ReservationTable">
            <div className="Table">
              <div className="DataGrid">
                {/* Table */}
                <div className="propActivity-table">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Guest Name</TableCell>
                          <TableCell>Mobile Number</TableCell>
                          <TableCell>Email Address</TableCell>
                          <TableCell>Gender</TableCell>                       
                          <TableCell>Room Number</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredRows.map((row, i) => (
                          <TableRow key={i} onClick={() => handleRowClick(row)} style={{ cursor: 'pointer' }}>
                            <TableCell>{row.GuestName}</TableCell>
                            <TableCell>{row.MobileNumber}</TableCell>
                            <TableCell>{row.EmailAddress}</TableCell>
                            <TableCell>{row.Gender}</TableCell>
                            <TableCell>
                              {row.BookingStatus === "Checked-In"
                                ? row.RoomNumber
                                : ""}
                            </TableCell>
                            <TableCell>
                              {" "}
                              <span
                                className={getStatusColor(row.BookingStatus)}
                              >
                                {row.BookingStatus}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* Pagination */}
                  <Pagination
                    count={totalPages}
                    page={page}
                    hideNextButton
                    hidePrevButton
                    onChange={(event, value) => setPage(value)}
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        page={item.page}
                        className={item.selected ? "pagination-selected" : ""}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* If data is selected, render appropriate tab */}
      {isDataSelected && (
        <>
          <Tabs
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
            aria-label="basic tabs example"
          >
            <Tab label="Guest Details" />
            <Tab label="Booking History" />
            <Tab label="Health History" />
            <Tab label="Treatment" />
            <Tab label="Diet Plan" />
            <Tab label="Daily Schedule" />
          </Tabs>
          <TabPanel
            value={value}
            index={0}
            GuestMasterId={selectedGuest.GuestMasterId}
          >
            <div className="product-detail">
              <GuestDetails
                handleBackToGuest={handleBackToGuest}
                GuestMasterId={selectedGuest.GuestMasterId}
              />
            </div>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            GuestMasterId={selectedGuest.GuestMasterId}
          >
            <div className="product-detail">
              <BookingHistory GuestMasterId={selectedGuest.GuestMasterId} />
            </div>
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            GuestMasterId={selectedGuest.GuestMasterId}
          >
            <div className="product-detail">
              <HealthHistory GuestMasterId={selectedGuest.GuestMasterId} />
            </div>
          </TabPanel>
          <TabPanel
            value={value}
            index={3}
            GuestMasterId={selectedGuest.GuestMasterId}
          >
            <div className="product-detail">
              <Treatment GuestMasterId={selectedGuest.GuestMasterId} />
            </div>
          </TabPanel>
          <TabPanel
            value={value}
            index={4}
            GuestMasterId={selectedGuest.GuestMasterId}
          >
            <div className="product-detail">
              <DietPage GuestMasterId={selectedGuest.GuestMasterId} />
            </div>
          </TabPanel>
          <TabPanel
            value={value}
            index={5}
            GuestMasterId={selectedGuest.GuestMasterId}
          >
            <div className="product-detail">
              <DailySchedule GuestMasterId={selectedGuest.GuestMasterId} />
            </div>
          </TabPanel>
        </>
      )}

      {showGuestPopup && (
        // />
        <Booking onClose={handleReservationPopupClose} isAddMode={isAddMode} />
      )}
    </>
  );
};

const TabPanel = ({ children, value, index, selectedGuest, ...other }) => {
  const { GuestMasterId } = selectedGuest || {};

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>
            {React.cloneElement(children, { GuestMasterId })}
          </Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  selectedGuest: PropTypes.object,
};

export default Guest;
