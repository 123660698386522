import React, { useState, useEffect } from "react";
import { toast,Zoom, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import CustomSelectEditable from "../../common/CustomSelectEditable/CustomSelectEditable";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import callApi from "../../ApiCall";
import Constants from "../../Constants";
const ServiceMasterPopup = ({
  onClose,
  isAddMode,
  selectedserviceActivity,

}) => {
  const [showPopup, setShowPopup] = useState(true);
  const [showOverlay, setShowOverlay] = React.useState(true);
 
  const [status, setStatus] = useState(
    selectedserviceActivity
      ? selectedserviceActivity.Active
        ? "Active"
        : "Inactive"
      : "Active"
  );
  const [propertyDetail, setPropertyDetail] = useState([]);
  const [property, setProperty] = useState("");



  const handleCancel = () => {
    setShowPopup(false);
    onClose();
  };

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setStatus(newStatus);
    }
  };

  const initialValuesFormik = {
    serviceName: isAddMode ? "" : selectedserviceActivity?.ServiceName,
  serviceRate: isAddMode ? "" : selectedserviceActivity?.ServiceRate,
    // LocationName: isAddMode ? "" : selectedserviceActivity?.PropertyLocationId,
    serviceCategory: isAddMode ? "" : selectedserviceActivity?.ServiceCategoryId,
  active: selectedserviceActivity ? (selectedserviceActivity.Active ? 1 : 0) : 1,
 
    

  };
  console.log(selectedserviceActivity?.ServiceCategoryId,"selectedserviceActivity?.ServiceCategoryId");
  const validationSchemaFormik = Yup.object({
    serviceName: Yup.string()
      .required("Service Name is required")
      .max(40, "Max 40 Characters"),
    serviceRate: Yup.string().required("Service Rate is required"),
    serviceCategory: Yup.number().required("Category Name is required")
    // LocationName: Yup.string().required("Location is required")
  });
  const [refreshPage, setRefreshPage] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [addCategoryDone,setAddCategoryDone] = useState(false)
  const [category, setCategory] = useState(!isAddMode ? selectedserviceActivity?.ServiceCategoryId : "");
  const selectedCategoryData= (id, setFieldValue)=>{
    setCategory(id);
    setFieldValue("serviceCategory",id)
  }

  // get category api
  const getCategoryDetail = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getServiceCategoryData,
      });

      const dropdownData = response?.data?.serviceList?.map((val) => {
        const parsedData = JSON.parse(val?.Data);
        
        setCategoryList(parsedData);
      });
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  // add new category api
  const addCategoryDetail = async (params) => {
    const addCat = {
      categoryName:params
    }
    try {
      const response = await callApi({
        apiURL: Constants.API.masteraddServiceCategory,
        requestBody:addCat
       
      });
      
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  // update current category detail api
  const updateCategoryDetail = async (id,selectedInputValueUpdate) => {
    const updateData = {
      serviceCategoryId: id,
      categoryName: selectedInputValueUpdate
    }
    
    try {
      const response = await callApi({
        apiURL: Constants.API.masterupdateServiceCategory,
        requestBody: updateData
      });
      
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

// delete category detail api
  const handleConfirmDeleteCategory = async (selectedId ) =>{
    try{
      const response = await callApi({
        apiURL: Constants.API.deleteServiceCategory,
        requestBody:{ServiceCategoryId:selectedId}
      })
      if(response?.success){
        getCategoryDetail();
      }
    }catch(error) {
      console.error("Error during API call:", error);
    }
  }
  
  useEffect(() => {
    getCategoryDetail();
  }, [refreshPage,addCategoryDone]);

  const handleSubmit = async (values, { resetForm }) => {
    console.log(values, "handlesubmit");
    values.active = status === "Active" ? 1 : 0;
    values.serviceCategory = category;

    // ADD update API
    let response;
    try {
      const params = { ...values };
      if (isAddMode) {
        response = await callApi({
          apiURL: Constants.API.masteraddService,
          requestBody: params,
        });
      } else {
        response = await callApi({
          apiURL: Constants.API.masterupdateService,
          requestBody: 
            {...values,
            ServiceMasterId:selectedserviceActivity.ServiceMasterId,
            updateDate:selectedserviceActivity.UpdatedDate}
        });
      }
      if (response?.success) {
        getCategoryDetail();
        const actionMessage = isAddMode
          ? "Added Service "
          : "Service";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Service has been {isAddMode ? "added." : "updated."}
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            transition: Zoom,
            theme: "colored",
            className:"toast_pop",
            autoClose: 1500,
            closeButton: true,
            onClose: () => {
              setShowPopup(false);
              onClose();
              resetForm();
            },
          }
        );
      } else {
        console.error("Error fetching guest activity data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
    resetForm();
    onClose();
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  });
  console.log("categoryList:", categoryList);
  console.log("addCategoryDone:", addCategoryDone);
  return (
    <>
      {showOverlay && <div className="Overlay" />}
      <div
        className={
          showPopup
            ? "serviceActivity_PopMain"
            : "serviceActivity_PopMain hidden"
        }
      >
        <div className="serviceActivity_First">
          <p>
            {isAddMode ? "Add Service Activity" : "Update Service Activity"}
          </p>
          <button onClick={handleCancel}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#424242"
              />
            </svg>
          </button>
        </div>

        <Formik
          initialValues={initialValuesFormik}
          validationSchema={validationSchemaFormik}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue}) =>
          
          <Form
          className="Service_Form"
          >
            
            <div className="serviceActivity_Name">
              <label htmlFor="serviceActivity_fname">
                Service Name <sup>*</sup>
              </label>
              
              <Field
                type="text"
                id="serviceName"
                name="serviceName"
                placeholder="Enter Service Name"
              ></Field>
              <div className="error">
                <ErrorMessage name="serviceName" />
              </div>
            </div>

            <div className="serviceActivity_Name">
              <label htmlFor="serviceActivity_fname">
                Service Rate <sup>*</sup>
              </label>

              <Field
                type="text"
                id="serviceRate"
                name="serviceRate"
                placeholder="Enter Service Rate"
              ></Field>
              <div className="error">
                {" "}
                <ErrorMessage name="serviceRate" />
              </div>
            </div>
            <div className="serviceActivity_ProductDropdown">
              <label htmlFor="product_dropdown">
                Category Name <sup>*</sup>
              </label>

              {categoryList.length > 0 && (
                <>
                <div  className="combo_CustomService">
                <Field
                component={CustomSelectEditable}
                options={categoryList}
                addData={addCategoryDetail} 
                updateData= {updateCategoryDetail}
                deleteCategory= {handleConfirmDeleteCategory}
                selectedCategory={selectedserviceActivity?.ServiceCategoryId}
                newSelectedData={(id) => 
                  selectedCategoryData(id,setFieldValue)}
                />
                 
                </div>
                <div className="error_Service">
                  <ErrorMessage name="serviceCategory" />
                </div>
                </>
              )}
            </div>

           
            <div className="serviceActivity_Togglebutton-1">
              <ToggleButtonGroup
                className="serviceActivity_ToggleButtonGroups-1"
                sx={{ width: "30px", height: "32px", fontSize: "14px" }}
                value={status}
                exclusive
                onChange={handleStatusChange}
                aria-label="text alignment"
              >
                <ToggleButton
                  sx={{ borderRadius: "8px 0px 0px 8px" }}
                  value="Active"
                  aria-label="Active"
                >
                  Active
                </ToggleButton>
                <ToggleButton
                  sx={{ borderRadius: "0px 8px 8px 0px" }}
                  value="Inactive"
                  aria-label="Inactive"
                >
                  Inactive
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <br/>
            <div className="serviceActivity_End">
              <button
                type="submit"
                className="serviceActivity_buttonb"
              >
                {isAddMode ? "Add New" : "Update GuestActivity"}
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                className="serviceActivity_buttona"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </Form>
          }
        </Formik>

       
      </div>
    </>
  );
};

export default ServiceMasterPopup;
