import React, { useState, useEffect } from "react";
import "./Slider.css";

const ImageSlider = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideClick = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === images.length - 1 ? 0 : prevSlide + 1
      );
    }, 5000);
    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <div className="slider-container">
      <div
        className="slider-wrapper"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {images.map((image, index) => (
          <div className="slide" key={index}>
            <img src={image} alt={`Slide ${index}`} />
            <p className="slider-text">
              "Wellness encompasses a healthy body, a sound mind, and a tranquil
              spirit. Enjoy the journey as you strive for wellness."
              <br /> - Laurette Gagnon Beaulieu
            </p>
          </div>
        ))}
      </div>
      <div className="slider-indicators">
        {images.map((image, index) => (
          <div
            key={index}
            className={`slider-indicator ${
              index === currentSlide ? "active" : ""
            }`}
            onClick={() => handleSlideClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
