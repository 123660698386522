import React from "react";
import * as XLSX from "xlsx";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./PropertyLocation.css";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import DeleteConfirmationDialog from "../../common/DeleteConfirmation/confirmdelete";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ExportFile from "../../common/ExportFile/ExportFile";
import { jsPDF } from "jspdf";
import callApi from "../../ApiCall";
import PropertyLocationPopup from "./PropertyLocationPopup";
import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";
import Constants from "../../Constants";
import SearchBar from "../../common/SearchBar/SearchBar";
import AddButton from "../../common/Button/Button";


const MenuItemWrapper = styled("li")(() => ({
  padding: "8px 8px",
  boxShadow: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F75555",
    color: "#FFFFFF",
    img: {
      filter:
        "invert(92%) sepia(4%) saturate(8%) hue-rotate(126deg) brightness(138%) contrast(200%)",
    },
  },
  transition: "all 0.3s ease",
}));

const IconWrapper = styled("span")(() => ({
  fontSize: "16px",
  marginRight: "8px",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));

const PropertyLocation = (props) => {
  //Table Columns
  const columns = [
    { field: "propertyId", headerName: "Sr No", flex: 1 },
    {
      field: "propertyLocationName",
      headerName: "location name",
      flex: 1,
    },
    { field: "address", headerName: "Address", flex: 1 },

    {
      field: "contactNo",
      headerName: "Contact No",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    props.currentMenu.Edit || props.currentMenu.Delete
      ? {
          field: "actions",
          headerName: "Actions",
          flex: 1,
          renderCell: (params) => (
            <div>
              <IconButton
                color="primary"
                aria-label="actions"
                onClick={(event) => handleMenuOpen(event, params.row)}
              >
                <MoreVertIcon style={{ color: "gray" }} />
              </IconButton>
              <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                sx={{
                  "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                    {
                      boxShadow: "none",
                      backgroundColor: "#EEE",
                      borderRadius: "8px",
                    },
                  "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                    paddingTop: "0",
                    paddingBottom: "0",
                  },
                }}
              >
                {props.currentMenu.Edit ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("edit")}>
                    <IconWrapper>
                      <img src={edit} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Edit</span>
                  </MenuItemWrapper>
                ) : null}

                {props.currentMenu.Delete ? (
                  <MenuItemWrapper onClick={() => handleMenuSelect("delete")}>
                    <IconWrapper>
                      <img src={trash} />
                    </IconWrapper>
                    <span style={{ fontWeight: "400" }}>Delete</span>
                  </MenuItemWrapper>
                ) : null}
              </Menu>
            </div>
          ),
        }
      : {},
  ];
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedLocationForDelete, setSelectedLocationForDelete] =
    useState(null);
  const [selectedLocationForUpdate, setSelectedLocationForUpdate] =
    useState(null);
  const [showLocationActivityPopup, setShowLocationActivityPopup] =
    useState(false);
  const [isAddMode, setIsAddMode] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [page, setPage] = useState(1);
  const [tempData, setTempData] = useState([]);
  const initialSortedRows = tempData;
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortedRows, setSortedRows] = useState(initialSortedRows);
  const pageSize = 10;
  const totalPages = Math.ceil(sortedRows.length / pageSize);
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const formattedPageNumber = (pageNumber) => {
    return pageNumber < 10 ? `0${pageNumber}` : `${pageNumber}`;
  };

  const handleDeleteClick = (selectedRow) => {
    setSelectedLocationForDelete(selectedRow);
    setShowDeleteConfirmation(true);
  };

  // call Delete
  const handleConfirmDelete = async (selectedRow) => {
    const params = {
      updatepropertyLocationId: selectedRow?.PropertyLocationId,
      updateDate: selectedRow?.UpdatedDate,
    };
    try {
      const response = await callApi({
        apiURL: Constants.API.deletePropertyLocation,
        requestBody: params,
      });
      if (response?.success) {
        const actionMessage = "Deleted Data";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Property Location has been Deleted successfully
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            hideProgressBar: true,
            className: "toast_pop",
            autoClose: 500,
            closeButton: true,
            transition: Zoom,
            onClose: () => {
              getPropertyLocationDetails();
            },
          }
        );
        getPropertyLocationDetails();
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setShowDeleteConfirmation(false);
      setPage(1);
    }
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedLocationForUpdate(row);
    setSelectedLocationForDelete(row);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleMenuSelect = (option) => {
    if (option === "edit") {
      handleUpdateClick(selectedLocationForUpdate);
      handleMenuClose();
    } else if (option === "delete") {
      handleDeleteClick(selectedLocationForDelete);
    }
  };

  const handleUpdateClick = (selectedRow) => {
    setSelectedLocationForUpdate(selectedRow);
    setIsAddMode(false);
    setShowLocationActivityPopup(true);
  };

  const handleAddLocationClick = () => {
    setIsAddMode(true);
    setShowLocationActivityPopup(true);
  };

  const handleLocationActivityPopupClose = () => {
    setShowLocationActivityPopup(false);
    setIsAddMode(true);
    setSelectedLocationForUpdate(null);
    getPropertyLocationDetails();
  };

  const getPropertyLocationDetails = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getPropertyLocation,
      });
      if (response?.success) {
        const { propertyList = [] } = response.data;
        const mappedData = propertyList.map((item, index) => ({
          propertyId: index + 1,
          ...item,
          propertyLocationName: item.PropertyLocationName,
          NoOfRooms: item.NoOfRooms,
          address: item.Address,
          billingAddress: item.BillingAddress,
          contactNo: item.ContactNumber,
          description: item.Description,
          email: item.Email,
          gstNo: item.GSTNumber,
          licenceNo: item.LicenseNumber,
        }));
        setTempData(mappedData);
      } else {
        console.error("Error fetching guest activity data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    getPropertyLocationDetails();
  }, []);

  //export to xl
  const handleExportClick = (tempData) => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      const fileName = `Property_Location_${formattedDate.replace(
        / /g,
        ""
      )}.xlsx`;
      const worksheetName = `Property Location`;

      if (tempData?.length > 0) {
        const exportData = tempData.map((row) => ({
          "Sr No.": row.propertyId,
          "Property Location Name": row.propertyLocationName,
          Address: row.Address,
          "contact No.": row.contactNo,
          Email: row.email,
        }));
        const workSheet = XLSX.utils.json_to_sheet(exportData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, worksheetName);
        XLSX.writeFile(workBook, fileName);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF("p");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    const fileName = `Property_Location_${formattedDate.replace(/ /g, "")}.pdf`;

    const tableHeaders = [
      "Sr No",
      "Property Location Name",
      "Address",
      "Contact No",
      "Email",
    ];

    if (tempData.length > 0) {
      let tableData = tempData.map((row) => {
        return [
          row.propertyId,
          row.propertyLocationName,
          row.address,
          row.contactNo,
          row.email,
        ];
      });

      // Add title
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text("Property Location", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      // Add table to PDF
      doc.autoTable({
        head: [tableHeaders],
        body: tableData,
        startY: 20,
        theme: "grid",
      });

      // Add date and time
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        formattedDate,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );

      // Save PDF
      doc.save(fileName);
    } else {
      console.error("No data to export to PDF.");
    }
  };

  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      return (
        <span className="arrow-icon">
          {sortOrder === "asc" ? (
            <i className="fa-solid fa-arrow-up" />
          ) : (
            <i className="fa-solid fa-arrow-down" />
          )}
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
    if (tempData.length > 0) {
      setSortedRows(tempData);
    }
  }, [tempData.length > 0]);

  useEffect(() => {
    if (sortedColumn) {
      const sorted = tempData.slice().sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];
        if (aValue < bValue) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
      setSortedRows(sorted);
    }
  }, [sortedColumn, sortOrder, tempData]);

  //search
  const [filtering, setFiltering] = useState("");
  const handleSearchfilter = (event) => {
    setFiltering(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    const searched = tempData.filter((item) => {
      if (!filtering) {
        return true;
      } else {
        return (
          (item.propertyLocationName !== null &&
            item.propertyLocationName
              .toLowerCase()
              .includes(filtering.toLowerCase())) ||
          (item.contactNo !== null &&
            item.contactNo
              .toString()
              .toLowerCase()
              .includes(filtering.toLowerCase())) ||
          (item.address !== null &&
            item.address.toLowerCase().includes(filtering.toLowerCase())) ||
          (item.email !== null &&
            item.email.toLowerCase().includes(filtering.toLowerCase()))
        );
      }
    });
    setSortedRows(searched);
  }, [filtering, tempData]);

  const paginatedRows = sortedRows.slice(startIndex, endIndex);

  return (
    <>
      {showLocationActivityPopup && (
        <PropertyLocationPopup
          onClose={handleLocationActivityPopupClose}
          isAddMode={isAddMode}
          selectedLocationActivity={selectedLocationForUpdate}
          getPropertyLocationDetails={getPropertyLocationDetails}
        />
      )}
      <div className="propActivity_MainPae">
        <div className="propActivity_sect-1">
          <div className="propActivity_manage">
            <h1 className="manage_page_heading">Manage Location</h1>
          </div>
          <div className="export_location">
          <ExportFile
            handleExportClick={handleExportClick}
            filteredRows={tempData}
            handleExportPDF={handleExportPDF}
          />
          &nbsp;&nbsp;&nbsp;
          {props?.currentMenu?.Add ? (
            <>
              <AddButton buttonText={"Add Location"} onClick={handleAddLocationClick}/>
            </>
          ) : (
            <></>
          )}
          </div>
        </div>
        <div className="propActivityTable">
          <div className="Table">
            <SearchBar value={filtering} onChange={handleSearchfilter} />
            <DeleteConfirmationDialog
              open={showDeleteConfirmation}
              handleClose={() => {
                setShowDeleteConfirmation(false);
                handleMenuClose();
              }}
              handleConfirm={() => {
                handleConfirmDelete(selectedLocationForDelete);
                handleMenuClose();
              }}
              product={"Location"}
            />
            <div className="Property_Location_DataGrid">
              <div className="propActivity-table">
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            className="tableHeaderCell"
                            onClick={() => handleSortClick(column.field)}
                          >
                            {column.headerName}
                            {renderArrowIcon(column.field)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedRows.map((row, i) => (
                        <TableRow key={i}>
                          {columns.map((column, ind) => (
                            <TableCell key={ind} className="tableBodyCell">
                              {column.renderCell
                                ? column.renderCell({ row })
                                : row[column.field]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={totalPages}
                  page={page}
                  hideNextButton
                  hidePrevButton
                  onChange={(event, value) => setPage(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      page={formattedPageNumber(item.page)}
                      className={item.selected ? "pagination-selected" : ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyLocation;
