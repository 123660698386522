import { React, useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import "react-toastify/dist/ReactToastify.css";
import "./CSS/Booking.css";
import Avatar from "@mui/material/Avatar";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import callApi from "../../ApiCall";
import Constants from "../../Constants";
import { convertDateInDBFormateDate } from "../../components/DateTimeUtils";
import MediaPicker from "../../components/mediaPicker";
import { awsFileUpload } from "../../fileUpload";
import Select from "react-select";

import trash from "../../assets/icons/trash.svg";
import edit from "../../assets/icons/edit.svg";
import moment from "moment";
import { Zoom, toast } from "react-toastify";

const Booking = ({ onClose, isAddMode, selectedReservation }) => {
  const [showPopup, setShowPopup] = useState(true);
  const [page, setPage] = useState(0);
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [singleBedCount, setSingleBedCount] = useState(0);
  const [doubleBedCount, setDoubleBedCount] = useState(0);
  // const [extraBedCount, setExtraBedCount] = useState(0);
  const [mobileNumber, setMobileNumber] = useState("");
  const [checkInDateError, setCheckInDateError] = useState("");
  const [checkOutDateError, setCheckOutDateError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [roomError, setRoomError] = useState("");

  const CountComponent = ({ label, count, handleChange, availableRooms }) => {
    return (
      <div style={{ width: "50%" }}>
        <div style={{ margin: "5px" }}>{label}</div>
        <div className="data_row1">
          <button
            className="border w-[1.6rem] h-[1.6rem] border-black rounded-full"
            onClick={() => handleChange(count - 1, 1)}
            disabled={count == 0}
          >
            -
          </button>
          <p className="font-bold">{count}</p>
          <button
            className="border w-[1.6rem] h-[1.6rem] border-black rounded-full"
            onClick={() => handleChange(count + 1, -1)}
          >
            +
          </button>
        </div>
        {"Available Rooms :- " + availableRooms}
      </div>
    );
  };

  const handleCancel = () => {
    setShowPopup(false);
    onClose();
  };

  const handleNext = async () => {
    setCheckInDateError("");
    setCheckOutDateError("");
    setMobileNumberError("");
    setRoomError("");
    if (
      !checkInDate ||
      !checkOutDate ||
      (singleBedCount == 0 && doubleBedCount == 0) ||
      mobileNumber == "" ||
      mobileNumber.length != 10
    ) {
      !checkInDate && setCheckInDateError("Check in date is required.");
      !checkOutDate && setCheckOutDateError("Check out date is required.");
      mobileNumber.length != 10 &&
        setMobileNumberError("Mobile number is invalid.");
      mobileNumber == "" && setMobileNumberError("Mobile number is required.");
      singleBedCount == 0 &&
        doubleBedCount == 0 &&
        // extraBedCount == 0 &&
        setRoomError("Please book atleast one room.");
      return;
    } else {
      if (bookingMasterId == 0) {
        const response = await callApi({
          apiURL: Constants.API.getGuestByMobileNumber,
          requestBody: {
            guestMobileNumber: mobileNumber,
          },
        });
        if (response?.success) {
          // console.log("res", response?.data);
          setPage(1);
          setShowSaveAndAddMore(true);
          resetErrorState();
          resetState();
          guestList.length == 0 && setGuestMobileNo(mobileNumber);
        } else {
          console.log("error");
        }
      } else {
        setPage(1);
        setShowSaveAndAddMore(true);
        resetErrorState();
        resetState();
        guestList.length == 0 && setGuestMobileNo(mobileNumber);
      }
    }
  };

  const resetForm1State = () => {
    setCheckInDate("");
    setCheckOutDate("");
    setMobileNumberError("");
    setSingleBedCount(0);
    setDoubleBedCount(0);
    // setExtraBedCount(0);
  };

  const resetForm1ErrorState = () => {
    setCheckInDateError("");
    setCheckOutDateError("");
    setMobileNumberError("");
    setRoomError("");
  };

  const resetState = () => {
    setFirstName("");
    setLastName("");
    setDateOfBirth("");
    setGender("");
    setPurpose("");
    setGuestMobileNo("");
    setEmail("");
    setZip("");
    setCity("");
    setCountry("");
    setState("");
    setAddress("");
    // setIdProof(null);
    // setIdProofImages([]);
  };

  const resetErrorState = () => {
    setFirstNameError("");
    setLastNameError("");
    setDateOfBirthError("");
    setGenderError("");
    setPurposeError("");
    setGuestMobileNoError("");
    setEmailError("");
    setZipError("");
    setAddressError("");
    // setIdProofError("");
  };

  const [bookingMasterId, setBookingMasterId] = useState(0);
  const [updatedDate, setUpdatedDate] = useState(null);
  const [guestMasterId, setGuestMasterId] = useState(0);
  const [availableSingleBed, setAvailableSingleBed] = useState(0);
  const [availableDoubleBed, setAvailableDoubleBed] = useState(0);

  useEffect(() => {
    resetState();
    resetErrorState();
    resetForm1State();
    resetForm1ErrorState();
    if (!isAddMode) {
      const guests = JSON.parse(selectedReservation.GuestList).sort(
        (a, b) => b.IsPrimary - a.IsPrimary
      );
      setBookingMasterId(selectedReservation.BookingMasterId);
      setUpdatedDate(selectedReservation.UpdatedDate);
      setCheckInDate(new Date(selectedReservation.CheckInDate));
      setCheckOutDate(new Date(selectedReservation.CheckOutDate));
      setMobileNumber(guests[0].MobileNumber);
      setSingleBedCount(selectedReservation.NoOfSingleBed);
      setDoubleBedCount(selectedReservation.NoOfDoubleBed);
      // setExtraBedCount(selectedReservation.NoOfExtraBed);
      setGuestList(
        guests.map((x) => {
          return {
            guestMasterId: x.GuestMasterId,
            guestMobileNo: x.MobileNumber,
            email: x.EmailAddress,
            firstName: x.FirstName,
            lastName: x.LastName,
            zip: x.ZipCode,
            dateOfBirth: new Date(x.DateOfBirth),
            gender: x.Gender,
            purpose: x.Purpose,
            address: x.Address,
            city: x.City,
            state: x.MobileNumber,
            country: x.Country,
            // idProofImages: JSON.parse(x.IDProof).map((x) => {
            //   return {
            //     image: Constants.awsMediaPath + x,
            //     key: x,
            //     isUpdated: false,
            //   };
            // }),

            isPrimary: x.IsPrimary == 0 ? true : false,
          };
        })
      );
    }
  }, [isAddMode]);

  const [genderList, setGenderList] = useState([]);
  const [purposeList, setPurposeList] = useState([]);

  const getStaticDataForBookingMaster = async () => {
    const response = await callApi({
      apiURL: Constants.API.getStaticDataForBookingMaster,
    });

    if (response?.success) {
      setGenderList(response.data.genderList);
      setPurposeList(response.data.purposeList);
    } else {
      console.error("error");
    }
  };

  const getRoomAvailability = async () => {
    setCheckInDateError("");
    setCheckOutDateError("");
    if (!checkInDate || !checkOutDate) {
      !checkInDate && setCheckInDateError("Check in date is required.");
      !checkOutDate && setCheckOutDateError("Check out date is required.");
    } else {
      const response = await callApi({
        apiURL: Constants.API.getRoomAvailability,
        requestBody: {
          bookingCheckInDate: convertDateInDBFormateDate(checkInDate),
          bookingCheckOutDate: convertDateInDBFormateDate(checkOutDate),
        },
      });

      if (response?.success) {
        setAvailableSingleBed(
          response.data.availableRoomCount[0].availableSingleBed
        );
        setAvailableDoubleBed(
          response.data.availableRoomCount[0].availableDoubleBed
        );
        setSingleBedCount(0);
        setDoubleBedCount(0);
      } else {
        console.error("error");
      }
    }
  };

  useEffect(() => {
    getStaticDataForBookingMaster();

    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [purpose, setPurpose] = useState("");
  const [guestMobileNo, setGuestMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [idProof, setIdProof] = useState(null);
  const [idProofImages, setIdProofImages] = useState([]);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [purposeError, setPurposeError] = useState("");
  const [guestMobileNoError, setGuestMobileNoError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [zipError, setZipError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [idProofError, setIdProofError] = useState("");
  const [guestList, setGuestList] = useState([]);
  const [showSaveAndAddMore, setShowSaveAndAddMore] = useState(true);

  const options = [];

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const editGuest = (index) => {
    const selectedGuest = guestList[index];
    setGuestMasterId(selectedGuest.guestMasterId);
    setFirstName(selectedGuest.firstName);
    setLastName(selectedGuest.lastName);
    setDateOfBirth(selectedGuest.dateOfBirth);
    setGender(
      selectedGuest
        ? genderList?.find((option) => option?.value === selectedGuest?.gender)
        : ""
    );
    setPurpose(
      selectedGuest
        ? purposeList?.find(
            (option) => option?.value == selectedGuest?.purpose
          )
        : ""
    );
    setGuestMobileNo(selectedGuest.guestMobileNo);
    setEmail(selectedGuest.email);
    setZip(selectedGuest.zip);
    setCity(selectedGuest.city);
    setCountry(selectedGuest.country);
    setState(selectedGuest.state);
    setAddress(selectedGuest.address);
    setIdProofImages(selectedGuest.idProofImages);
    setIdProof(null);
    setShowSaveAndAddMore(false);
    setSelectedIndex(index);
    resetErrorState();
  };
  const handleZipcodeChange = async (e) => {
    setZip(e.target.value);
    setCity("");
    setState("");
    setCountry("");
    if (e.target.value === "") {
      setZipError("Enter valid pincode.");
      return;
    } else {
      let reg = /^[0-9]*$/;
      if (reg.test(e.target.value) === false) {
        setZipError("Enter valid pincode.");
        return;
      } else {
        if (e.target.value.length == 6) {
          try {
            const response = await axios.get(
              `https://api.postalpincode.in/pincode/${e.target.value}`
            );
            if (
              response.status === 200 &&
              response.data[0].Status == "Success"
            ) {
              setZipError("");
              setCity(response.data[0].PostOffice[0].District);
              setState(response.data[0].PostOffice[0].State);
              setCountry(response.data[0].PostOffice[0].Country);
            } else {
              setZipError("Enter valid pincode");
            }
          } catch (error) {
            setZipError("Enter valid pincode");
          }
        } else {
          setZipError("Enter valid pincode.");
        }
      }
    }
  };

  const validateData = () => {
    resetErrorState();
    if (
      !guestMobileNo ||
      guestMobileNo.length != 10 ||
      !email ||
      !firstName ||
      !lastName ||
      !zip ||
      !dateOfBirth ||
      !address ||
      // idProofImages.length == 0 ||
      !gender ||
      !purpose
    ) {
      guestMobileNo.length != 10 &&
        setGuestMobileNoError("Mobile number is invalid.");
      !guestMobileNo && setGuestMobileNoError("Mobile number is required.");
      !email && setEmailError("Email is required.");
      !firstName && setFirstNameError("First Name is required.");
      !lastName && setLastNameError("Last Name is required.");
      !zip && setZipError("Zip Code is required.");
      !dateOfBirth && setDateOfBirthError("Date of birth is required.");
      !gender && setGenderError("Gender is required.");
      !purpose && setPurposeError("Purpose is required.");
      !address && setAddressError("Address is required.");
      // idProofImages.length == 0 &&
      //   setIdProofError("Id proof image is required.");
      return false;
    } else {
      if (!validateEmail(email)) {
        setEmailError("Please enter valid email.");
        return false;
      }
      return true;
    }
  };

  const SaveGuestData = async () => {
    console.log(1)
    if (!validateData()) {
      console.log("2")
      return;
    } else {
      setGuestList([
        ...guestList,
        {
          guestMasterId,
          guestMobileNo,
          email,
          firstName,
          lastName,
          zip,
          dateOfBirth,
          gender: gender?.value,
          purpose: purpose?.value,
          address,
          city,
          state,
          country,
          isPrimary: guestList.length == 0 ? true : false,
        },
      ]);
      resetState();
      resetErrorState();
    }
  };

  const updateGuestData = () => {
    if (!validateData()) {
      return;
    } else {
      guestList[selectedIndex].guestMasterId = guestMasterId;
      guestList[selectedIndex].guestMobileNo = guestMobileNo;
      guestList[selectedIndex].email = email;
      guestList[selectedIndex].firstName = firstName;
      guestList[selectedIndex].lastName = lastName;
      guestList[selectedIndex].zip = zip;
      guestList[selectedIndex].dateOfBirth = dateOfBirth;
      guestList[selectedIndex].gender = gender?.value;
      guestList[selectedIndex].purpose = purpose?.value;
      guestList[selectedIndex].address = address;
      guestList[selectedIndex].city = city;
      guestList[selectedIndex].state = state;
      guestList[selectedIndex].country = country;
      guestList[selectedIndex].idProofImages = idProofImages;
      guestList[selectedIndex].isPrimary = selectedIndex == 0 ? true : false;
      setGuestList([...guestList]);
      resetState();
      resetErrorState();
      setSelectedIndex("");
      setShowSaveAndAddMore(true);
    }
  };

  const deleteGuest = (index) => {
    guestList.splice(index, 1);
    setGuestList([...guestList]);
  };

  const handleImageChange = (file, image) => {
    setIdProofImages([
      ...idProofImages,
      {
        image,
        file,
        isUpdated: true,
      },
    ]);
    setIdProof(null);
  };

  const validateRooms = async () => {
    const response = await callApi({
      apiURL: Constants.API.getRoomAvailability,
      requestBody: {
        bookingCheckInDate: convertDateInDBFormateDate(checkInDate),
        bookingCheckOutDate: convertDateInDBFormateDate(checkOutDate),
      },
    });

    if (response?.success) {
      if (
        singleBedCount <=
          response.data.availableRoomCount[0].availableSingleBed &&
        doubleBedCount <= response.data.availableRoomCount[0].availableDoubleBed
      ) {
        return true;
      } else {
        toast.error(
          <div className="custom_Toast">
            <h5>Please check room availablity !!!</h5>
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            hideProgressBar: true,
            className: "toast_pop",
            autoClose: 1500,
            closeButton: true,
            transition: Zoom,
          }
        );
        return false;
      }
    } else {
      console.error("error");
      return false;
    }
  };
  const handleSave = async () => {
    let isValid = await validateRooms();
    if (guestList.length > 0 && isValid) {
      
      saveData([]); 
    } else {
      console.log("add guests");
    }
  };
  

  const saveData = async (media) => {
    const params = {
      bookingMaster: {
        bookingMasterId: bookingMasterId,
        updatedDate: updatedDate,
        totalMember: guestList.length,
        checkInDate: convertDateInDBFormateDate(checkInDate),
        checkOutDate: convertDateInDBFormateDate(checkOutDate),
        noOfSingleBed: singleBedCount,
        noOfDoubleBed: doubleBedCount,
        // noOfExtraBed: extraBedCount,
      },
      guestList: guestList.map((x, index) => {
        return {
          guestMasterId: x.guestMasterId,
          firstName: x.firstName,
          lastName: x.lastName,
          emailAddress: x.email,
          dob: convertDateInDBFormateDate(x.dateOfBirth),
          gender: x.gender,
          purpose: x.purpose,
          country: x.country,
          state: x.state,
          city: x.city,
          address: x.address,
          zip: x.zip,
          mobile: x.guestMobileNo,
          // idProof: x.idProofImages.map((img, curr) => {
          //   return img.isUpdated
          //     ? media.filter((y) => {
          //         return y.index == index + "-" + curr;
          //       })[0].key
          //     : img.key;
          // }),
          isPrimary: x.isPrimary,
        };
      }),
    };
    const response = await callApi({
      apiURL:
        bookingMasterId == 0
          ? Constants.API.addBookingData
          : Constants.API.updateBookingData,
      requestBody: params,
    });
    if (response?.success) {
      handleCancel();
      toast.success(
        <div className="custom_Toast">
          <h5>Booking Added successfully</h5>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          hideProgressBar: true,
          className: "toast_pop",
          autoClose: 1500,
          closeButton: true,
          transition: Zoom,
        }
      );
    } else {
      console.log("error");
    }
  };

  const handleGenderChange = (selectedOption) => {
    setGender(selectedOption);
    selectedOption !== ""
      ? setGenderError("")
      : setGenderError("Gender is required.");
  };

  const handlePurposeChange = (selectedOption) => {
    setPurpose(selectedOption);
    selectedOption !== ""
      ? setPurposeError("")
      : setPurposeError("Purpose is required.");
  };

  return (
    <>
      <div className="Overlay" />
      {page == 0 && (
        <div
          className={showPopup ? "booking_PopMain" : "booking_PopMain hidden"}
        >
          <div className="booking_First">
            <p>{isAddMode ? "Add Booking" : "Update Booking"}</p>
            <button onClick={handleCancel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                  fill="#424242"
                />
              </svg>
            </button>
          </div>
          <br />
          <div className="booking_mainContainer1">
            <div className="booking_mainContainer1_1">
              <div className="data_row1">
                <div className="data_row1-1">
                  <p className="data_label">
                    Check In Date <span className="custom-text-color">*</span>
                  </p>
                  <DatePicker
                    value={checkInDate}
                    onChange={(date) => {
                      setCheckInDate(date);
                      setCheckOutDate("");
                      setAvailableSingleBed(0);
                      setAvailableDoubleBed(0);
                    }}
                    format="DD-MM-YYYY"
                    placeholder="Start Date"
                    minDate={new Date()}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      borderColor: "#ccc",
                      height: "46px",
                    }}
                  />
                  {checkInDateError && (
                    <p className="booking_error">{checkInDateError}</p>
                  )}
                </div>
                <div className="data_row1-1">
                  <p className="data_label">
                    Check Out Date <span className="text-[#F75555]">*</span>
                  </p>
                  <DatePicker
                    value={checkOutDate}
                    onChange={(value) => {
                      setCheckOutDate(value);
                      setAvailableSingleBed(0);
                      setAvailableDoubleBed(0);
                      //   setError("");
                    }}
                    format="DD-MM-YYYY"
                    placeholder="End Date"
                    minDate={checkInDate}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      borderColor: "#ccc",
                      height: "46px",
                    }}
                  />
                  {checkOutDateError && (
                    <p className="booking_error">{checkOutDateError}</p>
                  )}
                </div>
              </div>
              <br />
              <div className="mt-2">
                <div className="data_row1-1">
                  <p className="data_label">
                    Mobile number <span className="custom-text-color">*</span>
                  </p>
                  <input
                    type="number"
                    maxLength={10}
                    className="mobile_input"
                    placeholder="Mobile number"
                    value={mobileNumber}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setMobileNumber(e.target.value);
                        e.target.value != ""
                          ? setMobileNumberError("")
                          : setMobileNumberError("mobile number is required.");
                      }
                    }}
                  ></input>
                  {mobileNumberError && (
                    <p className="booking_error">{mobileNumberError}</p>
                  )}
                </div>
              </div>
              <br />
              <p className="custom-style">
                Choose Rooms <span className="custom-text-color">*</span>
                <button
                  type="submit"
                  className="next_btn"
                  onClick={() => {
                    getRoomAvailability();
                  }}
                >
                  Check Availability
                </button>
              </p>
              {checkInDate && checkOutDate && (
                <div className="chooseRooms">
                  <CountComponent
                    label="Single Bed"
                    count={singleBedCount}
                    handleChange={(value, op) => {
                      if (availableSingleBed > 0) {
                        setSingleBedCount(value);
                        setAvailableSingleBed(availableSingleBed + op);
                      }
                    }}
                    availableRooms={availableSingleBed}
                  />
                  <CountComponent
                    label="Double Bed"
                    count={doubleBedCount}
                    handleChange={(value, op) => {
                      if (availableSingleBed > 0) {
                        setDoubleBedCount(value);
                        setAvailableDoubleBed(availableDoubleBed + op);
                      }
                    }}
                    availableRooms={availableDoubleBed}
                  />

                  {/* <CountComponent
                  label="Extra Bed"
                  count={extraBedCount}
                  handleChange={(value) => {
                    setExtraBedCount(value);
                  }}
                /> */}
                </div>
              )}
              {roomError && <p className="booking_error">{roomError}</p>}
            </div>
            <div className="booking_mainContainer1_2">
              <p className="details_title" style={{ fontSize: "16px" }}>
                Summary
              </p>
              <div className="info_container">
                <div className="info_container_data">
                  <p className="info_heading">Date</p>
                  <p className="info_heading">Package</p>
                  <p className="info_heading">Adults</p>
                  <p className="info_heading">Package price</p>
                  <p className="info_heading">Room</p>
                </div>

                <div className="flex flex-col items-end gap-2">
                  <p className=" info_data">12/04-16/04 (5)</p>
                  <p className=" info_data">Delux</p>
                  <p className=" info_data">{10}</p>
                  <p className=" info_data">5</p>
                  <p className=" info_data">
                    {"Delux"}(₹{"10"} x {"5"})
                  </p>
                </div>
              </div>
              <br />
              <input placeholder="Coupon" className="coupon_field"></input>
              <br /> <br />
              <div className="info_container">
                <p className="info_heading">Total</p>
                <p className="info_data">₹{"5000"}</p>
              </div>
              <hr className="w-[100%] mt-3 bg-[#9e9c9c] " />
              <div className="info_container">
                <div className="info_container_data">
                  <p className="info_heading">GST on base price</p>
                  <p className="info_heading">Service Fee</p>
                </div>
                <div className="flex flex-col items-end gap-2">
                  <p className="info_data">₹{"10"}(18%)</p>
                  <p className="info_data">₹{"10"} (18%)</p>
                </div>
              </div>
              <hr className="w-[100%] mt-3 bg-[#9e9c9c] " />
              <div className="info_container">
                <p className="info_heading">Payable Now</p>
                <p className="font-semibold" text-sm>
                  ₹{"6000"}
                </p>
              </div>
            </div>
          </div>
          <div className="booking_End">
            <button
              type="submit"
              className="next_btn"
              onClick={() => {
                handleNext();
              }}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {page == 1 && (
        <div
          className={showPopup ? "booking_PopMain" : "booking_PopMain hidden"}
        >
          <div className="booking_First">
            <p>{isAddMode ? "Add Booking" : "Update Booking"}</p>
            <button onClick={handleCancel}>X</button>
          </div>
          <br />
          <div className="booking_mainContainer2">
            <div className="booksect1">
              <div className="custom-flex-baseline">
                {showSaveAndAddMore ? (
                  <p className="custom-font">Add Guest Details</p>
                ) : (
                  <p className="custom-font">Update Guest Details</p>
                )}
                <div className="Bookingdiv1">
                  <button
                    className="btn_book"
                    onClick={SaveGuestData}
                    style={{ display: showSaveAndAddMore ? "block" : "none" }}
                  >
                    Save and Add more
                  </button>
                  <button
                    className="btn_book"
                    onClick={updateGuestData}
                    style={{ display: !showSaveAndAddMore ? "block" : "none" }}
                  >
                    Save
                  </button>
                  <button
                    className="btn_cancel"
                    onClick={() => {
                      resetErrorState();
                      resetState();
                      setSelectedIndex("");
                      setShowSaveAndAddMore(true);
                    }}
                    style={{ display: !showSaveAndAddMore ? "block" : "none" }}
                  >
                    Cancel
                  </button>
                </div>
              </div>

              <div className="form_container">
                <div className="form_container2">
                  <div>
                    <p className="formLabel">
                      Mobile Number <span style={{ color: "#ED3237" }}>*</span>
                    </p>
                    <input
                      type="number"
                      maxLength={10}
                      placeholder="Mobile number"
                      className="formInput"
                      value={guestMobileNo}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          setGuestMobileNo(e.target.value);
                          e.target.value != ""
                            ? setGuestMobileNoError("")
                            : setGuestMobileNoError(
                                "Mobile number is required."
                              );
                        }
                      }}
                      disabled={guestList.length == 0 || selectedIndex === 0}
                    ></input>
                    {guestMobileNoError && (
                      <p style={{ color: "red" }}>{guestMobileNoError}</p>
                    )}
                  </div>
                  <div>
                    <p className="formLabel">
                      First name <span style={{ color: "#ED3237" }}>*</span>
                    </p>
                    <input
                      id="firstNameInput"
                      placeholder="Enter first name"
                      className="formInput"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        e.target.value != ""
                          ? setFirstNameError("")
                          : setFirstNameError("First name is required.");
                      }}
                    ></input>
                    {firstNameError && (
                      <p style={{ color: "red" }}>{firstNameError}</p>
                    )}
                  </div>

                  <div className="form_field_container">
                    <div className="DetailsField">
                      <p className="formLabel">
                        Date of birth{" "}
                        <span style={{ color: "#ED3237" }}>*</span>
                      </p>
                      <DatePicker
                        value={dateOfBirth}
                        onChange={(value) => {
                          setDateOfBirth(value);
                          value != ""
                            ? setDateOfBirthError("")
                            : setDateOfBirthError("Date of birth is required.");
                        }}
                        format="DD-MM-YYYY"
                        maxDate={new Date()}
                        placeholder="Date Of Birth"
                        style={{ width: "100%" }}
                      />
                      {dateOfBirthError && (
                        <p style={{ color: "red" }}>{dateOfBirthError}</p>
                      )}
                    </div>
                    <div className="DetailsField">
                      <p className="formLabel">
                        Gender <span style={{ color: "#ED3237" }}>*</span>
                      </p>
                      <div>
                        <Select
                          options={genderList}
                          onChange={handleGenderChange}
                          name="gender"
                          id="gender"
                          className="booking_dropdown"
                          value={gender}
                        />
                      </div>
                      {genderError && (
                        <p style={{ color: "red" }}>{genderError}</p>
                      )}
                    </div>
                  </div>
                  <div className="-mt-1">
                    <p className="formLabel">
                      Zip <span style={{ color: "#ED3237" }}>*</span>
                    </p>
                    <input
                      id="zip"
                      placeholder="Enter Zip code"
                      className="formInput"
                      value={zip}
                      maxLength={6}
                      onChange={handleZipcodeChange}
                    ></input>{" "}
                    {zipError != "" && (
                      <div className="error-message" style={{ color: "red" }}>
                        {zipError}
                      </div>
                    )}
                  </div>
                  <div className="-mt-1">
                    <p className="formLabel">
                      State <span style={{ color: "#ED3237" }}>*</span>
                    </p>
                    <div>
                      <input
                        id="state"
                        placeholder="Enter state"
                        className="formInput"
                        value={state}
                        disabled
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="form_container2">
                  <div>
                    <p className="formLabel">
                      Email <span style={{ color: "#ED3237" }}>*</span>
                    </p>
                    <input
                      id="email"
                      placeholder="Enter email address"
                      className="formInput"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        e.target.value != ""
                          ? setEmailError("")
                          : setEmailError("Email is required.");
                      }}
                    ></input>
                    {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                  </div>

                  <div>
                    <p className="formLabel">
                      Last name <span style={{ color: "#ED3237" }}>*</span>
                    </p>
                    <input
                      id="lastNameInput"
                      placeholder="Enter last name"
                      className="formInput"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        e.target.value != ""
                          ? setLastNameError("")
                          : setLastNameError("Last Name is required.");
                      }}
                    ></input>
                    {lastNameError && (
                      <p style={{ color: "red" }}>{lastNameError}</p>
                    )}
                  </div>

                  <div className="field_container">
                    <p className="formLabel">
                      Purpose of coming{" "}
                      <span style={{ color: "#ED3237" }}>*</span>
                    </p>
                    <div>
                      <Select
                        id="purpose"
                        name="purpose"
                        options={purposeList}
                        onChange={handlePurposeChange}
                        className="booking_dropdown"
                        value={purpose}
                      />
                      {purposeError && (
                        <p style={{ color: "red" }}>{purposeError}</p>
                      )}
                    </div>
                  </div>

                  <div style={{ marginTop: "-3px" }}>
                    <p className="formLabel">
                      Country <span style={{ color: "#ED3237" }}>*</span>
                    </p>
                    <div>
                      <input
                        id="country"
                        placeholder="Enter country"
                        className="formInput"
                        value={country}
                        disabled
                      ></input>
                    </div>
                  </div>
                  <div style={{ marginTop: "-4px" }}>
                    <p className="formLabel">
                      City <span style={{ color: "#ED3237" }}>*</span>
                    </p>
                    <input
                      id="city"
                      placeholder="Enter city"
                      className="formInput"
                      value={city}
                      disabled
                    ></input>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <p className="formLabel">
                  Address <span style={{ color: "#ED3237" }}>*</span>
                </p>
                <input
                  placeholder="Enter Address"
                  className="formInput"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    e.target.value != ""
                      ? setAddressError("")
                      : setAddressError("Address is required.");
                  }}
                ></input>
                {addressError && <p style={{ color: "red" }}>{addressError}</p>}
              </div>
              {/* <div>
                <p className="font-semibold font-main mt-3">
                  Upload photo id document
                  <span style={{ color: "#ED3237" }}>*</span>
                </p>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {idProofImages.length > 0 &&
                    idProofImages.map((curr, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <MediaPicker
                            type="image"
                            source={curr?.image}
                            id={"fileUpload_" + index}
                            className={
                              "flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            }
                            onChange={handleImageChange}
                            imageHeight={150}
                            imageWidth={150}
                          />
                          <button
                            className="rmv_btn"
                            onClick={() => {
                              idProofImages.splice(index, 1);
                              setIdProofImages([...idProofImages]);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      );
                    })}
                  <div
                    style={{ width: "150px", height: "150px", margin: "10px" }}
                  >
                    <MediaPicker
                      type="image"
                      onChange={handleImageChange}
                      source={idProof?.image}
                      id={"fileUpload"}
                      className={
                        "flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      }
                    />
                  </div>
                </div>
                {idProofError && <p style={{ color: "red" }}>{idProofError}</p>}
              </div> */}
            </div>

            <div className="booksect2">
              {guestList.length === 0 ? (
                <div className="guest_non_content">
                  <p style={{ fontSize: "14px" }}>
                    None of the guests have been added yet.
                  </p>
                </div>
              ) : (
                guestList.map((guest, index) => (
                  <div className="guest_content" key={index}>
                    <div>
                      <Avatar>
                        {`${guest.firstName
                          .charAt(0)
                          .toUpperCase()}${guest.lastName
                          .charAt(0)
                          .toUpperCase()}`}
                      </Avatar>
                    </div>

                    <div style={{ lineHeight: "150%" }}>
                      <p
                        className="mb-0 font-semibold"
                        style={{ fontSize: "14px" }}
                      >
                        {`${guest.firstName
                          .charAt(0)
                          .toUpperCase()}${guest.firstName.slice(
                          1
                        )} ${guest.lastName
                          .charAt(0)
                          .toUpperCase()}${guest.lastName.slice(1)}`.length > 12
                          ? `${`${guest.firstName
                              .charAt(0)
                              .toUpperCase()}${guest.firstName.slice(
                              1
                            )} ${guest.lastName
                              .charAt(0)
                              .toUpperCase()}${guest.lastName.slice(
                              1
                            )}`.substring(0, 12)}...`
                          : `${guest.firstName
                              .charAt(0)
                              .toUpperCase()}${guest.firstName.slice(
                              1
                            )} ${guest.lastName
                              .charAt(0)
                              .toUpperCase()}${guest.lastName.slice(1)}`}
                      </p>
                      <p className="mb-0" style={{ fontSize: "14px" }}>
                        {guest.email.length > 12
                          ? `${guest.email.substring(0, 20)}...`
                          : guest.email}
                      </p>
                      <p className="mb-0" style={{ fontSize: "14px" }}>
                        {guest.city}
                      </p>
                    </div>
                    <button
                      className="btnedit"
                      disabled={!showSaveAndAddMore ? true : false}
                      style={{
                        opacity: !showSaveAndAddMore ? 0.2 : 1,
                        cursor: !showSaveAndAddMore ? "default" : "pointer",
                      }}
                    >
                      <div onClick={() => editGuest(index)}>
                        {/* <i className="fa-regular fa-pen-to-square"></i> */}
                        <img src={edit} />
                      </div>
                    </button>
                    <button
                      className="btndelete"
                      disabled={!showSaveAndAddMore ? true : false}
                      style={{
                        display: index == 0 ? "none" : "",
                        opacity: !showSaveAndAddMore ? 0.2 : 1,
                        cursor: !showSaveAndAddMore ? "default" : "pointer",
                      }}
                    >
                      <div onClick={() => deleteGuest(index)}>
                        {/* <i className="fa-solid fa-trash"></i> */}
                        <img src={trash} />
                      </div>
                    </button>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="booking_End">
            <button
              type="submit"
              className="cancel_btn"
              onClick={() => {
                setPage(0);
              }}
            >
              Back
            </button>
            &nbsp; &nbsp; &nbsp;
            <button
              type="submit"
              className="next_btn"
              onClick={() => {
                handleSave();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Booking;
