import React, { useEffect, useState } from "react";
import css from "./AddServices.module.scss";

import Logo from "../../../../src/assets/images/Logo.png";
import l from "../../../../src/assets/images/Onboarding_4.png";
import editicon from "../../../assets/icons/edit.svg"
import addicon from "../../../assets/icons/add.svg"
import { AiOutlineArrowLeft, AiOutlineCheck } from "react-icons/ai";

import { IconButton, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import callApi from "../../../ApiCall";
import Constants from "../../../Constants";
import { CiSquareRemove, CiEdit } from "react-icons/ci";
import { IoIosAddCircleOutline } from "react-icons/io";
import { VscDiffAdded } from "react-icons/vsc";

const AddServices = () => {
  const [serviceCategoryList, setServiceCategoryList] = useState([]);
  const [serviceCategory, setServiceCategory] = useState("");
  const [serviceCategoryError, setServiceCategoryError] = useState("");

  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [updatedDate, setUpdatedDate] = useState("");
  const [updateCategory, setUpdateCategory] = useState("");
  const [updateCategoryError, setUpdateCategoryError] = useState("");

  const [addCategoryId, setAddCategoryId] = useState(0);
  const [serviceName, setServiceName] = useState("");
  const [serviceNameError, setServiceNameError] = useState("");

  const [updateServiceId, setUpdateServiceId] = useState(0);
  const [serviceUpdatedDate, setServiceUpdatedDate] = useState("");
  const [updateServiceName, setUpdateServiceName] = useState("");
  const [updateServiceNameError, setUpdateServiceNameError] = useState("");

  const navigate = useNavigate();

  const getServices = async () => {
    setServiceCategory("");
    setServiceCategoryError("");

    setSelectedCategoryId(0);
    setUpdatedDate("");
    setUpdateCategory("");
    setUpdateCategoryError("");

    setServiceName("");
    setServiceNameError("");
    setAddCategoryId(0);

    setUpdateServiceId(0);
    setServiceUpdatedDate("");
    setUpdateServiceName("");
    setUpdateServiceNameError("");
    const response = await callApi({
      apiURL: Constants.API.getServices,
    });
    if (response?.success) {
      if (response.data.services) {
        setServiceCategoryList(response.data.services);
      }
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  const addServiceCategory = async () => {
    if (serviceCategory === "") {
      setServiceCategoryError("Please Enter Service Category");
      return;
    } else {
      setServiceCategoryError("");
    }
    const response = await callApi({
      apiURL: Constants.API.addServiceCategory,
      requestBody: {
        categoryName: serviceCategory,
      },
    });
    if (response?.success) {
      getServices();
    } else {
      console.log("error");
    }
  };

  const updateServiceCategory = async () => {
    if (updateCategory === "") {
      setUpdateCategoryError("Please Enter Service Category");
      return;
    } else {
      setUpdateCategoryError("");
    }
    const response = await callApi({
      apiURL: Constants.API.updateServiceCategory,
      requestBody: {
        categoryName: updateCategory,
        serviceCategoryId: selectedCategoryId,
        updatedDate,
      },
    });
    if (response?.success) {
      getServices();
    } else {
      console.log("error");
    }
  };

  const addService = async () => {
    if (serviceName === "") {
      setServiceNameError("Please Enter Service");
      return;
    } else {
      setServiceNameError("");
    }
    const response = await callApi({
      apiURL: Constants.API.addService,
      requestBody: {
        serviceCategoryId: addCategoryId,
        serviceName: serviceName,
      },
    });
    if (response?.success) {
      getServices();
    } else {
      console.log("error");
    }
  };

  const updateService = async () => {
    if (updateServiceName === "") {
      setUpdateServiceNameError("Please Enter Service");
      return;
    } else {
      setUpdateServiceNameError("");
    }
    const response = await callApi({
      apiURL: Constants.API.updateService,
      requestBody: {
        serviceMasterId: updateServiceId,
        serviceName: updateServiceName,
        updatedDate: serviceUpdatedDate,
      },
    });
    if (response?.success) {
      getServices();
    } else {
      console.log("error");
    }
  };

  const serviceStatusUpdate = async (
    ServiceMasterId,
    UpdatedDate,
    IsActive
  ) => {
    const response = await callApi({
      apiURL: Constants.API.serviceStatusUpdate,
      requestBody: {
        serviceMasterId: ServiceMasterId,
        isActive: IsActive == 0 ? 1 : 0,
        updatedDate: UpdatedDate,
      },
    });
    if (response?.success) {
      getServices();
    } else {
      console.log("error");
    }
  };

 
  const completeOnBoarding = async (e) => {
    const response = await callApi({
      apiURL: Constants.API.completeOnBoarding,
    });
    console.log(response.success,"iu")
    if (response?.success) {
      localStorage.setItem("isOnboarded", 1);
      navigate("/dashboard");
    } else {
      console.log("error");
    }
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <section className="sec-left">
          <img src={l} />
        </section>
        <section className="sec1-right">
          <div style={{ marginTop: "2%" }}>
            <div className={css.flexone}>
              <section className={css.left}>
                <img src={Logo} alt="" className={css.img} />
              </section>
              <section className={css.right}>
                <a
                  onClick={() => {
                    navigate("/onboading/add-room-rate");
                  }}
                >
                  <button className={css.icon}>
                    <AiOutlineArrowLeft />
                  </button>
                </a>

                {serviceCategoryList.length > 0 && (
                  <a
                    onClick={() => {
                      completeOnBoarding();
                    }}
                  >
                    <button className={css.icon}>
                      <AiOutlineCheck />
                    </button>
                  </a>
                )}
              </section>
            </div>
            <div className={css.title}>Add Services </div>
            <div className={css.desc}>
              Please select the service that you are providing
            </div>
            {serviceCategoryList?.length > 0 &&
              serviceCategoryList?.map((service, index) => (
                <div className={css.services}>
                  {selectedCategoryId != service.ServiceCategoryId ? (
                    <div className={css.heading} >
                      {service.CategoryName}
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setSelectedCategoryId(service.ServiceCategoryId);
                          setUpdateCategory(service.CategoryName);
                          setUpdatedDate(service.UpdatedDate);
                        }}
                        
                      >
                        <CiEdit
                          style={{ color: "#5D7202" }}
                          className={css.icon}
                        />
                        {/* <img src={editicon} /> */}
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setAddCategoryId(service.ServiceCategoryId);
                          setServiceName("");
                          setServiceNameError("");
                        }}
                        style={{width:"6%"}}
                      >
                        {/* <IoIosAddCircleOutline
                          style={{ color: "#ED3237" }}
                          className={css.icon}
                        /> */}
                        <img src={addicon} style={{width:"90%"}}/>
                      </IconButton>
                    </div>
                  ) : (
                    <div className={css.heading}>
                      {/* <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          marginTop: "6%",
                        }}
                      >
                        <TextField
                          label="Update Service Category"
                          variant="outlined"
                          value={updateCategory}
                          onChange={(e) => {
                            setUpdateCategory(e.target.value);
                            e.target.value === ""
                              ? setUpdateCategoryError(
                                  "Please Enter Service Category"
                                )
                              : setUpdateCategoryError("");
                          }}
                        />
                        <IconButton
                          color="primary"
                          onClick={updateServiceCategory}
                        >
                          <AiOutlineCheck />
                        </IconButton>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setSelectedCategoryId(0);
                            setUpdatedDate("");
                            setUpdateCategory("");
                            setUpdateCategoryError("");
                          }}
                        >
                          <CiSquareRemove style={{ color: "#ED3237" }} />
                        </IconButton>
                      </div> */}
                      <div
                        style={{
                          display: "flex",
                          marginTop: "2%",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Update Service Category"
                          value={updateCategory}
                          onChange={(e) => {
                            setUpdateCategory(e.target.value);
                            e.target.value === ""
                              ? setUpdateCategoryError(
                                  "Please Enter Service Category"
                                )
                              : setUpdateCategoryError("");
                          }}
                          style={{
                            height: "50px",
                            marginRight: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            paddingLeft: "10px",
                            outline: "none",
                          }}
                        />
                        <button
                          onClick={updateServiceCategory}
                          style={{
                            height: "50px",
                            backgroundColor: "transparent",

                            cursor: "pointer",
                            color: "#007bff",
                            padding: "0 10px",
                          }}
                        >
                          <AiOutlineCheck style={{ fontSize: "1.5rem" }} />
                        </button>
                        <button
                          onClick={() => {
                            setSelectedCategoryId(0);
                            setUpdatedDate("");
                            setUpdateCategory("");
                            setUpdateCategoryError("");
                          }}
                          style={{
                            height: "50px",
                            backgroundColor: "transparent",

                            cursor: "pointer",
                            color: "#ED3237",
                            padding: "0 10px",
                          }}
                        >
                          <CiSquareRemove style={{ fontSize: "1.5rem" }} />
                        </button>
                      </div>

                      {updateCategoryError != "" && (
                        <div className="error-message" style={{ color: "red" }}>
                          {updateCategoryError}
                        </div>
                      )}
                    </div>
                  )}
                  <div className={css.diseasegrid}>
                    {JSON.parse(service.ServiceList)
                      ?.filter((x) => {
                        return !!x.ServiceName;
                      })
                      .map((y) => {
                        return (
                          <>
                            {updateServiceId != y.ServiceMasterId ? (
                              <div
                                key={y.ServiceMasterId}
                                className={`${css.diseaseitem} ${
                                  y.IsActive ? css.selected : ""
                                }`}
                                // onClick={() => handleItemClick(disease)}
                              >
                                <span>{y.ServiceName}</span>
                                <div className={css.edit_plus}>
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      setUpdateServiceId(y.ServiceMasterId);
                                      setUpdateServiceName(y.ServiceName);
                                      setServiceUpdatedDate(y.UpdatedDate);
                                      setUpdateServiceNameError("");
                                    }}
                                  >
                                    <CiEdit
                                      style={{ color: "#5D7202" }}
                                      className={css.icon}
                                    />
                                    {/* <img src={editicon}  /> */}
                                  </IconButton>
                                  {/* <IconButton
                                    color="primary"
                                    onClick={() => {
                                      serviceStatusUpdate(
                                        y.ServiceMasterId,
                                        y.UpdatedDate,
                                        // y.IsActive
                                      );
                                    }}
                                    sx={{width:"65%", padding:"unset"}}
                      
                                  >
                                    <img src={addicon} style={{width:"125%"}} />
                                    {y.IsActive ? (
                                      <CiSquareRemove
                                        style={{ color: "#5D7202" }}
                                        className={css.icon}
                                      />
                                    ) : (
                                      <VscDiffAdded
                                        style={{ color: "#5D7202" }}
                                        className={css.icon}
                                      />
                                    )}

                                  </IconButton> */}
                                </div>
                              </div>
                            ) : (
                              <>
                                {/* <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  <TextField
                                    label="Enter Service"
                                    variant="outlined"
                                    value={updateServiceName}
                                    onChange={(e) => {
                                      setUpdateServiceName(e.target.value);
                                      e.target.value === ""
                                        ? setUpdateServiceNameError(
                                            "Please Enter Service"
                                          )
                                        : setUpdateServiceNameError("");
                                    }}
                                  />
                                  <IconButton
                                    color="primary"
                                    onClick={updateService}
                                  >
                                    <AiOutlineCheck />
                                  </IconButton>
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      setUpdateServiceId(0);
                                      setServiceUpdatedDate("");
                                      setUpdateServiceName("");
                                      setUpdateServiceNameError("");
                                    }}
                                  >
                                    <CiSquareRemove
                                      style={{ color: "#ED3237" }}
                                    />
                                  </IconButton>
                                </div> */}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  <input
                                    type="text"
                                    placeholder="Enter Service"
                                    value={updateServiceName}
                                    onChange={(e) => {
                                      setUpdateServiceName(e.target.value);
                                      e.target.value === ""
                                        ? setUpdateServiceNameError(
                                            "Please Enter Service"
                                          )
                                        : setUpdateServiceNameError("");
                                    }}
                                    style={{
                                      height: "50px",
                                      marginRight: "10px",
                                      border: "1px solid #ccc",
                                      borderRadius: "4px",
                                      paddingLeft: "10px",
                                    }}
                                  />
                                  <button
                                    onClick={updateService}
                                    style={{
                                      height: "50px",
                                      backgroundColor: "transparent",

                                      cursor: "pointer",
                                      color: "#007bff",
                                      padding: "0 10px",
                                    }}
                                  >
                                    <AiOutlineCheck
                                      style={{ fontSize: "1.5rem" }}
                                    />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setUpdateServiceId(0);
                                      setServiceUpdatedDate("");
                                      setUpdateServiceName("");
                                      setUpdateServiceNameError("");
                                    }}
                                    style={{
                                      height: "50px",
                                      backgroundColor: "transparent",

                                      cursor: "pointer",
                                      color: "#ED3237",
                                      padding: "0 10px",
                                    }}
                                  >
                                    <CiSquareRemove
                                      style={{ fontSize: "1.5rem" }}
                                    />
                                  </button>
                                </div>

                                {updateServiceNameError != "" && (
                                  <div
                                    className="error-message"
                                    style={{ color: "red" }}
                                  >
                                    {updateServiceNameError}
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        );
                      })}
                  </div>
                  {addCategoryId == service.ServiceCategoryId && (
                    <>
                      {/* <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <TextField
                          label="Enter Service"
                          variant="outlined"
                          value={serviceName}
                          onChange={(e) => {
                            setServiceName(e.target.value);
                            e.target.value === ""
                              ? setServiceNameError("Please Enter Service")
                              : setServiceNameError("");
                          }}
                        />
                        <IconButton color="primary" onClick={addService}>
                          <AiOutlineCheck />
                        </IconButton>
                      </div> */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="byby"
                          value={serviceName}
                          onChange={(e) => {
                            setServiceName(e.target.value);
                            e.target.value === ""
                              ? setServiceNameError("Please Enter Service")
                              : setServiceNameError("");
                          }}
                          style={{
                            height: "50px",
                            marginRight: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            paddingLeft: "10px",
                            outline: "none",
                          }}
                        />
                        <button
                          onClick={addService}
                          style={{
                            height: "50px",
                            backgroundColor: "transparent",

                            cursor: "pointer",
                            color: "#007bff",
                            padding: "0 10px",
                          }}
                        >
                          <AiOutlineCheck style={{ fontSize: "1.5rem" }} />
                        </button>

                        <button
                          onClick={() => {
                            setAddCategoryId(0);
                            setServiceName("");
                            setServiceNameError("");
                          }}
                          style={{
                            height: "50px",
                            backgroundColor: "transparent",

                            cursor: "pointer",
                            color: "#ED3237",
                            padding: "0 10px",
                          }}
                        >
                          <CiSquareRemove style={{ fontSize: "1.5rem" }} />
                        </button>
                      </div>

                      {serviceNameError != "" && (
                        <div className="error-message" style={{ color: "red" }}>
                          {serviceNameError}
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <TextField
                label="Enter Service Category"
                variant="outlined"
                value={serviceCategory}
                onChange={(e) => {
                  setServiceCategory(e.target.value);
                  e.target.value === ""
                    ? setServiceCategoryError("Please Enter Service Category")
                    : setServiceCategoryError("");
                }}
                style={{ height: "50px" }}
              />
              <IconButton color="primary" onClick={addServiceCategory}>
                <AiOutlineCheck />
              </IconButton>
            </div> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <input
                type="text"
                placeholder="Enter Service Category"
                value={serviceCategory}
                onChange={(e) => {
                  setServiceCategory(e.target.value);
                  e.target.value === ""
                    ? setServiceCategoryError("Please Enter Service Category")
                    : setServiceCategoryError("");
                }}
                style={{
                  height: "50px",
                  marginRight: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  paddingLeft: "10px",
                  outline: "none",
                }}
              />
              <button
                onClick={addServiceCategory}
                style={{
                  height: "50px",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <AiOutlineCheck
                  style={{ fontSize: "1.5rem", color: "#007bff" }}
                />
              </button>
            </div>

            {serviceCategoryError != "" && (
              <div className="error-message" style={{ color: "red" }}>
                {serviceCategoryError}
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddServices;
