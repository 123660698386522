import React from "react";
import "../../assets/colors/colors.css";
import "react-datepicker/dist/react-datepicker.css";
import "../Reservation/CSS/reservation.css";
import { useEffect, useState } from "react";
import callApi from "../../ApiCall";

import Constants from "../../Constants";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AddLeaveBalancePopUp from "./AddLeaveBalancePopUp";
import AddButton from "../../common/Button/Button";

export default function Leave(props) {
  const [roleList, setRoleList] = useState([]);
  const [showReservationPopup, setShowReservationPopup] = useState(false);
  const [isAddMode, setIsAddMode] = useState(true);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedProductForUpdate, setSelectedProductForUpdate] =
    useState(null);

  const getRoleWiseLeaveBalance = async () => {
    const response = await callApi({
      apiURL: Constants.API.getRoleWiseLeaveBalance,
    });
    if (response?.success) {
      const { leaveBalanceRoleWiseList = [] } = response.data;
      const mappedList = leaveBalanceRoleWiseList.map((role) => ({
        ...role,
        PrePost: role.PrePost === 81 ? "PreMonth" : "PostMonth",
      }));
      setRoleList(mappedList);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    getRoleWiseLeaveBalance();
  }, []);

  const columns = [
    {
      field: "DesignationName",
      headerName: <span>Designations</span>,
      flex: 1,
    },
    {
      field: "SickBalance",
      headerName: <span>Sick Leave Balance</span>,
      flex: 1,
    },
    {
      field: "PrevilegedLeaveBalance",
      headerName: <span>Previleged Leave Balance</span>,
      flex: 1,
    },
    {
      field: "CasualLeaveBalance",
      headerName: <span>Casual Leave Balance</span>,
      flex: 1,
    },
    {
      field: "EffectiveDate",
      headerName: <span>Effective Date</span>,
      flex: 1,
    },
    {
      field: "StaticName",
      headerName: <span>Quarter</span>,
      flex: 1,
    },
    {
      field: "PrePost",
      headerName: <span>PrePost</span>,
      flex: 1,
    },
  ].filter(Boolean);

  const handleAddProductClick = () => {
    setIsAddMode(true);
    setShowReservationPopup(true);
  };

  const handleReservationPopupClose = () => {
    setShowReservationPopup(false);
    setIsAddMode(true);
    setSelectedProductForUpdate(null);
    getRoleWiseLeaveBalance();
  };

  const handleSortClick = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const renderArrowIcon = (column) => {
    if (sortedColumn === column) {
      return (
        <span className="arrow-icon">
          {sortOrder === "asc" ? (
            <i className="fa-solid fa-arrow-up" />
          ) : (
            <i className="fa-solid fa-arrow-down" />
          )}
        </span>
      );
    }
    return null;
  };

  return (
    <>
      {showReservationPopup && (
        <AddLeaveBalancePopUp
          onClose={handleReservationPopupClose}
          isAddMode={isAddMode}
          leaveBalanceData={selectedProductForUpdate}
        />
      )}
      <div className="reservation_MainPae">
        <div className="reservation_sect-1">
          <div className="reservation_manage">
            <h1 className="manage_page_heading">Leave Balance</h1>
          </div>
          {props?.currentMenu?.Add ? (
            <>
              <AddButton
                buttonText={"Add Leave Balance"}
                onClick={handleAddProductClick}
              />
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="ReservationTable">
          <div className="Table">
            <div className="Property_Location_DataGrid">
              <div className="propActivity-table">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            className="tableHeaderCell"
                            onClick={() => handleSortClick(column.field)}
                          >
                            {column.headerName}
                            {renderArrowIcon(column.field)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {roleList.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {columns.map((column, colIndex) => (
                            <TableCell key={colIndex} className="tableBodyCell">
                              {row &&
                                (column.renderCell
                                  ? column.renderCell({ row })
                                  : row[column.field])}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
