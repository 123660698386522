import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { toast,Zoom } from "react-toastify";
import "./dashboardScreen.css";

const DashboardPopup = ({ onClose, isAddMode }) => {
  const [showPopup, setShowPopup] = useState(true);

  const initialValues = {
    items: [], 
  };

  const validationSchema = Yup.object().shape({
    items: Yup.array()
      .required("Select at least one item")
      .min(1, "Select at least one item"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
     
     
      setTimeout(() => {
        setSubmitting(false);
        setShowPopup(false); 
        onClose(); 
        const actionMessage = "Updated Dashboard";
        toast.success(
            <div className="custom_Toast">
              <h5>{actionMessage}</h5>
              Dashboard has been updated successfully
            </div>,
            {
              position: toast.POSITION.TOP_CENTER,
              theme: "colored",
              hideProgressBar: true,
              transition: Zoom,
              className: "toast_pop",
              autoClose: 1500,
              closeButton: true,
              onClose: () => {
                setShowPopup(false);
                onClose();
              },
            }
          );
      }, 1000);
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to save. Please try again later."); 
      setSubmitting(false);
    }
  };


  const itemsList = [
    { id: 1, label: "Reservation" },
    { id: 2, label: "Revenue" },
    { id: 3, label: "Check In Guest" },
    { id: 4, label: "Room Category" },
    { id: 5, label: "Gender" },
    { id: 6, label: "Room Occupied" },
    { id: 7, label: "House Keeping" },
    { id: 8, label: "Membership" },
   
  ];

  return (
    <>
      <div className="Overlay" />
      <div className={showPopup ? "dashboard_PopMain" : "dashboard_PopMain hidden"}>
        <div className="dashboard_First">
          <p>{isAddMode ? "Dashboard Item" : "Edit Dashboard Item"}</p>
          <button onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#424242"
              />
            </svg>
          </button>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className="dashboard_Form">
              <Grid container spacing={2}>
                {itemsList.map((item) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={item.id}
                          name="items"
                          value={item.id}
                          checked={values.items.includes(item.id)}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            if (isChecked) {
                              setFieldValue('items', [...values.items, item.id]);
                            } else {
                              setFieldValue('items', values.items.filter(id => id !== item.id));
                            }
                          }}
                        />
                      }
                      label={item.label}
                    />
                  </Grid>
                ))}
              </Grid>

              <ErrorMessage
                name="items"
                component="div"
                className="invalid-feedback"
              />

              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: "var(--primary-green)" }}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default DashboardPopup;
