import React, { useState, useEffect } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import callApi from "../../ApiCall";
import { toast, Zoom } from "react-toastify";
import MySelect from "../../common/SingleSelect";
import Constants from "../../Constants";

const ItemMasterPopup = ({ onClose, isAddMode, selectedItemToUpdate }) => {
  const [showPopup, setShowPopup] = useState(true);
  const [showOverlay, setShowOverlay] = useState(true);

  const [status, setStatus] = useState(
    selectedItemToUpdate
      ? selectedItemToUpdate.IsActive
        ? "Active"
        : "Inactive"
      : "Active"
  );

  const [returnstatus, setReturnstatus] = useState(
    selectedItemToUpdate
      ? selectedItemToUpdate.IsReturn
        ? "Return"
        : "NoReturn"
      : "Return"
  );
  const handleCancel = () => {
    setShowPopup(false);
    onClose();
  };

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setStatus(newStatus);
    }
  };

  const handleReturnChange = (event, newReturn) => {
    if (newReturn !== null) {
      setReturnstatus(newReturn);
    }
  };
  console.log("popup is working...");

  // Form Initial Values
  const initialValuesFormik = {
    itemName: isAddMode ? "" : selectedItemToUpdate?.ItemName,
    description: isAddMode ? "" : selectedItemToUpdate?.Description,
    itemCategory: isAddMode ? "" : selectedItemToUpdate.CategoryMasterId,
    pack: isAddMode ? "" : selectedItemToUpdate?.Pack,
    UOM: isAddMode ? "" : selectedItemToUpdate?.UOMId,
    status: selectedItemToUpdate ? (selectedItemToUpdate.IsActive ? 1 : 0) : 1,
    returnstatus: selectedItemToUpdate
      ? selectedItemToUpdate.IsReturn
        ? 1
        : 0
      : 1,
  };

  // Form Validation
  const validationSchemaFormik = Yup.object({
    itemName: Yup.string().required("Item Name is required"),
    description: Yup.string().required("Description is required"),
    pack: Yup.number().required("Pack number is required"),
    UOM: Yup.string().required("Uom is required"),
    itemCategory: Yup.string().required("Category Name is required"),
  });

  // UomList
  const [uom, setUom] = useState(!isAddMode ? selectedItemToUpdate?.UOMId : "");
  const [uomList, setuomList] = useState([]);

  // UomList Api
  const getUOMList = async () => {
    try {
      const response = await callApi({
        apiURL: "item/getUOMList",
      });
      if (response.data && response.data.uomList) {
        const typeData = JSON.parse(response.data.uomList[0].ActivityType)?.map(
          (item) => ({
            label: item?.Name,
            value: item?.ID,
          })
        );
        setuomList(typeData);
      }
    } catch (error) {}
  };

  const handleUomChange = (option) => {
    setUom(option);
  };

  useEffect(() => {
    getUOMList();
  }, [selectedItemToUpdate]);

  // CategoryList
  const [refreshPage, setRefreshPage] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const [addCategoryDone, setAddCategoryDone] = useState(false);
  const [category, setCategory] = useState(
    !isAddMode ? selectedItemToUpdate?.ItemCategoryId : ""
  );

  // get category api
  const getCategoryDetail = async () => {
    try {
      const response = await callApi({
        apiURL: Constants.API.getItemCategoryData,
      });

      if (response.data && response.data.item) {
        const typeData = JSON.parse(response.data.item[0].data)?.map(
          (item) => ({
            label: item?.name,
            value: item?.id,
          })
        );
        setCategoryList(typeData);
        console.log(typeData);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }

    console.log(categoryList);
  };

  const handleCategoryChange = (option) => {
    setCategory(option);
  };

  useEffect(() => {
    getCategoryDetail();
  }, [selectedItemToUpdate]);

  // Add update Api
  const handleSubmit = async (values, { resetForm }) => {
    values.status = status === "Active" ? 1 : 0;
    values.returnstatus = returnstatus === "Return" ? 1 : 0;

    let response;
    try {
      const params = { ...values };
      if (isAddMode) {
        response = await callApi({
          apiURL: Constants.API.addItemMaster,
          requestBody: params,
        });

        console.log("params:", params);
      } else {
        response = await callApi({
          apiURL: Constants.API.updateItemMaster,
          requestBody: {
            ...values,
            ItemMasterId: selectedItemToUpdate.ItemMasterId,
            updateDate: selectedItemToUpdate.UpdatedDate,
          },
        });
      }
      if (response?.success) {
        const actionMessage = isAddMode ? "Added Item" : "Update Item";
        toast.success(
          <div className="custom_Toast">
            <h5>{actionMessage}</h5>
            Item has been {isAddMode ? "added." : "updated."}
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            transition: Zoom,
            theme: "colored",
            className: "toast_pop",
            autoClose: 1500,
            closeButton: true,
            onClose: () => {
              setShowPopup(false);
              onClose();
              resetForm();
            },
          }
        );
        console.log(params);
      } else {
        console.error("Error fetching item activity data:", response.error);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
    resetForm();
    onClose();
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  });

  return (
    <>
      {showOverlay && <div className="Overlay" />}
      <div className={showPopup ? "item_PopMain" : "item_PopMain hidden"}>
        <div className="item_First">
          <p>{isAddMode ? "Add Item Activity" : "Update Item Activity"}</p>
          <button onClick={handleCancel}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#424242"
              />
            </svg>
          </button>
        </div>

        <Formik
          initialValues={initialValuesFormik}
          validationSchema={validationSchemaFormik}
          onSubmit={handleSubmit}
        >
          <Form className="Item_Form">
            <div className="item_name">
              <label htmlFor="item_fname">
                Item Name <sup>*</sup>
              </label>
              <Field
                type="text"
                id="itemName"
                name="itemName"
                placeholder="Enter Item Name"
              ></Field>
              <div className="error">
                <ErrorMessage name="itemName" />
              </div>
            </div>

            <div className="item_name">
              <label htmlFor="item_fname">
                Item Description <sup>*</sup>
              </label>
              <Field
                type="text"
                id="description"
                name="description"
                placeholder="Enter Item Description"
              ></Field>
              <div className="error">
                <ErrorMessage name="description" />
              </div>
            </div>

            <div className="item_name">
              <label htmlFor="item_fname"
              title="CategoryList">
                Category List<sup>*</sup>
              </label>

              <Field
                component={MySelect}
                id="itemCategory"
                name="itemCategory"
                placeholder="Select"
                className="employee-detail-dropdown"
                options={categoryList}
                onChange={handleCategoryChange}
              ></Field>
              <div className="error">
                <ErrorMessage name="itemCategory" />
              </div>
            </div>

            <div className="item_name">
              <label htmlFor="item_fname">
                Pack <sup>*</sup>
              </label>
              <Field
                type="text"
                id="pack"
                name="pack"
                placeholder="Enter No. of pack"
              ></Field>
              <div className="error">
                <ErrorMessage name="pack" />
              </div>
            </div>

            <div className="item_name">
              <label htmlFor="item_fname"
               title="UOM">
                UOM<sup>*</sup>
              </label>

              <Field
                component={MySelect}
                id="UOM"
                name="UOM"
                placeholder="Select"
                className="employee-detail-dropdown"
                options={uomList}
                onChange={handleUomChange}
              ></Field>
              <div className="error">
                <ErrorMessage name="UOM" />
              </div>
            </div>

            <div className="toggle_flex">
              <div className="item_Togglebutton-1">
                <ToggleButtonGroup
                  className="item_ToggleButtonGroups-1"
                  sx={{ width: "30px", height: "32px", fontSize: "14px" }}
                  value={status}
                  id="status"
                  name="status"
                  exclusive
                  onChange={handleStatusChange}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    sx={{ borderRadius: "8px 0px 0px 8px" }}
                    value="Active"
                    aria-label="Active"
                  >
                    Active
                  </ToggleButton>
                  <ToggleButton
                    sx={{ borderRadius: "0px 8px 8px 0px" }}
                    value="Inactive"
                    aria-label="Inactive"
                  >
                    Inactive
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className="item_Togglebutton-1">
                <ToggleButtonGroup
                  className="item_ToggleButtonGroups-1"
                  sx={{ width: "30px", height: "32px", fontSize: "14px" }}
                  value={returnstatus}
                  exclusive
                  onChange={handleReturnChange}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    sx={{ borderRadius: "8px 0px 0px 8px" }}
                    value="Return"
                    aria-label="Return"
                  >
                    Return
                  </ToggleButton>
                  <ToggleButton
                    sx={{ borderRadius: "0px 8px 8px 0px" }}
                    value="NoReturn"
                    aria-label="NoReturn"
                  >
                    NoReturn
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            <br />
            <div className="item_end">
              <button type="submit" className="item_buttonb">
                {isAddMode ? "Add" : "Update"}
              </button>
              &nbsp;&nbsp;&nbsp;
              <button className="item_buttona" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default ItemMasterPopup;
