import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import callApi from "../../../../ApiCall";
import Constants from "../../../../Constants";
import leaf1 from "../../../../assets/images/L1.png";
import "./Pricing.css";

const Pricing = () => {
  const [isINR, setIsINR] = useState(true);
  const [isMonthly, setIsMonthly] = useState(true);

  const [plansList, setPlansList] = useState([]);
  const navigate = useNavigate();
  const getPlans = async () => {
    const response = await callApi({
      apiURL: Constants.API.getPlans,
    });
    if (response?.success) {
      setPlansList(response.data.planList);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);
  return (
    <>
      <div>
        <div>
          <center>
            <h1 className="fourth-heading mt-24">Pricing</h1>
            <p className="fourth-text">
              Start monitoring for free and then choose your plan.
            </p>
          </center>
          <div className="option1">
            <div className="switch_">
              <main>
                <div className="_label">Monthly</div>
                <div className="switch-container">
                  <input
                    type="checkbox"
                    id="switch1"
                    className="switch-input"
                    onChange={(e) => setIsMonthly(!isMonthly)}
                    checked={!isMonthly}
                  />
                  <label htmlFor="switch1" className="switch-label">
                    <span className="switch-text" />
                    <span className="switch-text" />
                  </label>
                </div>
                <div className="_label">Annual</div>
              </main>
            </div>
            <div className="switch_1">
              <main>
                <div className="_label">INR</div>
                <div className="switch-container">
                  <input
                    type="checkbox"
                    id="switch2"
                    className="switch-input"
                    onChange={(e) => setIsINR(!isINR)}
                    checked={!isINR}
                  />
                  <label htmlFor="switch2" className="switch-label">
                    <span className="switch-text" />
                    <span className="switch-text" />
                  </label>
                </div>
                <div className="_label">USD</div>
              </main>
            </div>
          </div>
          <div className="subscription-cards">
            {plansList.map((plan) => (
              <div key={plan.PlanMasterId} className="subscription-card">
                <div>
                  <div className="firstcard1">
                    <h1 className="fourth-text">{plan.PlanName}</h1>
                    <img src={leaf1} />
                  </div>
                  <div>
                    <span className="inr">{isINR ? "INR " : "USD "}</span>
                    <span className="price">
                      {isINR
                        ? isMonthly
                          ? plan.MonthlyPriceINR
                          : plan.AnnualPriceINR
                        : isMonthly
                        ? plan.MonthlyPriceUSD
                        : plan.AnnualPriceUSD}
                    </span>
                    {plan.FreeTrialDays > 0 ? (
                      <span className="fourth-text">
                        {" / " + plan.FreeTrialDays + " Days"}
                      </span>
                    ) : (
                      <span className="fourth-text">
                        {isMonthly ? " / Month" : " / Year"}
                      </span>
                    )}
                  </div>
                  <ul>
                    {JSON.parse(plan.FeaturesList).map((feature, index) => (
                      <div className="flex ab ml-4">
                        <span className="mt-[4px]">
                          <AiOutlineCheck />
                        </span>
                        <span className="ml-2 text-[1rem]">
                          {feature.FeatureName}
                        </span>
                      </div>
                    ))}
                  </ul>
                </div>
                <div className="f-card-button1-container">
                  <button
                    className="f-card-button1"
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
